import axios from "axios";
import { configs } from "../constants";

export const signJWTToken = async (obj: any) => {
  const {
    data: { response },
  } = await axios.post(configs.urls.BASE_URL + `/token/sign`, { payload: obj });
  return response;
};

export const decodeJWTToken = async (token: string) => {
  const {
    data: { response },
  } = await axios.post(configs.urls.BASE_URL + `/token/decode`, {
    jwt: token,
  });
  return response;
};
