import React from "react";
import { Link } from "react-router-dom";
import { COLORS } from "../../constants/theme";

function PrimaryButton({
  onClick,
  style = {},
  className = "",
  children,
  isReactDomLink,
  to = {},
  disabled,
  loading,
}: any) {
  return isReactDomLink ? (
    <Link
      style={{
        backgroundColor: COLORS.greenPrimary,
        color: "white",
        width: "77%",
        fontWeight: "100",
        textDecoration: "none",
        ...style,
      }}
      to={to}
      className={"button " + className + (loading ? " is-loading" : "")}
    >
      {children}
    </Link>
  ) : (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: COLORS.greenPrimary,
        color: "white",
        width: "77%",
        fontWeight: "100",
        ...style,
      }}
      className={"button " + className + (loading ? " is-loading" : "")}
    >
      {children}
    </button>
  );
}

export default PrimaryButton;
