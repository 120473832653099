import { PaymentStatus } from "../constants/globalConstants";

export const getInvoiceStatus = (status: PaymentStatus) => {
  if (status === PaymentStatus.PartiallyPaid) {
    return "Partially Paid";
  } else if (status === PaymentStatus.Paid) {
    return "Paid";
  } else if (status === PaymentStatus.PendingAuthorization) {
    return "Pending Authorization";
  } else {
    return undefined;
  }
};
