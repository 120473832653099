export enum CategoryType {
  Payable = "Payable",
  Receivable = "Receivable",
}

export interface CreateCategoriesTemplatePayload {
  scenarioId: string;
  templateName: string;
  categories: {
    name: string;
    type: CategoryType;
  }[];
}

export interface CreateScenarioPayload {
  name: string;
  description?: string;
  scenarioId?: string;
  switchToNewScenario?: boolean;
}

export interface SwitchToScenarioParams {
  id: string;
}

export interface DeleteScenarioPayload {
  ids: string[];
}

export interface CheckoutSessionExistPayload {
  sessionId: string;
}

export interface TxnContactMapPayload {
  transactions: any[];
}

export interface DeleteTxnsPayload {
  ids: string[];
  bankId: string;
}

export interface CreateSnapshotPayload {
  cashflowScenarioId: string;
  snapshotId?: string;
  description: string;
}

export interface DeleteSnapshotPayload {
  ids: string[];
}

export interface NotificationSettingPayload {
  notificationStatus: boolean;
  frequencyValue: string;
}
