import { faCheck, faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useState, useEffect, useRef } from "react";
import { Select, Button, LoadingOverlay } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { CustomDrawer } from "../../../../../common/CustomDrawer";
import styled from "styled-components";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import { useAuth } from "../../../../../contexts";
import { getUserLS } from "../../../../../modules/localStorage";
import { Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import appTheme from "../../../../../constants/theme";
import { COLORS } from "../../../../../constants/theme";
import { useResizableColumns } from "../../../../../hooks/useResizableColumns";
import { configs } from "../../../../../constants";
import { showNotification } from "@mantine/notifications";
import AlertModal from "../../../../../common/AlertModal/AlertModal";

const bill_sample = require("../../../../../assets/files/bill_sample.csv");
const invoice_sample = require("../../../../../assets/files/invoice_sample.csv");

type InvoiceInterface = {
  name: string;
  amount_total: string;
  currency: string;
  issue_date: string;
  due_date: string;
  bill_no: string;
  description: string;
};

function UploadInvoiceInBulk({
  uploadInBulk,
  setUploadInBulk,
  title,
  recordType,
  contactType,
  cashflowType,
  getRecords,
  isBill,
}: any) {
  const user = getUserLS();

  const {
    recordsReceivable: _records,
    externalAccounts: _externalAccounts,
    selectedScenario,
  } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [invoicesList, setInvoicesList] = useState<any[]>([]);
  const [showList, setShowList] = useState<InvoiceInterface[]>([]);
  const [dateFormat, setDateFormat] = useState<string>("YYYY-MM-DD");

  const [isError, setIsError] = useState(false);

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    message: "",
  });

  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [widths, handleMouseDown] = useResizableColumns(
    headerRef,
    [100, 100, 100, 100, 100]
  );

  const COLUMNS = [
    { label: "Name" },
    { label: "Amount" },
    { label: "Currency" },
    { label: "Issue Date" },
    { label: "Due Date" },
    { label: isBill ? "Bill No." : "Invoice No." },
    { label: "Description" },
  ];

  const modalReset = () => {
    setModalState({
      open: false,
      title: "",
      message: "",
    });
  };

  const handleOnBack = () => {
    // setUploadInBulk(false);
    setInvoicesList([]);
    setShowList([]);
  };

  const handleOnClose = () => {
    setUploadInBulk(false);
    setInvoicesList([]);
    setShowList([]);
    setError(undefined);
  };

  const resetState = () => {
    if (isError) {
      setError(undefined);
    }
    if (isError && fileList.length > 0) {
      setFileList([]);
    }
  };

  const handleUpload = async (file: FileType[]) => {
    try {
      resetState();
      setLoading(true);
      if (!file || !file.length) return;
      setError(undefined);
      setFileList([file[file.length - 1]]);
    } catch (error) {
      console.log("Error inside handle upload: ", error);
    } finally {
      setLoading(false);
    }
  };

  function parseCSVLine(line: string): string[] {
    const fields = [];
    let currentField = "";
    let insideQuotedString = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === "," && !insideQuotedString) {
        fields.push(currentField);
        currentField = "";
      } else if (char === '"') {
        insideQuotedString = !insideQuotedString;
      } else {
        currentField += char;
      }
    }

    fields.push(currentField);

    return fields;
  }

  const padDateComponent = (component: string) => {
    return component.length === 1 ? `0${component}` : component;
  };

  const normalizeDate = (date: string, format: string) => {
    let normalizedDate = date;

    if (format.includes("/")) {
      const parts = date.split("/");
      if (parts.length === 3) {
        const [month, day, year] = parts;
        normalizedDate = `${padDateComponent(month)}/${padDateComponent(
          day
        )}/${year}`;
      } else {
        throw new Error(
          `Invalid date format: '${date}' does not match the expected format MM/DD/YYYY`
        );
      }
    } else if (format.includes("-")) {
      const parts = date.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        normalizedDate = `${year}-${padDateComponent(month)}-${padDateComponent(
          day
        )}`;
      } else {
        throw new Error(
          `Invalid date format: '${date}' does not match the expected format YYYY-MM-DD`
        );
      }
    }

    return normalizedDate;
  };

  const readCSVFile = async (file: File): Promise<InvoiceInterface[]> => {
    const reader = new FileReader();

    return new Promise<InvoiceInterface[]>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        setIsError(true);
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        const text = reader.result as string;
        // console.log("Raw CSV Data:", text); // Debug the raw CSV data
        const lines = text.split(/\r?\n/).filter((line) => line.trim() !== "");
        // console.log("Lines after splitting:", lines); // Debug the split lines

        const errors: any = [];
        const dateFields: string[] = [];

        lines.slice(1).forEach((line, index) => {
          const fields = parseCSVLine(line);

          const [
            name,
            amount_total,
            currency,
            issue_date,
            due_date,
            bill_no,
            description,
          ] = fields;

          if (
            !name ||
            !amount_total ||
            !currency ||
            !issue_date ||
            !due_date ||
            !bill_no ||
            !description
          ) {
            errors.push(`Missing required field(s) on line ${index + 1}`);
          }

          // Validate amount
          const isAmountValid = !isNaN(parseFloat(amount_total));
          if (!isAmountValid) {
            errors.push(
              `Invalid amount: '${amount_total}' on line ${index + 1}`
            );
          }

          try {
            const normalizedStartDate = normalizeDate(issue_date, dateFormat);
            const normalizedEndDate = normalizeDate(due_date, dateFormat);

            dateFields.push(normalizedStartDate, normalizedEndDate);

            const isValidStartDate = moment(
              normalizedStartDate,
              dateFormat,
              true
            ).isValid();
            const isValidEndDate = moment(
              normalizedEndDate,
              dateFormat,
              true
            ).isValid();

            if (!isValidStartDate) {
              errors.push(
                `Invalid start date format: '${issue_date}' on line ${
                  index + 1
                }`
              );
            }
            if (!isValidEndDate) {
              errors.push(
                `Invalid end date format: '${due_date}' on line ${index + 1}`
              );
            }

            if (isValidStartDate && isValidEndDate) {
              const startMoment = moment(normalizedStartDate, dateFormat, true);
              const endMoment = moment(normalizedEndDate, dateFormat, true);

              if (startMoment.isAfter(endMoment)) {
                errors.push(
                  `Start date '${issue_date}' is greater than end date '${due_date}' on line ${
                    index + 1
                  }`
                );
              }
            }
          } catch (error: any) {
            errors.push(`Error on line ${index + 1}: ${error.message}`);
          }
        });

        if (errors.length > 0) {
          setIsError(true);
          reject(new DOMException(errors.join("\n")));
          return;
        }

        const selectedFormat = dateFormat;
        const consistentFormat = dateFields.every((date) =>
          moment(date, selectedFormat, true).isValid()
        );

        if (!consistentFormat) {
          setIsError(true);
          reject(
            new DOMException(
              "Date format is inconsistent or does not match the selected format."
            )
          );
          return;
        }

        setIsError(false);

        const newShowList = lines.slice(1).map((line) => {
          const fields = parseCSVLine(line);
          return {
            name: fields[0],
            amount_total: fields[1],
            currency: fields[2],
            issue_date: fields[3],
            due_date: fields[4],
            bill_no: fields[5],
            description: fields[6],
          };
        });

        setShowList(newShowList);

        const billsOrInvoicesArray: any = newShowList.map((bill) => {
          return {
            name: bill.name,
            amount: bill.amount_total,
            currency: bill.currency,
            dueDate: moment(bill.due_date, dateFormat).format("YYYY-MM-DD"),
            issueDate: moment(bill.issue_date, dateFormat).format("YYYY-MM-DD"),
            description: bill.description,
            referenceNumber: bill.bill_no,
            amountPaid: "0",
            amountDue: bill.amount_total,
            type: cashflowType,
            tags: "",
            expectedDueDate: moment(bill.due_date, dateFormat).format(
              "YYYY-MM-DD"
            ),
            invoiceNumber: bill.bill_no,
            cashflowCategory: cashflowType,
          };
        });

        resolve(billsOrInvoicesArray);
      };

      reader.readAsText(file);
    });
  };

  const onSave = async () => {
    try {
      setLoading(true);
      axios.defaults.withCredentials = true;

      // console.log("Invoice List >>>> ", invoicesList);
      // return;

      if (invoicesList.length > 0) {
        const invalidInvoices = invoicesList.some(
          (inv) =>
            inv.issueDate === "Invalid date" || inv.dueDate === "Invalid date"
        );

        if (invalidInvoices) {
          setModalState({
            open: true,
            title: "Invalid date format!",
            message: "Please select valid date format.",
          });
          return;
        }
      }

      const response = await axios.post(
        configs.urls.BASE_URL + "/invoice/saveInBulk",
        { invoices: invoicesList, scenarioId: selectedScenario.id }
      );

      // console.log("On Save Response >>>> ", response);

      if (response.status === 200) {
        showNotification({
          color: "teal",
          message: "Records uploaded successfully.",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
        getRecords();
        setInvoicesList([]); // Clear invoicesList
        setShowList([]); // Clear showList
        setFileList([]);
        handleOnClose();
      }
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const onContinue = async () => {
    try {
      setLoading(true);
      const invoicesOrBills = await readCSVFile(fileList[0].blobFile as File);
      setError(undefined);
      setInvoicesList(invoicesOrBills);
      setLoading(false);
    } catch (error: any) {
      let errorMessage =
        error?.message ||
        "Invalid file format. Please upload a valid invoice file.";

      setError(errorMessage);
      setLoading(false);
      setFileList([]);
    }
  };

  useEffect(() => {
    console.log("invoicesList >>>>>> ", invoicesList);
  }, [invoicesList]);

  return (
    <CustomDrawer
      opened={uploadInBulk}
      onBack={() => {
        if (showList.length > 0) {
          handleOnBack();
        } else {
          handleOnClose();
        }
      }}
      onClose={handleOnClose}
      title={title}
    >
      <Container>
        {!isError && showList.length > 0 ? (
          <Fragment>
            <TableContainer className="custom-scrollbar">
              <Table>
                <thead>
                  <tr>
                    {COLUMNS.map((column, index) => (
                      <th
                        data-resizable
                        key={column.label}
                        style={{ width: `${widths[index]}px` }}
                        onMouseDown={(event) => handleMouseDown(event, index)}
                      >
                        {column.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <Body>
                  {showList.map((row: InvoiceInterface, i: number) => (
                    <tr key={i}>
                      {Object.keys(row).map((cell: string, j: number) => (
                        <td key={j}>{row[cell as keyof InvoiceInterface]}</td>
                      ))}
                    </tr>
                  ))}
                </Body>
              </Table>
            </TableContainer>
            <button
              onClick={onSave}
              style={{
                backgroundColor: COLORS.greenBlue,
                color: COLORS.white,
                width: "15rem",
              }}
              className={`button mv-6 is-bold is-small ${
                loading ? "is-loading" : ""
              }`}
            >
              Done
            </button>
          </Fragment>
        ) : (
          <Fragment>
            <Uploader
              action="//jsonplaceholder.typicode.com/posts/"
              accept=".csv"
              multiple={false}
              draggable
              autoUpload={false}
              listType="picture-text"
              onChange={handleUpload}
              fileList={fileList}
              removable={true}
              onRemove={() => {
                setFileList([]);
              }}
            >
              <CardButton
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "100px",
                }}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faUpload}
                    color={appTheme.COLORS.secondary}
                    size="3x"
                  />
                </div>
                <div>
                  <h5
                    style={{
                      color: appTheme.COLORS.secondary,
                      marginTop: "3%",
                    }}
                  >
                    Drag and drop a CSV file here
                  </h5>
                </div>
              </CardButton>
            </Uploader>
            {error ? <p style={{ color: "red" }}>{error}</p> : null}

            {fileList.length > 0 ? (
              <div>
                <ChooseDateFormat>
                  {/* radio choices */}
                  <p>
                    <b>Choose date format</b>
                  </p>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="yyyy-mm-dd"
                      name="date-format"
                      value="YYYY-MM-DD"
                      checked={dateFormat === "YYYY-MM-DD"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="yyyy-mm-dd">YYYY-MM-DD</label>
                  </div>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="mm-dd-yyyy"
                      name="date-format"
                      value="MM-DD-YYYY"
                      checked={dateFormat === "MM-DD-YYYY"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="mm-dd-yyyy">MM-DD-YYYY</label>
                  </div>

                  <div className="radio-group">
                    <input
                      type="radio"
                      id="mm/dd/yyyy"
                      name="date-format"
                      value="MM/DD/YYYY"
                      checked={dateFormat === "MM/DD/YYYY"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="mm/dd/yyyy">MM/DD/YYYY</label>
                  </div>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="dd/mm/yyyy"
                      name="date-format"
                      value="DD/MM/YYYY"
                      checked={dateFormat === "DD/MM/YYYY"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="dd/mm/yyyy">DD/MM/YYYY</label>
                  </div>
                </ChooseDateFormat>
                <button
                  onClick={onContinue}
                  style={{
                    backgroundColor: COLORS.greenBlue,
                    color: COLORS.white,
                    width: "15rem",
                  }}
                  className={`button mv-6 is-bold is-small ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Continue
                </button>
              </div>
            ) : (
              <div className="mt-5">
                Need a Sample CSV File? Download{" "}
                <a href={isBill ? bill_sample : invoice_sample} download>
                  here
                </a>
              </div>
            )}
          </Fragment>
        )}
        {modalState && (
          <>
            <AlertModal
              modalOpened={modalState.open}
              resetModal={modalReset}
              title={modalState.title}
              message={modalState.message}
            />
          </>
        )}
      </Container>
    </CustomDrawer>
  );
}

export default UploadInvoiceInBulk;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #707070;
`;

const CardButton = styled.button`
  border: 1px solid #707070;
  border-radius: 3px;
  background-color: white;
  color: #707070;
  font-size: 14px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #e5e5e5;
  }
`;

const TableContainer = styled.div`
  margin-top: 25px;
  height: 60vh;
  overflow-y: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
  }
`;

const Body = styled.tbody`
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ChooseDateFormat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 15px;

  .radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid ${COLORS.greenBlue};
    border-radius: 50%;
    outline: none;
    transition: all 0.3s ease;
  }

  input[type="radio"]:checked {
    background: ${COLORS.greenBlue};
    /* dot in */
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;

      transition: all 0.3s ease;
      opacity: 1;
    }
  }

  > p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;
