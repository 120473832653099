export const setOrganizationExistLS = (value: boolean) => {
  localStorage.setItem("exist", JSON.stringify(value));
};

export const getOrganizationExistLS = () => {
  let exist = localStorage.getItem("exist");

  if (exist === "false") {
    return false;
  }
  return true;
};

export const setUserLS = (payload: Record<string, any>) => {
  const user = localStorage.getItem("user");
  if (user) {
    const existingPayload = JSON.parse(user);
    const newPayload = { ...existingPayload, ...payload };
    localStorage.setItem("user", JSON.stringify(newPayload));
    return;
  }
  localStorage.setItem("user", JSON.stringify(payload));
};

export const getUserLS = () => {
  const user = localStorage.getItem("user");
  if (!user) {
    return {};
  } else {
    return JSON.parse(user);
  }
};

export const setSearchTextGetPaidLS = (value: string) => {
  localStorage.setItem("searchTextGetPaid", JSON.stringify(value));
};

export const getSearchTextGetPaidLS = () => {
  const result = localStorage.getItem("searchTextGetPaid") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};

export const removeSearchTextGetPaidLS = () =>
  localStorage.removeItem("searchTextGetPaid");

export const setSearchTextPayLS = (value: string) => {
  localStorage.setItem("searchTextPay", JSON.stringify(value));
};

export const getSearchTextPayLS = () => {
  const result = localStorage.getItem("searchTextPay") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};

export const removeSearchTextPayLS = () =>
  localStorage.removeItem("searchTextPay");

export const setSearchTextContactsLS = (value: string) => {
  localStorage.setItem("searchTextContacts", JSON.stringify(value));
};

export const getSearchTextContactsLS = () => {
  const result = localStorage.getItem("searchTextContacts") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};

export const removeSearchTextContactsLS = () =>
  localStorage.removeItem("searchTextContacts");

export const setSortByPayLS = (value: string) => {
  localStorage.setItem("sortByPay", JSON.stringify(value));
};

export const getSortByPayLS = () => {
  const result = localStorage.getItem("sortByPay") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};

export const removeSortByPayLS = () => localStorage.removeItem("sortByPay");

export const setSortByGetPaidLS = (value: string) => {
  localStorage.setItem("sortByGetPaid", JSON.stringify(value));
};

export const getSortByGetPaidLS = () => {
  const result = localStorage.getItem("sortByGetPaid") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};

export const removeSortByGetPaidLS = () =>
  localStorage.removeItem("sortByGetPaid");

export const setSortByContactsLS = (value: string) => {
  localStorage.setItem("sortByContacts", JSON.stringify(value));
};

export const getSortByContactsLS = () => {
  const result = localStorage.getItem("sortByContacts") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};

export const removeSortByContactsLS = () =>
  localStorage.removeItem("sortByContacts");

export const setFilterByPayLS = (value: string) => {
  localStorage.setItem("filterByPay", JSON.stringify(value));
};
export const getFilterByPayLS = () => {
  const result = localStorage.getItem("filterByPay") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};
export const removeFilterByPayLS = () => localStorage.removeItem("filterByPay");

export const setFilterByGetPaidLS = (value: string) => {
  localStorage.setItem("filterByGetPaid", JSON.stringify(value));
};
export const getFilterByGetPaidLS = () => {
  const result = localStorage.getItem("filterByGetPaid") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};
export const removeFilterByGetPaidLS = () =>
  localStorage.removeItem("filterByGetPaid");

export const setFilterByContactsLS = (value: string) => {
  localStorage.setItem("filterByContacts", JSON.stringify(value));
};
export const getFilterByContactsLS = () => {
  const result = localStorage.getItem("filterByContacts") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal;
};
export const removeFilterByContactsLS = () =>
  localStorage.removeItem("filterByContacts");

export const setContactsFilterPayLS = (value: string) => {
  localStorage.setItem("contactsFilterPay", JSON.stringify(value));
};

export const getContactsFilterPayLS = () => {
  const result = localStorage.getItem("contactsFilterPay") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal ? returnVal.split(",") : [];
};

/* -------- buckets stuff  START--------- */

export const setBucketsFilterPayLS = (value: string) => {
  localStorage.setItem("bucketsFilterPay", JSON.stringify(value));
};

export const getBucketsFilterPayLS = () => {
  const result = localStorage.getItem("bucketsFilterPay") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal ? returnVal.split(",") : [];
};
export const removeBucketsFilterPayLS = () =>
  localStorage.removeItem("bucketsFilterPay");

export const setBucketsFilterGetPaidLS = (value: string) => {
  localStorage.setItem("bucketsFilterGetPaid", JSON.stringify(value));
};

export const getBucketsFilterGetPaidLS = () => {
  const result = localStorage.getItem("bucketsFilterGetPaid") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal ? returnVal.split(",") : [];
};
export const removeBucketsFilterGetPaidLS = () =>
  localStorage.removeItem("bucketsFilterGetPaid");

/* -------- buckets stuff END --------- */

export const removeContactsFilterPayLS = () =>
  localStorage.removeItem("contactsFilterPay");

export const setContactsFilterGetPaidLS = (value: string) => {
  localStorage.setItem("contactsFilterGetPaid", JSON.stringify(value));
};
export const getContactsFilterGetPaidLS = () => {
  const result = localStorage.getItem("contactsFilterGetPaid") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal ? returnVal.split(",") : [];
};
export const removeContactsFilterGetPaidLS = () =>
  localStorage.removeItem("contactsFilterGetPaid");

export const setContactsFilterContactsLS = (value: string) => {
  localStorage.setItem("contactsFilterContacts", JSON.stringify(value));
};
export const getContactsFilterContactsLS = () => {
  const result = localStorage.getItem("contactsFilterContacts") || "";
  const returnVal = result.replace(/\"/g, "");
  return returnVal ? returnVal.split(",") : [];
};
export const removeContactsFilterContactsLS = () =>
  localStorage.removeItem("contactsFilterContacts");

export const getUserRole = () => {
  return localStorage.getItem("roleType");
};

export const isUserViewOnly = () => {
  return localStorage.getItem("roleType") === "viewer" ? true : false;
};

export const isShowDemoModal = () => {
  return localStorage.getItem("showDemoModal") === "true" ? true : false;
};

export const isStartTrial = () => {
  return localStorage.getItem("startTrial") === "true" ? true : false;
};
