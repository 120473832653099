import { cloneDeep, filter, map, compact } from "lodash";
import moment from "moment";
import { MENU } from "../../../../constants/globalConstants";
import {
  getLastMonthDates,
  getLastQuarterDates,
  getLastWeekDates,
  getLastYearDates,
  getThisMonthDates,
  getThisQuarterDates,
  getThisWeekDates,
  getThisYearDates,
} from "../../../../modules/getDates";
import { filterByDueDate } from "../common/FilterByDueDate";

export const filterRecords = (
  submenu: any,
  array: any[],
  contacts?: string[],
  mainmenu?: string,
  start_date: string = "",
  end_date: string = "",
  isModalOpen?: boolean
) => {
  let filteredRecords: any = cloneDeep(array);

  if (contacts?.length) {
    filteredRecords = filter(filteredRecords, ({ contact, displayName }) => {
      return contacts?.includes(contact?.displayName || displayName);
    });
  }
  if (
    (mainmenu === MENU.BY_TOP_CUSTOMER || mainmenu === MENU.BY_TOP_VENDOR) &&
    submenu !== MENU.DATE_RANGE
  ) {
    filteredRecords = filterByTopCustOrVendor(
      filteredRecords,
      mainmenu,
      submenu
    );
    return filteredRecords;
  }
  if (submenu === MENU.DATE_RANGE && !isModalOpen) {
    filteredRecords = filterByTopCustOrVendor(
      filteredRecords,
      mainmenu,
      submenu,
      moment(start_date).toISOString(),
      moment(end_date).toISOString()
    );
    return filteredRecords;
  }

  if (submenu === MENU.THIS_WEEK) {
    const { start, end } = getThisWeekDates();
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }
  if (submenu === MENU.THIS_MONTH) {
    const { start, end } = getThisMonthDates();
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }
  if (submenu === MENU.THIS_YEAR) {
    const { start, end } = getThisYearDates();
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }
  if (submenu === MENU.THIS_QUARTER) {
    const { start, end } = getThisQuarterDates();
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }

  if (submenu === MENU.LAST_WEEK) {
    const { start, end } = getLastWeekDates();
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }

  if (submenu === MENU.LAST_MONTH) {
    const { start, end } = getLastMonthDates();
    console.log({ start, end });
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }
  if (submenu === MENU.LAST_QUARTER) {
    const { start, end } = getLastQuarterDates();
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }
  if (submenu === MENU.LAST_YEAR) {
    const { start, end } = getLastYearDates();
    filteredRecords = filterByDueDate(filteredRecords, start, end);
  }
  return filteredRecords;
};

export const filterByTopCustOrVendor = (
  array: any[],
  mainmenu?: string,
  submenu?: string,
  start_date: string = "",
  end_date: string = ""
) => {
  let filteredRecords: any = cloneDeep(array);

  const type = mainmenu === MENU.BY_TOP_CUSTOMER ? "Customer" : "Vendor";
  let startDate = start_date;
  let endDate = end_date;
  if (submenu === MENU.THIS_YEAR) {
    const { start, end } = getThisYearDates();
    startDate = start;
    endDate = end;
  }
  if (submenu === MENU.THIS_MONTH) {
    const { start, end } = getThisMonthDates();
    startDate = start;
    endDate = end;
  }
  if (submenu === MENU.THIS_QUARTER) {
    const { start, end } = getThisQuarterDates();
    startDate = start;
    endDate = end;
  }
  if (submenu === MENU.THIS_WEEK) {
    const { start, end } = getThisWeekDates();
    startDate = start;
    endDate = end;
  }
  if (submenu === MENU.LAST_YEAR) {
    const { start, end } = getLastYearDates();
    startDate = start;
    endDate = end;
  }
  if (submenu === MENU.LAST_MONTH) {
    const { start, end } = getLastMonthDates();
    startDate = start;
    endDate = end;
  }
  if (submenu === MENU.LAST_QUARTER) {
    const { start, end } = getLastQuarterDates();
    startDate = start;
    endDate = end;
  }
  if (submenu === MENU.LAST_WEEK) {
    const { start, end } = getLastWeekDates();
    startDate = start;
    endDate = end;
  }

  console.log("here---");
  debugger;

  filteredRecords = compact(
    filteredRecords.map((filteredRecord: any) => {
      const { invoices = [] } = filteredRecord;
      const invoiceAmount = invoices.map(({ linkedTransactions = [] }: any) => {
        const amountList = map(
          linkedTransactions,
          ({ paidOrFailedAt, amountPaid }) => {
            const date = moment(paidOrFailedAt).toISOString();
            if (date >= startDate && date <= endDate) {
              return amountPaid;
            } else {
              return "0";
            }
          }
        );
        const totalAmountPaid = eval(amountList.join("+"));
        return totalAmountPaid || 0;
      });

      filteredRecord.totalAmountPaid = invoiceAmount.reduce(
        (a: any, b: any) => a + b,
        0
      );
      if (
        filteredRecord.totalAmountPaid <= 0 ||
        !filteredRecord?.types?.includes(type)
      ) {
        return undefined;
      } else {
        return filteredRecord;
      }
    })
  );
  return filteredRecords;
};
