import "../styles.css";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CardSubtilteSmall,
  CardTitleBlack,
} from "../../../../common/CardTitles";
import { contacts } from "../../../../constants/images";
import PrimaryButton from "../../../../common/PrimaryButton/PrimaryButton";
import axios from "axios";
import { configs } from "../../../../constants";
import { showNotification } from "@mantine/notifications";
import { useAuth } from "../../../../contexts";
import { decodeToken } from "react-jwt";
import { Button } from "rsuite";

function AcceptInvite() {
  const { user, setErrorInInvite } = useAuth();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [invalidUser, setInvalidUser] = useState(false);
  const [alreadyUsed, setAlreadyUsed] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [inviteDetails, setInviteDetails] = useState({
    userName: "",
    orgName: "",
  });

  const navigate = useNavigate();
  const location: any = useLocation();

  const {
    randomNumber: randomNumber,
    role: roleParam,
    organizationId: organizationIdParam,
    forUser: forUserIdParam,
  } = useParams();

  useEffect(() => {
    if (
      localStorage.getItem("invitationSignUp") &&
      localStorage.getItem("fromSignUp")
    ) {
      setIsSignUp(true);
      checkInvitationValidityAfterSignup();
    } else {
      setIsSignUp(false);
      checkInvitationValidity();
    }
  }, [location]);

  useEffect(() => {
    if (
      localStorage.getItem("invitationSignUp") &&
      localStorage.getItem("fromSignUp")
    ) {
      if (Number(forUserIdParam) !== user.id) {
        setInvalidUser(true);
      }
    } else if (forUserIdParam && user) {
      const decodedId = decodeToken(forUserIdParam);
      if (decodedId !== user.id) {
        setInvalidUser(true);
      }
    }
  }, [forUserIdParam, user]);

  const checkInvitationValidity = async () => {
    try {
      setLoading(true);
      const inviteLink =
        randomNumber +
        "/" +
        roleParam +
        "/" +
        organizationIdParam +
        "/" +
        forUserIdParam;

      const response = await axios.post(
        configs.urls.BASE_URL +
          `/invitation/check_invitation_validity/${inviteLink}`,
        {
          withCredentials: true,
        }
      );

      // console.log("RESPONSE >>> ", response);

      if (response.status === 200) {
        if (response.data.response.invitationIsValid === false) {
          setAlreadyUsed(true);
          return;
        }
        setInviteDetails({
          userName: response.data.response.invitaiton.senderUser.firstName,
          orgName: response.data.response.invitaiton.organization.name,
        });
      }
    } catch (error) {
      setErrorInInvite(true);
      console.log("Error checkInvitationValidity: ", error);
      showNotification({
        title: "Error",
        message: "Invalid invitation link",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const checkInvitationValidityAfterSignup = async () => {
    try {
      setLoading(true);
      const inviteLink =
        randomNumber +
        "/" +
        roleParam +
        "/" +
        organizationIdParam +
        "/" +
        forUserIdParam;

      const response = await axios.post(
        configs.urls.BASE_URL +
          `/invitation/check_invitation_validity_after_signup/${inviteLink}`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        if (response.data.response.invitationIsValid === false) {
          setAlreadyUsed(true);
          return;
        }
        setInviteDetails({
          userName: response.data.response.invitaiton.senderUser.firstName,
          orgName: response.data.response.invitaiton.organization.name,
        });
      }
    } catch (error) {
      setErrorInInvite(true);
      console.log("Error checkInvitationValidityAfterSignup: ", error);
      showNotification({
        title: "Error",
        message: "Invalid invitation link",
        color: "red",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptInvitation = async (reject: boolean) => {
    try {
      setLoading(true);
      const inviteLink =
        randomNumber +
        "/" +
        roleParam +
        "/" +
        organizationIdParam +
        "/" +
        forUserIdParam;

      const response = await axios.post(
        configs.urls.BASE_URL + `/invitation/accept_invitation/${inviteLink}`,
        {
          withCredentials: true,
          reject: reject,
          email: user.email,
          isSignUp: isSignUp,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        if (reject === true) {
          showNotification({
            title: "Invitation Rejected Successfully!",
            message: "You are not a part of this organization",
            color: "green",
          });
        } else {
          showNotification({
            title: "Invitation Accepted Successfully!",
            message: "Now you are part of this organization",
            color: "green",
          });
        }
      }
    } catch (error) {
      setErrorInInvite(true);
      if (reject) {
        console.log("Failed to reject: ", error);
        showNotification({
          title: "Error",
          message: "Failed to reject",
          color: "red",
        });
      } else {
        console.log("Failed to accept: ", error);
        showNotification({
          title: "Error",
          message: "Failed to accept",
          color: "red",
        });
      }
    } finally {
      setLoading(false);
      localStorage.removeItem("invitationSignUp");
      localStorage.removeItem("fromSignUp");
      navigate("/get-started");
    }
  };

  return (
    <Fragment>
      {invalidUser ? (
        <Fragment>
          <div
            className="currency-card"
            style={{
              padding: "10rem",
              marginLeft: "20rem",
              marginRight: "20rem",
            }}
          >
            <div
              style={{
                padding: "20px",
                borderRadius: "50%",
                border: "1px solid #fafafa",
                background: "#fafafa",
                boxShadow: "4px 10px 5px #eee",
                marginBottom: "5px",
              }}
            >
              <img
                style={{
                  width: "45px",
                  height: "45px",
                }}
                alt="currency_logo"
                src={contacts}
              />
            </div>
            <CardTitleBlack title={"Invalid Link!"} />
            <CardSubtilteSmall title="This invitation was intended for someone else." />

            <PrimaryButton
              className={`${loading && "is-loading"}`}
              style={{ width: "50%" }}
              onClick={() => {
                navigate("/cashflow?tab=forecast");
              }}
            >
              View Dashboard
            </PrimaryButton>
          </div>
        </Fragment>
      ) : alreadyUsed ? (
        <Fragment>
          <div
            className="currency-card"
            style={{
              padding: "10rem",
              marginLeft: "20rem",
              marginRight: "20rem",
            }}
          >
            <div
              style={{
                padding: "20px",
                borderRadius: "50%",
                border: "1px solid #fafafa",
                background: "#fafafa",
                boxShadow: "4px 10px 5px #eee",
                marginBottom: "5px",
              }}
            >
              <img
                style={{
                  width: "45px",
                  height: "45px",
                }}
                alt="currency_logo"
                src={contacts}
              />
            </div>

            <CardTitleBlack title={"Invitation Already Accepted!"} />
            <CardSubtilteSmall title="You have already accepted this invitation." />

            <PrimaryButton
              className={`${loading && "is-loading"}`}
              style={{ width: "50%" }}
              onClick={() => {
                navigate("/cashflow?tab=forecast");
              }}
            >
              View Dashboard
            </PrimaryButton>
          </div>
        </Fragment>
      ) : success ? (
        <div
          className="currency-card"
          style={{
            padding: "10rem",
            marginLeft: "20rem",
            marginRight: "20rem",
          }}
        >
          <div
            style={{
              padding: "20px",
              borderRadius: "50%",
              border: "1px solid #fafafa",
              background: "#fafafa",
              boxShadow: "4px 10px 5px #eee",
              marginBottom: "5px",
            }}
          >
            <img
              style={{
                width: "45px",
                height: "45px",
              }}
              alt="currency_logo"
              src={contacts}
            />
          </div>

          <CardTitleBlack title={"Invitation Accepted"} />
          <CardSubtilteSmall title="Congratulations! You are now part of this organization." />

          <PrimaryButton
            className={`${loading && "is-loading"}`}
            style={{ width: "50%" }}
            onClick={() => {
              navigate("/cashflow?tab=forecast");
            }}
          >
            View Dashboard
          </PrimaryButton>
        </div>
      ) : (
        <div
          className="currency-card"
          style={{
            padding: "10rem",
            marginLeft: "20rem",
            marginRight: "20rem",
          }}
        >
          <div
            style={{
              padding: "20px",
              borderRadius: "50%",
              border: "1px solid #fafafa",
              background: "#fafafa",
              boxShadow: "4px 10px 5px #eee",
              marginBottom: "5px",
            }}
          >
            <img
              style={{
                width: "45px",
                height: "45px",
              }}
              alt="currency_logo"
              src={contacts}
            />
          </div>

          <CardTitleBlack title={"Invitation"} />
          <CardSubtilteSmall
            title={`Accept invitation to join the ${inviteDetails?.userName}'s organization.`}
          />

          <PrimaryButton
            className={`${loading && "is-loading"}`}
            style={{ width: "50%" }}
            onClick={() => {
              let reject: boolean = false;
              handleAcceptInvitation(reject);
            }}
          >
            Accept Invite
          </PrimaryButton>
          <Button
            disabled={loading}
            className={`${loading && "is-loading"}`}
            style={{
              width: "50%",
              backgroundColor: "#fff",
              border: "1px solid #ced4da",
              color: "#000",
              marginTop: "10px",
            }}
            onClick={() => {
              let reject = true;
              handleAcceptInvitation(reject);
            }}
          >
            Reject Invite
          </Button>
        </div>
      )}
    </Fragment>
  );
}

export default AcceptInvite;
