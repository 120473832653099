import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group, HoverCard, List } from "@mantine/core";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { ICONS } from "../../../constants";
import { COLORS } from "../../../constants/theme";

const InfoIcon = forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} {...props}>
    <img
      style={{
        width: 24,
        height: 24,
      }}
      src={ICONS.info_icon}
      alt="info-icon"
    />
  </div>
));

function DetailsCardHeader({
  isViewOnly,
  hasButton,
  buttonTitle,
  buttonAction,
  title,
  buttonDisabled = false,
  hasBadge,
  renderButtons,
  titleInfo,
  setFullWidth,
}: any) {
  return (
    <div className="flex-direction-row-space-between mb-2">
      <div className="title is-4 is-flex is-flex-direction-row is-align-items-center">
        <span
          className="mr-2 title-span"
          style={{
            width: setFullWidth
              ? "100%"
              : title?.length > 15
              ? "80%"
              : undefined,
          }}
        >
          {title}
        </span>
        {titleInfo ? (
          <HoverCard width={400} position="top" withArrow shadow="lg">
            <HoverCard.Target>
              <InfoIcon />
            </HoverCard.Target>
            <HoverCard.Dropdown sx={{ pointerEvents: "none" }}>
              <Group>
                <List
                  icon={
                    <FontAwesomeIcon
                      className="mb-1"
                      icon={faDotCircle}
                      style={{
                        width: 10,
                        height: 10,
                        // position: "absolute",
                      }}
                    />
                  }
                >
                  <List.Item>
                    The “Expected Due Date” is different than the Due Date, it
                    allows you to change the date of any unpaid record so that
                    you can see how that impacts your cashflow.
                  </List.Item>
                  <List.Item>
                    The “Cashflow Category” helps you group records together to
                    simplify your cashflow
                  </List.Item>
                </List>
              </Group>
            </HoverCard.Dropdown>
          </HoverCard>
        ) : null}{" "}
      </div>

      {hasButton && renderButtons
        ? renderButtons()
        : hasButton && (
            <button
              disabled={buttonDisabled || isViewOnly}
              onClick={buttonAction}
              className="button is-small"
              style={{
                backgroundColor: "#2C6C76",
                color: COLORS.white,
                borderRadius: 8,
                height: 36,
              }}
            >
              {buttonTitle}
            </button>
          )}
    </div>
  );
}

export default DetailsCardHeader;

const PaidBadge = styled.div`
  border: 1px solid #2c6c76;
  color: #408180;
  height: fit-content;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
`;
