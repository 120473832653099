import {
  faArrowsAlt,
  faPlusSquare,
  faSave,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faPencilAlt,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Group,
  LoadingOverlay,
  Modal,
  TransferListItemComponent,
  TransferListItemComponentProps,
  TransferList,
  TransferListData,
  Text,
  TextInput,
  Anchor,
  Popover,
  Overlay,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { Button, IconButton, Input } from "rsuite";
import { configs } from "../../../constants";

const initialValues: any = [
  [
    {
      value: "receivables",
      label: "Receivables",
      collection: 0,
      editable: false,
    },
  ],
  [{ value: "payable", label: "Payable", collection: 1, editable: false }],
];

const EditCategory = ({
  initialValue,
  onCancel,
  handleSubCategorySave,
}: any) => {
  const [selectedCategory, setSelectedCategory] = useState(initialValue || "");
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubCategorySave(selectedCategory);
      }}
    >
      <Input value={selectedCategory} onChange={setSelectedCategory} required />

      <Group position="apart" style={{ marginTop: 15 }}>
        <Anchor component="button" color="gray" size="sm" onClick={onCancel}>
          Cancel
        </Anchor>
        <Button type="submit" size="sm">
          Save
        </Button>
      </Group>
    </form>
  );
};

function SortableList({ subCategories, setSubCategories }: any) {
  // const [cashIn, setCashIn] = useState({ text: "Cash In", editable: false });
  // const [cashOut, setCashOut] = useState({ text: "Cash Out", editable: false });

  // const [duplicateError, setDuplicateError] = useState(false);

  // const handleSortEnd = ({ oldIndex, newIndex }: any) => {
  //   setSubCategories((prvData) => {
  //     const moveData = prvData.splice(oldIndex, 1);
  //     const newData = [...prvData];
  //     newData.splice(newIndex, 0, moveData[0]);
  //     return newData;
  //   });
  // };

  // const handleSubCategoryChange = (index: number, value: string) => {
  //   setSubCategories((prev) => {
  //     const newData = [...prev];
  //     newData[index] = {
  //       ...newData[index],
  //       text: value,
  //     };
  //     return newData;
  //   });
  // };

  const handleAddNewCategory = (collection: number) => {
    setSubCategories((prev: any) => {
      const newData = [...prev];
      newData[collection].push({
        value: "new",
        label: "",
        collection,
        editable: true,
      });
      return newData;
    });
  };

  // const handleSubCategorySave = (index: number) => {
  //   setDuplicateError(false);
  //   //check if value already exists
  //   const valueFoundAtIndex = subCategories.findIndex(
  //     (elem) => elem.text === subCategories[index].text
  //   );
  //   if (index === valueFoundAtIndex) {
  //     setSubCategories((prev) => {
  //       if (index < prev.length && !prev[index].text.trim()) {
  //         return prev.filter((elem) => elem.text !== prev[index].text);
  //       } else {
  //         const newData = [...prev];

  //         newData[index] = {
  //           ...newData[index],
  //           editable: false,
  //         };
  //         return newData;
  //       }
  //     });
  //   } else {
  //     showNotification({
  //       color: "red",
  //       message: `Category already exists!`,
  //       icon: <FontAwesomeIcon icon={faTimes} />,
  //       autoClose: 2000,
  //     });
  //     setDuplicateError(true);
  //   }
  // };

  // const renderRemoveIcon = (text: string) => (
  //   <FontAwesomeIcon
  //     style={{ cursor: "pointer" }}
  //     icon={faTimesCircle}
  //     color="red"
  //     className="mr-4"
  //     onClick={() => {
  //       setSubCategories((prev) => {
  //         return prev.filter((elem) => elem.text !== text);
  //       });
  //     }}
  //   />
  // );

  // const renderTreeIcon = () => (
  //   <img
  //     src={ICONS.treeIcon}
  //     style={{
  //       width: 25,
  //       height: 10,
  //       marginBottom: 10,
  //       marginLeft: 5,
  //       marginRight: 5,
  //     }}
  //   />
  // );

  const renderAddIcon = (collection: number) => (
    <FontAwesomeIcon
      style={{ cursor: "pointer", marginTop: 2 }}
      className="ml-2"
      color="green"
      icon={faPlusSquare}
      onClick={() => {
        handleAddNewCategory(collection);
      }}
    />
  );

  // const checkIfOnlyItemInCollection = (collection: string) => {
  //   return (
  //     subCategories.filter((value) => value.collection === collection)
  //       .length === 1
  //   );
  // };
  // const checkIfLastItemInCollection = (index: number) => {
  //   return (
  //     index ===
  //     subCategories.filter((value) => value.collection === "cashIn").length - 1
  //   );
  // };

  const handleToggleEditable = (data: any) => {
    setSubCategories((prev: any) => {
      const newData = [...prev];
      newData[data.collection] = newData[data.collection].map((elem: any) => {
        if (elem.value === data.value) {
          return {
            ...elem,
            editable: !elem.editable,
          };
        } else {
          return elem;
        }
      });
      return newData;
    });
  };

  const handleSubCategorySave = (data: any, value: any) => {
    const categoryFound1 = subCategories[0].find(
      (elem: any) => elem.label === value
    );
    const categoryFound2 = subCategories[1].find(
      (elem: any) => elem.label === value
    );

    if (categoryFound1 || categoryFound2) {
      showNotification({
        color: "red",
        message: `Category already exists!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    } else {
      setSubCategories((prev: any) => {
        const newData = [...prev];
        newData[data.collection] = newData[data.collection].map(
          (elem: any, index: number) => {
            if (elem.value === data.value) {
              return {
                ...elem,
                label: value.replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) =>
                  letter.toUpperCase()
                ),
                value: value.toLowerCase().replace(/\s/g, "") + index,
                editable: false,
              };
            } else {
              return elem;
            }
          }
        );
        return newData;
      });
    }
  };

  const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => {
    return (
      <Group noWrap>
        <Checkbox
          checked={data.editable ? false : selected}
          onChange={() => {}}
          sx={{ pointerEvents: "none" }}
        />
        <div
          className="flex-direction-row-space-between mt-1"
          style={{ flex: 1 }}
        >
          <Text size="sm" weight={500}>
            {data.label}
          </Text>
          <Group>
            <Popover
              trapFocus
              closeOnClickOutside={true}
              // styles={{
              //   dropdown: {
              //     zIndex: 999,
              //   },
              // }}
              withArrow
              opened={data.editable}
              onClose={() => {
                handleToggleEditable(data);
              }}
              position="bottom"
              transition="pop-top-right"
              shadow="xl"
            >
              <Popover.Target>
                <IconButton
                  onClick={() => {
                    handleToggleEditable(data);
                  }}
                  size="xs"
                  icon={
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faPencilAlt}
                      color="blue"
                    />
                  }
                />
              </Popover.Target>
              <Popover.Dropdown title="Edit">
                <EditCategory
                  initialValue={data.label}
                  handleToggleEditable={() => handleToggleEditable(data)}
                  handleSubCategorySave={(selectedCategory: any) =>
                    handleSubCategorySave(data, selectedCategory)
                  }
                  onCancel={() => {
                    if (data.label === "") {
                      setSubCategories((prev: any) => {
                        const newData = [...prev];
                        newData[data.collection] = newData[
                          data.collection
                        ].filter((elem: any) => elem.value !== data.value);
                        return newData;
                      });
                    }
                    handleToggleEditable(data);
                  }}
                />
              </Popover.Dropdown>
            </Popover>

            <IconButton
              onClick={() => {
                setSubCategories((prev: any) => {
                  const newData = [...prev];
                  newData[data.collection] = newData[data.collection].filter(
                    (elem: any) => elem.value !== data.value
                  );
                  return newData;
                });
              }}
              size="xs"
              icon={<FontAwesomeIcon icon={faTrashAlt} color="red" />}
            />
          </Group>
        </div>
      </Group>
    );
  };

  return (
    <div>
      <div className="flex-direction-row-space-between">
        <div style={{ width: "48%" }} className="flex-direction-row">
          <h5>Cash In</h5>
          {renderAddIcon(0)}
        </div>
        <div style={{ width: "48%" }} className="flex-direction-row">
          <h5>Cash Out</h5>
          {renderAddIcon(1)}
        </div>
      </div>
      <TransferList
        value={subCategories}
        onChange={setSubCategories}
        searchPlaceholder="Search..."
        nothingFound="Nothing here"
        itemComponent={ItemComponent}
        listComponent="div"
      />
    </div>
  );
}

function EditLayout({
  showModal,
  setShowModal,
  onCloseModal,
  onCompleted,
}: any) {
  const [modalLoading, setModalLoading] = useState(false);
  const [subCategories, setSubCategories] = useState(initialValues);

  useEffect(() => {
    //getReportLayout
    axios.defaults.withCredentials = true;
    axios.get(configs.urls.BASE_URL + "/getReportLayout").then((res) => {
      if (res.data !== "error") {
        const data = res.data;
        if (data.length === 0) {
          setSubCategories(initialValues);
        } else {
          const cashIn: any[] = [];
          const cashOut: any[] = [];
          data.forEach((elem: any) => {
            if (elem.name === "Receivable") {
              cashIn.push({
                value: elem.value.toLowerCase().replace(/\s/g, ""),
                label: elem.value,
                editable: false,
                collection: 0,
              });
            } else {
              cashOut.push({
                value: elem.value.toLowerCase().replace(/\s/g, ""),
                label: elem.value,
                editable: false,
                collection: 1,
              });
            }
          });

          setSubCategories([cashIn, cashOut]);
        }
      }
    });
  }, [showModal]);

  const handleSaveCategories = () => {
    setModalLoading(true);

    axios.defaults.withCredentials = true;
    axios
      .post(configs.urls.BASE_URL + "/saveReportLayout", {
        layout: subCategories,
      })
      .then(
        (res: AxiosResponse) => {
          if (res.data === "success") {
            setTimeout(() => {
              showNotification({
                color: "green",
                message: "Saved!",
                icon: <FontAwesomeIcon icon={faCheck} />,
                autoClose: 2000,
              });
              onCompleted();
            }, 500);
          } else {
            showNotification({
              color: "red",
              message: "Error saving settings!",
              icon: <FontAwesomeIcon icon={faTimes} />,
              autoClose: 2000,
            });
          }
        },
        (err) => {
          showNotification({
            color: "red",
            message: `${err}`,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      )
      .finally(() => {
        setTimeout(() => {
          setModalLoading(false);
          setShowModal(false);
        }, 500);
      });
  };

  return (
    <Modal
      opened={showModal}
      onClose={() => {
        setShowModal(false);
        onCloseModal();
      }}
      size="lg"
      title="Edit Layout"
      overflow="inside"
      overlayBlur={2}
    >
      <LoadingOverlay overlayOpacity={0.8} visible={modalLoading} />

      <SortableList
        subCategories={subCategories}
        setSubCategories={setSubCategories}
      />
      <div
        className="mt-3 flex-direction-row"
        style={{ justifyContent: "flex-end" }}
      >
        <Button
          appearance="default"
          onClick={() => {
            setShowModal(false);
            onCloseModal();
          }}
          style={{ marginRight: "1rem" }}
        >
          Cancel
        </Button>
        <Button
          appearance="primary"
          onClick={() => {
            handleSaveCategories();
          }}
        >
          Save Changes
        </Button>
      </div>
    </Modal>
  );
}

export default EditLayout;
