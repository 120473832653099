import React, { PropsWithChildren } from "react";
import styled from "styled-components";

function AbsolutelyCentered({ children }: PropsWithChildren<{}>) {
  return <Centered>{children}</Centered>;
}

export default AbsolutelyCentered;

const Centered = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
