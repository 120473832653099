export const getFormattedTime = (ms: number) => {
  let seconds = ms / 1000;
  if (seconds < 60) {
    return `${seconds} seconds`;
  } else if (seconds < 3600) {
    return `${Math.floor(seconds / 60)} minutes`;
  } else {
    return `${Math.floor(seconds / 3600)} hours`;
  }
};
