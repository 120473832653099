import { Field, Formik } from "formik";
import React, { Fragment, useState } from "react";
import styled, { css } from "styled-components";
import { ScrollToFormikFieldError } from "../../../../common/ScrollToFormikFieldError/ScrollToFormikFieldError";
import { LoadingPlaceholders } from "../../../../common/LoadingPlaceholders";
import { IconButton } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../../../constants/theme";
import _ from "lodash";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface Props {
  selectedProfile: any;
  withEditButton: boolean;
  fetchUser: () => void;
  trialHasExpired: boolean;
}

export const AccountDetails: React.FC<Props> = ({
  selectedProfile,
  withEditButton = true,
  fetchUser = () => {},
  trialHasExpired,
}) => {
  const [editable, setEditable] = useState<boolean>(false);

  const FormItem = ({
    name,
    label,
    detailsWidth100,
    formik: { errors, touched, values },
    disabled,
  }: any) => {
    return (
      <Fragment>
        {_.isEmpty(selectedProfile) ? (
          <LoadingPlaceholders.Details
            style={{ marginTop: 15 }}
            detailsWidth={detailsWidth100 ? "100%" : undefined}
          />
        ) : (
          <InputContainer>
            <Label>{label}</Label>
            {!editable &&
            (_.isEmpty(values[name]) ||
              (typeof values[name] === "string" && !values[name].trim())) ? (
              <i style={{ fontSize: 12 }}>No {label.toLowerCase()}.</i>
            ) : (
              <Fragment>
                <Input
                  disabled={!editable || disabled}
                  name={name}
                  error={errors[name] && touched[name]}
                />
                {errors[name] && touched[name] && (
                  <FieldError
                    dangerouslySetInnerHTML={{
                      __html: errors[name],
                    }}
                  />
                )}
              </Fragment>
            )}
          </InputContainer>
        )}
      </Fragment>
    );
  };
  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          ...selectedProfile,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          //   saveProfile(values, setSubmitting);
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          values,
        }: /* and other goodies */
        any) => {
          console.log("VALUEEESSSSSSSS >>>> ", values.trialDaysRemaining);
          return (
            <div>
              <ScrollToFormikFieldError />
              <Fragment>
                <FormItem
                  formik={{ errors, touched, values }}
                  name="accountType"
                  label="Account type"
                  detailsWidth100
                />
                <FormItem
                  formik={{ errors, touched, values }}
                  name="companiesUnderManagement"
                  label="Companies under management"
                  detailsWidth100
                />
                <FormItem
                  formik={{ errors, touched, values }}
                  name="tier"
                  label="Current company tier"
                  detailsWidth100
                />
                <FormItem
                  formik={{ errors, touched, values }}
                  name="noOfScenarios"
                  label="Number of scenarios"
                  detailsWidth100
                />
                <FormItem
                  formik={{ errors, touched, values }}
                  name="invitedUsers"
                  label="Invited users"
                  detailsWidth100
                />
                {!trialHasExpired && (
                  <FormItem
                    formik={{ errors, touched, values }}
                    name="trialDaysRemaining"
                    label="Remaining trial days"
                    detailsWidth100
                  />
                )}
              </Fragment>
              {withEditButton &&
                (editable ? (
                  <div style={{ position: "absolute", right: 20, top: 20 }}>
                    <IconButton
                      appearance="default"
                      onClick={() => setEditable(false)}
                      icon={<FontAwesomeIcon icon={faTimes} />}
                      style={{ marginRight: 5 }}
                    />
                    <button
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="button is-small"
                      style={{
                        backgroundColor: "#2C6C76",
                        color: COLORS.white,
                        borderRadius: 8,
                        width: 132,
                        height: 36,
                      }}
                    >
                      {isSubmitting ? "Saving..." : "Save"}
                    </button>
                  </div>
                ) : (
                  <button
                    disabled={_.isEmpty(selectedProfile)}
                    className="button is-small"
                    style={{
                      backgroundColor: "#2C6C76",
                      color: COLORS.white,
                      borderRadius: 8,
                      width: 132,
                      height: 36,
                      position: "absolute",
                      right: 20,
                      top: 20,
                    }}
                    onClick={() => setEditable(true)}
                  >
                    Edit
                  </button>
                ))}
            </div>
          );
        }}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  position: relative;
  margin-top: 18px;
  font-weight: 700;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled(Field)`
  height: 40px;
  width: 100%;
  border: none;
  font-size: 24px;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 500;
  background-color: #eeee;
  ${(props) =>
    props.error &&
    css`
      border-color: red;
      color: red;
    `}
  ${(props) =>
    props.disabled &&
    css`
      border-bottom: none;
    `}
  :disabled {
    cursor: text;
    background-color: #fff;

    @media screen and (max-width: 1600px) {
      font-size: 20px;
    }
  }
`;

const FieldError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;
