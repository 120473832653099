import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  showNotification,
  updateNotification as updateNotificationMantine,
} from "@mantine/notifications";

type NotificationProps = {
  success?: string | false | undefined;
  error?: string | false | undefined;
  onSuccess?: () => void;
  onError?: () => void;
};

export const notify = ({
  success,
  error,
  onSuccess = () => {},
  onError = () => {},
}: NotificationProps) => {
  if (success) {
    showNotification({
      title: "Success",
      message: success,
      color: "teal",
      icon: <FontAwesomeIcon icon={faCheck} />,
    });
    onSuccess();
  } else {
    showNotification({
      title: "Error",
      message: error,
      color: "red",
      icon: <FontAwesomeIcon icon={faTimes} />,
    });
    onError();
  }
};

type AddNotificationProps = {
  title: string;
  message: string;
  isLoading?: boolean;
  closable?: boolean;
  id: string;
  error?: boolean;
};

export const addNotification = ({
  message,
  title,
  isLoading = false,
  closable = true,
  id,
}: AddNotificationProps) => {
  showNotification({
    id: id,
    title: title,
    message: message,
    color: "blue",
    loading: isLoading,
    disallowClose: !closable,
    autoClose: false,
  });
};

export const updateNotification = ({
  id,
  message,
  title,
  isLoading = false,
  closable = true,
  error = false,
}: AddNotificationProps) => {
  updateNotificationMantine({
    id: id,
    title: title,
    message: message,
    color: error ? "red" : "teal",
    icon: <FontAwesomeIcon icon={error ? faTimes : faCheck} />,
    loading: isLoading,
    disallowClose: !closable,
  });
};
