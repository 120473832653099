const prod = {
  urls: {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    LEAN_URL: "https://api.leantech.me",
    // LEAN_URL: "https://sandbox.leantech.me",
  },
};
const dev = {
  urls: {
    BASE_URL: "http://localhost:4000/api",
    // LEAN_URL: "https://api.leantech.me",
    LEAN_URL: "https://sandbox.leantech.me",
  },
};

export const configs =
  process.env.REACT_APP_NODE_ENV === "development" ? dev : prod;

export const LEAN_APP_TOKEN = process.env.REACT_APP_LEAN_TOKEN || "";

export const IS_LEAN_SANDBOX = process.env.REACT_APP_NODE_ENV === "development";
// export const IS_LEAN_SANDBOX = true;
// process.env.REACT_APP_NODE_ENV === "develoopment";
