import {
  faCalendarAlt,
  faCheck,
  faChevronDown,
  faPencilAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Modal, Select } from "@mantine/core";
import { Calendar, DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import RecordsTable from "../Documents/components/RecordsTable";
import { appTheme, DUMMY_DATA } from "../../constants";
import { Checkbox, Table } from "rsuite";
import useHttp from "../../hooks/useHttp";
import moment from "moment";
import CashForecastReport from "./components/CashForecastReport";

const { HeaderCell, Cell, Column } = Table;
const CompactHeaderCell = (props: any) => (
  <HeaderCell
    {...props}
    style={{
      backgroundColor: appTheme.COLORS.primary,
      color: appTheme.COLORS.white,
    }}
  />
);

function Payments() {
  const [showEditModal, setShowEditModal] = useState(false);

  //----------------------------------------------//

  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === DUMMY_DATA.TABLE_DATA.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (
    checkedKeys.length > 0 &&
    checkedKeys.length < DUMMY_DATA.TABLE_DATA.length
  ) {
    indeterminate = true;
  }

  const handleCheckAll = (_: any, checked: any) => {
    const keys = checked ? DUMMY_DATA.TABLE_DATA.map((item) => item.id) : [];
    setCheckedKeys(keys);
  };
  const handleCheck = (value: any, checked: any) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
  };

  const CheckCell = ({
    rowData,
    onChange,
    checkedKeys,
    dataKey,
    ...props
  }: any) => (
    <Cell {...props} style={{ padding: 0 }}>
      <Checkbox
        style={{ top: -8 }}
        value={rowData[dataKey]}
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </Cell>
  );

  //---------------------------------------------//

  return (
    <>
      {/* Modal */}
      <div style={{ overflowY: "hidden" }}>
        <Modal
          centered
          size="xl"
          opened={showEditModal}
          onClose={() => setShowEditModal(false)}
          withCloseButton={true}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h5>Edit Event Details Below</h5>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Calendar style={{ margin: 25 }} />
              <div style={{ margin: 25, marginLeft: 100, paddingRight: "8%" }}>
                <div>
                  <p style={{ marginBottom: 8 }}>Title</p>
                  <Input placeholder="Title" variant="filled" />
                </div>
                <p style={{ marginBottom: 8, marginTop: 20 }}>Frequency</p>
                <Select
                  variant="filled"
                  placeholder="Frequency"
                  rightSection={<FontAwesomeIcon icon={faChevronDown} />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={DUMMY_DATA.SELECT_FREQUENCY}
                />{" "}
                <p style={{ marginBottom: 8, marginTop: 20 }}>Reminder</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Select
                    variant="filled"
                    placeholder="30"
                    rightSection={<FontAwesomeIcon icon={faChevronDown} />}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    data={["1", "2", "3", "4", "5", "6", "7"]}
                  />

                  <Select
                    style={{ marginLeft: 20 }}
                    variant="filled"
                    placeholder="Day"
                    rightSection={<FontAwesomeIcon icon={faChevronDown} />}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    data={["Day", "Week", "Month", "Year"]}
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => setShowEditModal(false)}
              style={{
                alignSelf: "center",
                padding: "10px 0px",
                width: "30%",
                marginTop: "18px",
                backgroundColor: appTheme.COLORS.btnPrimary,
                color: "white",
              }}
            >
              SUBMIT
            </button>
          </div>
        </Modal>
      </div>
      <section
        style={{
          marginLeft: 30,
          marginTop: 25,
        }}
      >
        <div className="hero-body">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "4rem",
            }}
          >
            <div className="page-main-title">Cash Forecasting </div>
          </div>

          <CashForecastReport />
          <div>
            <div
              style={{
                color: appTheme.COLORS.subtitle,
                fontWeight: "400",
                fontSize: "18px",
                marginBottom: "2%",
              }}
            >
              Coming Up
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "2%",
                flexWrap: "wrap",
              }}
            >
              {DUMMY_DATA.EVENT_CARD_DATA.map((event, index) => (
                <div
                  className="card"
                  style={{
                    marginRight: 25,
                    padding: "23px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 25,
                  }}
                >
                  <div style={{ fontWeight: "600", marginBottom: 10 }}>
                    {event.company}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        width: "35px",
                        height: "35px",
                        color: appTheme.COLORS.secondary,
                        marginRight: "15px",
                      }}
                      icon={faCalendarAlt}
                    />
                    <div>
                      <div style={{ fontSize: "21px", fontWeight: "300" }}>
                        {event.date}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: appTheme.COLORS.gray2,
                        }}
                      >
                        at {event.time}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      color: appTheme.COLORS.gray2,
                    }}
                  >
                    Reminder: {event.reminderBefore} before
                  </div>
                  <button
                    onClick={() => setShowEditModal(true)}
                    style={{
                      alignSelf: "center",
                      padding: "14px 0px",
                      width: "100%",
                      marginTop: "18px",
                      backgroundColor: appTheme.COLORS.btnPrimary,
                      color: "white",
                    }}
                  >
                    EDIT
                  </button>
                </div>
              ))}
            </div>

            <div className="card">
              {/* // * Header Form */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 20,
                }}
              >
                <button
                  disabled={!checked && !indeterminate}
                  onClick={() => {
                    showNotification({
                      message: checkedKeys.length + " item(s) deleted!",
                      color: "teal",
                      icon: <FontAwesomeIcon icon={faCheck} />,
                    });
                  }}
                  style={{
                    alignSelf: "center",
                    padding: "8px 0px",
                    width: "15%",
                    marginTop: "18px",
                    marginRight: 10,
                    backgroundColor:
                      !checked && !indeterminate
                        ? appTheme.COLORS.btnDisabled
                        : appTheme.COLORS.btnDelete,
                    color: "white",
                    flex: 1,
                  }}
                >
                  DELETE
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flex: 3,
                  }}
                >
                  <div style={{ flex: 1, marginRight: 20 }}>
                    <p style={{ marginBottom: 8 }}>Due date</p>
                    <div>
                      <DatePicker
                        variant="filled"
                        placeholder="Pick date"
                        rightSection={<FontAwesomeIcon icon={faCalendarAlt} />}
                      />
                    </div>
                  </div>

                  <div style={{ flex: 1, marginRight: 20 }}>
                    <p style={{ marginBottom: 8 }}>Frequency</p>
                    <div>
                      <Select
                        variant="filled"
                        placeholder="Frequency"
                        rightSection={<FontAwesomeIcon icon={faChevronDown} />}
                        rightSectionWidth={30}
                        styles={{ rightSection: { pointerEvents: "none" } }}
                        data={DUMMY_DATA.SELECT_FREQUENCY}
                      />
                    </div>
                  </div>

                  <div style={{ width: "30%", flex: 1 }}>
                    <p style={{ marginBottom: 8 }}>Reminder</p>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Select
                          variant="filled"
                          placeholder="1"
                          rightSection={
                            <FontAwesomeIcon icon={faChevronDown} />
                          }
                          styles={{ rightSection: { pointerEvents: "none" } }}
                          data={["1", "2", "3", "4", "5", "6", "7"]}
                        />

                        <Select
                          style={{ marginLeft: 20 }}
                          variant="filled"
                          placeholder="Day"
                          rightSection={
                            <FontAwesomeIcon icon={faChevronDown} />
                          }
                          styles={{ rightSection: { pointerEvents: "none" } }}
                          data={["Day", "Week", "Month", "Year"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Search */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  padding: 20,
                }}
              >
                <div style={{ width: "70%" }}>
                  <p style={{ marginBottom: 8 }}>Search</p>
                  <Input placeholder="Type name, email address, or company name" />
                </div>
                <button
                  style={{
                    alignSelf: "flex-end",
                    padding: "8px 0px",
                    width: "25%",
                    marginTop: "18px",
                    backgroundColor: "#001736",
                    color: "white",
                  }}
                >
                  ADD A NEW EVENT
                </button>
              </div>

              {/* Table */}
              <Table
                hover
                virtualized
                headerHeight={100}
                height={400}
                data={DUMMY_DATA.TABLE_DATA}
                onRowClick={(data) => {
                  console.log(data);
                }}
              >
                <Column verticalAlign="middle" align="center" width={60} fixed>
                  <CompactHeaderCell>
                    <Checkbox
                      checked={checked}
                      indeterminate={indeterminate}
                      onChange={handleCheckAll}
                      // value={rowData[dataKey]}
                      // onChange={onChange}
                      // checked={checkedKeys.some(
                      //   (item) => item === rowData[dataKey]
                      // )}
                    />
                  </CompactHeaderCell>
                  <CheckCell
                    dataKey="id"
                    checkedKeys={checkedKeys}
                    onChange={handleCheck}
                  />
                </Column>

                <Column verticalAlign="middle" align="center" flexGrow={1}>
                  <CompactHeaderCell>Title</CompactHeaderCell>
                  <Cell dataKey="title" />
                </Column>

                <Column verticalAlign="middle" align="center" flexGrow={1}>
                  <CompactHeaderCell>Due Date</CompactHeaderCell>
                  <Cell dataKey="dueDate" />
                </Column>

                <Column verticalAlign="middle" align="center" flexGrow={1}>
                  <CompactHeaderCell>Frequency</CompactHeaderCell>
                  <Cell dataKey="frequency" />
                </Column>

                <Column verticalAlign="middle" align="center" flexGrow={1}>
                  <CompactHeaderCell>Actions</CompactHeaderCell>
                  <Cell>
                    <div>
                      <FontAwesomeIcon
                        onClick={() => setShowEditModal(true)}
                        size="2x"
                        color="white"
                        style={{
                          backgroundColor: appTheme.COLORS.btnPrimary,
                          padding: 5,
                          marginRight: 8,
                          cursor: "pointer",
                        }}
                        icon={faPencilAlt}
                      />
                      <FontAwesomeIcon
                        onClick={() => {
                          showNotification({
                            message: "Item deleted!",
                            color: "teal",
                            icon: <FontAwesomeIcon icon={faCheck} />,
                          });
                        }}
                        size="2x"
                        color="white"
                        style={{
                          backgroundColor: appTheme.COLORS.red,
                          padding: 5,
                          cursor: "pointer",
                        }}
                        icon={faTrashAlt}
                      />
                    </div>
                  </Cell>
                </Column>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Payments;
