import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/theme";

function HelpFooter() {
  return (
    <Container>
      <Title>Don’t know details?</Title>
      <Subtitle>
        Click{" "}
        <Button
          style={{ color: COLORS.greenBlue }}
          className="button is-ghost small p-0 pr-2 is-bold"
          onClick={() => alert("Hold your horses! We're working on this.")}
          type="button"
        >
          here
        </Button>
        to email your contact to fill their details out and get paid.
      </Subtitle>
    </Container>
  );
}

export default HelpFooter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-top: 1.5rem;
`;

const Title = styled.h4`
  font-size: 14px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: ${COLORS.brownishGrey};

  a {
    color: ${COLORS.greenBlue};
  }
`;

const Button = styled.button`
  color: ${COLORS.greenBlue};
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 23px;
  /* no active styles */
  &:focus {
    background: none;
    outline: none;
    border: none;
  }
`;
