import "./styles.css";
import React from "react";

interface CardSubTitlePropType {
  title: string;
}

function CardSubTitle({ title }: CardSubTitlePropType) {
  return (
    <p
      className="card-subtitle-heading"
      dangerouslySetInnerHTML={{ __html: title }}
    ></p>
  );
}

export default CardSubTitle;
