import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  margin: 8px;
  margin-top: 18px;
  flex: 1;
`;
export const InputLabel = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 18px;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

export const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
    margin-left: 6px;
  }
`;
