import "./styles.css";

import { Progress } from "@mantine/core";
import { useLocation } from "react-router-dom";

const routes = [
  "/tell-us-about-yourself",
  "/tell-us-about-your-business",
  "/where-is-it-located",
];

export const ProgressBar = () => {
  const location = useLocation();

  const getProgressBarValue = () => {
    const currentRoute = location.pathname;
    const routeIndex = routes.findIndex((route) => route === currentRoute);
    if (routeIndex === 0) {
      return 1;
    }

    if (routeIndex > -1) {
      const part = 100 / routes.length;
      return part * routeIndex;
    }

    return 10;
  };

  return (
    <div className="progress-bar-container">
      <Progress
        value={getProgressBarValue()}
        size={24}
        radius="xl"
        color={"#408180"}
      />
    </div>
  );
};
