import React from "react";

function BlankRow({ message = "" }: { message?: string }) {
  return (
    <tr>
      <td
        style={{
          border: "none",
          display: "data-cell",
          verticalAlign: "middle",
        }}
        className="expandable-table-row pl-6 "
      >
        {message}
      </td>
    </tr>
  );
}

export default BlankRow;
