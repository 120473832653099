import React, { useState } from "react";

interface Step {
  component: JSX.Element;
  getNextStep: (data: any) => string | null;
}

const steps: { [key: string]: Step } = {
  step1: {
    component: (
      <div
        style={{ width: "400px", height: "400px", backgroundColor: "red" }}
      ></div>
    ),
    getNextStep: (data: any) => "step2a",
  },
  step2a: {
    component: (
      <div
        style={{
          width: "400px",
          height: "400px",
          backgroundColor: "aliceblue",
        }}
      ></div>
    ),
    getNextStep: (data: any) => "step2b",
  },
  step2b: {
    component: (
      <div
        style={{ width: "400px", height: "400px", backgroundColor: "blue" }}
      ></div>
    ),
    getNextStep: (data: any) => "step3",
  },
  step3: {
    component: (
      <div
        style={{ width: "400px", height: "400px", backgroundColor: "green" }}
      ></div>
    ),
    getNextStep: (data: any) => null,
  },
};

export const ExperimentSteps = () => {
  const [currentStep, setCurrentStep] = useState("step1");

  const handleNext = (data: any) => {
    const nextStep = steps[currentStep].getNextStep(data);
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  };

  return (
    <div style={styles.container}>
      {Object.keys(steps).map((stepKey) => {
        const step = steps[stepKey];
        return (
          <div
            key={stepKey}
            style={{
              ...styles.step,
              transform: `translateX(${stepKey === currentStep ? 0 : 100}%)`,
              transition: "transform 0.5s ease-out",
            }}
          >
            {step.component}
          </div>
        );
      })}
      {steps[currentStep].getNextStep(null) !== null && (
        <button onClick={() => handleNext({})}>Next</button>
      )}
      {currentStep !== "step1" && (
        <button onClick={() => setCurrentStep("step1")}>Back</button>
      )}
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  step: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 100,
    left: 0,
  },
};
