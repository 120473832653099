import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Context from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { NotificationsProvider } from "@mantine/notifications";
import { MantineProvider } from "@mantine/core";
import TagManager from "react-gtm-module";
import "rsuite/dist/rsuite.min.css"; // or 'rsuite/dist/rsuite.min.css'
import { ModalsProvider } from "@mantine/modals";
import BanksProvider from "./pages/Banks/context/BanksContext";

const container = document.getElementById("root");

const tagManagerArgs = {
  gtmId: "G-79V1M6FZNT",
};
TagManager.initialize(tagManagerArgs);

// @ts-ignore
const root = ReactDOM.createRoot(container);

root.render(
  <MantineProvider theme={{ loader: "oval" }}>
    <ModalsProvider>
      <NotificationsProvider zIndex={10000}>
        <BrowserRouter>
          <Context>
            <BanksProvider>
              <App />
            </BanksProvider>
          </Context>
        </BrowserRouter>
      </NotificationsProvider>
    </ModalsProvider>
  </MantineProvider>
);
