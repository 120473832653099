import _ from "lodash";
import { useEffect, useState } from "react";
import { INITIAL_CONFIGS } from "../constants/dummyData";
import { CashflowConfigs, CashflowRowType } from "../constants/globalConstants";
import {
  getCashflowConfigs,
  getCashflowReport,
  updateCashflowConfigs,
} from "../pages/Cashflow/repository/cashflowConfigs";
import { configs } from "../constants";
import axios from "axios";
import { io } from "socket.io-client";
import {
  addNotification,
  notify,
  updateNotification,
} from "../modules/showNotification";
import { getFormattedTime } from "../modules/time";
import { CashflowTitles } from "../pages/Cashflow/constants";
import { ICashflowScenario } from "../constants/types";
import { getAllScenarios } from "../repositories/cashflowScenario";

const sync_url =
  process.env.REACT_APP_SOCKET_URL_SYNC || "http://localhost:5000";
const sync_server = io(sync_url, {
  path: process.env.REACT_APP_SOCKET_URL_SYNC ? "/sync/socket.io" : undefined,
});

export function useCashflow() {
  const [reportRows, setReportRows] = useState<any[]>([]);
  const [dashboardData, setDashboardData] = useState<any[]>([]);
  const [selectedScenario, setSelectedScenario] = useState<ICashflowScenario>(
    {} as ICashflowScenario
  );

  const [allScenarios, setAllScenarios] = useState<ICashflowScenario[]>([]);
  const [compareTo, setCompareTo] = useState<any>(); // cashflow version
  // const [reportRowsOriginal, setReportRowsOriginal] = useState<any[]>([]);

  const [cashflowConfigs, setCashflowConfigs] = useState<CashflowConfigs>(
    {} as CashflowConfigs
  );

  const [reportLoading, setReportLoading] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [configsLoading, setConfigsLoading] = useState(false);
  const [obErrorMessage, setObErrorMessage] = useState("");
  const [obMessage, setObMessage] = useState("");
  // const [currentOrg, setCurrentOrg] = useState(undefined);
  //message

  const fetchCashflowConfigs = async () => {
    setConfigsLoading(true);
    // setReportLoading(true);
    const { data, success } = await getCashflowConfigs();
    setConfigsLoading(false);
    // setReportLoading(false);
    // console.log("reportData", reportData);

    if (success) {
      setCashflowConfigs(data);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     const orgResponse = await axios.get(
  //       configs.urls.BASE_URL + "/getOrganization",
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //     let orgId = orgResponse.data.organization.id;
  //     console.log("orgId", orgId);

  //     setCurrentOrg(orgId);
  //   })();
  // }, []);

  useEffect(() => {
    sync_server.on("calculate_cashflow", async (data: any) => {
      // console.log("calculate_cashflow", data);
      // console.log("currentOrg", currentOrg);
      const orgResponse = await axios.get(
        configs.urls.BASE_URL + "/getOrganization",
        {
          withCredentials: true,
        }
      );
      let orgId = orgResponse.data.organization.id;

      if (data?.organizationId === orgId) {
        if (data?.type === "start") {
          addNotification({
            title: "Cashflow Report",
            message: "Updating cashflow...",
            id: "cashflow_report",
            isLoading: true,
            closable: false,
          });
        } else {
          getCashflowReportNew();
          getDashboardData();
          if (!data?.success) {
            updateNotification({
              id: "cashflow_report",
              message: "There is some error while updating cashflow",
              title: "Error",
              error: true,
            });
          }

          // notify({
          //   success:
          //     "Cashflow report is ready, time taken " +
          //     getFormattedTime(data?.timeSpent),
          // });
        }
      }
    });
  }, []);

  const transformCashflowRows = (rows: any[]) => {
    // console.log("rows", rows);
    // group rows where cashflowCategory is same
    let cashInCategories = rows.filter(
      (row: any) =>
        row.rowType === CashflowRowType.RecordsGroup &&
        row.type === "Receivable"
    );

    // sort by name
    cashInCategories = _.sortBy(cashInCategories, (row: any) => {
      return row.name;
    });

    cashInCategories = cashInCategories.map((categoryRow: any) => {
      let cashInGroupedRows = rows.filter(
        (row: any) =>
          row.rowType === CashflowRowType.Record &&
          row.cashflowCategory === categoryRow.name &&
          row.type === "Receivable"
      );

      // sort by name
      cashInGroupedRows = _.sortBy(cashInGroupedRows, (row: any) => {
        return row.name;
      });
      categoryRow.children = cashInGroupedRows;
      return categoryRow;
    });

    // add cashInGroupedRows as children to TotalCashIn to update rows
    let totalCashInRow = rows.find(
      (row: any) => row.rowType === CashflowRowType.CashInTotal
    );

    if (totalCashInRow) {
      totalCashInRow.children = cashInCategories;
    }

    let cashOutCategories = rows.filter(
      (row: any) =>
        row.rowType === CashflowRowType.RecordsGroup && row.type === "Payable"
    );

    //sort by name
    cashOutCategories = _.sortBy(cashOutCategories, (row: any) => {
      return row.name;
    });

    cashOutCategories = cashOutCategories.map((categoryRow: any) => {
      let cashOutGroupedRows = rows.filter(
        (row: any) =>
          row.rowType === CashflowRowType.Record &&
          row.cashflowCategory === categoryRow.name &&
          row.type === "Payable"
      );

      // sort by name
      cashOutGroupedRows = _.sortBy(cashOutGroupedRows, (row: any) => {
        return row.name;
      });

      categoryRow.children = cashOutGroupedRows;
      return categoryRow;
    });

    let totalCashOutRow = rows.find(
      (row: any) => row.rowType === CashflowRowType.CashOutTotal
    );

    if (totalCashOutRow) {
      totalCashOutRow.children = cashOutCategories;
    }

    let resultantRows = rows.filter(
      (row: any) =>
        row.rowType === CashflowRowType.OpeningBalance ||
        row.rowType === CashflowRowType.ClosingBalance ||
        row.rowType === CashflowRowType.NetCash ||
        row.rowType === CashflowRowType.OpeningBalanceTotal ||
        row.rowType === CashflowRowType.Unreconciled ||
        row.name === CashflowTitles.CashflowStatement
    );

    resultantRows.push(totalCashInRow);
    resultantRows.push(totalCashOutRow);

    // console.log("resultantRows", resultantRows);
    // remove undefined values
    resultantRows = resultantRows.filter((row: any) => row);

    return resultantRows;
  };

  const getCashflowReportNew = async () => {
    setReportLoading(true);
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow_scenario/get",
      {},
      {
        withCredentials: true,
      }
    );
    setReportLoading(false);

    if (data.success) {
      setSelectedScenario(
        (data.response?.scenario as ICashflowScenario) ||
          ({} as ICashflowScenario)
      );
      if (data.response?.rows?.length > 0) {
        let rows = data.response.rows;

        let resultantRows = transformCashflowRows(rows);

        setReportRows(resultantRows);

        updateNotification({
          id: "cashflow_report",
          message: "Cashflow saved!",
          title: "Success",
          error: false,
        });
      }
    }
  };

  const getDashboardData = async () => {
    setDashboardLoading(true);
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow_scenario/get",
      {
        isMonthlyOnly: true,
      },
      {
        withCredentials: true,
      }
    );
    setDashboardLoading(false);

    if (data.success) {
      if (data.response?.rows?.length > 0) {
        let rows = data.response.rows;

        let resultantRows = transformCashflowRows(rows);

        setDashboardData(resultantRows);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !_.isEmpty(cashflowConfigs) &&
        !_.isEqual(cashflowConfigs, INITIAL_CONFIGS)
      ) {
        await updateCashflowConfigs(cashflowConfigs).then((data: any) => {
          const prevConfigs = data?.data;

          if (!_.isEqual(cashflowConfigs, prevConfigs)) {
            getCashflowReportNew();
          }
        });
      }
    })();
  }, [cashflowConfigs]);

  const fetchScenarios = async () => {
    const { success, data } = await getAllScenarios();
    if (success) {
      // console.log("refetching and got", data);
      let cashflowScenarios = data.map((item: any) => ({
        name: item.name,
        id: item.id,
        createdAt: item.createdAt,
        isActive: item.isActive,
        description: item.description,
        isDefault: item.isDefault,
        categoriesTemplates: item.categoriesTemplates,
        snapshots: item.snapshots,
      }));
      setAllScenarios(cashflowScenarios);
    }
  };

  useEffect(() => {
    fetchScenarios();
  }, []);

  return {
    reportRows,
    setReportRows,
    fetchCashflowReport: getCashflowReportNew,
    cashflowConfigs,
    setCashflowConfigs,
    reportLoading,
    setReportLoading,
    configsLoading,
    setConfigsLoading,
    obErrorMessage,
    setObErrorMessage,
    obMessage,
    setObMessage,
    fetchCashflowConfigs,
    selectedScenario,
    allScenarios,
    fetchScenarios,
    dashboardData,
    getDashboardData,
    dashboardLoading,
    compareTo,
    setCompareTo,
  };
}
