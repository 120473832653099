import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../../../common/Card/Card";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import { configs, StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import CustomDatePicker from "./CustomDatePicker";
import {
  ReportViewTypes,
  SourceType,
} from "../../../constants/globalConstants";
import moment, { unitOfTime } from "moment";
import "../styles.css";
type Base = unitOfTime.Base;

type BalanceConfig = {
  startDate: Date;
  endDate: Date;
};

function ManualBalance({
  drawer,
  showDrawer,
  onSuccess = async () => {},
}: any) {
  const [balanceConfigs, setBalanceConfigs] = useState<BalanceConfig>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });

  const [openingBalances, setOpeningBalances] = useState<any>({});
  const [closingBalances, setClosingBalances] = useState<any>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [balances, setBalances] = useState<any>({ ob: {}, cb: {} });

  const fetchBalances = async (dates: string[]) => {
    const balanceResponse = await axios.post(
      configs.urls.BASE_URL + "/cashflow/getOpeningBalance",
      {
        sourceType: SourceType.Manual,
        dateRange: dates,
        isArray: false,
      },
      {
        withCredentials: true,
      }
    );

    setBalances(balanceResponse.data.response);
  };

  useEffect(() => {
    let maxDate = moment(balanceConfigs.endDate);
    let minDate = moment(balanceConfigs.startDate);

    const dates = [];

    while (
      maxDate > minDate ||
      minDate.format(ReportViewTypes[2].prefix) ===
        maxDate.format(ReportViewTypes[2].prefix)
    ) {
      dates.push(minDate.format(ReportViewTypes[2].format));
      minDate.add(1, ReportViewTypes[2].value as Base);
    }

    setDateRange(dates);

    (async () => {
      await fetchBalances(dates);
    })();
  }, [balanceConfigs]);

  useEffect(() => {
    setBalanceConfigs({
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date(),
    });
  }, [drawer]);

  const onSave = async () => {
    setIsSaving(true);
    const balances = [];
    for (const key in openingBalances) {
      balances.push({
        date: key,
        balanceType: "Opening",
        sourceType: SourceType.Manual,
        balance: openingBalances[key],
      });
    }

    const balanceResponse = await axios.post(
      configs.urls.BASE_URL + "/cashflow/saveManualBalanceHistory",
      {
        manualBalances: balances,
      },
      {
        withCredentials: true,
      }
    );

    if (balanceResponse.data.success) {
      await onSuccess();
      showNotification({
        color: "green",
        message: "Saved!",
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 2000,
      });
    } else {
      showNotification({
        color: "red",
        message: "Error saving balances!",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }

    await fetchBalances(dateRange);

    setIsSaving(false);
    setOpeningBalances({});
    setClosingBalances({});
  };

  const onChangeOB = (event: any) => {
    const { name, value } = event.target;
    setOpeningBalances({ ...openingBalances, [name]: value });
  };

  const onChangeCB = (event: any) => {
    const { name, value } = event.target;
    setClosingBalances({ ...closingBalances, [name]: value });
  };

  const rendorRows = () => {
    const mappedDates = [];

    const { ob, cb } = balances || {};

    for (const key in ob) {
      if (cb[key]) {
        mappedDates.push({
          opening: ob[key].balance,
          closing: cb[key].balance,
          date: ob[key].date,
          currency: ob[key].currency,
        });
      }
    }

    return mappedDates?.map(({ opening, closing, date }) => {
      return (
        <tr key={date}>
          <td>{date}</td>
          <td>
            <input
              className="balance-input"
              placeholder={opening === "0" ? "" : opening}
              type="number"
              name={date}
              onChange={onChangeOB}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <Drawer
      zIndex={999}
      lockScroll
      styles={{ header: { display: "none" }, drawer: { overflow: "auto" } }}
      position="bottom"
      opened={drawer}
      onClose={() => showDrawer(false)}
      size="full"
    >
      <StyledComponents.DrawerContainer>
        <StyledComponents.DrawerHeader>
          <StyledComponents.DrawerButtonContainer />
          <StyledComponents.DrawerModalTitle className="mt-5">
            Add your opening balance
          </StyledComponents.DrawerModalTitle>
          <StyledComponents.DrawerButtonContainer
            variant="default"
            onClick={() => showDrawer(false)}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: 30, height: 30, color: COLORS.veryLightPink }}
              />
            }
          />
        </StyledComponents.DrawerHeader>

        <StyledComponents.DrawerContent>
          <Container>
            <div
              className="is-flex is-flex-direction-column is-align-items-center"
              style={{ width: "100%" }}
            >
              <div className="is-flex">
                <CustomDatePicker
                  onDateChange={(date: Date) => {
                    setBalanceConfigs((prev: any) => ({
                      ...prev,
                      startDate: date.toISOString(),
                    }));
                  }}
                  date={new Date(balanceConfigs.startDate)}
                  label="Starting from"
                  // minDate={endDate}
                />
                <CustomDatePicker
                  onDateChange={(date: Date) => {
                    setBalanceConfigs((prev: any) => ({
                      ...prev,
                      endDate: date.toISOString(),
                    }));
                  }}
                  date={new Date(balanceConfigs.endDate)}
                  minDate={balanceConfigs.startDate}
                  label="Ending on"
                />
              </div>
              <Card
                style={{
                  padding: 15,
                  marginTop: 10,
                  position: "relative",
                  height: "70vh",
                }}
                isNormal
                noShadow
                className=""
              >
                <div className="balance-container custom-scrollbar">
                  <table className="balance-table">
                    <thead>
                      <tr>
                        <th>
                          <CardTitle>Date</CardTitle>
                        </th>
                        <th>
                          <CardTitle>Opening Balance</CardTitle>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{rendorRows()}</tbody>
                  </table>
                </div>
              </Card>
            </div>

            <PrimaryButton
              style={{ width: 400 }}
              onClick={onSave}
              className={`mt-4 ${isSaving ? "is-loading" : ""}`}
            >
              Save
            </PrimaryButton>
          </Container>
        </StyledComponents.DrawerContent>
      </StyledComponents.DrawerContainer>
    </Drawer>
  );
}

export default ManualBalance;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;

  @media screen and (max-width: 1600px) {
    padding-bottom: 0px;
  }
`;

const CardTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
`;
