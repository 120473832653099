import "./styles.css";

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";

import { INDUSTRIES_LIST } from "../../constants/globalConstants";
import {
  createOrganization,
  getOrganizationById,
} from "../../repositories/organizationRepository";
import { ProgressBar } from "../../common/ProgressBar/ProgressBar";
import BackChevron from "../../common/BackChevron/BackChevron";
import { updateUser } from "../../repositories/userRepository";
import { getUserLS, setUserLS } from "../../modules/localStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SubmitButton from "./components/SubmitButton";
import {
  checkoutSessionExists,
  unusedSubscription,
} from "../../repositories/subscription";
import { showNotification } from "@mantine/notifications";
import { LoadingOverlay } from "@mantine/core";

interface ListObjectType {
  label: string;
  value: string;
  id?: string;
}

function TellUsAboutYourBusiness() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  // arguments from navigate state
  const location = useLocation();
  const { addMore } = (location?.state || {}) as any;

  useEffect(() => {
    (async () => {
      let checkoutSessionId = searchParams.get("sessionId");
      if (checkoutSessionId) {
        const { success } = await checkoutSessionExists({
          sessionId: checkoutSessionId,
        });
        // fake load 2 secs
        // await new Promise((resolve) => setTimeout(resolve, 5000));

        if (success) {
          showNotification({
            title: "Success",
            message: "You are now subscribed to a plan",
            color: "green",
          });
          setAddMoreOrgs(true);
        } else {
          // showNotification({
          //   title: "Error",
          //   message: "Your payment was not successful",
          //   color: "red",
          // });
          navigate("/get-started");
        }
      } else {
        if (!addMore) {
          navigate("/get-started");
        }
      }
      setLoading(false);
    })();
  }, [searchParams]);

  const [hasShadow, showShadow] = useState(false);
  const [addMoreOrgs, setAddMoreOrgs] = useState(false);
  useState<SingleValue<ListObjectType>>();
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<ListObjectType>>();

  useEffect(() => {
    getUsersOrgInfo();
    const user = getUserLS();
    const addMore = user.addMore || false;
    setAddMoreOrgs(addMore);
  }, []);

  const getUsersOrgInfo = async () => {
    const orgId = localStorage.getItem("organizationId") || "";

    const org = await getOrganizationById(orgId);
    if (!org) {
      return;
    }
    const { name, yearsOfOperation, noOfEmployees, industry } = org;
    setFieldsValues(name, yearsOfOperation, noOfEmployees, industry);
  };

  const formik = useFormik({
    initialValues: {
      companyName: "",
      yearsOfOperation: "",
      numberOfEmployees: "",
      industry: "",
    },
    onSubmit: async (values) => {
      const orgId = localStorage.getItem("organizationId");
      const active = addMoreOrgs ? false : true;

      const { success, response: { id, name, createdAt } = {} as any } =
        await createOrganization({
          ...values,
          currentlyActive: active,
          completedTutorial: false,
        });
      if (!success) {
        showNotification({
          title: "Error",
          message:
            "You've to subscribe to a plan to create a new organization.",
          color: "red",
        });

        return;
      }
      localStorage.setItem("organizationId", id);
      setUserLS({
        organizationId: id,
        organizationName: name,
        isDemo: addMoreOrgs ? false : true,
        completedTutorial: false,
        createdAt,
      });
      await updateUser({ lastStage: "tell-us-about-your-business" });

      navigate("/where-is-it-located");
    },
  });

  const { companyName, yearsOfOperation, numberOfEmployees, industry } =
    formik.values;

  const isValid = () => {
    if (!companyName || !yearsOfOperation || !numberOfEmployees || !industry) {
      return false;
    }
    return true;
  };

  const handleChange = (selected: SingleValue<ListObjectType>) => {
    setSelectedOption(selected);
    formik.setFieldValue("industry", selected?.value);
  };

  const setFieldsValues = (
    companyName: string,
    yearsOfOperation: string,
    noOfEmployees: string,
    industry: string
  ) => {
    formik.setFieldValue("companyName", companyName);
    formik.setFieldValue("yearsOfOperation", yearsOfOperation);
    formik.setFieldValue("numberOfEmployees", noOfEmployees);
    formik.setFieldValue("industry", industry);
    const industrySelected =
      INDUSTRIES_LIST.find(({ value }: ListObjectType) => value === industry) ||
      null;

    setSelectedOption(industrySelected);
  };

  const handleClose = () => {
    const user = getUserLS();
    navigate(user.url);
  };

  if (loading) return <div>Payment processing ...</div>;

  return (
    <div>
      {/* {addMoreOrgs ? (
        <> */}
      <FontAwesomeIcon
        className="right-chevron"
        icon={faTimes}
        onClick={handleClose}
      />
      {/* </>
      ) : (
        <BackChevron />
      )} */}

      <div
        className="card-outer-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <form onSubmit={formik.handleSubmit} className="form-container">
          <ProgressBar />
          <div className="form-button-container">
            <div
              onMouseOver={() => showShadow(true)}
              onMouseOut={() => showShadow(false)}
              className={`card-inner-container ${hasShadow ? "card" : ""}`}
              style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
            >
              <div className="card-content card-padding onboarding">
                <div className="content">
                  <div className="logo-container">
                    <p className="register-form-title">
                      {addMoreOrgs
                        ? "Add a new company"
                        : "Tell us about your business"}
                    </p>
                  </div>
                  <div className="field">
                    <label className="label">Company Name</label>
                    <div className="control">
                      <input
                        required
                        className="input"
                        type="text"
                        id="companyName"
                        name="companyName"
                        placeholder="Enter Company Name"
                        onChange={formik.handleChange}
                        value={companyName}
                      />
                    </div>
                  </div>
                  <>
                    <div className="field">
                      <label className="label">Years of Operation</label>
                      <div className="control">
                        <input
                          required
                          className="input"
                          type="number"
                          id="yearsOfOperation"
                          name="yearsOfOperation"
                          placeholder="i.e. 4"
                          onChange={formik.handleChange}
                          value={yearsOfOperation}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Number Of Employees</label>
                      <div className="control">
                        <input
                          required
                          className="input"
                          type="number"
                          id="numberOfEmployees"
                          name="numberOfEmployees"
                          placeholder="i.e. 15"
                          onChange={formik.handleChange}
                          value={numberOfEmployees}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">Industry</label>
                      <div className="control">
                        <Select
                          className="select-field"
                          value={selectedOption}
                          isClearable={true}
                          onChange={handleChange}
                          options={INDUSTRIES_LIST}
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
            <SubmitButton
              style={{ width: "100%" }}
              type="submit"
              className="mt-6"
              disabled={!isValid()}
              loading={formik.isSubmitting}
            >
              Continue
            </SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TellUsAboutYourBusiness;
