import React from "react";

interface IfProps {
  condition: boolean | undefined | string | number | null;
  children: React.ReactNode;
}

export function If(props: IfProps) {
  const { condition, children } = props;

  if (condition) {
    return <>{children}</>;
  }

  return null;
}

interface IfElseProps {
  condition: boolean;
  trueRender: () => React.ReactNode;
  falseRender: () => React.ReactNode;
}

export function IfElse(props: IfElseProps): React.ReactNode {
  const { condition, trueRender, falseRender } = props;

  return condition ? trueRender() : falseRender();
}
