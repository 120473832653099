import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import axios from "axios";
import { IMAGES, appTheme, configs } from "../../../constants";
import TableRow from "./TableRow";
import ExpandableTableRow from "./ExpandableTableRow";
import BlankRow from "./BlankRow";
import { CashflowTitles } from "../constants";
import {
  CashflowRowType,
  MomentTimeUnitBase,
  PeriodFilter,
  ReportViewTypes,
} from "../../../constants/globalConstants";
import moment from "moment";
import { useAuth } from "../../../contexts";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import ConfigsHeader from "./ConfigsHeader";
import { useSearchParams } from "react-router-dom";
import ShowDetailsDrawer from "./ShowDetails/ShowDetailsDrawer";
import { COLORS } from "../../../constants/theme";
import EditableTableRow from "./EditableTableRow/EditableTableRow";
import { notify } from "../../../modules/showNotification";
import {
  disableBalanceHistoryBank,
  removeBalanceHistoryBank,
  upsertBalanceHistoryBank,
} from "../repository/cashflowConfigs";
import LoadMore from "./LoadMore";
import { getCashflowRecord } from "../repository/getRecords";
import { CustomModal } from "../../../common/CustomModal/CustomModal";
import { getUserLS } from "../../../modules/localStorage";
import { Group } from "@mantine/core";
import { deleteDemoData } from "../../../repositories/demoData";
import { useBanksContext } from "../../Banks/context/BanksContext";
import { useNavigate } from "react-router-dom";
import { isShowDemoModal } from "../../../modules/localStorage";
import { isUserViewOnly } from "../../../modules/localStorage";

type ReportType = {
  name: string;
  values: {
    value: any;
    source: string;
    date: any;
  }[];
};

const LOAD = 2;

function CashflowScenario() {
  const navigate = useNavigate();

  const {
    cashflowConfigs,
    reportRows: cashflowReport,
    setReportRows: setCashflowReport,
    fetchCashflowReport: getCashflowReport,
    reportLoading,
    setReportLoading,
    setCashflowStage,
    compareTo,
  } = useAuth();
  const { fetchLinkedBanks } = useBanksContext();
  const { isDemo, completedTutorial } = getUserLS();
  
  const [openTutorialModel, setOpenTutorialModal] = useState<boolean>(
    isShowDemoModal()
    // true
  );

  const isViewOnly = isUserViewOnly();

  const [selectedViewIndex, setSelectedViewIndex] = useState(0);

  const [loadingMore, setLoadingMore] = useState<undefined | string>(undefined);

  const [searchParams, setSearchParams] = useSearchParams();

  type WhichSectionClicked = {
    type: string;
    bucket: string;
    name: string;
  };
  const [whichSectionClicked, setWhichSectionClicked] =
    useState<WhichSectionClicked>({} as WhichSectionClicked);

  type Accordians = {
    isCashInOpen: boolean;
    isCashOutOpen: boolean;
    isOpeningBalanceOpen: boolean;
  };

  type AccordianKeys = keyof Accordians;

  const [accordians, setAccordians] = useState<Accordians>({
    isCashInOpen: JSON.parse(sessionStorage.getItem("isCashInOpen") || "true"),
    isCashOutOpen: JSON.parse(
      sessionStorage.getItem("isCashOutOpen") || "true"
    ),
    isOpeningBalanceOpen: JSON.parse(
      sessionStorage.getItem("isOpeningBalanceOpen") || "true"
    ),
  });

  const [openingBalanceBanks, setOpeningBalanceBanks] = useState<any[]>([]);

  const hideDemoModal = () => {
    localStorage.removeItem("showDemoModal");
  };

  const addBank = () => {
    const isAnyOtherRowEditable = openingBalanceBanks.some(
      (bank) => bank?.editable
    );

    if (isAnyOtherRowEditable) {
      return alert("Please save or cancel the current edit");
    }
    const rowLength = getRowValues(CashflowTitles.CashflowStatement)?.length;

    setOpeningBalanceBanks((prev) => {
      return [
        ...prev,
        {
          id: Math.random().toString(36).substr(2, 9),
          name: "Bank " + (openingBalanceBanks.length + 1),
          values: [...Array(rowLength).fill(undefined)],
          valuesType: [...Array(rowLength).fill("Manual")],
          editable: true,
          disabled: false,
          isNew: true,
          rowType: CashflowRowType.OpeningBalance,
        },
      ];
    });
  };

  const toggleBankEditable = (id: string, cancel?: boolean) => {
    if (!cancel) {
      // check if any row is editable some
      const isAnyOtherRowEditable = openingBalanceBanks.some(
        (bank) => bank.editable && bank.id !== id
      );

      if (isAnyOtherRowEditable) {
        return alert("Please save or cancel the current edit");
      }
    }

    // was new row
    if (cancel && openingBalanceBanks.find((bank) => bank.id === id)?.isNew) {
      return setOpeningBalanceBanks((prev) => {
        return prev.filter((bank) => bank.id !== id);
      });
    } else {
      setOpeningBalanceBanks((prev) => {
        return prev.map((bank) => {
          if (bank.id === id) {
            return {
              ...bank,
              editable: !bank.editable,
            };
          } else {
            return {
              ...bank,
              editable: false,
            };
          }
        });
      });
    }
  };

  const removeBank = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this bank?")) {
      setOpeningBalanceBanks((prev) => {
        return prev.filter((bank) => bank.id !== id);
      });

      const { success } = await removeBalanceHistoryBank(id);
      notify({
        success: success && "Bank updated successfully",
        error: !success && "Error while updating bank",
        // onSuccess: () => getCashflowReport(),
      });
      fetchLinkedBanks();
    }
  };

  const disableBank = async (id: string) => {
    setOpeningBalanceBanks((prev) => {
      return prev.map((bank) => {
        if (bank.id === id) {
          return {
            ...bank,
            disabled: !bank.disabled,
          };
        }
        return bank;
      });
    });
    const { success } = await disableBalanceHistoryBank(id);
    notify({
      success: success && "Bank updated successfully",
      error: !success && "Error while updating bank",
      // onSuccess: () => getCashflowReport(),
    });
  };

  const onChangeBankName = (id: string, name: string) => {
    setOpeningBalanceBanks((prev) => {
      return prev.map((bank) => {
        if (bank.id === id) {
          return {
            ...bank,
            name,
          };
        }
        return bank;
      });
    });
  };

  const onUpsertBank = async ({
    id,
    name,
    values,
    editedIndices,
  }: {
    id: string;
    name: string;
    values: number[];
    editedIndices: number[];
  }) => {
    // decide date range based on period filter
    let dateRange = getCashflowHeader();
    // filter if any value is edited
    if (editedIndices.length) {
      dateRange = dateRange.filter((_: any, index: number) =>
        editedIndices.includes(index)
      );
    } else {
      dateRange = [];
    }

    if (selectedViewIndex === 0) {
      // get first day of month instead if monthly view
      dateRange = dateRange.map((date: string) => {
        return moment(date).startOf("month").format("YYYY-MM-DD");
      });
    }

    const { success } = await upsertBalanceHistoryBank({
      id,
      name,
      values,
      dateRange,
      sourceType: "Manual",
    });
    fetchLinkedBanks();

    notify({
      success: success && "Bank updated successfully",
      error: !success && "Error while updating bank",
      // onSuccess: () => getCashflowReport(),
    });
  };

  const onChangeBankValues = (id: string, values: number[]) => {
    setOpeningBalanceBanks((prev) => {
      return prev.map((bank) => {
        if (bank.id === id) {
          return {
            ...bank,
            values,
          };
        }
        return bank;
      });
    });
  };

  useEffect(() => {
    if (cashflowReport && cashflowReport.length) {
      let expData = cashflowReport?.filter(
        (row: any) => row.rowType === CashflowRowType.OpeningBalance
      );

      expData = expData.map((row: any) => {
        return {
          ...row,
          values: row.values.map((value: any, index: number) => {
            return value.value == 0 && row.values[index]?.source === "None"
              ? undefined
              : parseFloat(value.value);
          }),
          valuesType: row.values.map((value: any) => {
            return value.source;
          }),
          id: row.referenceId,
        };
      });

      // console.log("expData", expData);

      setOpeningBalanceBanks(expData);
    }
  }, [cashflowReport]);

  useEffect(() => {
    // setReportLoading(true);
    if (cashflowConfigs?.periodFilter && !reportLoading) {
      setSelectedViewIndex(
        Object.keys(PeriodFilter).indexOf(cashflowConfigs?.periodFilter)
      );
    } else {
      //   setError("Error while fetching cashflow metadata");
    }
  }, [cashflowConfigs, reportLoading]);

  const getCashflowHeader = (): any[] => {
    return (
      cashflowReport?.find(
        (element: any) => element?.name === CashflowTitles.CashflowStatement
      )?.values || []
    );
  };

  const getRowValues = (title: string, negate: number = 1) =>
    cashflowReport
      ?.find((element: any) => element?.name === title)
      ?.values.map((value: any) => negate * parseFloat(value?.value)) ||
    Array(numberOfColumns).fill(0);

  const getRowValuesTypes = (title: string) =>
    cashflowReport
      ?.find((element: { name: string }) => element?.name === title)
      ?.values.map((value: any) => value.source);

  const getCurrentPeriodIndex = () => {
    const dates = getCashflowHeader();
    return dates?.findIndex((date: any) =>
      moment(date).isSame(new Date(), ReportViewTypes[selectedViewIndex].value)
    );
  };

  // console.log(
  //   "(JSON.parse(compareTo.data)[currentFilter]",
  //   JSON.parse(compareTo.data)[cashflowConfigs?.periodFilter]
  // );
  const getCompareWithValues = (title: string, negate: number = 1) => {
    if (compareTo) {
      let currentFilter = cashflowConfigs?.periodFilter;
      let compareToRows = (JSON.parse(compareTo.data)[currentFilter] ||
        []) as any[];

      // console.log("compareToRows", compareToRows);
      let rowFound =
        compareToRows.find((row) => row?.name === title)?.values || [];
      let compareToDates =
        compareToRows.find(
          (row) => row?.name === CashflowTitles.CashflowStatement
        )?.values || [];

      // console.log("compareToDates", compareToDates);
      const dates = getCashflowHeader();
      // console.log("dates", dates);

      let _row = dates.map((date) => {
        let indexFound = compareToDates.findIndex((d: string) => d === date);
        if (indexFound === -1) {
          return undefined;
        } else {
          let value = rowFound[indexFound]?.value;
          if (value) {
            return parseFloat(value) * negate;
          } else {
            return undefined;
          }
        }
      });

      return _row;
    }
    return [];
  };

  const [selectedContactId, setSelectedContactId] = useState<
    undefined | string
  >(undefined);

  const handleOnTitleClick = (row: any) => {
    setSelectedContactId(row.referenceId);
    setWhichSectionClicked({
      type: row.type,
      bucket: row.cashflowCategory,
      name: row.name,
    });
    // setSearchParams({
    //   tab: searchParams.get("tab") || "forcast",
    //   selectedContactId: row.referenceId,
    // });
  };

  let numberOfColumns = useMemo(
    () => getCashflowHeader()?.length,
    [cashflowReport]
  );

  const handleAccordiansToggle = (title: AccordianKeys) => {
    setAccordians((prev: any) => ({
      ...prev,
      [title]: !prev[title],
    }));
    sessionStorage.setItem(title, !accordians[title] ? "true" : "false");
  };

  const handleSetReportRows = (
    title: string,
    categoryRow: any,
    type: "Payable" | "Receivable"
  ) => {
    setCashflowReport((prev: any) => {
      let newData = [...prev];
      newData = newData.map((element) => {
        if (element.rowType === title) {
          let children = element.children.map((child: any) => {
            if (child.name === categoryRow.name && child.type === type) {
              return {
                ...child,
                isOpen: !child.isOpen,
              };
            }
            return child;
          });

          return {
            ...element,
            children,
          };
        }
        return element;
      });
      return newData;
    });
  };

  const onLoadMore = async (parentRow: any) => {
    setLoadingMore(parentRow.name);
    const records = await getCashflowRecord(
      parentRow.name,
      parentRow?.children.length
    );
    setLoadingMore(undefined);

    if (records.rows.length) {
      setCashflowReport((prev: any) => {
        let newData = [...prev];
        newData = newData.map((element) => {
          if (
            element.name === CashflowTitles.CashIn ||
            element.name === CashflowTitles.CashOut
          ) {
            let children = element.children.map((child: any) => {
              if (child.name === parentRow.name) {
                return {
                  ...child,
                  children: [...child.children, ...records.rows],
                };
              }
              return child;
            });

            return {
              ...element,
              children,
            };
          }
          return element;
        });
        return newData;
      });
    } else {
      alert("No more records to load");
    }
    // push these rows in the report where parentRow.name === row.name in in any rows children from report
  };

  const toggleHiddenData = (
    rowType: "Payable" | "Receivable",
    negate: number = 1
  ) => {
    let title =
      rowType === "Payable"
        ? CashflowRowType.CashOutTotal
        : CashflowRowType.CashInTotal;

    let data = cashflowReport.find(
      (row: any) => row?.rowType === title
    )?.children;

    return data?.map((parentRow: any) => {
      return (
        <Fragment key={parentRow.id}>
          <ExpandableTableRow
            // loading={reportLoading}
            rowHeader={parentRow.name}
            rowData={parentRow.values.map(
              (value: any) => negate * parseFloat(value.value)
            )}
            setIsOpen={() => handleSetReportRows(title, parentRow, rowType)}
            hasTreeIcon
            isOpen={parentRow?.isOpen}
            iconClosed={faPlusSquare}
            iconOpened={faMinusSquare}
            bgColor={appTheme.COLORS.white}
            rowType="Category"
            compareTo={getCompareWithValues(parentRow.name, negate)}
            compareMode={compareTo !== undefined}
          />
          {parentRow?.isOpen &&
            (parentRow?.children.length ? (
              <Fragment>
                {parentRow.children.map((row: any) => (
                  <TableRow
                    isViewOnly={isViewOnly}
                    key={row.name}
                    // loading={reportLoading}
                    id={row.id}
                    rowHeader={row.name}
                    rowData={row.values.map(
                      (value: any) => negate * parseFloat(value.value)
                    )}
                    valuesType={row.values.map((value: any) => value.source)}
                    paddingLeft="50px"
                    bgColor={appTheme.COLORS.lightGray}
                    onTitleClick={() => handleOnTitleClick(row)}
                    row={row}
                    compareTo={getCompareWithValues(row.name, negate)}
                    compareMode={compareTo !== undefined}
                  />
                ))}

                {parentRow?.children.length > 9 ? (
                  <LoadMore
                    onClick={() => onLoadMore(parentRow)}
                    loading={loadingMore === parentRow.name}
                  />
                ) : null}
              </Fragment>
            ) : (
              <BlankRow message="No data under this bucket" />
            ))}
        </Fragment>
      );
    });
  };

  return (
    <Fragment>
      {/* <CashflowAlert errorState={{ error, setError }} /> */}
      <Container>
        <ConfigsHeader
          selectedViewState={{ selectedViewIndex, setSelectedViewIndex }}
          searchParams={searchParams}
          isSaving={reportLoading}
        />

        <CustomModal
          isOpen={openTutorialModel}
          title={"Welcome to Madi!"}
          // buttonText={"Explore Cashflow"}
          handleClose={() => {}}
          handleButtonClick={() => {
            setCashflowStage(1);
            setOpenTutorialModal(false);
          }}
          buttonColor={COLORS.greenPrimary}
          className="cashflow-tutorial"
          withCloseButton={false}
          buttonPosition={"center"}
        >
          <div className="cashflow-tutorial-subtitle">
            How would you like to get started?
          </div>

          <Group position={"center"}>
            <div className="is-flex is-flex-direction-column is-align-items-center">
              <button
                className="button no-border my-2 mt-5"
                onClick={() => {
                  setCashflowStage(1);
                  setOpenTutorialModal(false);
                  hideDemoModal();
                }}
                autoFocus={false}
                style={{ width: "100%", borderRadius: 8 }}
              >
                Explore Cashflow with Demo Data
              </button>

              <strong>OR</strong>

              <button
                className="button no-border my-2"
                onClick={() => {
                  hideDemoModal();
                  deleteDemoData();
                  fetchLinkedBanks();

                  setCashflowStage(1);
                  setOpenTutorialModal(false);
                  // navigate("/settings?active=sync");
                  navigate("/get-started");
                }}
                autoFocus={false}
                style={{
                  backgroundColor: COLORS.greenPrimary,
                  color: "white",
                  width: "100%",
                  borderRadius: 8,
                }}
              >
                Connect account software and explore with my data
              </button>
              <span style={{ fontSize: 12 }}>(Recommended)</span>
            </div>
          </Group>

          <img
            className="cashflow-tutorial-img"
            src={IMAGES.cashflowValues}
            alt="cashflow-tutorial-img"
          />
        </CustomModal>

        <TableScroll>
          <ReportContainer className="">
            <Table className="table is-hoverable is-bordered">
              <Header>
                <TableRow
                  // loading={reportLoading}
                  rowHeader={CashflowTitles.CashflowStatement}
                  rowData={getCashflowHeader()?.map((date: any) =>
                    moment(date).format(
                      ReportViewTypes[selectedViewIndex].format
                    )
                  )}
                  isWeeklyView={selectedViewIndex === 1}
                  currentPeriod={selectedViewIndex}
                />
              </Header>
              <Body>
                <BlankRow />

                <ExpandableTableRow
                  isViewOnly={isViewOnly}
                  loading={reportLoading}
                  rowHeader={CashflowTitles.OpeningBalance}
                  rowData={getRowValues(CashflowTitles.OpeningBalance)}
                  valuesType={getRowValuesTypes(CashflowTitles.OpeningBalance)}
                  color="#408180"
                  isBold
                  setIsOpen={() =>
                    handleAccordiansToggle("isOpeningBalanceOpen")
                  }
                  isOpen={accordians.isOpeningBalanceOpen}
                  isRowEditable
                  onAddBank={() => {
                    addBank();
                    if (!accordians.isOpeningBalanceOpen) {
                      handleAccordiansToggle("isOpeningBalanceOpen");
                    }
                  }}
                  presentDateIndex={getCurrentPeriodIndex()}
                  compareTo={getCompareWithValues(
                    CashflowTitles.OpeningBalance
                  )}
                  compareMode={compareTo !== undefined}
                />

                {accordians.isOpeningBalanceOpen &&
                  openingBalanceBanks?.map((data: any) => (
                    <EditableTableRow
                      isViewOnly={isViewOnly}
                      key={data.id}
                      // loading={reportLoading}
                      rowHeader={data.name}
                      rowData={data.values}
                      valuesType={
                        data?.valuesType?.map((source: any) => source) || []
                      }
                      id={data.id}
                      color="#408180"
                      isBold
                      isRowEditable
                      editable={data.editable}
                      disabled={data.disabled}
                      toggleRowEditable={toggleBankEditable}
                      removeRow={removeBank}
                      disableRow={disableBank}
                      onChangeBankName={onChangeBankName}
                      onChangeBankValues={onChangeBankValues}
                      onUpsertBank={onUpsertBank}
                      presentDateIndex={getCurrentPeriodIndex()}
                      compareTo={getCompareWithValues(data.name)}
                      // compareMode={compareTo !== undefined}
                      compareMode={false}
                    />
                  ))}

                <BlankRow />

                <ExpandableTableRow
                  // loading={reportLoading}
                  rowHeader={CashflowTitles.CashIn}
                  rowData={getRowValues(CashflowTitles.CashIn)}
                  setIsOpen={() => handleAccordiansToggle("isCashInOpen")}
                  isOpen={accordians.isCashInOpen}
                  isBold
                  bgColor={appTheme.COLORS.white}
                  color="#10B480"
                  compareTo={getCompareWithValues(CashflowTitles.CashIn)}
                  compareMode={compareTo !== undefined}
                />

                {accordians.isCashInOpen && toggleHiddenData("Receivable")}

                <BlankRow />

                <ExpandableTableRow
                  // loading={reportLoading}
                  rowHeader={CashflowTitles.CashOut}
                  rowData={getRowValues(
                    CashflowTitles.CashOut,
                    compareTo === undefined ? -1 : 1
                  )}
                  setIsOpen={() => handleAccordiansToggle("isCashOutOpen")}
                  isOpen={accordians.isCashOutOpen}
                  isBold
                  bgColor={appTheme.COLORS.white}
                  color="#C12E5B"
                  compareTo={getCompareWithValues(CashflowTitles.CashOut)}
                  compareMode={compareTo !== undefined}
                />

                {accordians.isCashOutOpen &&
                  toggleHiddenData("Payable", compareTo === undefined ? -1 : 1)}

                <BlankRow />

                <TableRow
                  // loading={reportLoading}
                  rowHeader={CashflowTitles.NetCashflow}
                  rowData={getRowValues(CashflowTitles.NetCashflow)}
                  color="#10B480"
                  isBold
                  currentPeriod={selectedViewIndex}
                  compareTo={getCompareWithValues(CashflowTitles.NetCashflow)}
                  compareMode={compareTo !== undefined}
                />

                <BlankRow />

                <TableRow
                  // loading={reportLoading}
                  rowHeader={CashflowTitles.Unreconciled}
                  rowData={getRowValues(CashflowTitles.Unreconciled)}
                  isBold
                  currentPeriod={selectedViewIndex}
                  color="#10B480"
                  compareTo={getCompareWithValues(CashflowTitles.Unreconciled)}
                  compareMode={compareTo !== undefined}
                />

                <BlankRow />

                <TableRow
                  // loading={reportLoading}
                  rowHeader={CashflowTitles.ClosingBalance}
                  rowData={getRowValues(CashflowTitles.ClosingBalance)}
                  valuesType={getRowValuesTypes(CashflowTitles.ClosingBalance)}
                  color="black"
                  isBold
                  currentPeriod={selectedViewIndex}
                  presentDateIndex={getCurrentPeriodIndex()}
                  compareTo={getCompareWithValues(
                    CashflowTitles.ClosingBalance
                  )}
                  compareMode={compareTo !== undefined}
                />
              </Body>
            </Table>
          </ReportContainer>
        </TableScroll>
      </Container>
    </Fragment>
  );
}

export default CashflowScenario;

const Table = styled.table`
  tr > :first-child {
    padding: 0;
  }

  /* > tr :hover {
    background-color: ${COLORS.lightGray};
  } */

  border-collapse: separate;
`;

const Container = styled.div`
  max-width: 93vw;
  min-width: 93vw;
  position: relative;
`;

const TableScroll = styled.div`
  /* margin: auto;
  width: auto;
  height: auto;
  overflow: auto; */
  /* overflow-x: scroll; */
`;

const ReportContainer = styled.div`
  overflow-x: scroll;
  height: 90vh;
`;

const Header = styled.thead`
  th {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th::after {
    content: "";
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 10px;
    cursor: col-resize;
  }
`;

const Body = styled.tbody`
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-color: #efefef;
  }
`;
