import moment from "moment";
import { MENU } from "../constants/globalConstants";

export const getThisWeekDates = () => {
  const start = moment().startOf("week").toISOString();
  const end = moment().endOf("week").toISOString();
  return { start, end };
};

export const getThisMonthDates = () => {
  const start = moment().startOf("month").toISOString();
  const end = moment().endOf("month").toISOString();
  return { start, end };
};

export const getThisQuarterDates = () => {
  const start = moment().startOf("quarter").toISOString();
  const end = moment().endOf("quarter").toISOString();
  return { start, end };
};

export const getThisYearDates = () => {
  const start = moment().startOf("year").toISOString();
  const end = moment().endOf("year").toISOString();
  console.log({ start, end });
  return { start, end };
};

export const getLastWeekDates = () => {
  const start = moment().startOf("week").subtract(1, "week").toISOString();
  const end = moment().endOf("week").subtract(1, "week").toISOString();
  return { start, end };
};

export const getLastMonthDates = () => {
  const start = moment().startOf("month").subtract(1, "month").toISOString();
  const end = moment().endOf("month").subtract(1, "month").toISOString();
  console.log({ start, end });
  return { start, end };
};

export const getLastYearDates = () => {
  const start = moment().startOf("year").subtract(1, "year").toISOString();
  const end = moment().endOf("year").subtract(1, "year").toISOString();
  return { start, end };
};

export const getLastQuarterDates = () => {
  const start = moment()
    .startOf("quarter")
    .subtract(1, "quarter")
    .toISOString();
  const end = moment().endOf("quarter").subtract(1, "quarter").toISOString();
  console.log({ start, end });
  return { start, end };
};

export const getStartAndEndDates = (submenu: string) => {
  if (submenu === MENU.THIS_WEEK) {
    const { start, end } = getThisWeekDates();
    return { start, end };
  }
  if (submenu === MENU.THIS_MONTH) {
    const { start, end } = getThisMonthDates();
    return { start, end };
  }
  if (submenu === MENU.THIS_YEAR) {
    const { start, end } = getThisYearDates();
    return { start, end };
  }
  if (submenu === MENU.THIS_QUARTER) {
    const { start, end } = getThisQuarterDates();
    return { start, end };
  }

  if (submenu === MENU.LAST_WEEK) {
    const { start, end } = getLastWeekDates();
    return { start, end };
  }

  if (submenu === MENU.LAST_MONTH) {
    const { start, end } = getLastMonthDates();
    return { start, end };
  }
  if (submenu === MENU.LAST_QUARTER) {
    const { start, end } = getLastQuarterDates();
    return { start, end };
  }
  if (submenu === MENU.LAST_YEAR) {
    const { start, end } = getLastYearDates();
    return { start, end };
  }
};
