import "./styles.css";

import { Fragment, useEffect, useState } from "react";

import { CompanyToggleMenu } from "../CompanyToggleMenu";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { configs } from "../../constants";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import ToggleCashflowScenario from "../ToggleCashflowScenario/ToggleCashflowScenario";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconHelp } from "@tabler/icons";
import { deleteDemoData } from "../../repositories/demoData";
import { useBanksContext } from "../../pages/Banks/context/BanksContext";
import { isUserViewOnly } from "../../modules/localStorage";

function Navigation({ sidebarCollapsed, setSidebarCollapsed }: any) {
  const [searchParams] = useSearchParams();
  const {
    cashflowStage,
    syncing,
    setSyncing,
    connectedToXero,
    connectedToQuickBooks,
    connectedToZoho,
    isSyncingAllowed,
    currentPlan,
    totalSyncs,
    demoDataExists,
  } = useAuth();
  const { fetchLinkedBanks } = useBanksContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [deletingData, setDeletingData] = useState(false);

  const onUpgrade = () => {
    navigate("/pricing");
  };
  const isViewOnly = isUserViewOnly();

  const getProviderSyncUrl = () => {
    if (connectedToXero) {
      return "/syncXero";
    } else if (connectedToQuickBooks) {
      return "/syncQbo";
    } else if (connectedToZoho) {
      return "/zoho/sync";
    } else return "";
  };

  const syncMissingValues = () => {
    setSyncing(true);
    axios
      .post(
        configs.urls.BASE_URL + getProviderSyncUrl(),
        { missingDataOnly: true },
        {
          withCredentials: true,
        }
      )
      .then(
        (res) => {
          if (res.data !== "err") {
            showNotification({
              color: "teal",
              message: `${res.data}`,
              icon: <FontAwesomeIcon icon={faCheck} />,
              autoClose: 2000,
            });
          }
        },
        (err) => {
          showNotification({
            color: "red",
            message: `${err}`,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      );
  };

  const deleteData = async () => {
    setDeletingData(true);
    const { success } = await deleteDemoData();
    setDeletingData(false);
    if (success) {
      showNotification({
        title: "Success",
        message: "Demo data has been deleted sunccessfully!",
        color: "green",
      });
      fetchLinkedBanks();
    } else {
      showNotification({
        title: "Error",
        message: "There was some error while deleting data.",
        color: "red",
      });
    }
  };

  return (
    <Navbar
      style={{
        backgroundColor: "white",
        top: 0,
        borderBottom: "1px solid #ccc",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <NavLeft>
        <ToggleCashflowScenario loading={loading} setLoading={setLoading} />
      </NavLeft>
      {/* {window?.location?.pathname === "/cashflow" ? (
        <ListHeader
          style={{ display: "flex" }}
          renderTabs={() => (
            <CustomTabs
              tabSize={17}
              active={activeTab}
              setActive={setActiveTab}
              tabPadding={"18px 16px"}
              tabs={[
                { key: "forecast", title: "Cashflow" },
                { key: "history", title: "Bank" },
                { key: "dashboard", title: "Dashboard" },
                // { key: "scenario", title: "Cashflow Scenario" },
              ]}
              style={{ marginLeft: "1rem" }}
            />
          )}
        />
      ) : (
        <div></div>
      )} */}
      <NavRight
        className={`navbar-brand is-flex ${
          cashflowStage === 5 ? "tutorial-btn-container" : ""
        }`}
      >
        <Tooltip
          transition="scale-y"
          label={`Help center`}
          position="bottom"
          offset={15}
          multiline
          withArrow
          style={{ textAlign: "center" }}
        >
          <HelpButton>
            <IconHelp
              onClick={() => {
                window.open(
                  "https://thescalablecfo.notion.site/The-Scalable-CFO-Help-Center-dfba71475ddb41e2ae6b0aad911639f9",
                  "__blank"
                );
              }}
            />
          </HelpButton>
        </Tooltip>
        {!isViewOnly && (
          <Tooltip
            transition="scale-x"
            label={`${
              currentPlan?.capabilities?.noOfSyncsPerDay - totalSyncs
            } more sync(s) available. ${
              currentPlan?.capabilities?.noOfSyncsPerDay - totalSyncs === 0
                ? "Please try again tomorrow or upgrade your plan."
                : ""
            } `}
            position="left"
            offset={15}
            multiline
            withArrow
            // hidden={isSyncingAllowed}
            style={{ textAlign: "center" }}
          >
            <div>
              {connectedToXero || connectedToQuickBooks || connectedToZoho ? (
                <Fragment>
                  {!isSyncingAllowed ? (
                    <PrimaryButton className={""} onClick={onUpgrade}>
                      Upgrade
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      className={
                        "mt-2 mb-2 mr-3  " + (syncing ? `is-loading` : "")
                      }
                      disabled={syncing || !isSyncingAllowed}
                      onClick={syncMissingValues}
                      style={{
                        borderRadius: 8,
                        width: "auto",
                      }}
                    >
                      Sync
                    </PrimaryButton>
                  )}
                </Fragment>
              ) : null}
            </div>
          </Tooltip>
        )}

        {/* {demoDataExists ||
          (!isViewOnly && (
            <PrimaryButton
              className={"mt-2 mb-2 mr-3  " + (syncing ? `is-loading` : "")}
              style={{
                borderRadius: 8,
                width: "auto",
              }}
              loading={deletingData}
              onClick={() => {
                deleteData();
              }}
            >
              Clear Demo Data
            </PrimaryButton>
          ))} */}

        {demoDataExists === true && isViewOnly === false && (
          <PrimaryButton
            className={"mt-2 mb-2 mr-3  " + (syncing ? `is-loading` : "")}
            style={{
              borderRadius: 8,
              width: "auto",
            }}
            loading={deletingData}
            onClick={() => {
              deleteData();
            }}
          >
            Clear Demo Data
          </PrimaryButton>
        )}

        <CompanyToggleMenu />
      </NavRight>
    </Navbar>
  );
}

export default Navigation;

const Navbar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HelpButton = styled(ActionIcon)`
  margin: 10px;
`;

const NavRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const NavLeft = styled.div``;
