import PageLayout from "../../common/PageLayout/PageLayout";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import LeftView from "./layouts/LeftView";
import RightView from "./layouts/RightView";
import { isUserViewOnly } from "../../modules/localStorage";

function Settings() {
  useScrollBlock();
  const isViewOnly = isUserViewOnly();

  return <PageLayout left={<LeftView isViewOnly={isViewOnly} />} right={<RightView isViewOnly={isViewOnly} />} />;
}

export default Settings;
