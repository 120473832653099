import React from "react";

function Card({
  children,
  style = {},
  isNormal,
  className = "",
  noShadow,
}: any) {
  const [hasShadow, showShadow] = React.useState(false);

  if (noShadow)
    return (
      <div
        className={"" + className}
        style={{
          border: "1px solid #e6e6e6",
          padding: 25,
          borderRadius: 15,
          backgroundColor: "#fff",
          marginBottom: 10,
          ...style,
        }}
      >
        {children}
      </div>
    );

  return isNormal ? (
    <div
      className={"card " + className}
      style={{
        border: "1px solid #e6e6e6",
        padding: 15,
        borderRadius: 15,
        backgroundColor: "#fff",
        ...style,
      }}
    >
      {children}
    </div>
  ) : (
    <div
      onMouseOver={() => showShadow(true)}
      onMouseOut={() => showShadow(false)}
      className={(hasShadow ? "card" : "") + " " + className}
      style={{
        border: "1px solid #e6e6e6",
        padding: 15,
        borderRadius: 15,
        backgroundColor: "#fff",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default Card;
