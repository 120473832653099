import React from "react";
import styled from "styled-components";
import { CardForm } from "../../../common/CardForm";
import { CardTitle } from "../../../common/CardForm/CardForm";
import LabelValue from "../../../common/PageLayout/components/LabelValue";
import { IMAGES } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import { getAlpha2CodeForCountry } from "../../../modules/getAlpha2CodeForCountry";

function ReviewAndConfirm({ onClose, bank, contact, beneficiary }: any) {
  return (
    <div className="review-and-confirm-container">
      <CardForm
        cardStyles={{
          width: "100%",
        }}
        title={"Contact details"}
        icon={IMAGES.profile}
      >
        <LabelValue
          label={"Contact type"}
          value={Object.keys(contact.types)
            .map((type: any) => {
              // return capitalized type
              return type.charAt(0).toUpperCase() + type.slice(1);
            })
            .join(", ")}
          fontSize={"20px"}
          className="mt-4"
        />
        <div className="flex-direction-row is-justify-content-space-between">
          <LabelValue
            label={"Full name of person"}
            value={contact.name}
            fontSize={"20px"}
            isWidth50
          />
          <LabelValue
            isWidth50
            label={"Email address"}
            value={contact.email}
            fontSize={"20px"}
          />
        </div>
        <CardTitle className="mt-5">Beneficiary details</CardTitle>
        <LabelValue
          label={"Beneficiary type"}
          value={
            beneficiary?.type.charAt(0).toUpperCase() +
            beneficiary?.type.slice(1)
          }
          fontSize={"20px"}
          className="mt-4"
        />
        <div className="flex-direction-row is-justify-content-space-between">
          <LabelValue
            label={"Full name of organization"}
            value={beneficiary.name}
            fontSize={"20px"}
            isWidth50
          />
          <LabelValue
            isWidth50
            label={"Email address"}
            value={beneficiary.email}
            fontSize={"20px"}
          />
        </div>
        <CardTitle className="mt-5">Billing address </CardTitle>
        <div className="flex-direction-row is-justify-content-space-between mt-4">
          <LabelValue
            label={"Country"}
            value={`<span class="flex-direction-row ">
                <span class="fi fi-${getAlpha2CodeForCountry(
                  beneficiary.country
                )} mr-2"></span> <span class="pt-1">${
              beneficiary.country
            }</span>
              </span>`}
            fontSize={"20px"}
            isWidth50
          />
          <LabelValue
            label={"State"}
            value={beneficiary.state}
            fontSize={"20px"}
            isWidth50
          />
        </div>
        <div className="flex-direction-row is-justify-content-space-between">
          <LabelValue
            label={"Street"}
            value={beneficiary.street}
            fontSize={"20px"}
            isWidth50
          />
          <LabelValue
            label={"P.O. Box"}
            value={beneficiary.poBox}
            fontSize={"20px"}
            isWidth50
          />
        </div>
      </CardForm>

      <Separator />

      <CardForm
        cardStyles={{
          width: "100%",
        }}
        title={"Bank address"}
        icon={IMAGES.mini_bank_logo}
      >
        <div className="flex-direction-row is-justify-content-space-between mt-4">
          <LabelValue
            label={"Country"}
            value={`<span  class="flex-direction-row ">
                <span class="fi fi-${getAlpha2CodeForCountry(
                  bank.country
                )} mr-2"></span> <span class="pt-1">${bank.country}</span>
              </span>`}
            fontSize={"20px"}
            isWidth50
          />
          <LabelValue
            label={"State"}
            value={bank.state}
            fontSize={"20px"}
            isWidth50
          />
        </div>

        <div className="flex-direction-row is-justify-content-space-between">
          <LabelValue
            label={"Street"}
            value={bank.street}
            fontSize={"20px"}
            isWidth50
          />
          <LabelValue
            label={"P.O. Box"}
            value={bank.poBox}
            fontSize={"20px"}
            isWidth50
          />
        </div>

        <CardTitle className="mt-5 mb-3">Banking details</CardTitle>

        <LabelValue
          label={"Currency"}
          value={bank.currency}
          fontSize={"20px"}
        />
        <LabelValue
          label={"Bank name"}
          value={bank.bankName}
          fontSize={"20px"}
        />
        <LabelValue
          label={"IBAN (23-digits)"}
          value={bank.iban}
          fontSize={"20px"}
        />
        <LabelValue
          label={"Account number (13-digits)"}
          value={bank.accountNumber}
          fontSize={"20px"}
        />
        <LabelValue
          label={"Swift code"}
          value={bank.swiftCode}
          fontSize={"20px"}
        />
      </CardForm>
      <div className="flex-direction-column is-align-items-center">
        <button
          style={{
            backgroundColor: COLORS.greenPrimary,
            color: "white",
            width: "50%",
          }}
          onClick={onClose}
          className={`button mt-4 is-bold mr-2 mb-5`}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export default ReviewAndConfirm;

const Separator = styled.div`
  margin: 60px;
  font-size: 20px;
`;
