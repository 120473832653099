import React from "react";
import styled from "styled-components";
import CardButton from "../../../common/CardButton/CardButton";
import { IMAGES } from "../../../constants";
import { STEPS } from "../constants";

function AddContactMethod({
  handleNext,
}: {
  handleNext: (step: string) => void;
}) {
  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center"
      style={{
        width: "100%",
        marginTop: "10rem",
      }}
    >
      <CardButton
        icon={IMAGES.add_beneficiary}
        imageStyles={{
          width: "60px",
          height: "60px",
        }}
        title="Add a contact manually"
        description="Add a person or business that you deal with"
        onClick={() => {
          handleNext(STEPS.ADD_MANUALLY);
        }}
      />
      <Separator>OR</Separator>
      <CardButton
        imageStyles={{
          width: "30px",
          height: "30px",
          margin: 25,
        }}
        icon={IMAGES.add_accounting_software}
        title="Connect your accounting software"
        description="Automatically sync all your contacts in one click"
        onClick={() => {
          handleNext(STEPS.SYNC);
        }}
      />
    </div>
  );
}

export default AddContactMethod;

const Separator = styled.div`
  margin: 20px;
  font-size: 20px;
`;
