import { Fragment } from "react";
import Card from "../../../common/Card/Card";
import DetailsCardHeader from "../../../common/PageLayout/components/DetailsCardHeader";
import { useAuth } from "../../../contexts";
import "../styles.css"
import EditCompany from "./EditCompany";
import EditCompanyAddress from "./EditCompanyAddress";

function Company() {
  const { organization, fetchOrganization } = useAuth();

  return (
    <div>
      <Fragment>
            <Card
              style={{
                padding: "30px 32px",
                marginBottom: 15,
                position: "relative"
              }}
            >
              <DetailsCardHeader
                hasButton={false}
                title="Company details"
              />
              <EditCompany selectedCompany={organization} fetchOrganization={fetchOrganization} />
            </Card>
            <Card
              style={{
                padding: "30px 32px",
                marginBottom: 15,
                position: "relative"
              }}
            >
              <DetailsCardHeader
                hasButton={false}
                title="Company address"
              />
              <EditCompanyAddress selectedCompany={organization} fetchOrganization={fetchOrganization} />
            </Card>
          </Fragment>
    </div>
  );
}

export default Company;
