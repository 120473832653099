import { PROVIDERS } from "./globalConstants";

export type AccountingTransaction = {
  id: string;
  txnReferenceId: string;
  amountPaid: string;
  paidOrFailedAt: string;
};

export enum DisbursementMethod {
  BankTransfer = "BankTransfer",
  CardPayment = "CardPayment",
  Cheque = "Cheque",
  DigitalWallet = "DigitalWallet",
  Cash = "Cash",
}

export enum InvoiceStatus {
  Paid = "Paid",
  Unpaid = "Unpaid",
  PartiallyPaid = "PartiallyPaid",
}
export enum InvoiceProviders {
  Xero = "Xero",
  QuickBooks = "QuickBooks",
  Zoho = "Zoho",
  Transaction = "Transaction",
  None = "None",
}

export enum InvoiceStep {
  INVOICE = "INVOICE",
  ADD_PAYMENT = "ADD_PAYMENT",
  EDIT_PAYMENT = "EDIT_PAYMENT",
}

export interface IContactCategoryMapping {
  id: string | number;
  contactType: string;
  scenario: Record<string, string>;
  category: Record<string, string>;
  type: string;
}

export interface ICashflowScenario {
  id: string;
  name: string;
  organization: any;
  cashflowRows?: any[];
  cashflowConfigs?: any[];
  createdAt: Date;
  updatedAt: Date;
  cashflowCategoryMappings?: any[];
  categoriesTemplates?: any[];
  isActive?: boolean;
  description?: string;
  isDefault?: boolean;
  snapshots: any[];
}

export interface ISyncingLogs {
  date: Date;
  totalSuccessfulSyncs: number;
  logs: {
    total: number;
    successfullSyncs: number;
    failedSyncs: number;
    autoSyncs: number;
    manualSyncs: number;
    provider: PROVIDERS;
  }[];
}
