import React from "react";

interface CardTitleBlackType {
  title: string;
}
function CardTitleBlack({ title }: CardTitleBlackType) {
  return <div className="card-title-black">{title}</div>;
}

export default CardTitleBlack;
