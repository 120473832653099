export const INITIAL_CONTACT = {
  types: {
    vendor: true,
    customer: false,
    employee: false,
  },
  name: "",
  email: "",
};

export const INITIAL_BENEFICIARY = {
  name: "",
  email: "",
  type: "person",
  country: "",
  state: "",
  street: "",
  poBox: "",
};

export const INITIAL_BANK = {
  country: "",
  state: "",
  street: "",
  poBox: "",
  bankName: "",
  currency: "",
  accountNumber: "",
  routingNumber: "",
  sortCode: "",
  swiftCode: "",
  iban: "",
};

export interface Step {
  component: JSX.Element;
  getNextStep: (data: any) => string | null;
  prevStep: string | null;
}

export const STEPS = {
  ADD_CONTACT: "step0",
  ADD_MANUALLY: "step1a",
  SYNC: "step1b",
  SELECT_METHOD: "step2",
  ADD_BENEFICIARY_DETAILS: "step3",
  ADD_BANK_DETAILS: "step4",
  REVIEW_AND_CONFIRM: "step5",
  EDIT_BANKS: "listBanksStep",
};
