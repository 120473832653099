import { IContactCategoryMapping } from "../constants/types";

export const formatCashflowMapping = (contacts: any[]): any[] => {
  return contacts.map((contact: any) => {
    let cashflowCategoryMappings: IContactCategoryMapping[] =
      contact.cashflowCategoryMappings.map((mapping: any) => ({
        id: mapping.id,
        category: {
          name: mapping.category?.name,
          type: mapping.category?.type,
        },
        scenario: {
          id: mapping.category?.template?.cashflowScenario?.id,
          name: mapping.category?.template?.cashflowScenario?.name,
        },
        type: mapping.type,
        contactType: mapping.contactType,
      }));

    return {
      ...contact,
      cashflowCategoryMappings,
    };
  });
};

// format cashflow mapping in invoices
export const formatCashflowMappingInInvoices = (invoices: any[]): any[] => {
  return invoices.map((invoice: any) => {
    let filteredMappings = invoice?.contact?.cashflowCategoryMappings.filter(
      (mapping: any) => invoice.type === mapping.type
    );

    let cashflowCategoryMappings: IContactCategoryMapping[] =
      filteredMappings?.map((mapping: any) => ({
        id: mapping.id,
        category: {
          name: mapping.category?.name,
          type: mapping.category?.type,
        },
        scenario: {
          id: mapping.category?.template?.cashflowScenario?.id,
          name: mapping.category?.template?.cashflowScenario?.name,
        },
        type: mapping.type,
        contactType: mapping.contactType,
      }));

    return {
      ...invoice,
      cashflowCategoryMappings,
    };
  });
};
