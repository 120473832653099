import { Group, Overlay } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ListContainer from "../../common/PageLayout/components/ListContainer";
import { useAuth } from "../../contexts";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import CompaniesContext from "../Companies/contexts/CompaniesContext";
import CompanyDashboard from "../Companies/Dashboard/CompanyDashboard";
import CashflowHistory from "./components/CashflowHistory";
import CashForecastReport from "./components/CashForecastReport";
import { Helmet } from "react-helmet";
import CashflowScenario from "./components/CashflowScenario";
import axios from "axios";
import { configs } from "../../constants";
import { showNotification } from "@mantine/notifications";
import { CustomModal } from "../../common/CustomModal/CustomModal";
import { isStartTrial, isShowDemoModal } from "../../modules/localStorage";
import { COLORS } from "../../constants/theme";
import { getUserLS } from "../../modules/localStorage";
import moment from "moment";

function Cashflow() {
  useScrollBlock();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "forcast"
  );
  const [allOkay, setAllOkay] = useState(false);

  const [openTrialModal, setOpenTrialModal] = useState(isStartTrial());

  const [trialExpiredModal, setTrialExpiredModal] = useState(false);

  const { isDemo } = getUserLS();

  const { user, cashflowStage, errorInInvite, setTrialDaysRemaining } =
    useAuth();

  useEffect(() => {
    if (localStorage.getItem("showUpgradeModal") === "true") {
      setTrialExpiredModal(true);
      localStorage.removeItem("showUpgradeModal");
    }
  }, []);

  useEffect(() => {
    checkTrialStatus();
  }, [user]);

  const checkTrialStatus = () => {
    if (user && user.trialExpiryDate) {
      const now = moment();
      const userTrialDate = moment(user.trialExpiryDate);

      const timeDiff = userTrialDate.diff(now, "days");

      const daysRemaining = timeDiff >= 0 ? timeDiff : 0;

      console.log(
        `User ${user.id} has ${daysRemaining} days remaining in Pro trial`
      );
      setTrialDaysRemaining(daysRemaining);
    }
  };

  const getInvitationStatus = async () => {
    try {
      const userEmail = localStorage.getItem("email");
      const response = await axios.post(
        configs.urls.BASE_URL + `/invitation/get_user_invitation_status`,
        {
          withCredentials: true,
          email: userEmail,
        }
      );

      if (response.data.response.invitationIsValid === true) {
        navigate(response.data.response.invitatonLink);
        return;
      }
    } catch (error) {
      console.log("Error check invalid validity: ", error);
      showNotification({
        title: "Error",
        message: "Error check invalid validity",
        color: "red",
      });
    } finally {
      setAllOkay(true);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("invitationSignUp")) {
      localStorage.setItem("fromSignUp", "true");
      if (Boolean(localStorage.getItem("invitationSignUp")) === true) {
        const navigateToInvitation = localStorage.getItem(
          "invitationLinkAfterSignup"
        );
        if (navigateToInvitation && !errorInInvite) {
          navigate(navigateToInvitation);
        } else {
          console.error("invitationLinkAfterSignup is null");
        }
      }
    } else {
      getInvitationStatus();
    }
  }, []);

  useEffect(() => {
    if (allOkay) {
      setActiveTab(searchParams.get("tab") || "forcast");
    }
  }, [searchParams, allOkay]);

  const whichHeadTitle = () => {
    switch (activeTab) {
      case "forecast":
        return "Cashflow Forecast";
      case "history":
        return "Cashflow History";
      case "dashboard":
        return "Dashboard";
      default:
        return "Cashflow";
    }
  };

  return (
    <div
      style={{
        height: "90vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Helmet>
        <title>{whichHeadTitle()}</title>
      </Helmet>
      <ListContainer
        style={{
          maxWidth: "95vw",
          minWidth: "95vw",
          zIndex: 0,
        }}
        className="pt-3 px-6 mr-6"
      >
        {cashflowStage !== 0 && cashflowStage !== 8 && (
          <Overlay opacity={0.6} color="#000" zIndex={5} />
        )}

        {activeTab === "history" && <CashflowHistory />}
      </ListContainer>

      {activeTab === "forecast" && <CashflowScenario />}
      {/* {activeTab === "scenario" && <CashflowScenario />} */}
      {activeTab === "dashboard" && (
        <>
          <CompaniesContext>
            <div
              className="hero-body-companies hero-body"
              style={{ marginTop: "-0.5rem", marginRight: "2.5rem" }}
            >
              <CompanyDashboard />
            </div>
          </CompaniesContext>
        </>
      )}
      <CustomModal
        isOpen={trialExpiredModal}
        title="Upgrade your plan."
        handleClose={() => {}}
        handleButtonClick={() => {
          setOpenTrialModal(false);
        }}
        buttonColor={COLORS.greenPrimary}
        className="cashflow-tutorial"
        withCloseButton={false}
        buttonPosition={"center"}
      >
        <div className="trial-welcome-modal-body">
          <div className="trial-welcome-modal-text">
            Your free trial has expired. Please upgrade your plan.
          </div>
        </div>
        <Group position={"center"}>
          <div className="is-flex is-flex-direction-column is-align-items-center">
            <button
              style={{
                backgroundColor: COLORS.greenPrimary,
                color: "white",
                width: "100%",
                borderRadius: 8,
              }}
              className="button no-border my-2 mt-5"
              onClick={() => {
                setTrialExpiredModal(false);
                navigate("/pricing");
              }}
              autoFocus={false}
            >
              Upgrade Plan
            </button>

            <strong>OR</strong>

            <button
              className="button no-border my-2"
              onClick={() => {
                setTrialExpiredModal(false);
              }}
              autoFocus={false}
              style={{
                width: "100%",
                borderRadius: 8,
              }}
            >
              Continue with free plan
            </button>
          </div>
        </Group>
      </CustomModal>
      <CustomModal
        isOpen={openTrialModal}
        title="Congratulations! 🎉"
        handleClose={() => {}}
        handleButtonClick={() => {
          setOpenTrialModal(false);
        }}
        buttonColor={COLORS.greenPrimary}
        className="cashflow-tutorial"
        withCloseButton={false}
        buttonPosition={"center"}
      >
        <div className="trial-welcome-modal-body">
          <div className="trial-welcome-modal-text">
            You have been subscribed to a free Pro trial for 30 days.
          </div>
          <div className="trial-welcome-modal-text">This includes:</div>
          <div className="trial-welcome-modal-text">
            ✅ Number of scenarios: 25
          </div>
          <div className="trial-welcome-modal-text">
            ✅ On demand of syncs per day: 25
          </div>
          <div className="trial-welcome-modal-text">
            ✅ Scenario date range: +3 years from now
          </div>
          <div className="trial-welcome-modal-text">✅ Number of users: 10</div>
        </div>
        <Group position={"center"}>
          <div className="is-flex is-flex-direction-column is-align-items-center">
            <button
              style={{
                backgroundColor: COLORS.greenPrimary,
                color: "white",
                width: "100%",
                borderRadius: 8,
              }}
              className="button no-border my-2 mt-5"
              onClick={() => {
                setOpenTrialModal(false);
                localStorage.setItem("showDemoModal", "true");
                localStorage.removeItem("startTrial");
                window.location.reload();
              }}
              autoFocus={false}
            >
              Get Started
            </button>
          </div>
        </Group>
      </CustomModal>
    </div>
  );
}

export default Cashflow;
