import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  INITIAL_BANK,
  INITIAL_BENEFICIARY,
  INITIAL_CONTACT,
  STEPS,
} from "../constants";
import { flattenBank, flattenBeneficiary, flattenContact } from "../utils";

export const myContext = createContext<any>({});

export function useContactsContext() {
  return useContext(myContext);
}

export default function ContactsProvider(props: PropsWithChildren<any>) {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [contact, setContact] = useState<any>(INITIAL_CONTACT);
  const [disbursement, setDisbursement] = useState<any>(INITIAL_BENEFICIARY);
  const [bank, setBank] = useState<any>(INITIAL_BANK);
  const [banks, setBanks] = useState<any>([]);
  const [step, setStep] = useState(STEPS.ADD_CONTACT);

  const [mode, setMode] = useState<"add" | "edit" | undefined>("add");

  const onAddDisbursement = (contact: any) => {
    setMode("add");
    setStep(STEPS.ADD_BENEFICIARY_DETAILS);

    setContact({ ...flattenContact(contact) });

    setShowDrawer(true);
  };

  const onEditDisbursement = (contact: any, disbursement: any, banks: any) => {
    setMode("edit");
    setStep(STEPS.ADD_BENEFICIARY_DETAILS);

    setContact({ ...flattenContact(contact) });
    setDisbursement({ ...flattenBeneficiary(disbursement) });
    setBanks(banks);

    setShowDrawer(true);
  };

  const onAddBankAccount = (contact: any, disbursement: any) => {
    setMode("add");
    setStep(STEPS.ADD_BANK_DETAILS);

    setContact({ ...flattenContact(contact) });
    setDisbursement({ ...flattenBeneficiary(disbursement) });

    setShowDrawer(true);
  };

  const resetForm = () => {
    setStep(STEPS.ADD_CONTACT);
    setContact(INITIAL_CONTACT);
    setBank(INITIAL_BANK);
    setDisbursement(INITIAL_BENEFICIARY);
    setMode("add");
  };

  const onClose = () => {
    setShowDrawer(false);
    setTimeout(() => {
      resetForm();
    }, 300);
  };

  const isEditMode = () => mode === "edit";

  // useEffect(() => {
  //   if (selectedContact) {
  //     setContact({ ...flattenContact(selectedContact) });
  //   } else {
  //     setContact(INITIAL_CONTACT);
  //   }
  //   if (selectedDisbursement) {
  //     setBeneficiary({
  //       ...flattenBeneficiary(selectedDisbursement),
  //     });
  //   } else {
  //     setBeneficiary(INITIAL_BENEFICIARY);
  //   }

  //   if (selectedBank) {
  //     setBank({
  //       ...flattenBank(selectedBank),
  //     });
  //   } else {
  //     setBank(INITIAL_BANK);
  //   }
  // }, [selectedContact, selectedDisbursement, selectedBank]);

  return (
    <myContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        contact,
        setContact,
        disbursement,
        setDisbursement,
        bank,
        setBank,
        banks,
        setBanks,
        step,
        setStep,
        onAddBankAccount,
        onAddDisbursement,
        mode,
        setMode,
        onClose,
        onEditDisbursement,
        isEditMode,
      }}
    >
      {props.children}
    </myContext.Provider>
  );
}
