import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingOverlay } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { appTheme, configs, IMAGES } from "../../constants";
import { IS_LEAN_SANDBOX, LEAN_APP_TOKEN } from "../../constants/configs";
import { COLORS } from "../../constants/theme";
import { useAuth } from "../../contexts";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

function ConnectBankAccount({
  fetchLinkedBanks = () => {},
  fetchPaymentSource: reFetchPaymentSource = () => {},
  bankAccounts,
}: any) {
  const { organizationName, setGlobalLoading, lastBankSyncDate } = useAuth();
  const [paymentSource, setPaymentSource] = React.useState<any>(null);
  const [bankConnected, setBankConnected] = React.useState<boolean>(false);
  const [customerId, setCustomerId] = React.useState<string>("");
  const [endUserId, setEndUserId] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchPaymentSource = async () => {
    // getPaymentSource
    setLoading(true);
    axios
      .get(configs.urls.BASE_URL + "/getPaymentSource", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data !== "error") {
          setPaymentSource(res.data);
          // setBankConnected(!_.isEmpty(paymentSource));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCustomerId = () => {
    axios
      .get(configs.urls.BASE_URL + "/getLeanCustomerId", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data !== "error") {
          setCustomerId(res.data);
        }
      });
  };

  const getEndUserId = async () => {
    axios
      .get(configs.urls.BASE_URL + "/getEndUserId", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          setEndUserId(res.data.response);
        }
      });
  };

  const getBankConnection = async () => {
    const bankResponse = await axios.get(
      configs.urls.BASE_URL + "/isBankConnected",
      {
        withCredentials: true,
      }
    );
    if (bankResponse.data.success) {
      // alert("Bank is connected");
      setBankConnected(true);
    } else {
      setBankConnected(false);
    }
  };

  React.useEffect(() => {
    fetchPaymentSource();
    getEndUserId();
    fetchCustomerId();
    getBankConnection();
  }, []);

  React.useEffect(() => {
    getBankConnection();
  }, [bankAccounts]);

  const onUnlink = () => {
    axios
      .delete(configs.urls.BASE_URL + "/contact/unlinkBankConnections", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data === "success") {
          showNotification({
            color: "teal",
            message: "Bank has been unlinked successfully.",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
          fetchPaymentSource();
        } else {
          showNotification({
            color: "red",
            message: "Error: Cannot unlink the bank.",
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      });
  };

  const onLink = () => {
    // setBankConnected(true);
    // alert(customerId);

    console.log("-----------Lean.connect (with endUserId)-----------");
    console.log("LEAN_APP_TOKEN", LEAN_APP_TOKEN);
    console.log("customerId", customerId);
    console.log("endUserId", endUserId);
    console.log("sandbox", IS_LEAN_SANDBOX);
    console.log("----------------------");
    //@ts-ignore
    Lean.connect({
      app_token: LEAN_APP_TOKEN,
      customer_id: customerId,
      // end_user_id: endUserId,
      // payment_destination_id: "3247c8c7-0cbe-4ed8-a073-dd346dc7bea9",
      permissions: [
        "identity",
        "accounts",
        "balance",
        "transactions",
        // "payments",
      ],
      sandbox: IS_LEAN_SANDBOX,
      callback: (payload: any) => {
        if (payload.status === "ERROR" || payload.status === "CANCELLED") {
          showNotification({
            color: "orange",
            title: payload.status,
            message: `${payload.message ? payload.message : ""}`,
            autoClose: 2000,
          });
        } else {
          showNotification({
            color: "teal",
            title: payload.status,
            message: payload.message,
            autoClose: 2000,
          });
          setGlobalLoading(true);
          setTimeout(async () => {
            await fetchLinkedBanks();
            await fetchPaymentSource();
            await reFetchPaymentSource();
            await getBankConnection();
            setGlobalLoading(false);
          }, 5000);
        }
      },
    });
  };
  const ConnectedView = () => {
    return (
      <div className="is-flex is-flex-direction-column is-align-items-center my-4 mx-4">
        <img
          src={IMAGES.bank_connected}
          alt="bank_connected"
          style={{ width: "143px", height: "140px" }}
        />
        {/* {bankConnected && (
          <div className="title is-5 mt-4">Bank Feed Activated</div>
        )}
        {!_.isEmpty(paymentSource) && (
          <div className="title is-5 mt-2">Bank Payments Activated</div>
        )} */}

        <h4 className="mt-6">Connected to {organizationName}</h4>

        <div
          className=" mt-1 has-text-centered mb-6"
          style={{ fontSize: 14, color: COLORS.brownishGrey }}
        >
          <span>
            Your bank data was last in sync with Madi{" "}
            <strong>
              {lastBankSyncDate ? moment(lastBankSyncDate).fromNow() : "N/A"}
            </strong>
          </span>
        </div>

        {bankConnected ? (
          <PrimaryButton isReactDomLink to={{ pathname: "/cashflow" }}>
            View Cashflow
          </PrimaryButton>
        ) : (
          <PrimaryButton onClick={onLink} className="mt-4">
            Update Bank Feed
          </PrimaryButton>
        )}
        {/* {_.isEmpty(paymentSource) && (
          <PrimaryButton
            onClick={() =>
              navigate("/pay?tab=all", { state: { showDrawer: true } })
            }
            className="mt-4"
          >
            Activate Bank Payments
          </PrimaryButton>
        )} */}
        {/* <PrimaryButton
          onClick={() => setBankConnected(false)}
          // disabled={_.isEmpty(paymentSource)}
          className="mt-4"
          style={{ backgroundColor: COLORS.btnDelete }}
        >
          Unlink Bank
        </PrimaryButton> */}
      </div>
    );
  };

  const NotConnectedView = () => {
    return (
      <div className="is-flex is-flex-direction-column is-align-items-center my-4 mx-4">
        <img
          src={IMAGES.connect_bank_account}
          alt="connect_bank_account"
          style={{ width: "254px", height: "90px" }}
        />
        <div className="title has-text-centered is-5 mt-6">
          Connect your company bank account
        </div>
        <div className="subtitle is-6 mt-1 has-text-centered">
          Securely connect your company's bank account to make payments and
          manage your cashflow all in one.
        </div>

        <ComingSoon className=" mt-4">Coming Soon</ComingSoon>

        {/* {_.isEmpty(paymentSource) && (
          <PrimaryButton
            onClick={() =>
              navigate("/pay?tab=all", { state: { showDrawer: true } })
            }
            className="mt-4"
          >
            Activate Bank Payments
          </PrimaryButton>
        )} */}
      </div>
    );
  };
  return (
    <div>
      <LoadingOverlay
        visible={loading}
        overlayOpacity={0.9}
        overlayBlur={0.5}
        loaderProps={{
          color: appTheme.COLORS.greenBlue,
          variant: "oval",
        }}
      />
      {bankConnected || !_.isEmpty(paymentSource) ? (
        <ConnectedView />
      ) : (
        <NotConnectedView />
      )}
    </div>
  );
}

export default ConnectBankAccount;

const ComingSoon = styled.div`
  width: 403px;
  height: 48px;

  font-weight: 700;

  font-size: 20px;
  color: ${COLORS.greenBlue};

  padding: 8px 20px;

  border: 2px solid ${COLORS.greenBlue};
  border-radius: 8px;

  text-align: center;
  user-select: none;
`;
