import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { invalid } from "moment";
import React, { Fragment, useRef, useState } from "react";
import { Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import styled from "styled-components";
import { CustomDrawer } from "../../../../common/CustomDrawer";
import { appTheme } from "../../../../constants";
import { COLORS } from "../../../../constants/theme";
import { useAuth } from "../../../../contexts";
import { useResizableColumns } from "../../../../hooks/useResizableColumns";
import { notify } from "../../../../modules/showNotification";
import { calculateOpeningBalanceFromBankStatement } from "../../repository/cashflowConfigs";
import { showNotification } from "@mantine/notifications";
import AlertModal from "../../../../common/AlertModal/AlertModal";
const bank_statement = require("../../../../assets/files/bank_statement_updated.csv");

type Transaction = {
  date: string;
  description: string;
  amount: number;
  currency: string;
  running_balance: number;
};

const COLUMNS = [
  { label: "Date" },
  { label: "Description" },
  { label: "Amount" },
  { label: "Currency" },
  { label: "Running Balance" },
];

function UploadCSVModal({
  open,
  onClose,
  bank,
}: {
  open: boolean;
  onClose: (uploadDone: boolean) => void;
  bank: { name: string; id: string | undefined };
}) {
  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [widths, handleMouseDown] = useResizableColumns(
    headerRef,
    [100, 200, 100, 100, 100]
  );

  const [fileList, setFileList] = useState<FileType[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [dateFormat, setDateFormat] = useState<string>("DD-MM-YYYY");

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    message: "",
  });

  const modalReset = () => {
    setModalState({
      open: false,
      title: "",
      message: "",
    });
  };

  const onCloseModal = (uploadDone: boolean = false) => {
    setTimeout(() => {
      setFileList([]);
      setTransactions([]);
    }, 500);
    onClose(uploadDone);
  };

  const handleUpload = (file: FileType[]) => {
    if (!file || !file.length) return;
    // Set the uploaded file if the length of the file list is less than or equal to 1
    setError(undefined);
    setFileList([file[file.length - 1]]);
  };

  const onSave = async () => {
    if (transactions.length > 0) {
      const invalidTransactions = transactions.some(
        (trans) => trans.date === "Invalid date"
      );
      if (invalidTransactions) {
        setModalState({
          open: true,
          title: "Invalid date format!",
          message: "Please select valid date format.",
        });
        return;
      }
    }
    setFileList([]);
    setLoading(true);
    const { success } = await calculateOpeningBalanceFromBankStatement({
      id: bank.id,
      name: bank.name,
      transactions: transactions.reverse(),
      dateFormat,
    });

    notify({
      success: success && "Bank balance updated successfully",
      error: !success && "Error while updating bank",
      onSuccess: () => {
        // fetchCashflowReport();
        onCloseModal(true);
      },
    });
    setLoading(false);
  };

  const onContinue = async () => {
    try {
      setLoading(true);
      const txns = await readCSVFile(fileList[0].blobFile as File);
      // sort txns by date desc
      txns.sort((a, b) => {
        return moment(a.date).isBefore(b.date) ? 1 : -1;
      });
      setError(undefined);
      setTransactions(txns);
      setLoading(false);
    } catch (error: any) {
      let errorMessage =
        error?.message ||
        "Invalid file format. Please upload a valid bank statement file.";

      setError(errorMessage);
      setLoading(false);
      setFileList([]);
    }
  };

  function parseCSVLine(line: string): string[] {
    const fields = [];
    let currentField = "";
    let insideQuotedString = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === "," && !insideQuotedString) {
        fields.push(currentField);
        currentField = "";
      } else if (char === '"') {
        insideQuotedString = !insideQuotedString;
      } else {
        currentField += char;
      }
    }

    fields.push(currentField);

    return fields;
  }

  const readCSVFile = async (file: File): Promise<Transaction[]> => {
    const reader = new FileReader();

    return new Promise<Transaction[]>((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        setLoading(false);
        reject(new DOMException("Problem parsing input file."));
      };

      reader.onload = () => {
        const text = reader.result as string;
        // const lines = text.split("\n").filter((line) => line.trim() !== "");
        const lines = text.split("\r\n").filter((line) => line.trim() !== "");

        const headerColumns = lines[0]?.split("\n");

        console.log("reader on load headerColumns >>> ", headerColumns[0]);

        const areNotFiveFields = headerColumns[0].split(",").length !== 5;

        console.log("areNotFiveFields >>> ", areNotFiveFields);

        if (areNotFiveFields) {
          reject(new DOMException("Invalid CSV format."));
        }

        const transactions: Transaction[] = lines
          .slice(1)
          .map((line, index) => {
            // const fields = line.split(",");
            const fields = parseCSVLine(line);

            // remove 0 from the start of the dateFormat
            let newDateFormat: string;
            if (dateFormat === "DD-MM-YYYY") {
              newDateFormat = "D-M-YYYY";
            } else if (dateFormat === "MM-DD-YYYY") {
              newDateFormat = "M-D-YYYY";
            } else if (dateFormat === "MM/DD/YYYY") {
              newDateFormat = "M/D/YYYY";
            } else {
              newDateFormat = "D/M/YYYY";
            }

            // console.log("fields[0]", fields);
            // console.log(
            //   "moment(fields[0]).format(dateFormat)",
            //   moment(fields[0]).format(newDateFormat)
            // );

            let isDateValid =
              moment(fields[0]).isValid() &&
              // check if date is in the format
              (moment(fields[0]).format(dateFormat) === fields[0] ||
                moment(fields[0]).format(newDateFormat) === fields[0]);

            const isAmountValid = !isNaN(parseFloat(fields[2]));
            const isRunningBalanceValid = !isNaN(parseFloat(fields[4]));

            // if (!isDateValid)
            //   reject(
            //     new DOMException(
            //       "Invalid date: '" + fields[0] + "', in line: " + (index + 2)
            //     )
            //   );

            if (!isAmountValid) {
              reject(
                new DOMException(
                  "Invalid amount: '" + fields[2] + "', in line: " + (index + 2)
                )
              );
            }

            if (!isRunningBalanceValid) {
              reject(
                new DOMException(
                  "Invalid running balance: '" +
                    fields[4] +
                    "', in line: " +
                    (index + 2)
                )
              );
            }

            const currencyRegExp = /^[A-Z]{3}$/;
            if (!currencyRegExp.test(fields[3])) {
              // currency is invalid
              let errorMessage =
                "Invalid currency format in data. Please upload a valid bank statement file." +
                ", in line: " +
                (index + 2);

              reject(new DOMException(errorMessage));
            }

            // remove if comma is present in the running balance
            if (fields[4].includes(",")) {
              fields[4] = fields[4].replace(/,/g, "");
            }

            if (fields[2].includes(",")) {
              fields[2] = fields[2].replace(/,/g, "");
            }

            return {
              date: moment(fields[0], dateFormat).format(dateFormat),
              // date: fields[0],
              description: fields[1],
              amount: parseFloat(fields[2]) || 0,
              currency: fields[3],
              running_balance: parseFloat(fields[4]) || 0,
            };
          });

        resolve(transactions);
      };

      reader.readAsText(file);
    });
  };

  return (
    <CustomDrawer
      opened={open}
      onClose={onCloseModal}
      title={"Upload bank statement for " + bank.name}
      onBack={() => {
        if (transactions.length > 0) {
          setTransactions([]);
        } else {
          onCloseModal();
        }
      }}
    >
      <Container>
        {transactions.length > 0 ? (
          <Fragment>
            <TableContainer className="custom-scrollbar">
              <Table>
                <thead>
                  <tr>
                    {COLUMNS.map((column, index) => (
                      <th
                        data-resizable
                        key={column.label}
                        style={{ width: `${widths[index]}px` }}
                        onMouseDown={(event) => handleMouseDown(event, index)}
                      >
                        {column.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <Body>
                  {transactions.map((row: Transaction, i: number) => (
                    <tr key={i}>
                      {Object.keys(row).map((cell: string, j: number) => (
                        <td key={j}>{row[cell as keyof Transaction]}</td>
                      ))}
                    </tr>
                  ))}
                </Body>
              </Table>
            </TableContainer>
            <button
              onClick={onSave}
              style={{
                backgroundColor: COLORS.greenBlue,
                color: COLORS.white,
                width: "15rem",
              }}
              className={`button mv-6 is-bold is-small ${
                loading ? "is-loading" : ""
              }`}
            >
              Done
            </button>
          </Fragment>
        ) : (
          <Fragment>
            <Uploader
              action="//jsonplaceholder.typicode.com/posts/"
              accept=".csv"
              multiple={false}
              draggable
              autoUpload={false}
              listType="picture-text"
              onChange={handleUpload}
              fileList={fileList}
              removable={false}
            >
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  padding: "100px",
                }}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faUpload}
                    color={appTheme.COLORS.secondary}
                    size="3x"
                  />
                </div>
                <div>
                  <h5
                    style={{
                      color: appTheme.COLORS.secondary,
                      marginTop: "3%",
                    }}
                  >
                    Drag and drop a CSV file here
                  </h5>
                </div>
              </Card>
            </Uploader>
            {error ? <p style={{ color: "red" }}>{error}</p> : null}
            {fileList.length > 0 ? (
              <div>
                <ChooseDateFormat>
                  {/* radio choices */}
                  <p>
                    <b>Choose date format</b>
                  </p>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="dd-mm-yyyy"
                      name="date-format"
                      value="DD-MM-YYYY"
                      checked={dateFormat === "DD-MM-YYYY"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="dd-mm-yyyy">DD-MM-YYYY</label>
                  </div>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="mm-dd-yyyy"
                      name="date-format"
                      value="MM-DD-YYYY"
                      checked={dateFormat === "MM-DD-YYYY"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="mm-dd-yyyy">MM-DD-YYYY</label>
                  </div>

                  <div className="radio-group">
                    <input
                      type="radio"
                      id="mm/dd/yyyy"
                      name="date-format"
                      value="MM/DD/YYYY"
                      checked={dateFormat === "MM/DD/YYYY"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="mm/dd/yyyy">MM/DD/YYYY</label>
                  </div>
                  <div className="radio-group">
                    <input
                      type="radio"
                      id="dd/mm/yyyy"
                      name="date-format"
                      value="DD/MM/YYYY"
                      checked={dateFormat === "DD/MM/YYYY"}
                      onChange={(e) => setDateFormat(e.target.value)}
                    />
                    <label htmlFor="dd/mm/yyyy">DD/MM/YYYY</label>
                  </div>
                </ChooseDateFormat>
                <button
                  onClick={onContinue}
                  style={{
                    backgroundColor: COLORS.greenBlue,
                    color: COLORS.white,
                    width: "15rem",
                  }}
                  className={`button mv-6 is-bold is-small ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Continue
                </button>
              </div>
            ) : (
              <div className="mt-5">
                Need a Sample CSV File? Download{" "}
                <a href={bank_statement} download>
                  here
                </a>
              </div>
            )}
          </Fragment>
        )}
        {modalState && (
          <>
            <AlertModal
              modalOpened={modalState.open}
              resetModal={modalReset}
              title={modalState.title}
              message={modalState.message}
            />
          </>
        )}
      </Container>
    </CustomDrawer>
  );
}

export default UploadCSVModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const Card = styled.div`
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px dashed ${appTheme.COLORS.secondary};
  cursor: pointer;

  margin-top: 50px;
`;

const ChooseDateFormat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 15px;

  .radio-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid ${COLORS.greenBlue};
    border-radius: 50%;
    outline: none;
    transition: all 0.3s ease;
  }

  input[type="radio"]:checked {
    background: ${COLORS.greenBlue};
    /* dot in */
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;

      transition: all 0.3s ease;
      opacity: 1;
    }
  }

  > p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const TableContainer = styled.div`
  margin-top: 25px;
  height: 60vh;
  overflow-y: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
  }
`;

const Header = styled.thead`
  th {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th::after {
    content: "";
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 10px;
    cursor: col-resize;
  }
`;

const Body = styled.tbody`
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
