import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { COLORS } from "../../constants/theme";

function Error404() {
  return (
    <div
      className="hero fullscreen mb-6"
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: -100,
      }}
    >
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        size="10x"
        color={COLORS.gray2}
      />
      <div className="title mt-4" style={{ fontSize: 80, color: COLORS.gray }}>
        Error404
      </div>
      <div className="subtitle">
        The page you're looking for, is not found :(
      </div>
    </div>
  );
}

export default Error404;
