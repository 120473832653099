import { Modal, Button, Text } from "@mantine/core";
import { COLORS } from "../../constants/theme";

function ConfirmationModal({
  loading,
  modalOpened,
  setModalOpened,
  confirmDeleteFunction,
  title,
  message,
  confirmDeleteParams,
}: any) {
  return (
    <>
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        size="auto"
        title={title}
        styles={{
          modal: {
            zIndex: 1050, // Increase z-index for modal
          },
          overlay: {
            zIndex: 1049, // Increase z-index for overlay if necessary
          },
        }}
      >
        <Text mb={5}>{message}</Text>

        <div className="flex-center">
          <Button
            disabled={loading}
            styles={{
              root: {
                backgroundColor: COLORS.greenPrimary,
                color: "white",
                "&:hover": {
                  backgroundColor: COLORS.greenPrimary,
                },
              },
            }}
            onClick={() => {
              confirmDeleteFunction(...confirmDeleteParams);
            }}
          >
            Confirm
          </Button>

          <Button
            disabled={loading}
            styles={{
              root: {
                backgroundColor: COLORS.white,
                color: "#000",
                marginLeft: "10px",
                border: `1px solid ${COLORS.gray}`,
                "&:hover": {
                  backgroundColor: COLORS.white,
                },
              },
            }}
            onClick={() => setModalOpened(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
