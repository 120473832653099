// import "../../styles.css";
import { LoadingPlaceholders } from "../../../../common/LoadingPlaceholders";
import DetailsCardFooter from "../../../../common/PageLayout/components/DetailsCardFooter";
import { formatCurrency } from "../../../../modules";

interface OpenBalanceType {
  loading: boolean;
  placeholder: boolean;
  selectedBill: Record<string, any>;
}

function OpenBalance({ loading, placeholder, selectedBill }: OpenBalanceType) {
  return (
    <DetailsCardFooter
      left={() =>
        loading || placeholder ? (
          <LoadingPlaceholders.Text style={{ height: 30 }} active={loading} />
        ) : (
          <div className="open-balance-title">Open Balance</div>
        )
      }
      right={() =>
        loading || placeholder ? (
          <LoadingPlaceholders.Text style={{ height: 30 }} active={loading} />
        ) : (
          <div className="open-balance-value">
            {selectedBill?.currency}{" "}
            {formatCurrency(parseFloat(selectedBill?.amountDue), 2)}
          </div>
        )
      }
    />
  );
}

export default OpenBalance;
