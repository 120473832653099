import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "../../../../common/Card/Card";
import { LoadingPlaceholders } from "../../../../common/LoadingPlaceholders";
import DetailsCardHeader from "../../../../common/PageLayout/components/DetailsCardHeader";
import EditModal from "../../../../common/PageLayout/components/EditModal";
import LabelValue from "../../../../common/PageLayout/components/LabelValue";
import ManageRunwayCard from "../../../../common/PageLayout/components/ManageRunwayCard";
import ShowDetailsCard from "../../../../common/PageLayout/components/ShowDetailsCard";
import { configs, IMAGES, StyledComponents } from "../../../../constants";
import { IS_LEAN_SANDBOX, LEAN_APP_TOKEN } from "../../../../constants/configs";
import {
  InvoiceTypes,
  PaymentStatus,
} from "../../../../constants/globalConstants";
import { COLORS } from "../../../../constants/theme";
import { InvoiceProviders } from "../../../../constants/types";
import { useAuth } from "../../../../contexts";
import { formatCurrency } from "../../../../modules";
import { getInvoiceStatus } from "../../../../modules/getInvoiceStatus";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";
import { usePaymentsContext } from "../../contexts/PaymentsContext";
import { rejectPayments } from "../../repository";
import OpenBalance from "../common/OpenBalance";
import { getAvatarPlaceholder } from "../../../../modules/getAvatarPlaceholder";
import EditForecastBudget from "../../../Cashflow/components/ForecastBudget/EditForecastBudgetDetails";
import EditForecastBudgetDetails from "../../../Cashflow/components/ForecastBudget/EditForecastBudgetDetails";
import AddEditForecastBudgetModal from "../../../Cashflow/components/ForecastBudget/AddEditForecastBudgetModal";
import { Helmet } from "react-helmet";
import { If } from "../../../../common/Utils/If";
import { MapCategory } from "../../../Contacts/components/MapCategory";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function RightView({ isViewOnly }: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<boolean>(true);
  const {
    showDrawer,
    setPaymentType,
    selectRecord,
    customerId,
    endUserId,
    leanCallback,
    searchParams,
    setSearchParams,
  } = usePaymentsContext();

  const [isEstimates, setIsEstimates] = useState(false);

  const query = useQuery();

  useEffect(() => {
    const tab = query.get("tab");
    if (tab === "estimates") {
      setIsEstimates(true);
    } else {
      setIsEstimates(false);
    }
  }, [query]);

  const { setGlobalLoading, records, getRecords, forecasts } = useAuth();

  const [showImage, setShowImage] = React.useState(false);
  const [showEditPayModal, setShowEditPayModal] = React.useState(false);
  const [editForecastBudgetModal, setEditForecastBudgetModal] =
    React.useState(false);
  const [isDetailsOnlyModal, setIsDetailsOnlyModal] = React.useState(false);

  const [showMore, setShowMore] = React.useState(true);

  const selectedBillId = searchParams.get("selectedBillId");
  const selectedForecastId = searchParams.get("selectedForecastId");

  let isForecastOrBudget = selectedForecastId ? true : false;

  const [selectedBill, setSelectedBill] = useState<any>({});
  const [contactId, setContactId] = useState<any>();

  useEffect(() => {
    const billIdFromStorage = localStorage.getItem("selectedBillId");
    const forecastIdFromStorage = localStorage.getItem("selectedForecastIdPay");

    if ((selectedForecastId || forecastIdFromStorage) && forecasts?.length) {
      setLoading(true);
      setPlaceholder(false);
      let searchId = selectedForecastId || forecastIdFromStorage;
      const forecast = forecasts.find((record: any) => record.id == searchId);
      if (forecast) {
        setSelectedBill(forecast);
      } else {
        setSelectedBill(forecasts[0]);
      }
      // setContactId(forecast?.contact?.id);
      setSearchParams({
        tab: searchParams.get("tab") || "all",
        selectedForecastId: forecast?.id,
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else if ((selectedBillId || billIdFromStorage) && records.length) {
      setLoading(true);
      setPlaceholder(false);

      if (billIdFromStorage) {
        const bill = records.find(
          (record: any) => record?.id == billIdFromStorage
        );
        if (bill) {
          setSelectedBill(bill);
          setContactId(bill?.contact?.id);
          setSearchParams({
            tab: searchParams.get("tab") || "all",
            selectedBillId: billIdFromStorage,
          });
        } else {
          setSelectedBill(records[0]);
          setContactId(records[0]?.contact?.id);
          setSearchParams({
            tab: searchParams.get("tab") || "all",
            selectedBillId: records[0].id,
          });
        }
      } else {
        const bill = records.find((record: any) => record.id == selectedBillId);
        setSelectedBill(bill);
        setContactId(bill?.contact?.id);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [selectedBillId, records, selectedForecastId]);

  const payThisBill = () => {
    setGlobalLoading(true);
    selectRecord(selectedBill);
    setPaymentType("existing");
    setTimeout(() => {
      setGlobalLoading(false);
      showDrawer(true);
    }, 1000);
  };

  const whichSource = (provider: string) => {
    switch (provider) {
      case InvoiceProviders.None:
        return "Created on MADI";
      case InvoiceProviders.Transaction:
        return "Added from Transactions";
      default:
        return `Synced from ${provider}`;
    }
  };

  const handleEditPay = () => {
    setShowEditPayModal(true);
  };

  const renderTopHeader = () => (
    <StyledComponents.ListItemCardHeaderContainer>
      {loading || placeholder ? (
        <span className="mr-3">
          <LoadingPlaceholders.Avatar
            style={{ marginBottom: -5, marginLeft: 3 }}
            active={loading}
          />
        </span>
      ) : (
        <StyledComponents.ImageOnListItemCard
          src={
            isForecastOrBudget
              ? getAvatarPlaceholder(selectedBill?.name)
              : IMAGES.company_logo_placeholder
          }
          style={isForecastOrBudget ? { opacity: 0.6 } : {}}
        />
      )}

      <div>
        <StyledComponents.NameOnListItemCard>
          {loading || placeholder ? (
            <LoadingPlaceholders.Text
              style={{ marginBottom: -5, marginLeft: 3, height: 25 }}
              active={loading}
            />
          ) : (
            selectedBill?.name
          )}
        </StyledComponents.NameOnListItemCard>
        {loading || placeholder ? (
          <LoadingPlaceholders.Text
            style={{ marginBottom: -5, marginLeft: 3, height: 15 }}
            active={loading}
            width={80}
          />
        ) : isForecastOrBudget ? (
          <div>Estimate</div>
        ) : (
          <div className="detail-subheading-code">
            {_.isEmpty(selectedBill?.invoiceNumber) ||
            !selectedBill?.invoiceNumber.trim() ? (
              <i>No bill number.</i>
            ) : (
              `Bill # ${selectedBill?.invoiceNumber}`
            )}
          </div>
        )}
      </div>
    </StyledComponents.ListItemCardHeaderContainer>
  );

  const renderBillOrInvoice = () => (
    <Fragment>
      <Card className="details-card-container">
        <DetailsCardHeader
          isViewOnly={isViewOnly}
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={handleEditPay}
          hasBadge={false}
          renderButtons={
            selectedBill?.status === PaymentStatus.PendingAuthorization
              ? () => (
                  <div className="is-flex is-justify-content-center buttons are-small pb-2">
                    <button
                      disabled={isViewOnly}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to reject this payment?"
                          )
                        ) {
                          setGlobalLoading(true);
                          rejectPayments([selectedBill?.id], () => {
                            getRecords();
                            setGlobalLoading(false);
                            setSearchParams({ tab: "queued" });
                          });
                        }
                      }}
                      className="button is-danger"
                      style={{
                        color: "white",
                        backgroundColor: COLORS.btnDelete,
                      }}
                    >
                      Reject
                    </button>
                    <button
                      disabled={isViewOnly}
                      onClick={() => {
                        //@ts-ignore
                        Lean.authorize({
                          app_token: LEAN_APP_TOKEN,
                          payment_intent_ids: [selectedBill?.paymentIntentId],
                          customer_id: customerId,
                          end_user_id: endUserId,
                          sandbox: IS_LEAN_SANDBOX,
                          callback: (payload: any) => {
                            if (
                              payload.status !== "ERROR" &&
                              payload.status !== "CANCELLED"
                            ) {
                              axios
                                .post(
                                  configs.urls.BASE_URL +
                                    "/invoice/updatePaymentStatus",
                                  {
                                    id: selectedBill.id,
                                    status: PaymentStatus.Paid,
                                  },
                                  {
                                    withCredentials: true,
                                  }
                                )
                                .then((res) => {
                                  if (res.data.success) {
                                    getRecords();
                                    showNotification({
                                      title: "Success",
                                      message: "Payment successful",
                                      color: "green",
                                      icon: <FontAwesomeIcon icon={faCheck} />,
                                    });
                                    setSearchParams({ tab: "queued" });
                                  }
                                });
                            } else {
                              leanCallback(payload);
                            }
                          },
                        });
                      }}
                      className="button"
                      style={{
                        color: "white",
                        backgroundColor: COLORS.uglyBlue,
                      }}
                    >
                      Authorize
                    </button>
                  </div>
                )
              : undefined
          }
          buttonDisabled={loading || placeholder}
          title="Bill Details"
        />

        <EditModal
          showEditPayModal={showEditPayModal}
          setShowEditPayModal={setShowEditPayModal}
          selectedBill={selectedBill}
          invoiceType={InvoiceTypes.Payable}
          title="Edit Bill Details"
        />
        <div className="flex-direction-row-space-between mt-4">
          <div>
            <LabelValue
              label="Name"
              value={selectedBill?.name}
              loading={loading}
              placeholder={placeholder}
              linkTo={
                contactId &&
                `/contacts?tab=vendor&selectedContactId=${contactId}`
              }
            />
            <LabelValue
              label="Total Amount"
              value={`${
                // localStorage.getItem("baseCurrency") || "AED"
                selectedBill?.currency
              } ${formatCurrency(
                parseFloat(selectedBill?.amountTotal || "0"),
                2
              )}`}
              loading={loading}
              placeholder={placeholder}
            />
            <LabelValue
              label="Due Date"
              value={moment(selectedBill?.dueDate).format("MMM DD, YYYY")}
              loading={loading}
              placeholder={placeholder}
            />
            <LabelValue
              label="Issue Date"
              value={moment(selectedBill?.issueDate).format("MMM DD, YYYY")}
              loading={loading}
              placeholder={placeholder}
            />
          </div>

          {/* File/Image */}
          {loading || placeholder ? (
            <LoadingPlaceholders.Avatar
              isSquare
              active={loading}
              style={{ width: 200, height: 270 }}
            />
          ) : (
            selectedBill?.image && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setShowImage(true)}
              >
                <img
                  src={`${configs.urls.BASE_URL}/static${selectedBill?.image}`}
                  alt=""
                  style={{
                    width: 200,
                    height: 270,
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                />
              </div>
            )
          )}
        </div>
        <LabelValue
          label="Bill No"
          value={selectedBill?.invoiceNumber}
          loading={loading}
          placeholder={placeholder}
        />

        {loading || placeholder ? (
          <div className="mb-5">
            <LoadingPlaceholders.Text
              style={{ marginBottom: -8, marginLeft: 3, height: 15 }}
              active={loading}
              width={80}
            />
            <LoadingPlaceholders.Text
              style={{ marginBottom: -5, marginLeft: 3 }}
              active={loading}
              width={380}
            />
          </div>
        ) : (
          <div className="mb-5" style={{ width: "500px" }}>
            <div style={{ fontSize: 12, fontWeight: "600" }}>Note to Self</div>
            <div
              style={{
                fontSize: 20,
              }}
            >
              {_.isEmpty(selectedBill?.description) ||
              !selectedBill?.description.trim() ? (
                <i style={{ fontSize: 12 }}>No note to self.</i>
              ) : selectedBill?.description.length > 80 ? (
                showMore ? (
                  <span>
                    {selectedBill?.description.slice(0, 80)}...{" "}
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: COLORS.greenPrimary,
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: 14,
                      }}
                    >
                      see more
                    </span>
                  </span>
                ) : (
                  <span>
                    {selectedBill?.description}
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: COLORS.greenPrimary,
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: 14,
                      }}
                    >
                      {"  "}see less
                    </span>
                  </span>
                )
              ) : (
                selectedBill?.description
              )}
            </div>
          </div>
        )}

        {selectedBill?.linkedTransactions?.length &&
        (selectedBill?.status === PaymentStatus.PartiallyPaid ||
          selectedBill?.status === PaymentStatus.Paid) ? (
          <Fragment>
            <LabelValue
              label={"Paid at"}
              value={moment(
                getLatestTransaction(selectedBill?.linkedTransactions)
                  ?.paidOrFailedAt
              ).format("MMM DD, YYYY")}
              loading={loading}
              placeholder={placeholder}
              labelUnderValue={
                selectedBill?.linkedTransactions.length + " Payment(s)"
              }
              renderHoverableDropdown={() => (
                <div
                  style={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                  }}
                >
                  {selectedBill?.linkedTransactions.map(
                    (transaction: any, index: number) => (
                      <div
                        key={transaction.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          padding: "5px 0",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontWeight: "600",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Ref: {transaction.txnReferenceId}
                        </div>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                          <div style={{ fontSize: 14, fontWeight: "600" }}>
                            {moment(transaction.paidOrFailedAt).format(
                              "MMM DD, YYYY"
                            )}
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {/* {selectedBill?.currency}{" "} */}
                            {transaction?.currency}{" "}
                            <span
                              style={{
                                fontWeight: "600",
                                color: COLORS.red,
                              }}
                            >
                              {formatCurrency(
                                parseFloat(transaction.amountPaid || "0"),
                                2
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            />
            {selectedBill?.authorizedAt && (
              <LabelValue
                label="Authorized at"
                value={moment(
                  getLatestTransaction(selectedBill?.linkedTransactions)
                    ?.paidOrFailedAt
                ).format("MMM DD, YYYY")}
                loading={loading}
                placeholder={placeholder}
              />
            )}
            {selectedBill?.authorizedBy && (
              <LabelValue
                label="Authorized by"
                value={selectedBill?.authorizedBy}
                loading={loading}
                placeholder={placeholder}
              />
            )}
          </Fragment>
        ) : null}

        {selectedBill.creditNotes?.length ? (
          <LabelValue
            label={"Credit notes applied"}
            value={moment(selectedBill?.creditNotes[0]?.date).format(
              "MMM DD, YYYY"
            )}
            loading={loading}
            placeholder={placeholder}
            labelUnderValue={
              selectedBill?.creditNotes.length + " Credit Note(s)"
            }
            renderHoverableDropdown={() => (
              <div
                style={{
                  overflowY: "hidden",
                  overflowX: "hidden",
                }}
              >
                {selectedBill?.creditNotes.map((note: any, index: number) => (
                  <div
                    key={note.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: "5px 0",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontWeight: "600",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Ref: {note.creditNoteNumber}
                    </div>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                      <div style={{ fontSize: 14, fontWeight: "600" }}>
                        {moment(note.date).format("MMM DD, YYYY")}
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {selectedBill?.currency}{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            color: COLORS.greenPrimary,
                          }}
                        >
                          {formatCurrency(parseFloat(note.amount || "0"), 2)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />
        ) : null}

        {selectedBill?.status === PaymentStatus.PendingAuthorization && (
          <Fragment>
            <LabelValue
              label="Queued At"
              value={moment(selectedBill?.queuedAt).format("MMM DD, YYYY")}
              loading={loading}
              placeholder={placeholder}
            />
            <LabelValue
              label="Queued by"
              value={selectedBill?.queuedBy}
              loading={loading}
              placeholder={placeholder}
            />
          </Fragment>
        )}

        <LabelValue
          label="Source"
          value={whichSource(selectedBill?.provider)}
          loading={loading}
          placeholder={placeholder}
          isValueBold
        />
        <Modal
          opened={showImage}
          onClose={() => setShowImage(false)}
          withCloseButton={false}
        >
          <img
            src={`${configs.urls.BASE_URL}/static${selectedBill?.image}`}
            alt=""
          />
        </Modal>

        <OpenBalance
          loading={loading}
          placeholder={placeholder}
          selectedBill={selectedBill}
        />
      </Card>
      <ManageRunwayCard
        isViewOnly={isViewOnly}
        record={selectedBill}
        loading={loading}
        placeholder={placeholder}
      />
    </Fragment>
  );

  const renderForecastOrBudget = () => (
    <Fragment>
      <Card className="details-card-container" style={{ marginBottom: 20 }}>
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={() => {
            setIsDetailsOnlyModal(true);
            setEditForecastBudgetModal(true);
          }}
          hasBadge={false}
          buttonDisabled={loading || placeholder}
          title="Estimate Details"
        />

        <LabelValue
          label="Estimate name"
          value={selectedBill?.name}
          loading={loading}
          placeholder={placeholder}
        />
    
        {/* {isEstimates ? (
          <LabelValue
            label="Total Amount"
            value={`${selectedBill?.currency} ${selectedBill?.amount || "0"}`}
            loading={loading}
            placeholder={placeholder}
          />
        ) : ( */}
        <LabelValue
          label="Total Amount"
          value={`${selectedBill?.currency} ${formatCurrency(
            parseFloat(selectedBill?.amount || "0"),
            2
          )}`}
          loading={loading}
          placeholder={placeholder}
        />
        {/* )} */}

        <LabelValue
          label="Current estimate date"
          value={moment(selectedBill?.dueDate).format("MMM DD, YYYY")}
          loading={loading}
          placeholder={placeholder}
        />
      </Card>

      <Card className="details-card-container">
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={() => {
            setIsDetailsOnlyModal(false);
            setEditForecastBudgetModal(true);
          }}
          hasBadge={false}
          buttonDisabled={loading || placeholder}
          title="Estimate Settings"
        />

        <LabelValue
          label="Estimate name"
          value={selectedBill?.name}
          loading={loading}
          placeholder={placeholder}
        />

        {isEstimates ? (
          <LabelValue
            label="Total Amount"
            value={`${selectedBill?.currency} ${selectedBill?.amount || "0"}`}
            loading={loading}
            placeholder={placeholder}
          />
        ) : (
          <LabelValue
            label="Total Amount"
            value={`${selectedBill?.currency} ${formatCurrency(
              parseFloat(selectedBill?.settings?.amount || "0"),
              2
            )}`}
            loading={loading}
            placeholder={placeholder}
          />
        )}

        <LabelValue
          label="Starting estimate date"
          value={moment(selectedBill?.settings?.startForecastDate).format(
            "MMM DD, YYYY"
          )}
          loading={loading}
          placeholder={placeholder}
        />

        <LabelValue
          label="End estimate date"
          value={moment(selectedBill?.settings?.endForecastDate).format(
            "MMM DD, YYYY"
          )}
          loading={loading}
          placeholder={placeholder}
        />

        <LabelValue
          label="Repeats"
          value={selectedBill?.settings?.repeats}
          loading={loading}
          placeholder={placeholder}
        />

        <LabelValue
          label="Does the amount change over time?"
          value={
            selectedBill?.settings?.valueForChangeInAmount
              ? "Yes"
              : "No, it’s Fixed"
          }
          loading={loading}
          placeholder={placeholder}
        />

        {/* <LabelValue
          label="Cashflow category"
          value={selectedBill?.settings?.cashflowCategory}
          loading={loading}
          placeholder={placeholder}
        /> */}
        <If
          condition={
            selectedBill?.settings?.cashflowCategoryMappings?.length > 0
          }
        >
          <MapCategory
            mode="view"
            mappings={selectedBill?.settings?.cashflowCategoryMappings}
            showTitle={false}
          />
        </If>
        {/*
        <LabelValue
          label="Budget"
          value={selectedBill?.settings.repeats}
          loading={loading}
          placeholder={placeholder}
        /> */}
      </Card>

      <AddEditForecastBudgetModal
        opened={editForecastBudgetModal}
        onClose={() => setEditForecastBudgetModal(false)}
        type="forecast"
        isDetailsOnly={isDetailsOnlyModal}
        data={selectedBill}
        section="Payable"
      />
    </Fragment>
  );

  return (
    <Fragment>
      <Helmet>
        <title>{selectedBill?.name}</title>
      </Helmet>
      <ShowDetailsCard
        renderTopHeader={renderTopHeader}
        renderCard={
          selectedForecastId ? renderForecastOrBudget : renderBillOrInvoice
        }
      />
    </Fragment>
  );
}

export default RightView;
