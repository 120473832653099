import React, { Fragment, useState } from "react";
import { useBanksContext } from "../context/BanksContext";
import Card from "../../../common/Card/Card";
import styled from "styled-components";
import { ButtonContainer } from "../../../common/CardButton/CardButton";
import { IMAGES } from "../../../constants";
import { ActionIcon, Image, Text } from "@mantine/core";
import { IconTrash, IconEdit } from "@tabler/icons";
import { removeBalanceHistoryBank } from "../../Cashflow/repository/cashflowConfigs";
import { notify } from "../../../modules/showNotification";
import { openConfirmModal } from "@mantine/modals";
import { COLORS } from "../../../constants/theme";
import { CustomDrawer } from "../../../common/CustomDrawer";
import CreateBank from "../ModalFrames/CreateBank";

function BanksList({ isViewOnly }: any) {
  const {
    banks,
    setSelectedBank,
    searchParams,
    setSearchParams,
    fetchLinkedBanks,
  } = useBanksContext();
  const [deleting, setDeleting] = useState(false);
  const [open, setOpen] = useState(false);

  const [selectedBankToEdit, setSelectedBankToEdit] = useState(null);

  const onClose = () => {
    setOpen(false);
  };

  const onDeleteBank = async (bankId: string) => {
    setDeleting(true);
    const { success } = await removeBalanceHistoryBank(bankId);
    searchParams.delete("selectedBankId");
    setSearchParams(searchParams);
    setDeleting(false);
    fetchLinkedBanks();
    notify({
      success: success && "Bank deleted successfully",
      error: !success && "Error while deleting bank",
      // onSuccess: () => getCashflowReport(),
    });
  };

  const confirmDeletion = (bank: any) =>
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Are you sure you would like to delete <strong>{bank.bankName}</strong>
          ?{" "}
        </Text>
      ),
      styles: {},
      confirmProps: {
        style: {
          backgroundColor: COLORS.greenPrimary,
        },
        loading: deleting,
      },
      groupProps: {
        style: {
          flexDirection: "row-reverse",
          justifyContent: "center",
        },
      },
      labels: { confirm: "Yes delete", cancel: "No, Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: async () => {
        onDeleteBank(bank.bankId);
      },
    });

  return (
    <Fragment>
      <BanksContainer className="custom-scrollbar">
        {banks.map((bank) => {
          return (
            <Fragment key={bank.bankId}>
              <div className="is-flex">
                <ButtonContainer
                  className="is-flex is-align-items-center "
                  style={{
                    width: "430px",
                    minHeight: 80,
                    gap: 5,
                  }}
                  onClick={() => {
                    searchParams.set("selectedBankId", bank.bankId);
                    setSearchParams(searchParams);
                    //   setSelectedBank(bank);
                  }}
                >
                  <Image
                    width={60}
                    height={60}
                    src={IMAGES.bank_circular_logo}
                  />
                  <BankName>{bank.bankName} </BankName>
                </ButtonContainer>

                <ActionIcon
                  disabled={isViewOnly}
                  className="mt-3"
                  variant="default"
                >
                  <IconTrash onClick={() => confirmDeletion(bank)} />
                </ActionIcon>
                <ActionIcon
                  disabled={isViewOnly}
                  className="mt-3 ml-1"
                  variant="default"
                >
                  <IconEdit
                    onClick={() => {
                      setSelectedBankToEdit(bank);
                      setOpen(true);
                    }}
                  />
                </ActionIcon>

                <CustomDrawer
                  opened={open}
                  onBack={onClose}
                  onClose={onClose}
                  title={"Edit bank"}
                >
                  <EditBankContainer>
                    <CreateBank
                      mode="edit"
                      onSubmit={() => {
                        fetchLinkedBanks();
                        onClose();
                      }}
                      selectedBankToEdit={selectedBankToEdit}
                    />
                  </EditBankContainer>
                </CustomDrawer>
              </div>
            </Fragment>
          );
        })}
      </BanksContainer>
    </Fragment>
  );
}

export default BanksList;

const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
`;

const BankName = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;

  white-space: break-spaces;
  text-align: left;
`;
const BanksContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  max-height: 75vh;
  flex-wrap: wrap;
  overflow-y: auto;
`;

const EditBankContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 31px 32px;
  width: 100%;
`;
