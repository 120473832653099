import { LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { COLORS } from "../../constants/theme";
import axios, { AxiosResponse } from "axios";
import { configs } from "../../constants";
import { signJWTToken } from "../../repositories/tokenRepository";
import { setUserLS } from "../../modules/localStorage";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { tscLogoWithName, tsc_logo } from "../../constants/images";
import {
  getAllOrganizationsOfCurrentUser,
  getCurrentMemberShip,
  getUserWithActiveOrganization,
  isEmailVerified,
} from "../../repositories/userRepository";
import { NEXT_STAGE } from "../../constants/globalConstants";
import { useAuth } from "../../contexts";
import { setActiveOrganization } from "../../repositories/organizationRepository";

function XeroSignUpRedirect() {
  const { userId, isLogin, userExists } = useParams();
  const navigate = useNavigate();
  const location: any = useLocation();

  const fetchUserById = async () => {
    try {
      if (userExists === "true") {
        showNotification({
          color: "red",
          message: "User already exists, please login.",
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });

        localStorage.clear();
        navigate("/");
        return;
      }

      const res = await axios.post(configs.urls.BASE_URL + "/user/getById", {
        userId: userId,
      });

      if (res.status === 200) {
        const id = res.data.response.id;
        const userEmail = res.data.response.email;
        const firstName = res.data.response.firstName;

        localStorage.setItem("userId", id);
        localStorage.setItem("email", userEmail);
        const obj = { email: userEmail, password: "5a4E3b2d1C" };
        const token = await signJWTToken(obj);
        localStorage.setItem("token", token);
        setUserLS({ firstName: firstName });

        navigate("/account-type-selection");
      }
    } catch (error) {
      console.log("Error in fetchUserById: ", error);
    }
  };

  const login = async () => {
    if (userExists === "false") {
      showNotification({
        color: "red",
        message: "User is not registered, please register with xero first.",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });

      localStorage.clear();
      navigate("/signup");
      return;
    }

    const res = await axios.post(configs.urls.BASE_URL + "/user/getById", {
      userId: userId,
    });

    if (res.status === 200) {
      const username = res.data.response.email;
      const password = "5a4E3b2d1C";

      const { isXero } = res.data.response;

      if (!isXero) {
        showNotification({
          color: "red",
          message:
            "Login by entering email and password, your account is not registered with Xero",
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });

        localStorage.clear();
        navigate("/");
        return;
      }

      const options = {
        headers: { "content-type": "application/json" },
      };
      axios.defaults.withCredentials = true;
      axios
        .post(
          configs.urls.BASE_URL + "/login",
          {
            username,
            password,
          },
          options
        )
        .then(
          async (res: AxiosResponse) => {
            const {
              response: { id = "", email = "", lastStage = "" },
            } = res.data;

            const user = (await getUserWithActiveOrganization(id)) || {};
            const {
              isXero,
              isDemo,
              id: orgId,
              organizationName,
              completedTutorial,
              createdAt,
              trialExpiryDate,
            } = user;

            setUserLS({
              id,
              email,
              lastStage,
              isDemo,
              organizationId: orgId,
              organizationName,
              completedTutorial,
              createdAt,
            });

            if (res.status === 401) {
              showNotification({
                color: "red",
                message: "User not found!",
                icon: <FontAwesomeIcon icon={faTimes} />,
                autoClose: 2000,
              });
            }

            if (!res.data.success) {
              showNotification({
                color: "red",
                message: "Wrong Credentials!",
                icon: <FontAwesomeIcon icon={faTimes} />,
                autoClose: 2000,
              });
              return;
            }
            localStorage.setItem("userId", id);
            localStorage.setItem("email", email);

            const dontShowDate = new Date("2024-06-03");
            const createdAtDate = new Date(createdAt);

            if (!trialExpiryDate && isDemo && createdAtDate >= dontShowDate) {
              localStorage.setItem("showUpgradeModal", "true");
            }

            const emailVerified = await isEmailVerified(id);

            if (!emailVerified) {
              window.location.href = "verify-email";
              return;
            }
            if (!lastStage) {
              window.location.href = "companies?tab=all";
              return;
            }

            const url = NEXT_STAGE[lastStage];
            if (url) {
              navigate(`/${url}`);
              return;
            }
            if (location.state?.from.pathname.includes("/accept_invitation")) {
              window.location.href = location.state?.from?.pathname;
              return;
            }

            if (orgId) {
              const { organizations } =
                await getAllOrganizationsOfCurrentUser();

              const { id } = await getUserWithActiveOrganization();

              await setActiveOrganization(id);

              const org =
                organizations.find(({ value }: any) => value == id) || {};
              const {
                isDemo = false,
                completedTutorial = false,
                createdAt,
              } = org;

              if (org) {
                const userMembership = await getCurrentMemberShip(id);
                localStorage.setItem("roleType", userMembership?.role?.type);
              }

              setUserLS({
                isDemo,
                completedTutorial: completedTutorial ? true : false,
                createdAt,
              });
              localStorage.setItem("organizationId", id);
            }

            if (isDemo) {
              window.location.href = "cashflow?tab=forecast";
            } else {
              window.location.href = "/get-started";
            }
          },
          (err) => {
            if (JSON.stringify(err).includes("401")) {
              showNotification({
                color: "red",
                message: `Cannot login, user not found! Please Signup first.`,
                icon: <FontAwesomeIcon icon={faTimes} />,
                autoClose: 2000,
              });
            } else {
              showNotification({
                color: "red",
                message: `${err}`,
                icon: <FontAwesomeIcon icon={faTimes} />,
                autoClose: 2000,
              });
            }
          }
        );
    }
  };

  useEffect(() => {
    if (isLogin === "true") {
      login();
    } else {
      fetchUserById();
    }
  }, [userId, isLogin]);

  return (
    <div>
      <LoadingOverlay
        loaderProps={{
          size: "xl",
          color: COLORS.greenBlue,
          variant: "oval",
        }}
        overlayOpacity={0.8}
        visible={true}
      />
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "58%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
          fontSize: "1.2rem",
          fontWeight: "bolder",
        }}
      >
        Loading...
      </div>
    </div>
  );
}

export default XeroSignUpRedirect;
