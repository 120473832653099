import axios from "axios";
import { CreateCategoriesTemplatePayload } from "../constants/payloads";
import { configs } from "../constants";
import { ROUTES } from "../constants/routes";

export const upsertCategoriesTemplate = async (
  payload: CreateCategoriesTemplatePayload
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL +
        ROUTES.CashflowCategoriesTemplate.Base +
        "/create",
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
