import {
  faCheck,
  faCheckCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import React, { Fragment } from "react";
import { IconButton } from "rsuite";
import styled from "styled-components";
import { ButtonContainer } from "../../../common/CardButton/CardButton";
import { IMAGES, StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { getAlpha2CodeForCountry } from "../../../modules/getAlpha2CodeForCountry";
import { deleteBank } from "../../../repositories/contactRepository";
import { useContactsContext } from "../contexts/ContactsContext";

function ListBanks({ handleNext }: any) {
  const { banks, contact, disbursement, setBanks, onClose } =
    useContactsContext();

  const { fetchExternalAccounts } = useAuth();

  const onDeleteBank = (bank: any) => {
    window.confirm("Are you sure you want to delete this bank?") &&
      deleteBank({
        bankId: bank.id,
        beneficiaryId: disbursement?.id,
        contactId: contact.id,
        onComplete: () => {
          showNotification({
            title: "Bank deleted successfully",
            message: "Bank deleted successfully",
            color: "green",
            icon: <FontAwesomeIcon icon={faCheck} />,
          });
          fetchExternalAccounts();
          setBanks((prev: any) => {
            const newBanks = prev.filter((b: any) => b.id !== bank.id);
            if (newBanks.length === 0) {
              onClose();
            }
            return newBanks;
          });
        },
        onError: (error: any) => {
          showNotification({
            title: "Error deleting bank",
            message: error,
            color: "red",
            icon: <FontAwesomeIcon icon={faTrash} />,
          });
        },
      });
  };

  return (
    <Fragment>
      {banks.map((bank: any) => (
        <Container>
          <ButtonContainer
            style={{ margin: 10, padding: 10, width: "100%" }}
            className="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-flex-start "
            onClick={() => handleNext(bank)}
          >
            <div className="is-flex is-flex-direction-row is-align-items-center">
              <StyledComponents.ImageOnListItemCard
                style={{
                  width: 50,
                  height: 50,
                }}
                src={IMAGES.mini_bank_logo}
              />
              <StyledComponents.NameOnListItemCard
                style={{ fontWeight: "600" }}
              >
                {bank.bankName}
              </StyledComponents.NameOnListItemCard>
            </div>
            {bank.accounts?.map((account: any) => {
              let iban = account.iban;

              // format iban with spaces and to upper case

              // remove space from iban
              //   iban = iban?.replace(/\s/g, "");
              //   // shrink iban with ...
              //   if (iban?.length > 15) {
              //     iban = ` <abbr style="text-decoration: none !important;" title=${iban}><span>${iban?.slice(
              //       0,
              //       4
              //     )}
              // ...
              // ${iban?.slice(iban.length - 4)}</span></abbr> `;
              //   }
              let country = bank.address.country;

              return (
                <div
                  className="ml-1"
                  style={{ fontSize: "20px", color: COLORS.brownishGrey }}
                >
                  <span
                    className={`fi fi-${getAlpha2CodeForCountry(
                      country || "United Arab Emirates"
                    )} m-2`}
                  ></span>{" "}
                  | {contact?.displayName || contact?.name} |{" "}
                  {/* IBAN: AE02…6500 */}
                  <span dangerouslySetInnerHTML={{ __html: iban }}></span>
                </div>
              );
            })}
          </ButtonContainer>
          <Actions
            onClick={() => onDeleteBank(bank)}
            icon={<img src={IMAGES.deleteIcon} alt="delete" />}
          />
        </Container>
      ))}
    </Fragment>
  );
}

export default ListBanks;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 37%;
  @media screen and (max-width: 1600px) {
    width: 57%;
  }
`;

const Actions = styled(IconButton)`
  width: 50px;
  height: 50px;
`;
