import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification, updateNotification } from "@mantine/notifications";
import axios from "axios";
import { configs } from "../constants";

export const getZohoProfitAndLossReport = async (
  startDate: string,
  endDate: string
) => {
  const { data } = await axios.get(configs.urls.BASE_URL + "/zoho/pnl-report", {
    params: { startDate, endDate },
    withCredentials: true,
  });
  return data;
};

export const disconnectFromZoho = () => {
  showNotification({
    id: "disconnect",
    loading: true,
    title: "Disconnecting...",
    message: "Please wait while we disconnect you from Zoho",
    autoClose: false,
    disallowClose: true,
  });
  axios
    .get(configs.urls.BASE_URL + "/zoho/disconnect", {
      withCredentials: true,
    })
    .then(
      (res) => {
        if (res.data) {
          updateNotification({
            id: "disconnect",
            color: "teal",
            message: "Disconnected Successfully!",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      (err) => {
        updateNotification({
          id: "disconnect",
          color: "red",
          message: "Cannot Disconnect",
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      }
    );
};

export const connectZoho = () => {
  axios.get(configs.urls.BASE_URL + "/zoho").then((res) => {
    const url = res.data;
    window.open(url, "_self");
  });
};
