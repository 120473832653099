import "./styles.css";

import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

import { verify_email_icon } from "../../constants/images";
import {
  deleteUser,
  resendVerificationCode,
  verifyEmail,
} from "../../repositories/userRepository";

const codeCount = 4;
const VerifyEmail = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem("email");
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const location = useLocation();
  const { accountType } = location.state as any;

  const [hasShadow, showShadow] = useState(false);
  const [code, setCode] = useState(new Array(codeCount));

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handlePassCodeChange = async (e: any) => {
    e.preventDefault();

    const { id, value, nextElementSibling, previousElementSibling } =
      e.target as any;
    code[Number(id)] = value;
    setCode(code);

    if (value !== "" && nextElementSibling) {
      nextElementSibling.focus();
    }
    if (value === "" && previousElementSibling) {
      previousElementSibling.focus();
    }

    if (code.join("").length !== 4) {
      return;
    }
    const userId = localStorage.getItem("userId");
    const result = await verifyEmail(userId || "", code.join(""));
    if (result) {
      // @ts-ignore
      window.gtag("event", "conversion", {
        send_to: "AW-709694029/48tPCIC535gYEM2ktNIC",
      });
      navigate("/tell-us-who-you-are", {
        state: { accountType },
      });
    } else {
      showNotification({
        color: "red",
        message: "Email verification code is incorrect!",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const resendEmailVerificationCode = async () => {
    const userId = localStorage.getItem("userId");
    const codeSent = await resendVerificationCode(userId || "");
    if (!codeSent) {
      return;
    }
    showNotification({
      color: "green",
      message: "New Verification Code Sent! Please check your email",
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      autoClose: 2000,
    });
  };

  const changeEmailAddress = async () => {
    const id = localStorage.getItem("userId");
    const isDeleted = await deleteUser(id || "");
    if (isDeleted) {
      navigate("/signup");
    }
  };
  return (
    <div
      className="card-outer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        onMouseOver={() => showShadow(true)}
        onMouseOut={() => showShadow(false)}
        className={`card-inner-container ${hasShadow ? "card" : ""}`}
        style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
      >
        <div className="card-content verify-email-card">
          <div className="content">
            <div className="verify-email-container">
              <img className="verify-email-image" src={verify_email_icon}></img>
              <p className="verify-email">Verify your email</p>
              <div className="verify-box-container">
                {getInputBlocks(handlePassCodeChange, inputRefs)}
              </div>
            </div>

            <p className="verify-email-question">We sent a 4-digit code to</p>
            <p className="user-email">{email}</p>

            <p className="verify-email-question">Can't find the code</p>
            <p className="verify-email-answer">
              Check your spam folder or resend the code &nbsp;
              <span
                className="verify-email-link"
                onClick={resendEmailVerificationCode}
              >
                here
              </span>
            </p>
            <p className="verify-email-question">Incorrect Email?</p>
            <p className="verify-email-answer">
              Change email address &nbsp;
              <span className="verify-email-link" onClick={changeEmailAddress}>
                here
              </span>
            </p>
            <p className="verify-email-question">Sincerely</p>
            <p className="verify-email-yours-sincerely">
              The Scalable CFO team
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const getInputBlocks = (
  handlePassCodeChange: (e: any) => void,
  inputRefs: any
) => {
  const blocks = [];
  for (let count = 0; count < codeCount; count++) {
    blocks.push(
      <input
        type="text"
        id={String(count)}
        onChange={handlePassCodeChange}
        ref={(el) => (inputRefs.current[count] = el)}
        maxLength={1}
        className="verify-box"
      />
    );
  }
  return blocks;
};
export default VerifyEmail;
