export const getPercentageDiff = (from: number, to: number = 0): number => {
  if (from === 0 && to === 0) {
    return 0;
  }

  if (to === 0) {
    return from < 0 ? -100 : 100; // -100% for negative num1, 100% for positive num1
  }
  const diff = from - to;
  const avg = (from + to) / 2;
  const relativeDiff = (diff / avg) * 100;
  return relativeDiff;
};

export const getValueDiff = (from: number, to: number = 0) => from - to;
