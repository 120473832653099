import React from "react";
import { Formik, Form, Field, ErrorMessage, FormikErrors } from "formik";
import { Modal } from "@mantine/core";
import { Button } from "rsuite";
import axios from "axios";
import { configs } from "../../../constants";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

// Shape of form values
interface FormValues {
  displayName: string;
  name: string;
  bankIdentifier: string;
  address: string;
  city: string;
  country: string;
  accountNumber: string;
  swiftCode: string;
  iban: string;
}

const ErrorMsg = ({ name, className }: any) => (
  <ErrorMessage className={className} name={name} component="p">
    {(errMsg: string) => <p>{errMsg}</p>}
  </ErrorMessage>
);

const CustomField = ({
  errors,
  type = "text",
  label,
  name,
  touched = {},
}: any) => (
  <div className="field">
    <label className="label">{label}</label>
    <div className="control">
      <Field
        className={`input ${errors[name] && touched[name] ? "is-danger" : ""}`}
        type={type}
        name={name}
      />
    </div>
    <ErrorMessage name={name} component="div">
      {(msg) => (
        <div
          style={{ fontWeight: "700" }}
          className="help is-danger"
          dangerouslySetInnerHTML={{ __html: msg }}
        />
      )}
    </ErrorMessage>
  </div>
);

const createDestination = (
  values: FormValues,
  setSubmitting: any,
  onClose: any
) => {
  // axios
  //   .post(configs.urls.BASE_URL + "/createExternalAccount", values, {
  //     withCredentials: true,
  //   })
  //   .then((res) => {
  //     console.log("createExternalAccount", res);
  //     if (res.data === "duplicate_error") {
  //       showNotification({
  //         color: "red",
  //         title: "Cannot create duplicate account",
  //         message: `Account already exists with given IBAN`,
  //         icon: <FontAwesomeIcon icon={faTimes} />,
  //         autoClose: 2000,
  //       });
  //       setSubmitting(false);
  //     } else {
  //       // show success notification
  //       setTimeout(() => {
  //         showNotification({
  //           color: "teal",
  //           message: "A new account has been created!",
  //           icon: <FontAwesomeIcon icon={faCheck} />,
  //           autoClose: 2000,
  //         });
  //         setSubmitting(false);
  //         onClose();
  //       }, 2000);
  //     }
  //   })
  //   .catch((err) => {
  //     showNotification({
  //       color: "red",
  //       message: `${err}`,
  //       icon: <FontAwesomeIcon icon={faTimes} />,
  //       autoClose: 2000,
  //     });
  //     setSubmitting(false);
  //   });
};

const CreateDestination = ({ opened, onClose }: any) => (
  <Modal
    opened={opened}
    onClose={onClose}
    overlayColor={"#ccc"}
    overlayOpacity={0.55}
    overlayBlur={3}
    size="lg"
    title="Create Payment Destination"
    closeOnClickOutside={false}
  >
    <div>
      <Formik
        initialValues={{
          // displayName: "",
          // name: "",
          // bankIdentifier: "",
          // address: "",
          // city: "",
          // country: "",
          // accountNumber: "",
          // swiftCode: "",
          // iban: "",
          displayName: "Acme USD Account",
          name: "Acme Inc",
          bankIdentifier: "ENBD_UAE",
          address: "123 Fake St.",
          city: "Dubai",
          country: "ARE",
          accountNumber: "1008223921001",
          swiftCode: "EBILAEAD",
          iban: "AB12 1234 1234 1234 1234",
        }}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};
          //   displayName: string;
          if (!values.displayName) {
            errors.displayName = "Display Name Required";
          }
          //   name: string;
          if (!values.name) {
            errors.name = "Name Required";
          } else if (!/^[a-zA-Z0-9\s_]*$/g.test(values.name)) {
            errors.name = "Name should be only alphanumeric.";
          }
          //   bankIdentifier: string;
          if (!values.bankIdentifier) {
            errors.bankIdentifier = "Bank Identifier Required";
          }
          //   address: string;
          if (!values.address) {
            errors.address = "Address Required";
          }
          //   city: string;
          if (!values.city) {
            errors.city = "City Required";
          }
          //   country: string; /^[^a-z]*$/
          if (!values.country) {
            errors.country = "Country Required";
          } else if (!/^[A-Z]{3}$/g.test(values.country)) {
            errors.country = "Country must be uppercase 3 letter code";
          }
          //   accountNumber: number; (/^[0-9]+$/)
          if (!values.accountNumber) {
            errors.accountNumber = "Account Number Required";
          } else if (!/^[0-9]+$/i.test(values.accountNumber)) {
            errors.accountNumber = "Account Number must be a number";
          }
          //   swiftCode: string; /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
          if (!values.swiftCode) {
            errors.swiftCode = "Swift Code Required";
          } else if (
            !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(values.swiftCode)
          ) {
            errors.swiftCode = `<div>Swift Code must be 8 or 11 upper case alphanumeric characters & comprised of:
                <div style="font-weight: 700">* 4 alpha character bank code then </div>
                <div style="font-weight: 700">* 2 character ISO 3166-1 country code then</div>
                <div  style="font-weight: 700">* 2 character alphanumeric location code and an optional</div>
                <div  style="font-weight: 700">* 3 character alphanumeric branch code</div></div>`;
          }

          //   iban: string;
          //

          if (!values.iban) {
            errors.iban = "IBAN Required";
          } else if (
            !/^\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b$/.test(
              values.iban
            )
          ) {
            errors.iban = "Invalid iban.";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            createDestination(values, setSubmitting, onClose);
          }, 400);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <div className="columns">
              <div className="column is-half">
                <CustomField
                  errors={errors}
                  touched={touched}
                  label="Display Name"
                  name="displayName"
                />
                <CustomField
                  errors={errors}
                  touched={touched}
                  label="Name"
                  name="name"
                />
                <CustomField
                  errors={errors}
                  touched={touched}
                  label="City"
                  name="city"
                />
                <CustomField
                  errors={errors}
                  touched={touched}
                  label="Country"
                  name="country"
                />
              </div>
              <div className="column is-half">
                <CustomField
                  errors={errors}
                  touched={touched}
                  label="Bank Identifier"
                  name="bankIdentifier"
                />
                <CustomField
                  touched={touched}
                  errors={errors}
                  label="Account Number"
                  name="accountNumber"
                />
                <CustomField
                  touched={touched}
                  errors={errors}
                  label="Swift Code"
                  name="swiftCode"
                />
                <CustomField
                  errors={errors}
                  touched={touched}
                  label="IBAN"
                  name="iban"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Address</label>
              <div className="control">
                <Field
                  className={`input ${
                    errors.address && touched.address ? "is-danger" : ""
                  }`}
                  name="address"
                />
              </div>
              <ErrorMessage
                className="help is-danger"
                name="address"
                component="div"
              />
            </div>

            <div
              className="mt-3 flex-direction-row"
              style={{ justifyContent: "flex-end" }}
            >
              <Button
                appearance="default"
                onClick={() => {
                  onClose();
                }}
                style={{ marginRight: "1rem" }}
              >
                Cancel
              </Button>
              <Button
                appearance="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </Modal>
);

export default CreateDestination;

/**
 * <Field type="email" name="email" />
            <ErrorMsg name="email" />
            <Field type="password" name="password" />

            <ErrorMsg name="password" />
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
 */
