import React from "react";
import styled from "styled-components";
import { useAuth } from "../../contexts";

let pricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
let publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

function PricingPage() {
  const { user } = useAuth();
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <PricingContainer>
      <stripe-pricing-table
        // pricing-table-id="prctbl_1NcmWOF93NLsr0vsygZaikiD"
        // publishable-key="pk_test_51IyqHeF93NLsr0vsE4tk9nNKvKh6utqDydIbTp9JsUwYfl0vsFz5KmkYh2HMc1GyjYYKfIMy0xX70VTMOc7B5hUu0010tL2MAg"
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
        // client-reference-id="cus_OQgoULRlLvyjhu"
        customer-email={user?.email}
      ></stripe-pricing-table>
    </PricingContainer>
  );
}

export default PricingPage;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const PricingContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);

  width: 100%;
`;
