import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { configs } from "../../../constants";

export const authorizePayments = async (
  keys: any[],
  onComplete: any = () => {}
) => {
  axios
    .post(
      configs.urls.BASE_URL + "/invoice/authorizePayments",
      {
        ids: keys,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data.success) {
        showNotification({
          title: "Success",
          message: res.data.response,
          color: "green",
        });
      } else {
        showNotification({
          title: "Error",
          message: res.data.response,
          color: "red",
        });
      }
    })
    .catch((err) => {
      showNotification({
        title: "Error",
        message: "Error authorizing payments",
        color: "red",
      });
    })
    .finally(() => {
      onComplete();
    });
};

export const rejectPayments = async (
  keys: any[],
  onComplete: any = () => {}
) => {
  axios
    .post(
      configs.urls.BASE_URL + "/invoice/rejectPayments",
      {
        ids: keys,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data.success) {
        showNotification({
          title: "Success",
          message: res.data.response,
          color: "green",
        });
      } else {
        showNotification({
          title: "Error",
          message: res.data.response,
          color: "red",
        });
      }
    })
    .catch((err) => {
      showNotification({
        title: "Error",
        message: "Error rejecting payments",
        color: "red",
      });
    })
    .finally(() => {
      onComplete();
    });
};

export const updateRecord = async (
  id: any,
  fieldsToUpdate: any,
  onComplete: any = () => {}
) => {
  axios
    .patch(
      configs.urls.BASE_URL + "/invoice/update/" + id,
      {
        fieldsToUpdate: fieldsToUpdate,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.data.success) {
        showNotification({
          title: "Success",
          message: res.data.response,
          color: "green",
        });
      } else {
        showNotification({
          title: "Error",
          message: res.data.errors,
          color: "red",
        });
      }
    })
    .catch((err) => {
      showNotification({
        title: "Error",
        message: "Error while updating record",
        color: "red",
      });
    })
    .finally(() => {
      onComplete();
    });
};
