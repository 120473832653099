import "../../../../pages/SignUp/styles.css";
import "../../../../pages/index.css";

import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { Link, useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { configs } from "../../../../constants";
import { tscLogoWithName, tsc_logo } from "../../../../constants/images";
import { signJWTToken } from "../../../../repositories/tokenRepository";
import { useFormik } from "formik";
import { Input, PasswordInput } from "@mantine/core";
import styled from "styled-components";
import { If } from "../../../../common/Utils/If";
import PasswordRequired from "../../../SignUp/components/PasswordRequired";
import SubmitButton from "../../../SignUp/components/SubmitButton";
import { setUserLS } from "../../../../modules/localStorage";

interface ISignUp {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export default function SignUpInviteAccept() {
  // const [email, setEmail] = useState<string>("");
  // const [password, setPassword] = useState<string>("");
  const [hasShadow, showShadow] = useState(false);

  const { randomNumber, role, organizationId } = useParams();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const register = (values: ISignUp) => {
    const { firstName, lastName, email, password } = values;
    if (email === "") {
      showNotification({
        color: "red",
        message: "Please enter a email!",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
      return;
    }

    if (password === "") {
      showNotification({
        color: "red",
        message: "Please enter a password!",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
      return;
    }

    axios
      .post(configs.urls.BASE_URL + "/register", {
        email,
        password,
        firstName,
        lastName,
      })
      .then(
        async (res: AxiosResponse) => {
          const { success, response: { id = "", email = "" } = {} } = res.data;

          if (success) {
            window.localStorage.setItem("userId", id);
            window.localStorage.setItem("email", email);
            const obj = { email, password };
            const token = await signJWTToken(obj);
            localStorage.setItem("token", token);
            setUserLS({ firstName: firstName });
            localStorage.setItem("invitationSignUp", "true");
            const forUser = id;
            const invitationLink = `/accept_invitation/${randomNumber}/${role}/${organizationId}/${forUser}`;
            localStorage.setItem("invitationLinkAfterSignup", invitationLink);
            localStorage.setItem("invitationForEmail", email);

            showNotification({
              color: "teal",
              message: "User created successfully!",
              icon: <FontAwesomeIcon icon={faCheck} />,
              autoClose: 2000,
            });
            setTimeout(() => {
              window.location.href = "/verify-email";
            }, 1000);
          } else {
            showNotification({
              color: "red",
              message: "User already exists!",
              icon: <FontAwesomeIcon icon={faTimes} />,
              autoClose: 2000,
            });
          }
        },
        (err) => {
          showNotification({
            color: "red",
            message: `${err}`,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      )
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const validate = (values: ISignUp) => {
    const errors: ISignUp = {} as ISignUp;

    if (!values.firstName) {
      errors.firstName = "First name required";
    }

    if (!values.lastName) {
      errors.lastName = "Last name required";
    }

    if (!values.email) {
      errors.email = "Email required";
    } else if (
      // is valid
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    onSubmit: register,
    validate: validate,
  });

  const { firstName, lastName, email, password } = formik.values;

  const isFieldError = (field: keyof ISignUp) => {
    return formik.touched[field] && formik.errors[field];
  };

  return (
    <div
      className="card-outer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        onMouseOver={() => showShadow(true)}
        onMouseOut={() => showShadow(false)}
        className={`card-inner-container ${hasShadow ? "card" : ""}`}
        style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
      >
        <div className="card-content">
          <div className="content">
            <div className="logo-container">
              <img
                src={tscLogoWithName}
                className="logo"
                alt="tscLogoWithName"
              />
            </div>

            <p className="already-have-account">
              Sign Up to accept the invitation
              {/* <Link to={{ pathname: "/login" }} className="sign-in-link">
                Sign In
              </Link> */}
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label className="label">First Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="John"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    value={firstName}
                  />
                  <If condition={isFieldError("firstName")}>
                    <Error>{formik.errors.firstName}</Error>
                  </If>
                </div>
              </div>
              <div className="field">
                <label className="label">Last Name</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Doe"
                    onChange={formik.handleChange}
                    value={lastName}
                  />
                  <If condition={isFieldError("lastName")}>
                    <Error>{formik.errors.lastName}</Error>
                  </If>
                </div>
              </div>
              <div className="field">
                <label className="label">Company Email</label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    placeholder="Email"
                    value={email}
                    name="email"
                    // onChange={formik.handleChange}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "email",
                        e.target.value?.toLocaleLowerCase()
                      )
                    }
                  />
                  <If condition={isFieldError("email")}>
                    <Error>{formik.errors.email}</Error>
                  </If>
                </div>
              </div>
              <div className="field">
                <label className="label">Password</label>
                <div className="control">
                  {/* <input
                    required
                    className="input"
                    type="password"
                    placeholder="Password"
                    value={password}
                    // onChange={(e) => setPassword(e.target.value)}
                  /> */}
                  <CustomMantinePasswordInput
                    withAsterisk
                    name="password"
                    placeholder="Your password"
                    onChange={formik.handleChange}
                    value={password}
                  />
                  <If condition={isFieldError("password")}>
                    <Error>{formik.errors.password}</Error>
                  </If>

                  {/* <PasswordRequired
                    value={password}
                    onChange={(e, isValid: boolean) => {
                      formik.handleChange(e);
                      formik.setFieldError(
                        "password",
                        isValid ? "" : "Required"
                      );
                    }}
                  /> */}
                </div>
              </div>
              <div
                className="field"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <SubmitButton loading={formik.isSubmitting} type="submit">
                  Sign Up
                </SubmitButton>
              </div>
            </form>
            <p className="my-2">
              By signing up to the platform you agree to the
              <br />
              <a
                href="https://www.thescalablecfo.com/terms_conditions/"
                className="sign-in-link"
                target="_blank"
                rel="noreferrer"
              >
                Terms of service
              </a>
              &nbsp; and &nbsp;
              <a
                href="https://www.thescalablecfo.com/terms_conditions/#lp-pom-block-251"
                className="sign-in-link"
                target="_blank"
                rel="noreferrer"
              >
                data privacy
              </a>
              &nbsp; herein.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const CustomMantinePasswordInput = styled(PasswordInput)`
  .mantine-PasswordInput-innerInput {
    font-size: 1rem;
  }
  .mantine-PasswordInput-input {
    border: none;
    outline: none;
    border-bottom: 1px solid #707070;
  }

  .mantine-InputWrapper-root {
    margin: 0;
    padding: 0;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.7rem;
  margin-top: 0.5rem;
`;
