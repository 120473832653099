export const ascendingSortByDate = (array: any, field: string) => {
  return array.sort(
    (a: any, b: any) =>
      new Date(a[field])?.getTime() - new Date(b[field])?.getTime()
  );
};

export const descendingSortByDate = (array: any, field: string) => {
  return array.sort(
    (a: any, b: any) =>
      new Date(b[field]).getTime() - new Date(a[field]).getTime()
  );
};
