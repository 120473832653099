import { cloneDeep } from "lodash";
import moment from "moment";

import { PaymentStatus } from "../../../../constants/globalConstants";
import { formatCurrency } from "../../../../modules";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";

export const searchFilter = (searchText: string, array: any[]) => {
  let filteredRecords: any = cloneDeep(array);

  filteredRecords = filteredRecords.filter(
    (record: any) =>
      record?.name.toLowerCase().includes(searchText.toLowerCase().trim()) ||
      (
        (record.status === PaymentStatus.Paid
          ? formatCurrency(
              parseFloat(
                getLatestTransaction(record?.linkedTransactions)?.amountPaid ||
                  "0"
              ),
              2
            )
          : formatCurrency(parseFloat(record.amountDue), 2)) || ""
      )
        .toLowerCase()
        .includes(searchText.toLowerCase().trim()) ||
      record.invoiceNumber
        .toLowerCase()
        .includes(searchText.toLowerCase().trim()) ||
      (record.status === PaymentStatus.Paid
        ? "Paid on " +
          moment(
            getLatestTransaction(record?.linkedTransactions)?.paidOrFailedAt
          ).format("MMM, DD, YYYY")
        : "Due " + `${moment(record.dueDate).format("MMM, DD, YYYY")}`
      )
        .toLowerCase()
        .includes(searchText.toLowerCase().trim())
  );
  return filteredRecords;
};
