import PageLayout from "../../common/PageLayout/PageLayout";
import LeftView from "./layouts/LeftView";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import RightView from "./layouts/RightView";
import AddContact from "./components/AddContact";
import ContactsProvider from "./contexts/ContactsContext";

function Contacts() {
  useScrollBlock();

  return (
    <ContactsProvider>
      <AddContact />
      <PageLayout left={<LeftView />} right={<RightView />} />
    </ContactsProvider>
  );
}

export default Contacts;
