import { RefObject, useEffect, useState } from "react";

type ResizableColumnWidths = number[];

type UseResizableColumnsReturnType = [
  ResizableColumnWidths,
  (event: React.MouseEvent<HTMLTableHeaderCellElement>, index: number) => void
];

export function useResizableColumns(
  headerRef: RefObject<HTMLTableSectionElement>,
  initialWidths: ResizableColumnWidths
): UseResizableColumnsReturnType {
  const [widths, setWidths] = useState<ResizableColumnWidths>(initialWidths);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeIndex, setResizeIndex] = useState<number | null>(null);
  const [initialX, setInitialX] = useState<number | null>(null);

  useEffect(() => {
    if (!isResizing) return;

    function handleMouseMove(event: MouseEvent) {
      if (!resizeIndex || !initialX) return;

      const newWidths = [...widths];
      const diffX = event.pageX - initialX;
      const newWidth = Math.max(30, widths[resizeIndex] + diffX);
      newWidths[resizeIndex] = newWidth;
      setWidths(newWidths);
    }

    function handleMouseUp() {
      setIsResizing(false);
      setResizeIndex(null);
      setInitialX(null);
    }

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing, resizeIndex, initialX, widths]);

  function handleMouseDown(
    event: React.MouseEvent<HTMLTableHeaderCellElement>,
    index: number
  ) {
    setIsResizing(true);
    setResizeIndex(index);
    setInitialX(event.pageX);
  }

  return [widths, handleMouseDown];
}
