// import "../styles.css";

import React from "react";
import { Bar, Chart, Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Card from "../Card/Card";
import { CardSubTitle } from "../CardSubTitle";
import { GraphLegend } from "../GraphLegend";
import { MONTHS_LIST } from "../../constants/globalConstants";
import { getDateRangeMonths } from "../../modules/getDateRangeMonths";
import { COLORS } from "../../constants/theme";
import { CardTitle } from "../CardTitles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface LegendType {
  title: string;
  value: string;
  color: string;
}
interface DataSetType {
  label: string;
  data: any[];
  borderColor: string;
}
interface LineChartType {
  title: string;
  subTitle: string;
  legends: LegendType[];
  dataSets: DataSetType[];
  tension?: any;
  pointRadius?: any;
  isLineChart?: boolean;
}

function LineChart({
  title,
  subTitle,
  legends,
  dataSets,
  tension = 0.3,
  pointRadius = 0,
  isLineChart = false,
}: LineChartType) {
  const date = getDateRangeMonths(true);
  const graphData = dataSets.map((data) => ({ ...data, tension, pointRadius }));

  return (
    <Card className="m-2" style={{ padding: "41px", height: "65vh" }}>
      <CardTitle title={subTitle} />
      <CardSubTitle title={title} />
      <div className="legend-container mt-6">
        {legends.map(({ title, value, color }) => (
          <GraphLegend title={title} value={value} color={color} />
        ))}
      </div>

      {isLineChart ? (
        <Line
          height={"55vh"}
          width={"80vw"}
          options={{
            responsive: true,
            maintainAspectRatio: true,

            interaction: {
              intersect: false,
              mode: "index",
            },

            plugins: {
              legend: {
                display: false,
              },
            },
          }}
          data={{
            labels: date.months,
            datasets: graphData,
          }}
        />
      ) : (
        <Chart
          type="bar"
          className="mt-5"
          height={"120%"}
          data={{
            // labels: chartData?.months,
            datasets: [
              {
                type: "line" as const,
                label: "Closing Balance", //appTheme.COLORS.yellow
                borderColor: dataSets[0].borderColor,
                borderWidth: 3,
                data: dataSets[0].data,
                tension: 0.4,
                pointRadius: 2,
              },
              {
                type: "bar" as const,
                label: "Cash In",
                backgroundColor: dataSets[1].borderColor,
                borderColor: dataSets[1].borderColor,
                data: dataSets[1].data,
                borderWidth: 2,
                barThickness: 12,
              },
              {
                type: "bar" as const,
                label: "Cash Out",
                backgroundColor: dataSets[2].borderColor,
                borderColor: dataSets[2].borderColor,
                data: dataSets[2].data,
                borderWidth: 2,
                barThickness: 12,
              },
              {
                type: "bar" as const,
                label: "Unreconciled",
                backgroundColor: dataSets[3].borderColor,
                borderColor: dataSets[3].borderColor,
                data: dataSets[3].data,
                borderWidth: 2,
                barThickness: 12,
              },
            ],
            labels: date.months,

            // datasets: graphData,
          }}
          options={{
            elements: {
              point: {
                radius: 4,
                borderWidth: 3,
                borderColor: "#fff",
                hoverRadius: 7,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                  borderColor: "transparent",
                },
                ticks: {
                  color: "#5c5c5e",
                },
              },
              y: {
                stacked: true,
                grid: {
                  display: false,
                  borderColor: "transparent",
                },
                ticks: {
                  color: "#5c5c5e",
                  callback: function (_value) {
                    let value: number;
                    if (typeof _value === "string") {
                      value = parseFloat(_value);
                    } else {
                      value = _value;
                    }

                    if (value > 0) {
                      return Intl.NumberFormat("en", {
                        notation: "compact",
                      }).format(value);
                    } else if (value < 0) {
                      return `(${Intl.NumberFormat("en", {
                        notation: "compact",
                      }).format(Math.abs(value))})`;
                    } else {
                      return 0;
                    }
                  },
                },
              },
            },
          }}
        />
      )}
    </Card>
  );
}

export default LineChart;
