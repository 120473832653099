import { DatePicker } from "@mantine/dates";
import { Button, Select, Input } from "@mantine/core";
import styled from "styled-components";
import currencies from "../../assets/files/currencies.json";
import { calculateTotalAmount } from "../../modules/getTotalPaymentAmount";
import { Fragment } from "react";

function AddMorePayments({
  billCurrency,
  convertedTotal,
  setAmountIsConverted,
  data,
  handleDeletePayment,
  updateFieldInData,
}: any) {
  const totalAmount = calculateTotalAmount(data);

  // console.log("Add more payments data ... ", data);

  return (
    <>
      {data.map((row: any, index: any) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <CustomDatePicker
            size="md"
            clearable={false}
            value={new Date(row.paidOrFailedAt)}
            onChange={(date: any) =>
              updateFieldInData(index, "paidOrFailedAt", date)
            }
          />
          <Input
            size="md"
            type="text"
            value={row.amountPaid || ""}
            onChange={(e: any) =>
              updateFieldInData(index, "amountPaid", e.target.value)
            }
          />
          <Select
            readOnly={false}
            value={row.currency || "AED"}
            onChange={(selectedValue: any) =>
              updateFieldInData(index, "currency", selectedValue)
            }
            styles={{
              input: { marginTop: 4, fontWeight: "bold", fontSize: 18 },
            }}
            data={currencies}
            searchable
            maxDropdownHeight={400}
            nothingFound="Nothing here"
            filter={(value: any, item: any) =>
              item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.value.toLowerCase().includes(value.toLowerCase().trim())
            }
          />
          <Button
            variant="filled"
            color="pink"
            onClick={() => handleDeletePayment(index)}
          >
            Delete
          </Button>
        </div>
      ))}
      {convertedTotal > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Total Amount: {convertedTotal.toFixed(2)} {billCurrency}
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </>
  );
}
const CustomDatePicker = styled(DatePicker)`
  & input {
    min-height: "40px !important";
    border-radius: "0px !important";
  }
`;

export default AddMorePayments;
