import React from "react";
import styled from "styled-components";
import "./styles.css";

function ListContainer({
  children,
  style = {},
  className = "list-container",
}: any) {
  return (
    <div
      className={`${className}`}
      style={{
        // position: "relative",
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export default ListContainer;
