import axios from "axios";
import { configs } from "../constants";
import { ROUTES } from "../constants/routes";

export const doesDemoDataExists = async () => {
  try {
    const { data } = await axios.get(
      configs.urls.BASE_URL + ROUTES.DemoData.Base + "/exists",
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const deleteDemoData = async () => {
  try {
    const { data } = await axios.delete(
      configs.urls.BASE_URL + ROUTES.DemoData.Base + "/delete",
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
