import { Tabs } from "@mantine/core";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { COLORS } from "../../../constants/theme";

function CustomTabs({
  active,
  setActive,
  tabs,
  list,
  onSelect,
  style = {},
  tabSize = 14,
  totalRecords,
  tabPadding,
}: any) {
  const [_, setSearchParams] = useSearchParams();

  return (
    <div
      className=" is-flex is-flex-direction-row is-align-items-center"
      style={{ position: "relative", ...style }}
    >
      <Tabs
        onTabChange={(value: string) => {
          if (onSelect) {
            onSelect(value);
          } else {
            setSearchParams({ tab: value });
            setActive(value);
          }
        }}
        styles={(theme) => ({
          tab: {
            fontSize: tabSize,
            "&[data-active]": {
              color: "black",
              fontWeight: 700,
              padding: tabPadding,
              borderBottom: `2px solid ${COLORS.black}`,
              "&:hover": {
                borderColor: "black",
              },
            },
          },
        })}
        defaultValue={tabs[0].key}
        value={active || _.get("tab") || tabs[0].key}
      >
        <Tabs.List>
          {tabs.map((tab: any, index: number) => (
            <Tabs.Tab key={index} value={tab.key}>
              {tab.title}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>

      {/* <Tooltip
          style={{ position: "absolute", right: 0, top: 0 }}
          label="Delete all records"
        >
          <DeleteButton
            appearance="default"
            onClick={deleteRecords}
            icon={<FontAwesomeIcon icon={faTrash} />}
          />
        </Tooltip> */}
    </div>
  );
}

export default CustomTabs;
