import React, { Fragment } from "react";
import { Placeholder } from "rsuite";

const Details = ({ active = false, style, detailsWidth = 200 }: any) => (
  <div style={{ ...style }}>
    <p>
      <Placeholder.Graph
        active={active}
        width={100}
        height={15}
        style={{ marginTop: 13 }}
      />
    </p>
    <Placeholder.Graph active={active} width={detailsWidth} height={23} />
  </div>
);

const Avatar = ({ active = false, size = 1, isSquare, style }: any) => (
  <p>
    <Placeholder.Graph
      active={active}
      width={40 * size}
      height={40 * size}
      style={{ marginTop: 3, borderRadius: isSquare ? 0 : "50%", ...style }}
    />
  </p>
);

const Text = ({
  active = false,
  style,
  width = 150,
  height = 20,
  inline,
}: any) => (
  <Placeholder.Graph
    active={active}
    width={width}
    height={height}
    style={{
      marginTop: 3,
      ...style,
    }}
  />
);

export { Details, Avatar, Text };
