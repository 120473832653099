import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { useSteps } from "../hooks/useSteps";
import { usePayments } from "../hooks/usePayments";
import { useBill } from "../hooks/useBill";
import { useDrawer } from "../hooks/useDrawer";
import { useBeneficiary } from "../hooks/useBeneficiary";
import { useRecords } from "../hooks/useRecords";
import { useSearchParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { configs } from "../../../constants";
import { useAuth } from "../../../contexts";

import io from "socket.io-client";
const url = process.env.REACT_APP_SOCKET_URL || "http://localhost:4000";
const socket = io(url);

export const myContext = createContext<any>({});

export function usePaymentsContext() {
  return useContext(myContext);
}

export default function PaymentsContext(props: PropsWithChildren<any>) {
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState<string>("create");
  const [searchParams, setSearchParams] = useSearchParams();

  const { beneficiary, setBeneficiary, resetBeneficiary } = useBeneficiary();
  const { step, stepUp, stepDown, setStep } = useSteps();

  const { bill, setBill, resetBill } = useBill();
  const { drawer, showDrawer } = useDrawer();
  const { isUserPartOfOrganization } = useAuth();

  const billId = searchParams.get("selectedBill");
  const {
    selectedRecord,
    records,
    setRecords,
    selectRecord,
    getRecords,
    externalAccounts,
    fetchExternalAccounts,
    recordsReceivable,
    recordsLoading,
    getBillWithId,
    totalRecords,
  } = useRecords({ billId });

  const {
    ifBankConnected,
    customerId,
    destinationId,
    fetchPaymentSource,
    paymentSource,
    checkIfPaymentSourceExists,
    getDestinationId,
    endUserId,
  } = usePayments({ iban: selectedRecord?.name });

  const leanCallback = (payload: any) => {
    console.log("Callback triggered", payload);
    if (payload.status === "ERROR" || payload.status === "CANCELLED") {
      showNotification({
        color: "orange",
        title: payload.status,
        message: `${payload.message ? payload.message : ""}`,
        autoClose: 2000,
      });
    } else {
      showNotification({
        color: "teal",
        title: payload.status,
        message: payload.message,
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 2000,
      });
    }
  };

  useEffect(() => {
    if (paymentType === "existing" && drawer) {
      setBill({
        referenceNumber: selectedRecord.referenceNumber,
        name: selectedRecord.name,
        description: selectedRecord.description,
        amountDue: selectedRecord.amountDue,
        dueDate: selectedRecord.dueDate,
      });
      if (bill) {
        setStep(3);
        if (selectedRecord.contact.destinationId !== "") {
          setStep(4);
        }
        // axios
        //   .post(
        //     configs.urls.BASE_URL + "/contact/getByName",
        //     { name: selectedRecord.name },
        //     {
        //       withCredentials: true,
        //     }
        //   )
        // .then((res) => {
        //   console.log("res in getBeneficiaryByName", res.data);
        //   if (res.data?.destinationId !== "") {
        //     setBeneficiary({
        //       bankIdentifier: res.data.bankIdentifier,
        //       iban: res.data.iban,
        //       accountNumber: res.data.accountNumber,
        //       swiftCode: res.data.swiftCode,
        //     });
        //     setStep(4);
        //     // if (paymentSource) {
        //     //   setBankConnected(true);
        //     // }
        //   } else {
        //     showNotification({
        //       color: "orange",
        //       message: "Add this vendor as a Beneficiary first.",
        //       icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
        //       autoClose: 2000,
        //     });
        //   }
        // })
        // .finally(() => {});
      }
    }

    return () => {
      if (paymentType !== "create_invoice") setStep(1);
      resetBill();
      resetBeneficiary();
    };
  }, [drawer]);

  useEffect(() => {
    socket.on(
      "dataChangeEvent",
      async ({ name, operation, tenantId, provider }: any) => {
        if (name === "Invoice" || name === "Bill" || name === "INVOICE") {
          if (await isUserPartOfOrganization(tenantId, provider)) {
            getRecords();
          }
        }
      }
    );
  }, [socket]);

  return (
    <myContext.Provider
      value={{
        step,
        stepUp,
        stepDown,
        setStep,
        ifBankConnected,
        customerId,
        destinationId,
        fetchPaymentSource,
        bill,
        setBill,
        drawer,
        showDrawer,
        beneficiary,
        setBeneficiary,
        selectedRecord,
        records,
        setRecords,
        selectRecord,
        getRecords,
        leanCallback,
        paymentSource,
        paymentType,
        setPaymentType,
        checkIfPaymentSourceExists,
        getDestinationId,
        externalAccounts,
        fetchExternalAccounts,
        recordsReceivable,
        recordsLoading,
        endUserId,
        searchParams,
        setSearchParams,
        getBillWithId,
        totalRecords,
      }}
    >
      {props.children}
    </myContext.Provider>
  );
}
