import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/theme";

function ListHeader({
  hasButton,
  buttonTitle,
  buttonAction,
  title,
  buttonIsOutlined,
  style = {},
  renderTabs,
  renderButton,
}: any) {
  return (
    <div>
      <div className="flex-direction-row-space-between" style={{ ...style }}>
        {title && <div className="title page-title">{title}</div>}
        {hasButton &&
          (renderButton ? (
            renderButton()
          ) : (
            <CustomButton
              onClick={buttonAction}
              // disabled={_.isEmpty(selectedRecord)}
              className={`button ${
                buttonIsOutlined ? "is-outlined is-primary" : ""
              }`}
              style={
                !buttonIsOutlined
                  ? { color: "white", backgroundColor: COLORS.greenPrimary }
                  : {}
              }
            >
              {buttonTitle}
            </CustomButton>
          ))}
      </div>
      <div>{renderTabs && renderTabs()}</div>
    </div>
  );
}

export default ListHeader;

const CustomButton = styled.button`
  color: ${COLORS.greenPrimary};
  border-color: ${COLORS.greenPrimary};
`;
