import React, { useState } from "react";
import { CustomDrawer } from "../../common/CustomDrawer";
import PageLayout from "../../common/PageLayout/PageLayout";
import { InvoiceTypes } from "../../constants/globalConstants";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import AddRecordAndPayments from "./components/AddRecordAndPayments";
// import InitiatePayments from "./components/InitiatePayments";
import PaymentsContext from "./contexts/PaymentsContext";
import LeftView from "./layouts/GetPaid/LeftView";
import RightView from "./layouts/GetPaid/RightView";

enum AddRecordType {
  ADD_BILL = "ADD_BILL",
  ADD_PAYMENT = "ADD_PAYMENT",
}

function GetPaid() {
  useScrollBlock();
  const [drawer, showDrawer] = React.useState(false);
  const [paymentStep, setPaymentStep] = useState<string>(
    AddRecordType.ADD_BILL
  );

  return (
    <PaymentsContext>
      <PageLayout
        left={<LeftView onAddClick={() => showDrawer(true)} />}
        right={<RightView />}
      />

      <CustomDrawer
        opened={drawer}
        onBack={() => {
          if (paymentStep === AddRecordType.ADD_BILL) {
            showDrawer(false);
          } else {
            setPaymentStep(AddRecordType.ADD_BILL);
          }
        }}
        onClose={() => {
          showDrawer(false);
          setPaymentStep(AddRecordType.ADD_BILL);
        }}
        title="Add Invoice"
      >
        <AddRecordAndPayments
          recordType={InvoiceTypes.Receivable}
          onSubmit={() => {
            showDrawer(false);
            setPaymentStep(AddRecordType.ADD_BILL);
          }}
          step={paymentStep}
          setStep={setPaymentStep}
        />
      </CustomDrawer>
      {/* <InitiatePayments /> */}
    </PaymentsContext>
  );
}

export default GetPaid;
