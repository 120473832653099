import React from 'react'

interface CardSubtilteSmallType{
    title: string;
}

function CardSubtilteSmall({title}: CardSubtilteSmallType) {
  return (
    <div className='card-subtitle-small'>{title}</div>
  )
}

export default CardSubtilteSmall