import React from "react";
import PageLayout from "../../common/PageLayout/PageLayout";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import InitiatePayments from "./components/InitiatePayments";
import PaymentsContext from "./contexts/PaymentsContext";
import LeftView from "./layouts/Pay/LeftView";
import RightView from "./layouts/Pay/RightView";
import { isUserViewOnly } from "../../modules/localStorage";

function Pay() {
  useScrollBlock();

  const isViewOnly = isUserViewOnly();

  return (
    <PaymentsContext>
      <PageLayout left={<LeftView isViewOnly={isViewOnly} />} right={<RightView isViewOnly={isViewOnly} />} />
      <InitiatePayments />
    </PaymentsContext>
  );
}

export default Pay;
