import { Fragment } from "react";
import styled from "styled-components";
import Card from "../../../common/Card/Card";
import { CardSubTitle } from "../../../common/CardSubTitle";
import DetailsCardHeader from "../../../common/PageLayout/components/DetailsCardHeader";
import { useAuth } from "../../../contexts";
import { getAvatarPlaceholder } from "../../../modules/getAvatarPlaceholder";
import "../styles.css";
import EditProfile from "./EditProfile";

function Profile() {
  const { user, fetchUser } = useAuth();

  return (
    <div>
      <Fragment>
        <Card className="profile-details-card-container">
          <DetailsCardHeader hasButton={false} title="Profile Details" />
          <EditProfile selectedProfile={user} fetchUser={fetchUser} />
        </Card>
        <Card className="profile-picture-card-container">
          <DetailsCardHeader hasButton={false} title="Profile Picture" />
          <div>
            <CardSubtitleSmall>Currrent photo</CardSubtitleSmall>
            <img
              className="profile-image"
              src={getAvatarPlaceholder(user?.email)}
              alt="profile"
            />
          </div>
        </Card>
        <Card className="profile-picture-card-container">
          <DetailsCardHeader
            hasButton
            buttonTitle="Edit"
            buttonAction={() => {
              window.location.href = "/reset-password";
            }}
            title="Password"
          />
          <div>
            <CardSubtitleSmall>Current password</CardSubtitleSmall>
            <CardSubTitle title="**********" />
          </div>
        </Card>
      </Fragment>
    </div>
  );
}

export default Profile;

const CardSubtitleSmall = styled.h4`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.17;
  letter-spacing: normal;
  color: #969696;
`;
