import { Modal, Select } from "@mantine/core";
import { ErrorMessage, Field, Form, Formik, FormikErrors } from "formik";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Button } from "rsuite";

interface FormValues {
  amount: number;
}

function EnterAmountModal({
  opened,
  onClose,
  onSubmit,
  paymentSource,
  onBankChange,
}: any) {
  const [selectedAccount, setSelectedAccount] = React.useState<any>(
    paymentSource?.accounts![2]
  );
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      overlayColor={"#ccc"}
      overlayOpacity={0.55}
      overlayBlur={3}
      size="md"
      title="Enter Amount to Transfer"
      closeOnClickOutside={false}
    >
      <Formik
        initialValues={{
          amount: "",
        }}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};

          if (!values.amount) {
            errors.amount = "Value Required.";
          } else if (
            parseFloat(values.amount) > 27000 ||
            parseFloat(values.amount) < 100
          ) {
            errors.amount =
              "You cannot pay value more than $27,000 or less than $100";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            onSubmit(values, selectedAccount);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting, errors, touched, values }) => (
          <Form>
            <strong>Connected Bank</strong>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // border: "1px solid #ccc",
                alignItems: "center",
                padding: 10,
                borderRadius: 5,
                marginBottom: 10,
              }}
            >
              {selectedAccount ? (
                <>
                  <div className="is-bold is-italic">
                    Bank:{" "}
                    <span style={{ fontWeight: "700" }}>
                      {paymentSource.bankIdentifier}
                    </span>
                  </div>
                  {/* <button
                    type={"button"}
                    className="button is-ghost is-small is-bold"
                    onClick={onBankChange}
                  >
                    Change
                  </button> */}
                </>
              ) : (
                <div>No Bank connected</div>
              )}
            </div>
            <Select
              className="mb-2"
              label="Current Account"
              defaultValue={selectedAccount.name}
              onChange={(value: string) => {
                setSelectedAccount(
                  paymentSource?.accounts?.find(
                    (item: any) => item.name === value
                  )
                );
              }}
              data={paymentSource?.accounts?.map((source: any) => ({
                label: source.name,
                value: source.name,
              }))}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
                border: "1px solid #ccc",
                borderRadius: 5,
                marginBottom: 10,
              }}
            >
              {selectedAccount ? (
                <>
                  <div>
                    Account Number{" "}
                    <span className="is-bold is-italic">
                      {selectedAccount.accountNumber}
                    </span>
                    <div>
                      {" "}
                      Balance:{" "}
                      <span
                        style={{
                          color: selectedAccount.balance <= 0 ? "red" : "green",
                          fontWeight: "700",
                        }}
                      >
                        {parseFloat(selectedAccount.balance)
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </div>
                    <div>
                      Last updated:{" "}
                      <span style={{ fontWeight: "700" }}>
                        {moment(selectedAccount.balanceLastUpdated).fromNow()}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div>No account found</div>
              )}
            </div>
            {selectedAccount.balance <= 0 && (
              <div
                className="m-3 subtitle"
                style={{ color: "red", textAlign: "center", fontSize: 12 }}
              >
                You don't have enough balance in this account to pay.
              </div>
            )}

            <div className="field">
              <label className="label">Amount</label>
              <div className="control">
                <Field
                  className={`input ${
                    errors.amount && touched.amount ? "is-danger" : ""
                  }`}
                  name="amount"
                  type="number"
                  step={100}
                />
              </div>
              <ErrorMessage
                className="help is-danger"
                name="amount"
                component="div"
              />
            </div>
            <div className="field">
              <label className="label subtitle ml-1 is-5">
                $
                {values.amount !== ""
                  ? parseFloat(values.amount)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : 0.0}
              </label>
            </div>

            <div
              className="mt-3 flex-direction-row"
              style={{ justifyContent: "flex-end" }}
            >
              <Button
                appearance="default"
                onClick={() => {
                  onClose();
                }}
                style={{ marginRight: "1rem" }}
              >
                Cancel
              </Button>
              <Button
                appearance="primary"
                type="submit"
                // disabled={isSubmitting || !_.isEmpty(errors)}
                disabled={isSubmitting || selectedAccount.balance <= 0}
              >
                Transfer
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default EnterAmountModal;
