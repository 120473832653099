export const ROUTES = {
  Static: {
    Base: "/static",
  },
  Zoho: {
    Base: "/zoho",
  },
  Organization: {
    Base: "/organization",
  },
  Token: {
    Base: "/token",
  },
  Contact: {
    Base: "/contact",
  },
  Invoice: {
    Base: "/invoice",
  },
  Cashflow: {
    Base: "/cashflow",
  },
  CashflowCategory: {
    Base: "/cashflow_category",
  },
  CashflowMapping: {
    Base: "/cashflow_mapping",
  },
  FinancialProjection: {
    Base: "/financial_projection",
  },
  CashflowScenario: {
    Base: "/cashflow_scenario",
  },
  CashflowCategoriesTemplate: {
    Base: "/cashflow_categories_template",
    Create: "/create",
    GetAll: "/get_all",
    GetOne: "/get_one",
    Update: "/update",
    Delete: "/delete",
  },
  Subscription: {
    Base: "/subscription",
  },
  DemoData: {
    Base: "/demo_data",
  },
};
