import React from "react";
import { Button } from "rsuite";
import styled from "styled-components";
import CardButton from "../../../common/CardButton/CardButton";
import { IMAGES } from "../../../constants";
import { usePaymentsContext } from "../contexts/PaymentsContext";

function AddBill({ setFlow }: any) {
  const { stepUp, showDrawer, setStep } = usePaymentsContext();
  return (
    <Container>
      <CardButton
        icon={IMAGES.bill}
        title="Add a bill manually"
        description="Create a bill from TSC directly"
        onClick={() => {
          setFlow("add-bill");
          stepUp();
        }}
      />
      <CardButton
        icon={IMAGES.upload_bill}
        title="Upload a bill"
        description="Quickly upload bills into TSC"
        onClick={() => {
          setFlow("upload-bill");
          stepUp();
        }}
      />

      <Separator>OR</Separator>

      <CardButton
        icon={IMAGES.accounting_firm}
        title="Connect your accounting software"
        description="Automatically sync all your records in one click"
        onClick={() => {
          setStep(0);
        }}
      />

      <Button
        onClick={() => showDrawer(false)}
        appearance="link"
        className="mt-3"
        style={{ color: "black" }}
      >
        Cancel
      </Button>
    </Container>
  );
}

export default AddBill;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
`;

const Separator = styled.div`
  margin: 20px;
  font-size: 20px;
`;
