import React, { Fragment, useState } from "react";
import NoBanksView from "./NoBanksView";
import SwitchBank from "./SwitchBank";
import styled from "styled-components";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import { COLORS } from "../../constants/theme";
import { ICONS } from "../../constants";
import { Button, IconButton } from "rsuite";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import TransactionsTable from "./TransactionsTable";
import { useBanksContext } from "./context/BanksContext";
import { LoadingOverlay } from "@mantine/core";
import UploadCSVModal from "../Cashflow/components/EditableTableRow/UploadCSVModal";
import BanksList from "./components/BanksList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { IconPlus } from "@tabler/icons";
import { CustomDrawer } from "../../common/CustomDrawer";
import CreateBank from "./ModalFrames/CreateBank";
import { isUserViewOnly } from "../../modules/localStorage";

const Banks: React.FC = () => {
  useScrollBlock();
  const {
    banks,
    loading: banksLoading,
    selectedBank,
    setSelectedBank,
    searchParams,
    setSearchParams,
    fetchLinkedBanks,
  } = useBanksContext();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const isViewOnly = isUserViewOnly();

  return banks.length ? (
    <Container className="is-flex is-flex-direction-column">
      <LoadingOverlay visible={banksLoading} />
      <div className="pl-4 pt-4 is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
        <div className="is-flex is-flex-direction-row is-align-items-center">
          {selectedBank ? (
            <BackButton
              onClick={() => {
                setSelectedBank(null);
                searchParams.delete("selectedBankId");
                setSearchParams(searchParams);
              }}
              icon={
                <FontAwesomeIcon
                  style={{
                    fontSize: 20,
                  }}
                  color="black"
                  icon={faChevronLeft}
                />
              }
            />
          ) : null}

          <div className="title page-title">Banks</div>
        </div>
        {selectedBank ? null : (
          <Button
            disabled={isViewOnly}
            title="Add a bank"
            className="cashflow-actions-btn"
            style={{
              backgroundColor: isViewOnly
                ? COLORS.btnDisabled
                : COLORS.greenBlue,
              color: "white",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </div>
      <div
        className="p-5 is-flex is-flex-direction-column "
        style={{ gap: 20 }}
      >
        {/* <BanksList />
        <div
          className="p-5 is-flex is-flex-direction-column"
          style={{ gap: 20 }}
        >
          <TransactionsTable />
        </div> */}
        {selectedBank ? <TransactionsTable isViewOnly={isViewOnly} /> : <BanksList isViewOnly={isViewOnly} />}
      </div>
      {/* <SwitchBank /> */}
      <CustomDrawer
        opened={open}
        onBack={onClose}
        onClose={onClose}
        title={"Create bank"}
      >
        <AddBankContainer>
          <CreateBank
            mode="add"
            onSubmit={(id) => {
              fetchLinkedBanks();
              onClose();
            }}
          />
        </AddBankContainer>
      </CustomDrawer>
    </Container>
  ) : (
    <NoBanksView />
  );
};

export default Banks;

const Container = styled.div`
  height: 100vh;
  padding: 20px;
`;

const BackButton = styled(IconButton)`
  margin-top: 3px;
  margin-right: 15px;
  /* slide from left on mount */
  animation: slide-in-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
      visibility: visible;
    }

    100% {
      transform: translateX(0);
    }
  }
`;

const AddBankContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 31px 32px;
  width: 100%;
`;
