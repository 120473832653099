import React from "react";
import styled from "styled-components";
import Card from "../Card/Card";

function CardForm({ containerStyles, cardStyles, title, children, icon }: any) {
  return (
    <Container style={{ ...containerStyles }}>
      {icon ? <CardIcon src={icon} /> : null}
      <Card
        style={{
          padding: 25,
          width: "30%",
          margin: 0,
          borderRadius: 10,
          ...cardStyles,
        }}
        isNormal
        noShadow
      >
        <CardTitle>{title}</CardTitle>

        {children}
      </Card>
    </Container>
  );
}

export default CardForm;

export const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  margin-top: 15px;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 30px;
`;

const CardIcon = styled.img`
  width: 100px;
  height: 100px;
  position: absolute;
  top: -55px;
  left: 15px;
`;
