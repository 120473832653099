import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { Field, Formik } from "formik";
import React from "react";
import { useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import Card from "../../../common/Card/Card";
import { CustomInput } from "../../../common/CustomInput";
import { ScrollToFormikFieldError } from "../../../common/ScrollToFormikFieldError/ScrollToFormikFieldError";
import { configs } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { usePaymentsContext } from "../contexts/PaymentsContext";

function AddBeneficiary() {
  const { bill, stepUp, getRecords, externalAccounts, searchParams } =
    usePaymentsContext();
  const { setGlobalLoading } = useAuth();

  const createDestination = (values: any, setSubmitting: any) => {
    axios
      .post(
        configs.urls.BASE_URL + "/contact/create",
        {
          ...values,
          displayName: bill.name,
          name: bill.name,
          country: "ARE",
          createDestinationWithLean: true,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          // show success notification
          showNotification({
            color: "teal",
            message: "A new beneficiary has been added!",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
          setSubmitting(false);
          setGlobalLoading(false);
          getRecords(searchParams.get("selectedBillId"));
          stepUp();
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
          setSubmitting(false);
          setGlobalLoading(false);
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
        setSubmitting(false);
        setGlobalLoading(false);
      });
  };

  let currentAccount = React.useMemo(() => {
    return externalAccounts.find((account: any) => bill.name === account.name);
  }, [externalAccounts, bill]);

  // React.useEffect(() => {
  //   alert(JSON.stringify(currentAccount));
  // }, []);

  return (
    <Container>
      <Card className="manual-bill-container" isNormal noShadow>
        <CardTitle>Beneficiary Details</CardTitle>

        <Formik
          initialValues={{
            // bankIdentifier: "ENBD_UAE",
            // accountNumber: "1008223921001",
            // swiftCode: "EBILAEAD",
            // iban: "AB12 1234 1234 1234 1234",
            bankIdentifier: currentAccount?.bankIdentifier || "",
            accountNumber: currentAccount?.accountNumber || "",
            swiftCode: currentAccount?.swiftCode || "",
            iban: currentAccount?.iban || "",
            // city: "Dubai",
            // country: "ARE",
            // address: "Jam Marina Residence, Abdul Aziz st",
            city: currentAccount?.city || "",
            country: "UAE",
            address: currentAccount?.address || "",
          }}
          validate={(values: any) => {
            const errors: any = {};

            //   bankIdentifier: string;
            if (!values.bankIdentifier) {
              errors.bankIdentifier = "Bank Identifier Required";
            }

            //   accountNumber: number; (/^[0-9]+$/)
            if (!values.accountNumber) {
              errors.accountNumber = "Account Number Required";
            } else if (!/^[0-9]+$/i.test(values.accountNumber)) {
              errors.accountNumber = "Account Number must be a number";
            }
            //   swiftCode: string; /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
            if (!values.swiftCode) {
              errors.swiftCode = "Swift Code Required";
            } else if (
              !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(values.swiftCode)
            ) {
              errors.swiftCode = `<div>Swift Code must be 8 or 11 upper case alphanumeric characters & comprised of:
                  <div style="font-weight: 700">* 4 alpha character bank code then </div>
                  <div style="font-weight: 700">* 2 character ISO 3166-1 country code then</div>
                  <div  style="font-weight: 700">* 2 character alphanumeric location code and an optional</div>
                  <div  style="font-weight: 700">* 3 character alphanumeric branch code</div></div>`;
            }

            //   iban: string;
            // AB12 1234 1234 1234 1234

            if (!values.iban) {
              errors.iban = "IBAN Required";
            } else if (values.iban.trim().length > 23) {
              errors.iban = "IBAN must be max 23 characters";
            }
            // else if (
            //   !/^\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b$/.test(
            //     values.iban
            //   )
            // ) {
            //   errors.iban = `<div>Invalid IBAN, follow one of these formats:
            //   <div style="font-weight: 700">* XX## #### #### #### #### </div>
            //   <div style="font-weight: 700">* XX## #### #### #### #### ## </div>
            //   <div style="font-weight: 700">* XX##################</div></div>`;
            // }

            if (!values.city) {
              errors.city = "City Required";
            }

            if (!values.country) {
              errors.country = "Country Required";
            }

            if (!values.address) {
              errors.address = "Address Required";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setGlobalLoading(true);
            setTimeout(() => {
              // check name should be alphanumeric
              createDestination(values, setSubmitting);
              // stepUp();
            }, 1000);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <ScrollToFormikFieldError />
              <div className="flex-direction-row">
                <CustomInput
                  value={values.bankIdentifier}
                  label="Bank Identifier"
                  onChange={handleChange}
                  name="bankIdentifier"
                  placeholder="ENBD_UAE"
                  formikErrors={{ errors, touched }}
                />
                <CustomInput
                  value={values.accountNumber}
                  label="Account Number"
                  onChange={handleChange}
                  name="accountNumber"
                  placeholder="1008223921001"
                  formikErrors={{ errors, touched }}
                />
              </div>
              <CustomInput
                value={values.swiftCode}
                label="Swift Code"
                onChange={handleChange}
                name="swiftCode"
                placeholder="EBILAEAD"
                formikErrors={{ errors, touched }}
              />

              <CustomInput
                value={values.iban}
                label="IBAN"
                onChange={handleChange}
                name="iban"
                placeholder="AB12 1234 1234 1234 1234"
                formikErrors={{ errors, touched }}
              />

              <DashedSeparator />

              <InputContainer>
                <Label>Vendor name</Label>
                <Value>{bill.name}</Value>
              </InputContainer>

              <div className="flex-direction-row">
                <CustomInput
                  value={values.city}
                  label="City"
                  onChange={handleChange}
                  name="city"
                  placeholder="Dubai"
                  formikErrors={{ errors, touched }}
                />
                <CustomInput
                  label="Country"
                  value={values.country}
                  onChange={handleChange}
                  name="country"
                  placeholder="UAE"
                  formikErrors={{ errors, touched }}
                />
              </div>

              <CustomInput
                value={values.address}
                label="Address"
                onChange={handleChange}
                name="address"
                placeholder="Street, building, floor"
                formikErrors={{ errors, touched }}
              />

              <button
                type="submit"
                disabled={isSubmitting}
                style={{
                  backgroundColor: COLORS.greenPrimary,
                  color: "white",
                  width: "100%",
                }}
                className="button mt-6 is-bold"
              >
                Create
              </button>
            </form>
          )}
        </Formik>
      </Card>
    </Container>
  );
}

export default AddBeneficiary;

const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 8px;
  margin-top: 18px;
  flex: 1;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled(Field)`
  width: 100%;
  height: 40px;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 24px;
  ${(props) =>
    props.error &&
    css`
      border-color: red;
      color: red;
    `}
`;

const Value = styled.div`
  width: 100%;
  height: 40px;
  font-weight: normal;
  font-size: 24px;
  margin-top: 5px;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const DashedSeparator = styled.div`
  width: 100%;
  height: 1px;
  border: dashed 1px #707070;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const FieldError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;
