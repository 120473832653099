import "../styles.css";
import { Tooltip } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../../constants/theme";
import { Popover, Whisper } from "rsuite";
import { IMAGES } from "../../../constants";
import CustomButtonPrimary from "../../CustomButtonPrimary/CustomButtonPrimary";
import SkipButton from "../../SkipButton/SkipButton";
import { useAuth } from "../../../contexts";
import { updateOrganization } from "../../../repositories/organizationRepository";
import { setUserLS } from "../../../modules/localStorage";

export const CollapsedSidebarItem = ({
  isViewOnly,
  title,
  path,
  icon,
  defaultTab,
  active,
}: any) => {
  const { cashflowStage, setCashflowStage } = useAuth();
  const navigate = useNavigate();

  const handleClose = async () => {
    setCashflowStage(8);
    setUserLS({ completedTutorial: true });
    await updateOrganization({ completedTutorial: true });
    // navigate("/sync-accounting-software");
  };

  const handleOnSkip = async () => {
    setCashflowStage(8);
    setUserLS({ completedTutorial: true });
    await updateOrganization({ completedTutorial: true });
  };
  const speaker = (
    <Popover
      title="Connect your accounting software"
      className="tutorial-popover"
      arrow={false}
    >
      <div className="popover-container">
        <p className="tutorial-popover-subtitle">
          Securely import all your accounting data and <br /> generate a
          real-time cashflow with one click.
        </p>
        <img
          style={{ height: "220px", marginTop: "1rem" }}
          src={IMAGES.connectAccountingSoftware}
          className="tutorial-popover-img"
        ></img>

        <CustomButtonPrimary title={"Finish"} handleOnClick={handleClose} />
        <SkipButton handleOnClick={handleOnSkip} />
      </div>
    </Popover>
  );
  return (
    <Whisper
      placement="auto"
      open={cashflowStage === 6 && path === "companies"}
      speaker={speaker}
    >
      <Tooltip
        label={title}
        withArrow
        position="right"
        transition="scale-x"
        transitionDuration={300}
        offset={15}
      >
        <Link
          onClick={() => {
            window.scrollTo({ top: 0 });
          }}
          to={{
            pathname: `/${path}`,
            search: defaultTab ? `?tab=${defaultTab}` : "",
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: active ? "#d6ede8" : "white",
          }}
          className={`item collapsed-sidebar-item-link ${
            cashflowStage === 6 && path === "companies"
              ? "tutorials-container-sidebar"
              : ""
          }  `}
        >
          <div style={{ color: "#2b2b2bd4" }}>
            <img className="sidebar-image" src={icon} alt={title} />
          </div>

          <SidebarItemName
            style={{
              color: active ? COLORS.black : COLORS.brownishGrey,
              fontWeight: active ? 900 : 500,
            }}
          >
            {title}
          </SidebarItemName>
        </Link>
      </Tooltip>
    </Whisper>
  );
};

const SidebarItemName = styled.div`
  font-size: 12px;
  line-height: 22.41px;
  letter-spacing: 0.05em;
  font-weight: 700;
  padding: 8px;
  color: ${COLORS.brownishGrey};
  padding-bottom: 0px;

  @media screen and (max-width: 1600px) {
    padding: 6px;
    padding-bottom: 0px;
  }
`;
