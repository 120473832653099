import "./styles.css";
import { IconButton } from "rsuite";
import {
  sortBlackIcon,
  sortIcon,
  sortSelectedButton,
} from "../../constants/images";

export const renderSortIconButton = (
  handleSortClick?: any,
  sortSelected?: boolean,
  menuSelected?: string,
  isViewOnly?: boolean
) => {
  return (
    <IconButton
      disabled={isViewOnly}
      className="sort-icon-button"
      title="Sort"
      onClick={() => {
        handleSortClick();
      }}
      icon={
        <img
          height="1rem"
          src={
            menuSelected
              ? sortSelectedButton
              : sortSelected
              ? sortBlackIcon
              : sortIcon
          }
        />
      }
    />
  );
};
