import { MENU } from "../../../../constants/globalConstants";
import {
  ascendingSortByDate,
  descendingSortByDate,
} from "../../../../modules/sortByDate";
import {
  ascendingSortByLetters,
  descendingSortByLetters,
} from "../../../../modules/sortByLetters";
import {
  ascendingSortByDueDate,
  descendingSortByDueDate,
} from "./sortByDueDate";

export const sortRecords = (
  mainmenu: string,
  submenu: string,
  array: any[]
) => {
  let filteredRecords: any = [];
  if (mainmenu === MENU.BY_DATE && submenu === MENU.NEWEST_TO_OLDEST) {
    filteredRecords = descendingSortByDueDate(array, "dueDate");
  }
  if (mainmenu === MENU.BY_DATE && submenu === MENU.OLDEST_TO_NEWEST) {
    filteredRecords = ascendingSortByDueDate(array, "dueDate");
  }
  if (mainmenu === MENU.BY_ISSUE_DATE && submenu === MENU.NEWEST_TO_OLDEST) {
    filteredRecords = descendingSortByDate(array, "issueDate");
  }
  if (mainmenu === MENU.BY_ISSUE_DATE && submenu === MENU.OLDEST_TO_NEWEST) {
    filteredRecords = ascendingSortByDate(array, "issueDate");
  }
  if (mainmenu === MENU.BY_CONTACT && submenu === MENU.ASCENDING) {
    filteredRecords = ascendingSortByLetters(array, "contact", "displayName");
  }
  if (mainmenu === MENU.BY_CONTACT && submenu === MENU.ASCENDING) {
    filteredRecords = ascendingSortByLetters(array, "contact", "displayName");
  }
  if (mainmenu === MENU.BY_CONTACT && submenu === MENU.DESCENDING) {
    filteredRecords = descendingSortByLetters(array, "contact", "displayName");
  }
  if (mainmenu === MENU.BY_INVOICE && submenu === MENU.ASCENDING) {
    filteredRecords = ascendingSortByLetters(array, "invoiceNumber");
  }
  if (mainmenu === MENU.BY_INVOICE && submenu === MENU.DESCENDING) {
    filteredRecords = descendingSortByLetters(array, "invoiceNumber");
  }
  return filteredRecords;
};
