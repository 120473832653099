import { Checkbox, Tooltip } from "@mantine/core";
import React, { useEffect, useState, useRef } from "react";
import { Nav, Placeholder } from "rsuite";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextInput } from "@mantine/core";
import styled from "styled-components";
import { StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import ListItem from "./ListItem";

function List({
  isViewOnly,
  list,
  renderItem,
  selectItemId,
  onCardClick,
  renderTabs,
  renderActions,
  renderDropdown,
  style = {},
  listLoading = false,
  loaderItemHeight = 120,
  type = "",
  renderSubHeader,
  compareId = "id",
  noDataMessage,
  multiselect = true,
  isCashflowHistory = false,
  handleChangeSearch,
  openSearch,
  searchText,
  handleSelectedKeys,
  paginate,
  onLoadMore = async () => {},
  setAllSelected,
  totalRecords,
  activeTab,
  paginateWhen = true,
}: any) {
  const searchBar: any = useRef();

  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === list?.length && list?.length > 0) {
    setAllSelected && setAllSelected(true);
    checked = true;
  } else if (checkedKeys.length === 0) {
    setAllSelected && setAllSelected(false);
    checked = false;
  } else if (checkedKeys?.length > 0 && checkedKeys?.length < list?.length) {
    indeterminate = true;
  }

  const handleCheckAll = ({ target: { value, checked } }: any) => {
    const keys = checked ? list.map((item: any) => item.id) : [];
    handleSelectedKeys(keys);
    setCheckedKeys(keys);
  };
  const handleCheck = ({ target: { value, checked } }: any) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item != value);
    if (!checked) {
      setAllSelected && setAllSelected(false);
    }
    handleSelectedKeys(keys);
    setCheckedKeys(keys);
  };

  useEffect(() => {
    setCheckedKeys([]);
  }, [list]);

  useEffect(() => {
    if (openSearch) {
      searchBar.current.focus();
    }
  }, [openSearch]);
  const whichScreenList = () => {
    switch (type) {
      case "pay":
        return "Add bills by entering, uploading, or syncing your accounting software.";
      case "get-paid":
        return "Add invoices by entering, uploading, or syncing your accounting software.";
      case "contacts":
        return "Add contacts by entering, uploading, or syncing your accounting software. You can pay or get paid by your contacts.";
      case "bank-accounts":
        return "No bank accounts connected!";
      default:
        return "Nothing here!";
    }
  };

  useEffect(() => {
    if (selectItemId) {
      let item = document.getElementById(selectItemId);
      // console.log("selectItemId", item);
      if (item) {
        item.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [selectItemId]);

  return (
    <div style={{ position: "relative" }}>
      {/* Show Tabs */}
      <div className="flex-direction-row-space-between filters-tabs">
        <div>{renderTabs && renderTabs()}</div>
        <div style={{ marginTop: "-0.4rem" }}>
          {renderActions && renderActions()}
        </div>
      </div>

      {openSearch && (
        <TextInput
          style={{
            margin: "0.8rem 2rem",
            marginTop: "0.4rem",
            marginBottom: "1rem",
            marginRight: "3rem",
          }}
          ref={searchBar}
          value={searchText}
          placeholder="Search"
          onChange={handleChangeSearch}
          size="lg"
          icon={<FontAwesomeIcon size="lg" icon={faSearch} />}
        />
      )}

      {isCashflowHistory && renderSubHeader()}
      <div style={{ margin: "0.4rem 3rem 1rem 2rem" }}>
        {renderDropdown && renderDropdown()}
      </div>

      {multiselect && checkedKeys.length > 0 && (
        <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
          <Tooltip
            label={checked ? "Unselect all" : "Select all"}
            position="right"
            withArrow
            transition="scale-x"
            transitionDuration={300}
            offset={10}
          >
            <StyledComponents.CustomCheckbox
              checked={checked}
              indeterminate={indeterminate}
              onChange={handleCheckAll}
              // label={`${checkedKeys.length} selected`}
            />
          </Tooltip>

          <div className="title is-6 mr-6">
            {renderSubHeader ? (
              renderSubHeader(checkedKeys)
            ) : (
              <div>{checked ? totalRecords : checkedKeys.length} selected</div>
            )}
          </div>
        </div>
      )}

      <ListContainerStyled
        className="custom-scrollbar pb-2"
        style={{
          overflowY: "scroll",
          height: "75vh",
          marginBottom: 100,
          overflowX: "hidden",
          ...style,
        }}
      >
        {/* The List */}
        {listLoading ? (
          new Array(10).fill(0).map((_, index) => (
            <Placeholder.Graph
              active
              height={loaderItemHeight}
              style={{
                margin: 15,
                borderRadius: 10,
                width: "90%",
              }}
            />
          ))
        ) : list && list.length > 0 ? (
          list.map((item: any, index: number) => (
            <div key={`${index + item.name}`}>
              <ListItem 
                isViewOnly={isViewOnly}
                key={item?.id}
                data={item}
                isSelected={selectItemId == item![compareId]}
                isChecked={checkedKeys.some((key: any) => key == item.id)}
                onCardClick={() => onCardClick(item)}
                renderItem={renderItem}
                onChange={handleCheck}
                checkedKeys={checkedKeys}
                selectable={multiselect}
              >
                {renderItem}
              </ListItem>
            </div>
          ))
        ) : (
          <div
            style={{ display: "flex", flexDirection: "row", height: "65vh" }}
          >
            <NothingFound>
              {noDataMessage ? noDataMessage : whichScreenList()}
            </NothingFound>
          </div>
        )}
        {paginateWhen && list && paginate ? (
          <LoadMore>
            <ShowingRecords className="">
              Showing <strong>{list.length}</strong> of{" "}
              <strong>{paginate}</strong> items
            </ShowingRecords>
            {list.length < paginate ? (
              <LoadMoreButton
                onClick={async () => {
                  setLoadingMore(true);
                  await onLoadMore(list.length);
                  setLoadingMore(false);
                }}
                style={{
                  color: COLORS.greenBlue,
                  outline: "none",
                }}
                className={`button is-text pt-1 ${loadingMore && "is-loading"}`}
              >
                Load more
              </LoadMoreButton>
            ) : (
              <div className="title is-6 pb-3">No more items</div>
            )}
          </LoadMore>
        ) : null}
      </ListContainerStyled>
    </div>
  );
}

export default List;

const NothingFound = styled.div`
  color: ${COLORS.brownGrey};
  font-size: 16px;
  font-weight: 700;
  width: 85%;
  margin-left: 15px;
  align-self: center;
  text-align: center;
`;

const LoadMore = styled.div`
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShowingRecords = styled.div`
  font-size: 14px;
  color: ${COLORS.brownGrey};
  font-weight: 700;
  margin-bottom: 5px;
  padding-top: 8px;
  width: 60%;
  text-align: center;
  border-top: 1px solid ${COLORS.brownGrey};
`;

const LoadMoreButton = styled.button`
  font-weight: 700;
  /* remove all borders */
  border: none;
  /* remove all margins */
  margin: 0;
  /* remove all paddings */
  /* remove all background */
  background: none;
  /* remove all outline */
  outline: none;
  ::focus {
    outline: none;
  }
`;

const ListContainerStyled = styled.div`
  /* blur at the end of list if scroll is not at the end */
  /* ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  } */
  /* &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px; 
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.7),
      red
    ); 
    filter: blur(10px); 
  } */
`;
