import "../../pages/index.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Card from "./components/Card";

import { updateOrganization } from "../../repositories/organizationRepository";
import { updateUser } from "../../repositories/userRepository";
import SubmitButton from "./components/SubmitButton";

const cards = [
  {
    image: "",
    title: "Up to USD 10K",
    content: "",
    minExpense: 0,
    maxExpense: 10000,
  },
  {
    image: "",
    content: "",
    title: "USD 10K to 50K",
    minExpense: 10000,
    maxExpense: 50000,
  },
  {
    image: "",
    content: "",
    title: "USD 50K to 150K",
    minExpense: 50000,
    maxExpense: 150000,
  },
  {
    image: "",
    content: "",
    title: "Over USD 150K",
    minExpense: 150000,
    maxExpense: null,
  },
];

function EstimatedMonthlyExpense() {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setLoading(true);
    if (selectedValue) {
      const { minExpense, maxExpense } =
        cards.find(({ title }) => title === selectedValue) || {};
      await updateUser({ lastStage: "estimated-monthly-expense" });
      await updateOrganization({ minExpense, maxExpense });
    }
    setLoading(false);
    navigate("/you-are-all-set-up");
  };
  const handleClick = (id: string) => {
    setSelectedValue(id);
  };

  const handleSkip = async () => {
    await updateUser({ lastStage: "estimated-monthly-expense" });
    navigate("/you-are-all-set-up");
  };

  return (
    <div
      className="card-outer-container py-3"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p className="what-we-do-title">
        What is your estimated monthly
        <br />
        expenses ?
      </p>

      <br />

      <div className="card-container  estimated-expense-container">
        {cards.map(({ title, image, content }, index) => (
          <>
            <Card
              title={title}
              image={image}
              content={content}
              className="hover-card"
              onClick={handleClick}
              selected={selectedValue}
            />
            {index == 1 && <div style={{ flexBasis: "100%", height: 0 }}></div>}
          </>
        ))}
      </div>

      <SubmitButton
        style={{
          width: "300px",
        }}
        loading={loading}
        onClick={handleContinue}
        disabled={!selectedValue}
      >
        Continue
      </SubmitButton>

      <SubmitButton
        disabled={loading}
        style={{
          width: "300px",
          background: "#fafafa",
          color: "#4F4F4F",
        }}
        className="is-text"
        onClick={handleSkip}
      >
        Skip
      </SubmitButton>
      {/* <button className="skip-button" onClick={handleSkip}>
        Skip
      </button> */}
    </div>
  );
}

export default EstimatedMonthlyExpense;
