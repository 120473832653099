import "./styles.css";
import {
  faCheck,
  faCheckCircle,
  faPowerOff,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import React, { Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import styled from "styled-components";
import { configs, IMAGES } from "../../constants";
import moment from "moment";
import { COLORS } from "../../constants/theme";
import { useAuth } from "../../contexts";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

import {
  connectQbo,
  disconnectFromQbo,
} from "../../repositories/quickbooksRepository";
import {
  connectXero,
  disconnectFromXero,
} from "../../repositories/xeroRepository";
import {
  connectZoho,
  disconnectFromZoho,
} from "../../repositories/zohoRepository";
import { getFormattedTime } from "../../modules/time";
import { doPlanAllowMoreSyncs } from "../../modules/doPlanAllow";
import { Overlay, Text, Tooltip } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { deleteDemoData } from "../../repositories/demoData";
import { useBanksContext } from "../../pages/Banks/context/BanksContext";

function SyncAccountingSoftware({
  isSettingsScreen,
  isGettingStartedScreen,
}: {
  isSettingsScreen?: boolean;
  isGettingStartedScreen?: boolean;
}) {
  const {
    isSyncingAllowed,
    connectedToQuickBooks,
    connectedToXero,
    connectedToZoho,
    status,
    syncing,
    syncingError,
    xeroOrganizationName,
    qboOrganizationName,
    zohoOrganizationName,
    setSyncing,
    lastSyncDate,
    currentPlan,
    totalSyncs,
    checkingSyncingStatus,
    demoDataExists,
  } = useAuth();

  const { fetchLinkedBanks } = useBanksContext();
  const navigate = useNavigate();

  const onUpgrade = () => {
    navigate("/pricing");
  };

  let provider = useCallback(() => {
    if (connectedToXero) {
      return "Xero";
    } else if (connectedToQuickBooks) {
      return "QuickBooks";
    } else if (connectedToZoho) {
      return "Zoho";
    } else return <span></span>;
  }, [connectedToXero, connectedToQuickBooks, connectedToZoho]);

  const getProviderSyncUrl = () => {
    if (connectedToXero) {
      return "/syncXero";
    } else if (connectedToQuickBooks) {
      return "/syncQbo";
    } else if (connectedToZoho) {
      return "/zoho/sync";
    } else return "";
  };

  const syncMissingValues = () => {
    setSyncing(true);
    axios
      .post(
        configs.urls.BASE_URL + getProviderSyncUrl(),
        { missingDataOnly: true },
        {
          withCredentials: true,
        }
      )
      .then(
        (res) => {
          if (res.data !== "err") {
            showNotification({
              color: "teal",
              message: `${res.data}`,
              icon: <FontAwesomeIcon icon={faCheck} />,
              autoClose: 2000,
            });
          }
        },
        (err) => {
          showNotification({
            color: "red",
            message: `${err}`,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      );
  };

  const disconnectConnected = () => {
    if (connectedToXero) {
      disconnectFromXero();
    } else if (connectedToQuickBooks) {
      disconnectFromQbo();
    } else if (connectedToZoho) {
      disconnectFromZoho();
    } else {
      return;
    }
  };

  let providerOrgName = useCallback(() => {
    if (connectedToXero) {
      return <span>{xeroOrganizationName}</span>;
    } else if (connectedToQuickBooks) {
      return <span>{qboOrganizationName}</span>;
    } else if (connectedToZoho) {
      return <span>{zohoOrganizationName}</span>;
    } else return <span></span>;
  }, [
    connectedToXero,
    connectedToQuickBooks,
    connectedToZoho,
    xeroOrganizationName,
    qboOrganizationName,
    zohoOrganizationName,
  ]);

  let providerIcon = useCallback(() => {
    if (connectedToXero) {
      return IMAGES.xero_connected;
    } else if (connectedToQuickBooks) {
      return IMAGES.qbo_connected;
    } else if (connectedToZoho) {
      return IMAGES.zoho_connected;
    } else return undefined;
  }, [connectedToXero, connectedToQuickBooks, connectedToZoho]);

  let syncingInProgressIcon = useCallback(() => {
    if (connectedToXero) {
      return IMAGES.xero_in_progress;
    } else if (connectedToQuickBooks) {
      return IMAGES.qbo_in_progress;
    } else if (connectedToZoho) {
      return IMAGES.zoho_in_progress;
    } else return undefined;
  }, [connectedToXero, connectedToQuickBooks, connectedToZoho]);

  // get time taken by converting seconds with moment in seconds, minutes or hour

  const Importing = ({ label, status }: { label: string; status: boolean }) => {
    return (
      <div className="is-flex is-flex-direction-row is-align-items-flex-start my-4 mx-4">
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={status ? COLORS.greenBlue : "white"}
          style={{
            width: "18px",
            height: "18px",
            backgroundColor: status ? undefined : "white",
            borderRadius: "50%",
            border: status ? undefined : `1px solid black`,
          }}
        />
        <div className="subtitle is-6 ml-4">{label}</div>
      </div>
    );
  };

  const Progress = () => {
    return (
      <Fragment>
        {syncing || Object.values(status).some((val) => val) ? (
          <div className="is-align-items-flex-start my-4 mx-4">
            <Importing label={`Importing contacts`} status={status.contacts} />
            <Importing label={`Importing "pay" data`} status={status.bills} />
            <Importing
              label={`Importing "get-paid" data`}
              status={status.invoices}
            />
            <Importing
              label={`Updating cashflow`}
              status={status.openingBalance}
            />
            <Importing
              label={`Updating dashboard`}
              status={status.openingBalance}
            />
          </div>
        ) : null}

        {status.timeSpent ? (
          <div className="subtitle is-6 mt-1 has-text-centered">
            Time taken: <strong> {getFormattedTime(status.timeSpent)}</strong>
          </div>
        ) : null}

        {syncingError !== "" ? (
          <div
            className="subtitle mt-1 has-text-centered"
            style={{
              color: COLORS.btnDelete,
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            {syncingError}
          </div>
        ) : null}
      </Fragment>
    );
  };

  const SyncingInProgress = () => {
    return (
      <div
        className="is-flex is-flex-direction-column is-align-items-center my-4 mx-4"
        style={
          {
            // width: isGettingStartedScreen || isSettingsScreen ? undefined : "40%",
          }
        }
      >
        {syncing ? (
          <Fragment>
            <img
              src={syncingInProgressIcon()}
              alt="xero_connected"
              style={{ width: "254px", height: "90px" }}
            />
            <div className="title is-5 mt-4">Syncing in progress…</div>
            <div className="subtitle is-6 mt-1 has-text-centered">
              Syncing your accounting data for the first time can take up to 10
              min
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <img
              src={providerIcon()}
              alt="xero_connected"
              style={{ width: "143px", height: "140px" }}
            />
            <h4 className="mt-6 connect-title-text has-text-centered">
              Connected to {providerOrgName()}
            </h4>
            <div
              className=" mt-1 has-text-centered mb-6"
              style={{ fontSize: 14, color: COLORS.brownishGrey }}
            >
              <span>
                Your accounting data was last in sync with Madi{" "}
                <strong>{moment(lastSyncDate).fromNow()}</strong>
              </span>
            </div>
          </Fragment>
        )}

        <Progress />

        <Fragment>
          {!checkingSyncingStatus && !isSyncingAllowed && (
            <div
              className="my-3 has-text-centered"
              style={{
                fontSize: 12,
                color: "red",
                fontWeight: "600",
              }}
            >
              {currentPlan?.capabilities?.noOfSyncsPerDay - totalSyncs} more
              syncs available. Please try again tomorrow or upgrade your plan.
            </div>
          )}
          <Tooltip
            transition="scale-y"
            label={`${
              currentPlan?.capabilities?.noOfSyncsPerDay - totalSyncs
            } more sync(s) available. ${
              currentPlan?.capabilities?.noOfSyncsPerDay - totalSyncs === 0
                ? "Please try again tomorrow or upgrade your plan."
                : ""
            } `}
            position="top"
            offset={5}
            multiline
            withArrow
            // hidden={isSyncingAllowed}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {isGettingStartedScreen ? (
                !syncing ? (
                  <Fragment>
                    {!isSyncingAllowed ? (
                      <PrimaryButton className={""} onClick={onUpgrade}>
                        Upgrade
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        className={syncing ? `is-loading` : ""}
                        disabled={syncing || !isSyncingAllowed}
                        onClick={syncMissingValues}
                      >
                        Sync
                      </PrimaryButton>
                    )}
                  </Fragment>
                ) : null
              ) : (
                <Fragment>
                  {!isSyncingAllowed ? (
                    <PrimaryButton className={""} onClick={onUpgrade}>
                      Upgrade
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      className={syncing ? `is-loading` : ""}
                      disabled={syncing || !isSyncingAllowed}
                      onClick={syncMissingValues}
                    >
                      Sync
                    </PrimaryButton>
                  )}
                </Fragment>
              )}
            </div>
          </Tooltip>

          {isSettingsScreen ? (
            <PrimaryButton
              style={{
                backgroundColor: COLORS.btnDelete,
                color: COLORS.white,
              }}
              className="button is-small mt-3"
              onClick={disconnectConnected}
            >
              <span className="icon is-small mr-2">
                <FontAwesomeIcon icon={faPowerOff} />
              </span>
              Disconnect {provider()}
            </PrimaryButton>
          ) : null}
        </Fragment>
      </div>
    );
  };

  const openModal = (cb: Function) =>
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Your company contains demo data. Please note that we will clear your
          demo data before connecting your accounting software.
        </Text>
      ),
      styles: {},
      confirmProps: {
        style: {
          backgroundColor: COLORS.greenPrimary,
        },
      },
      groupProps: {
        style: {
          flexDirection: "row-reverse",
          justifyContent: "center",
        },
      },
      labels: { confirm: "Clear demo data and connect", cancel: "No, Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        deleteDemoData();
        fetchLinkedBanks();
        cb();
      },
    });

  const NotConnectedView = () => {
    return (
      <div className="is-flex is-flex-direction-column is-align-items-center my-4 mx-4">
        <img
          src={IMAGES.sync_accounting_softwares}
          alt="Sync accounting softwares"
          style={{ width: "234px", height: "90px" }}
        />
        <div className="title is-5 mt-6 has-text-centered">
          Connect your accounting software
        </div>
        <div className="subtitle is-6 mt-1 has-text-centered">
          Securely connect Quickbooks, Xero or Zoho to gain real-time insight
          into your financials.
        </div>

        {!checkingSyncingStatus && !isSyncingAllowed && (
          <div
            className="my-3 has-text-centered"
            style={{
              fontSize: 12,
              color: "red",
              fontWeight: "600",
            }}
          >
            Your daily syncing limit is{" "}
            {currentPlan?.capabilities?.noOfSyncsPerDay}. Please try again
            tomorrow or upgrade your plan.
          </div>
        )}

        <div
          className="is-flex is-flex-direction-column is-align-items-center"
          style={{ width: "100%", position: "relative" }}
        >
          {!checkingSyncingStatus && !isSyncingAllowed && <Overlay blur={2} />}

          <Container
            onClick={() =>
              demoDataExists ? openModal(connectXero) : connectXero()
            }
          >
            <Image src={IMAGES.xero_round_logo} alt="xero" />
            <span className="is-size-6">Sync with Xero</span>
          </Container>
          <Container
            onClick={() =>
              demoDataExists ? openModal(connectQbo) : connectQbo()
            }
          >
            <Image src={IMAGES.qbo_round_logo} alt="xero" />
            <span className="is-size-6">Sync with Quickbooks</span>
          </Container>
          <Container
            onClick={() =>
              demoDataExists ? openModal(connectZoho) : connectZoho()
            }
          >
            <Image src={IMAGES.zoho_round_logo} alt="xero" />
            <span className="is-size-6">Sync with Zoho</span>
          </Container>
        </div>
      </div>
    );
  };

  return connectedToQuickBooks || connectedToXero || connectedToZoho ? (
    <SyncingInProgress />
  ) : (
    <NotConnectedView />
  );
}

export default SyncAccountingSoftware;

const Container = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 77%;
  box-shadow: 0px 1px 5px #9e9e9e;
  background-color: white;
  border: 1px solid #0009;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  margin-right: 10px;
  width: 28px;
  height: 28px;
`;
