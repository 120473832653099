import "./styles.css";

import { Button } from "rsuite";
import { COLORS } from "../../constants/theme";

function CustomButtonPrimary({ title = "Next", handleOnClick }: any) {
  return (
    <div className="button-container">
      <Button
        className="button-primary"
        style={{
          backgroundColor: COLORS.greenPrimary,
        }}
        onClick={handleOnClick}
      >
        {title}
      </Button>
    </div>
  );
}

export default CustomButtonPrimary;
