import axios from "axios";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { configs } from "../../../constants";
import { useAuth } from "../../../contexts";
import { useDrawer } from "./useDrawer";

export const usePayments = ({ name }: any) => {
  const [customerId, setCustomerId] = useState<string>("");
  const [endUserId, setEndUserId] = useState<string>("");
  const [destinationId, setDestinationId] = useState<string>();
  const [paymentSource, setPaymentSource] = useState<any>();
  const [ifBankConnected, setIfBankConnected] = useState<boolean>(false);
  const { drawer } = useDrawer();
  const { setGlobalLoading } = useAuth();

  const fetchPaymentSource = async () => {
    // getPaymentSource
    axios
      .get(configs.urls.BASE_URL + "/getPaymentSource", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data !== "error") {
          setPaymentSource(res.data);
        }
      });
  };

  const fetchBeneficary = async () => {
    if (name) {
      axios
        .post(
          configs.urls.BASE_URL + "/contact/getByName",
          { name: name + " Account" },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.success) {
            setDestinationId(res.data?.response?.destinationId);
          }
        });
    }
  };

  const getDestinationId = async (name: string) => {
    const response = await axios.post(
      configs.urls.BASE_URL + "/contact/getByName",
      { name: name },
      {
        withCredentials: true,
      }
    );
    if (response.data.success) {
      return response.data?.response?.destinationId;
    } else return "";
  };

  const getCustomerId = async () => {
    // get customer Id
    axios
      .get(configs.urls.BASE_URL + "/getLeanCustomerId", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data !== "error") {
          setCustomerId(res.data);
        }
      });
  };

  const getEndUserId = async () => {
    axios
      .get(configs.urls.BASE_URL + "/getEndUserId", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          setEndUserId(res.data.response);
        }
      });
  };

  useEffect(() => {
    getCustomerId();
    fetchBeneficary();
    // fetchPaymentSource();
    getEndUserId();
  }, []);

  useEffect(() => {
    setIfBankConnected(!_.isEmpty(paymentSource));
  }, [paymentSource]);

  // check if payment source is connected
  const checkIfPaymentSourceExists = () => {
    if (ifBankConnected) {
      return paymentSource.beneficiaries.find(
        (b: any) => b.destinationId === destinationId
      );
    } else {
      return false;
    }
  };

  return {
    customerId,
    destinationId,
    paymentSource,
    ifBankConnected,
    fetchPaymentSource,
    checkIfPaymentSourceExists,
    getDestinationId,
    endUserId,
  };
};
