import axios from "axios";
import { configs } from "../constants";
import {
  CreateScenarioPayload,
  CreateSnapshotPayload,
  DeleteScenarioPayload,
  DeleteSnapshotPayload,
} from "../constants/payloads";
import { ROUTES } from "../constants/routes";

export const getAllScenarios = async () => {
  try {
    const { data } = await axios.get(
      configs.urls.BASE_URL + `/cashflow_scenario/getAll`,
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const upsertScenario = async (payload: CreateScenarioPayload) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + ROUTES.CashflowScenario.Base + "/upsert",
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const switchScenario = async (scenarioId: string) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL +
        ROUTES.CashflowScenario.Base +
        `/switch_to/${scenarioId}`,
      {},
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const deleteScenario = async (payload: DeleteScenarioPayload) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + ROUTES.CashflowScenario.Base + `/delete`,
      { ...payload },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const createSnapshot = async (payload: CreateSnapshotPayload) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + ROUTES.CashflowScenario.Base + "/snapshot/create",
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const deleteSnapshot = async (payload: DeleteSnapshotPayload) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + ROUTES.CashflowScenario.Base + "/snapshot/delete",
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
