import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "@mantine/core";
import React, { Fragment } from "react";
import { useAuth } from "../../../contexts";

function CashflowAlert({ errorState }: { errorState: any }) {
  const { obErrorMessage, setObErrorMessage, obMessage, setObMessage } =
    useAuth();

  const { error, setError } = errorState;

  return (
    <Fragment>
      {obMessage !== "" && (
        <div
          className="flex-direction-row alert-box-cashflow"
          style={{ justifyContent: "center", position: "relative" }}
        >
          <Alert
            icon={<FontAwesomeIcon icon={faInfoCircle} />}
            className="mt-6"
            title="Info!"
            color="green"
            style={{
              width: "auto",
              marginBottom: 10,
              position: "absolute",
              right: 30,
              zIndex: 1,
              bottom: 10,
              marginRight: 45,
            }}
            withCloseButton
            onClose={() => {
              setObMessage("");
            }}
          >
            {obMessage}
          </Alert>
        </div>
      )}
      {(error !== "" || obErrorMessage !== "") && (
        <div
          className="flex-direction-row"
          style={{ justifyContent: "center", position: "relative" }}
        >
          <Alert
            icon={<FontAwesomeIcon icon={faInfoCircle} />}
            title="Bummer!"
            color="red"
            style={{
              width: "auto",
              marginBottom: 10,
              position: "absolute",
              right: 30,
              zIndex: 1,
              bottom: 10,
              marginRight: 45,
            }}
            withCloseButton
            onClose={() => {
              setError("");
              setObErrorMessage("");
            }}
          >
            {error || obErrorMessage}
          </Alert>
        </div>
      )}
    </Fragment>
  );
}

export default CashflowAlert;
