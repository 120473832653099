import "./styles.css";
import "react-phone-input-2/lib/style.css";
import React, { useEffect, forwardRef, useState } from "react";
import { Group, Avatar, Text, Select } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { IMAGES, configs } from "../../constants";
import {
  getAllOrganizationsOfCurrentUser,
  getCurrentMemberShip,
  getAllOwnedOrgsOfCurrentUser,
} from "../../repositories/userRepository";
import { setActiveOrganization } from "../../repositories/organizationRepository";
import { setUserLS } from "../../modules/localStorage";
import axios from "axios";
import { Tier } from "../../constants/globalConstants";

import { showNotification } from "@mantine/notifications";

enum GROUPS {
  ACTIVE_COMPANY = "ACTIVE COMPANY",
  OTHER_COMPANIES = "OTHER COMPANIES",
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
  disabled: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, disabled, ...others }: ItemProps, ref) => (
    <div
      ref={ref}
      {...others}
      title={disabled ? "You subscription to this company is blocked" : ""}
    >
      <Group noWrap>
        <Avatar src={IMAGES.company_logo_placeholder} />

        <div>
          <Text size="sm" className="mantine-font-text">
            {label}
          </Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

function SwitchCompany() {
  const [hasShadow, showShadow] = useState(false);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");

  const [currUserOrgs, setCurrUserOrgs] = useState<any[]>([]);

  const fetchOrganizations = async () => {
    const orgsList: any[] = [];
    const { organizations } = await getAllOrganizationsOfCurrentUser();
    // console.log({ organizations });
    for (let organization of organizations) {
      const {
        currentlyActive,
        name,
        id,
        isDemo = false,
        completedTutorial = false,
      } = organization;
      if (currentlyActive) {
        orgsList.unshift({
          label: name,
          value: id,
          group: GROUPS.ACTIVE_COMPANY,
          description: "",
          isDemo,
          completedTutorial,
          disabled: organization?.subscription.status === "blocked",
        });
      } else {
        orgsList.push({
          label: name,
          value: id,
          group: GROUPS.OTHER_COMPANIES,
          description: "",
          isDemo,
          completedTutorial,
          disabled: organization?.subscription.status === "blocked",
        });
      }
    }
    setOrganizations(orgsList);
  };

  const fetchOwnedOrgsByUser = async () => {
    const { organizations } = await getAllOwnedOrgsOfCurrentUser();
    const temp = [];
    // console.log({ organizations });
    for (let organization of organizations) {
      const { id } = organization;
      temp.push(id);
    }
    setCurrUserOrgs(temp);
  };

  useEffect(() => {
    fetchOwnedOrgsByUser();
    fetchOrganizations();
  }, []);

  const handleSelect = (orgId: string) => {
    setSelectedOrgId(orgId);
  };

  const checkOrganizationExpiry = async (selectedOrgId: string) => {
    try {
      const response = await axios.post(
        configs.urls.BASE_URL +
          `/organization/checkExpiryStatus/${selectedOrgId}`
      );

      if (currUserOrgs.includes(selectedOrgId)) {
        return true;
      } else {
        console.log(
          "subscription tier >>>> ",
          response.data.response.subscription.tier
        );
        if (response.data.response.subscription.tier !== Tier.Demo) {
          return true;
        } else if (!response.data.response.user.trialExpiryDate) {
          return false;
        } else {
          return true;
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const handleSwitch = async () => {
    const allowSwitch = await checkOrganizationExpiry(selectedOrgId);
    console.log("ALLOW SWITCH >>> ", allowSwitch);
    if (!allowSwitch) {
      return showNotification({
        color: "red",
        message: `You cannot switch, because admin needs to upgrade thier account`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
    await setActiveOrganization(selectedOrgId);
    const org = organizations.find(({ value }) => value == selectedOrgId) || {};
    const { isDemo = false, completedTutorial = false, createdAt } = org;

    if (org) {
      const userMembership = await getCurrentMemberShip(selectedOrgId);

      if (userMembership?.role?.type) {
        localStorage.setItem("roleType", userMembership?.role?.type);
      } else {
        localStorage.setItem("roleType", userMembership?.roleName);
      }
    }

    setUserLS({
      isDemo,
      completedTutorial: completedTutorial ? true : false,
      createdAt,
    });
    localStorage.setItem("organizationId", selectedOrgId);

    if (isDemo) {
      window.location.href = "cashflow?tab=forecast";
    } else {
      window.location.href = "companies";
    }
  };

  return (
    <div
      className="card-outer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        onMouseOver={() => showShadow(true)}
        onMouseOut={() => showShadow(false)}
        className={`card-inner-container switch-company-card ${
          hasShadow ? "card" : ""
        }`}
      >
        <div className="card-content card-padding onboarding">
          <div className="content">
            <div className="logo-container">
              <p className="register-form-title">Switch to Other Companies</p>
            </div>
          </div>
          <div>
            <Select
              label="Company Name"
              placeholder="i.e Amazon"
              itemComponent={SelectItem}
              data={organizations}
              searchable
              initiallyOpened={true}
              dropdownPosition="bottom"
              maxDropdownHeight={400}
              onChange={handleSelect}
              nothingFound="Nothing found"
              filter={(value, item: any) =>
                item?.label
                  .toLowerCase()
                  .includes(value.toLowerCase().trim()) ||
                item.description
                  .toLowerCase()
                  .includes(value.toLowerCase().trim())
              }
            />
          </div>
        </div>
      </div>
      <button className="button-image margin-top-2" onClick={handleSwitch}>
        <span>Save And Continue</span>
      </button>
    </div>
  );
}

export default SwitchCompany;
