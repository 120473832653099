import { Image, LoadingOverlay } from "@mantine/core";
import "./style.css";
import styled from "styled-components";

interface ICard {
  image: any;
  title: string;
  content: string;
  className?: string;
  selected?: string;
  onClick?: (e: any) => void;
  loading?: boolean;
}

function Card({
  image,
  title,
  content,
  onClick,
  className = "",
  selected,
  loading = false,
}: ICard) {
  return (
    <div
      id={title}
      className={`card-style  ${
        title && selected === title ? "selected-type" : className
      } ${!title ? "no-shadow" : ""}`}
      onClick={() => {
        onClick && onClick(title);
      }}
      style={{ position: "relative" }}
    >
      <LoadingOverlay visible={loading} />
      {image && (
        <ResponsiveImage
          style={
            title === "Sync Accounting"
              ? {
                  width: "182px",
                  height: "65px",
                }
              : {}
          }
          src={image}
          alt="what-we-do"
        />
      )}

      <p className="card-title">{title}</p>
      <p className="card-description">{content}</p>
    </div>
  );
}

export default Card;

const ResponsiveImage = styled.img`
  width: 85px;
  height: 90px;

  @media (max-width: 480px) {
    width: 65px;
    height: 75px;
  }
`;
