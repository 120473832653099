import "../styles.css";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { CardSubtilteSmall, CardTitleBlack } from "../../../common/CardTitles";
import { appTheme, configs } from "../../../constants";
import currencies from "../../../assets/files/currencies.json";
import { currency_logo } from "../../../constants/images";
import { useAuth } from "../../../contexts";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import styled from "styled-components";
import { COLORS } from "../../../constants/theme";

function Currencies() {
  const {
    connectedToXero,
    connectedToQuickBooks,
    connectedToZoho,
    baseCurrency,
    setBaseCurrency,
    currenciesExchangeDate,
    fetchCashflowReport,
  } = useAuth();

  const [loading, setLoading] = useState(false);

  const [provider] = useState(() => {
    if (connectedToXero) {
      return "Xero";
    } else if (connectedToQuickBooks) {
      return "QuickBooks";
    } else if (connectedToZoho) {
      return "Zoho";
    } else return "";
  });

  const [selectedCurrency, setSelectedCurrency] =
    useState<string>(baseCurrency);

  const setCurrency = async (currency: string) => {
    setLoading(true);
    await axios.post(
      configs.urls.BASE_URL + "/set-currency",
      { provider, baseCurrency: currency },
      { withCredentials: true }
    );

    fetchCashflowReport();
    setBaseCurrency(currency);
    setLoading(false);
    showNotification({
      message: "Configuraton saved!",
      color: "teal",
      icon: <FontAwesomeIcon icon={faCheck} />,
    });
  };

  // useEffect(() => {
  //   // Get Currencies
  //   const getCurrencies = async () => {
  //     const response = await axios.get(
  //       configs.urls.BASE_URL + "/getCurrencies",
  //       {
  //         withCredentials: true,
  //       }
  //     );

  //     if (response.data === "error")
  //       return setCurrencies([{ label: "AED", value: "AED" }]);

  //     console.log("CURRENCIES: ", response.data);
  //     const curs = response.data;
  //     const currenciesTemp = [];
  //     for (let i = 0; i < curs.length; i++) {
  //       currenciesTemp.push({
  //         value: curs[i].code,
  //         label: curs[i].code,
  //       });
  //     }

  //     setCurrencies([...currenciesTemp]);
  //     // setOrganizationName(response.data);
  //   };

  //   getCurrencies();
  // }, []);

  return (
    <div
      className="currency-card"
      style={{ marginLeft: "5rem", marginRight: "5rem" }}
    >
      <img className="currency-image" alt="currency_logo" src={currency_logo} />
      <CardTitleBlack title={"Cashflow & Dashboard Currency"} />
      <CardSubtilteSmall title="Set your currency to view your cashflow and dashboard in one currency." />
      <Select
        // search by label and value
        style={{ margin: "20px 0px", width: "100%" }}
        label="Currency"
        placeholder={currencies.find((c) => c.value === baseCurrency)?.label}
        onChange={(value: string) => {
          setSelectedCurrency(value);
        }}
        data={currencies}
        searchable
        maxDropdownHeight={400}
        nothingFound="Nothing here"
        filter={(value, item: any) =>
          item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.value.toLowerCase().includes(value.toLowerCase().trim())
        }
      />
      <PrimaryButton
        className={`${loading && "is-loading"}`}
        style={{ width: "100%" }}
        onClick={() => {
          setCurrency(selectedCurrency);
        }}
      >
        Save Currency
      </PrimaryButton>

      {currenciesExchangeDate && (
        <CurrencyRateDate>
          Exchange rates last updated on {currenciesExchangeDate}
        </CurrencyRateDate>
      )}
    </div>
  );
}

export default Currencies;

const CurrencyRateDate = styled.div`
  font-size: 12px;
  color: ${COLORS.gray};
  margin-top: 20px;
`;
