import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@mantine/core";
import React from "react";
import { IconButton } from "rsuite";
import styled from "styled-components";
import { COLORS } from "../../constants/theme";

function CustomDrawer({
  opened,
  onBack,
  children,
  onClose,
  title,
  zIndex,
  containerStyle = {},
}: any) {
  return (
    <Drawer
      zIndex={zIndex || 10}
      className="custom-scrollbar"
      lockScroll
      styles={{
        header: { display: "none" },
        drawer: { overflow: "auto", backgroundColor: "#fafafa" },
      }}
      position="bottom"
      opened={opened}
      onClose={onBack}
      size="full"
      id="cutsom-drawer"
    >
      <Container style={{ backgroundColor: "#fafafa" }}>
        <Header>
          <ButtonContainer
            variant="default"
            disabled={!onBack}
            onClick={onBack}
            icon={
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ width: 30, height: 30, color: COLORS.veryLightPink }}
              />
            }
          />

          <ModalTitle className="mt-5">{title}</ModalTitle>
          <ButtonContainer
            variant="default"
            onClick={onClose}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: 30, height: 30, color: COLORS.veryLightPink }}
              />
            }
          />
        </Header>

        <Content style={{ ...containerStyle }}>{children}</Content>
      </Container>
    </Drawer>
  );
}

export default CustomDrawer;

const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
  padding-right: 15px;
  padding-left: 15px;
`;

const ModalTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  margin: 20px;
`;

const ButtonContainer = styled(IconButton)`
  border-radius: 50%;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #fafafa;
  z-index: 9;
`;

const Content = styled.div`
  width: 100%;
  padding: 15px;

  @media screen and (max-width: 1600px) {
    margin-top: 0rem;
    paddin-top: 0rem;
  }
`;
