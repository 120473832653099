import { faTimes } from "@fortawesome/free-solid-svg-icons";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { InvoiceTypes } from "../../../constants/globalConstants";
import AddPayment from "./AddPayment";
import AddRecord from "./AddRecord";

enum AddRecordType {
  ADD_BILL = "ADD_BILL",
  ADD_PAYMENT = "ADD_PAYMENT",
}

function AddRecordAndPayments({ recordType, onSubmit, step, setStep }: any) {
  let contactType = recordType === InvoiceTypes.Payable ? "Vendor" : "Customer";

  const [record, setRecord] = useState({});

  const handleAddPayment = (
    step: string,
    rec?: Record<string, any>,
    setSelectedCashflowBucket?: string
  ) => {
    setRecord(record);
    if (!rec?.name) {
      return showNotification({
        color: "red",
        message: `Please enter ${contactType}  name!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
    if (!rec?.amount) {
      return showNotification({
        color: "red",
        message: `Amount Required!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    } else if (
      parseFloat(rec?.amount) <= 0 ||
      _.isNaN(parseFloat(rec.amount))
    ) {
      return showNotification({
        color: "red",
        title: "Invalid Amount",
        message: "Amount should be greater than 0",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }

    setStep(step);
    setRecord({ ...rec, setSelectedCashflowBucket });
  };

  return (
    <div>
      {step === AddRecordType.ADD_PAYMENT ? (
        <AddPayment
          record={record}
          recordType={recordType}
          onSubmit={onSubmit}
          title={"Add Payment"}
        />
      ) : (
        <AddRecord
          recordType={recordType}
          onSubmit={onSubmit}
          record={record}
          handleUpdateStep={handleAddPayment}
        />
      )}
    </div>
  );
}

export default AddRecordAndPayments;
