import React from "react";
import Card from "../../common/Card/Card";
import SyncAccountingSoftware from "../../common/SyncAccountingSoftware/SyncAccountingSoftware";

function SyncAccountingSoftwarePage() {
  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center mt-6"
      style={{ height: "89vh" }}
    >
      <Card style={{ width: "50%" }}>
        <SyncAccountingSoftware />
      </Card>
    </div>
  );
}

export default SyncAccountingSoftwarePage;
