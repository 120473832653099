import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { IconButton } from "rsuite";
import styled from "styled-components";
import { LoadingPlaceholders } from "../../../../common/LoadingPlaceholders";
import CustomTabs from "../../../../common/PageLayout/components/CustomTabs";
import List from "../../../../common/PageLayout/components/List";
import ListContainer from "../../../../common/PageLayout/components/ListContainer";
import RecordListCard from "../../../../common/PageLayout/components/RecordListCard";
import { StyledComponents } from "../../../../constants";
import {
  PaymentStatus,
  PeriodFilter,
  ReportViewTypes,
} from "../../../../constants/globalConstants";
import { useAuth } from "../../../../contexts";
import { getRecordsByContact } from "../../repository/getRecords";
import ShowRecord from "./ShowRecord";
import EstimateListCard from "../../../../common/PageLayout/components/EstimateListCard";
import formatEstimates from "../../../../modules/formatEstimates";
import ShowEstimate from "../../../../common/PageLayout/components/ShowEstimate";
import { formatCashflowMappingInInvoices } from "../../../../modules/formatCashflowMapping";

interface ListOfRecordsProps {
  selectedContactId: any;
  type: any;
  onClose: () => void;
}

type Tabs = "paid" | "upcoming" | "estimates";

type TotalRecords = {
  total: number;
  totalPaid: number;
  totalUnpaid: number;
};

function ListOfRecords({
  selectedContactId,
  type,
  onClose,
}: ListOfRecordsProps) {
  const { cashflowConfigs } = useAuth();
  let selectedViewIndex = Object.keys(PeriodFilter).indexOf(
    cashflowConfigs?.periodFilter
  );

  const [activeTab, setActiveTab] = React.useState<Tabs>("paid");
  const [gettingRecords, setGettingRecords] = React.useState(false);
  const [records, setRecords] = React.useState<any[]>([]);
  const [estimates, setEstimates] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<TotalRecords>(
    {} as TotalRecords
  );

  const [selectedRecord, setSelectedRecord] = React.useState<any>();

  const getRecords = async ({ skip = 0 }: { skip?: number }) => {
    setGettingRecords(true);
    const { data, success } = await getRecordsByContact({
      contactId: selectedContactId,
      dateRange: [cashflowConfigs?.startDate, cashflowConfigs?.endDate],
      skip: skip,
      type,
    });
    setGettingRecords(false);

    if (success) {
      if (skip === 0) {
        setRecords(data.invoices);
        setEstimates(data.estimates);
        setTotalRecords({
          total: data.total,
          totalPaid: data.totalPaid,
          totalUnpaid: data.totalUnpaid,
        });
      } else {
        setRecords([...records, ...data.invoices]);
        setEstimates([...estimates, ...data.estimates]);
      }
    }
  };

  let filteredRecords = useMemo(() => {
    if (activeTab === "estimates") {
      const _estimates = formatCashflowMappingInInvoices(estimates);
      const formattedEstimates = formatEstimates(_estimates);
      return formattedEstimates;
    } else {
      let _records = formatCashflowMappingInInvoices(records);

      return _records.filter((record: any) => {
        if (activeTab === "paid") {
          return record.status === PaymentStatus.Paid;
        } else if (activeTab === "upcoming") {
          return (
            record.status === PaymentStatus.Unpaid ||
            record.status === PaymentStatus.PartiallyPaid
          );
        } else {
          return true;
        }
      });
    }
  }, [records, activeTab]);

  useEffect(() => {
    getRecords({});
  }, []);

  useEffect(() => {
    // set active tab upcoming by default if there are no paid records
    if (
      activeTab === "paid" &&
      filteredRecords.length === 0 &&
      records.length
    ) {
      setActiveTab("upcoming");
    }

    if (
      (activeTab === "paid" || activeTab === "upcoming") &&
      !records.length &&
      estimates.length
    ) {
      setActiveTab("estimates");
    }
  }, [records]);

  const whichTotal = useMemo(() => {
    if (activeTab === "paid") {
      return totalRecords.totalPaid;
    } else if (activeTab === "upcoming") {
      return totalRecords.totalUnpaid;
    } else {
      return totalRecords.total;
    }
  }, [activeTab, totalRecords]);

  const renderView = (selectedRecord: any) => {
    if (selectedRecord) {
      return activeTab === "estimates" ? (
        <ShowEstimate
          selectedRecord={selectedRecord}
          loading={false}
          setSelectedRecord={setSelectedRecord}
          onClose={onClose}
        />
      ) : (
        <ShowRecord
          selectedRecord={selectedRecord}
          loading={false}
          setSelectedRecord={setSelectedRecord}
          onClose={onClose}
        />
      );
    } else {
      return (
        <ListContainer
          style={{
            margin: "15px 0px 0px 40px",
            padding: 0,
          }}
        >
          <List
            style={{
              height: "calc(100vh - 180px)",
              overflowY: "scroll",
            }}
            multiselect={false}
            paginate={whichTotal}
            paginateWhen={whichTotal > 5}
            activeTab={activeTab}
            onLoadMore={async (skip: number) => {
              getRecords({ skip });
            }}
            list={filteredRecords}
            renderItem={(record: any) =>
              activeTab === "estimates" ? (
                <EstimateListCard record={record} />
              ) : (
                <RecordListCard record={record} />
              )
            }
            listLoading={gettingRecords}
            renderTabs={() => (
              <CustomTabs
                active={activeTab}
                setActive={setActiveTab}
                tabs={[
                  { key: "paid", title: "Paid" },
                  { key: "upcoming", title: "Upcoming" },
                  { key: "estimates", title: "Estimates" },
                ]}
                onSelect={(value: string) => {
                  setActiveTab(value as Tabs);
                }}
              />
            )}
            onCardClick={(clickedItem: any) => {
              setSelectedRecord(clickedItem);
            }}
          />
        </ListContainer>
      );
    }
  };

  return (
    <div>
      <StyledComponents.ListItemCardHeaderContainer
        className="pv-6"
        style={{
          backgroundColor: "#d6ede8",
          position: "sticky",
          top: 0,
          zIndex: 99,
          height: 100,
          gap: 20,
          paddingLeft: 35,
        }}
      >
        {/* <BackButton> */}
        {selectedRecord ? (
          <BackButton
            onClick={() => {
              setSelectedRecord(null);
            }}
            icon={
              <FontAwesomeIcon
                style={{
                  fontSize: 20,
                }}
                color="black"
                icon={faChevronLeft}
              />
            }
          />
        ) : null}

        {/* </BackButton> */}
        <div>
          <DateRange>
            {cashflowConfigs === undefined ? (
              <LoadingPlaceholders.Text
                style={{ marginBottom: -5, marginLeft: 3, height: 25 }}
                active={gettingRecords}
              />
            ) : (
              <span>
                From{" "}
                {moment(cashflowConfigs?.startDate).format(
                  ReportViewTypes[selectedViewIndex]?.format
                )}{" "}
                to{" "}
                {moment(cashflowConfigs?.endDate).format(
                  ReportViewTypes[selectedViewIndex]?.format
                )}
              </span>
            )}
          </DateRange>
          <StyledComponents.NameOnListItemCard>
            {!cashflowConfigs ? (
              <LoadingPlaceholders.Text
                style={{ marginBottom: -5, marginLeft: 3, height: 25 }}
                active={gettingRecords}
              />
            ) : (
              type?.name || records![0]?.name
            )}
          </StyledComponents.NameOnListItemCard>
        </div>
      </StyledComponents.ListItemCardHeaderContainer>
      {renderView(selectedRecord)}
    </div>
  );
}

export default ListOfRecords;

const DateRange = styled.div`
  font-size: 12px;
  color: #000;
`;
const BackButton = styled(IconButton)`
  background-color: #d6ede8;
  border: 2px solid black;
  padding: 5px;

  :hover {
    background-color: #d6ede8;
  }

  /* slide from left on mount */
  animation: slide-in-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
      visibility: visible;
    }

    100% {
      transform: translateX(0);
    }
  }
`;
