import React from "react";
import FinancialHealth from "../Companies/Dashboard/FinancialHealth";

function Hire() {
  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <FinancialHealth />
    </div>
  );
}

export default Hire;
