import React, { Fragment } from "react";
import { IMAGES } from "../../constants";
import { COLORS } from "../../constants/theme";
import { Dropdown } from "rsuite";
import styled from "styled-components";

export const CashInFlowsSection = () => (
  <Section>
    <div className="mb-3 m-4">
      <img className="cash-img" src={IMAGES.cash_in} alt="bank_icon" />
      <span className="ml-2 main-item-cashflow">Cash Inflows</span>
    </div>
    <ItemsContainer>
      <DropdownItem eventKey={1}>
        <Color style={{ background: COLORS.pale }} />
        <div className="ml-2">Add Category</div>
      </DropdownItem>

      {/* <DropdownItem eventKey={3}>
        <Color style={{ background: "#d6ede8" }} />
        <div className="ml-2">Add Budgets</div>
      </DropdownItem> */}

      <DropdownItem eventKey={5}>
        <Color style={{ background: "#bbf4f1" }} />
        <div className="ml-2">Add Estimates</div>
      </DropdownItem>

      <DropdownItem eventKey={2}>
        <Color style={{ background: "#10b480" }} />
        <div className="ml-2">Add Invoices</div>
      </DropdownItem>
    </ItemsContainer>
  </Section>
);

export const CashOutFlowsSection = () => (
  <Section>
    <div className="mb-3 m-4">
      <img className="cash-img" src={IMAGES.cash_out} alt="bank_icon" />
      <span className="ml-2 main-item-cashflow">Cash Outflows</span>
    </div>
    <ItemsContainer>
      <DropdownItem eventKey={1}>
        <Color style={{ background: COLORS.pale }} />
        <div className="ml-2">Add Category</div>
      </DropdownItem>

      {/* <DropdownItem eventKey={3}>
        <Color style={{ background: "#d6ede8" }} />
        <div className="ml-2">Add Budgets</div>
      </DropdownItem> */}

      <DropdownItem eventKey={6}>
        <Color style={{ background: "#bbf4f1" }} />
        <div className="ml-2">Add Estimates</div>
      </DropdownItem>

      <DropdownItem eventKey={4}>
        <Color style={{ background: "#c12e5b" }} />
        <div className="ml-2">Add Bills</div>
      </DropdownItem>
    </ItemsContainer>
  </Section>
);

function AddActionPopover({
  handleSelect,
  show = "both",
}: {
  handleSelect: (eventKey: any, event: React.SyntheticEvent<unknown>) => void;
  show?: "both" | "cash-in" | "cash-out";
}) {
  const renderList = () => {
    if (show === "both") {
      return (
        <Fragment>
          <CashInFlowsSection />
          <CashOutFlowsSection />
        </Fragment>
      );
    } else if (show === "cash-in") {
      return <CashInFlowsSection />;
    } else if (show === "cash-out") {
      return <CashOutFlowsSection />;
    }
  };

  return (
    <Dropdown.Menu
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
      onSelect={handleSelect}
    >
      {renderList()}
    </Dropdown.Menu>
  );
}

export default AddActionPopover;

export const Color = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  align-self: flex-start;
`;

export const DropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 10px;
  margin: 0;
  width: 100%;
`;

export const Section = styled.div`
  /* padding: 20px; */
  border-bottom: 1px solid #e5e5e5;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding: 0 15px;
`;
