import { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import Card from "../../../common/Card/Card";
import DetailsCardHeader from "../../../common/PageLayout/components/DetailsCardHeader";
import "../styles.css";
import { CustomSwitch } from "../../../constants/styledComponents";
import { Select, Stack } from "@mantine/core";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import { FREQUENCY_FILTERS } from "../../../constants/globalConstants";
import { showNotification } from "@mantine/notifications";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getNotificationSettingsApi,
  setNotificationSettingsApi,
} from "../../../repositories/settingsRepository";

function Notification() {
  const [isNotificationEnabled, setNotificationEnabled] =
    useState<boolean>(false);
  const [frequency, setFrequency] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const frequencySelectRef = useRef<HTMLInputElement>(null);

  const fetchSavedSettings = async () => {
    try {
      setLoading(true);
      const response = await getNotificationSettingsApi();
      if (response.success) {
        const { data } = response;
        if (data) {
          setNotificationEnabled(
            data.notificationStatus === "false" ? false : true
          );
          setFrequency(data.frequencyValue);
        }
      }
    } catch (err: any) {
      console.log("Error while fetching data: ", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedSettings();
  }, []);

  useEffect(() => {
    if (!frequency && frequencySelectRef.current) {
      frequencySelectRef.current.focus();
    }
  }, [frequency]);

  const submitNotificationSettings = async () => {
    try {
      if (!frequency) {
        return showNotification({
          color: "red",
          message: `${"Frequency is required"}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      }

      setLoading(true);

      const payload = {
        notificationStatus: isNotificationEnabled,
        frequencyValue: frequency,
      };

      const response = await setNotificationSettingsApi(payload);

      if (response.success) {
        return showNotification({
          color: "teal",
          message: "Notification settings saved",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
      }
    } catch (err: any) {
      console.log("Error: ", err);
      return showNotification({
        color: "red",
        message: `${
          err?.response?.data?.message || "Failed to save notification settings"
        }`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Fragment>
        <Card className="profile-details-card-container">
          <DetailsCardHeader
            setFullWidth={true}
            hasButton={false}
            title="Notification Settings"
          />
          <Container>
            <Stack mt={20}>
              <Label>Notification Status</Label>
              <CustomSwitch
                color="teal"
                checked={isNotificationEnabled}
                name="notificationStatus"
                onChange={(e: any) => {
                  setNotificationEnabled(e.target.checked);
                }}
              />
            </Stack>
            <Stack mt={20}>
              <Label>Frequency</Label>
              <Select
                ref={frequencySelectRef}
                value={frequency}
                disabled={!isNotificationEnabled}
                placeholder={
                  FREQUENCY_FILTERS.filter(
                    (freq) => freq.value === frequency
                  )[0]?.label
                }
                data={FREQUENCY_FILTERS}
                onChange={(value: any) => {
                  setFrequency(value);
                }}
              />
            </Stack>
            <Stack mt={10}>
              <PrimaryButton
                className={`${loading && "is-loading"}`}
                onClick={submitNotificationSettings}
                disabled={loading}
                style={{ width: "100%" }}
              >
                Save Settings
              </PrimaryButton>
            </Stack>
          </Container>
        </Card>
      </Fragment>
    </div>
  );
}

export default Notification;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
