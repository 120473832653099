import React from "react";

interface Bill {
  referenceNumber: string;
  name: string;
  description: string;
  amountDue: number;
  dueDate: Date;
  //   amountPaid: "32123",
  //   issueDate: moment(new Date()).format("MMM, DD, YYYY"),
  //   type: "",
  //   tags: "",
  //   currency: "USD",
}
const initialValues: Bill = {
  //   referenceNumber: "EWRDE-74354",
  //   name: "Amazon Web Services",
  //   description: "This is a test description",
  //   amountDue: 100,
  //   dueDate: new Date(),
  referenceNumber: "",
  name: "",
  description: "",
  amountDue: 0,
  dueDate: new Date(),
};

export const useBill = () => {
  const [bill, setBill] = React.useState<Bill>(initialValues);

  const resetBill = () => {
    setBill(initialValues);
  };

  return { bill, setBill, resetBill };
};
