import {
  faCheck,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mantine/core";
import {
  cleanNotificationsQueue,
  showNotification,
} from "@mantine/notifications";
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import List from "../../../common/PageLayout/components/List";
import PageLayout from "../../../common/PageLayout/PageLayout";
import { configs, IMAGES } from "../../../constants";
import { IS_LEAN_SANDBOX, LEAN_APP_TOKEN } from "../../../constants/configs";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { formatCurrency } from "../../../modules";
import { cutLengthyString } from "../../../modules/cutLengthyString";
import TransactionsTable from "../../Banks/TransactionsTable";

function CashflowHistory() {
  const { customerId } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const [banks, setBanks] = React.useState<any[]>([]);
  const [transactions, setTransaction] = React.useState<any[]>([]);

  const fetchLinkedBanks = async () => {
    const banksResponse = await axios.get(
      configs.urls.BASE_URL + "/cashflow/balanceHistoryBank/getAll",
      {
        withCredentials: true,
      }
    );

    if (banksResponse.data.success) {
      setBanks(banksResponse.data.response);
      setSearchParams({
        tab: searchParams.get("tab") || "history",
        selectedAccountId: banksResponse.data.response[0]?.bankId,
      });
    }
  };

  useEffect(() => {
    fetchLinkedBanks();
  }, []);

  //
  useEffect(() => {
    const bankId = searchParams.get("selectedAccountId" || "");
    if (bankId) {
      const bank = banks.find((bank: any) => bank.bankId === bankId);
      if (bank) {
        setTransaction(bank.transactions || []);
      }
    }
  }, [searchParams]);

  // let accounts = useMemo(
  //   () =>
  //     banks.map((bank: any) => {
  //       let accountsTemp: any = [];
  //       bank.transactions.forEach((account: any) => {
  //         accountsTemp.push({
  //           ...account,
  //           bank: {
  //             entityId: bank.bankId,
  //             name: bank.bankName,
  //             logo: "",
  //             mainColor: "#fff",
  //             iban: "",
  //           },
  //         });
  //       });
  //       return accountsTemp;
  //     }),
  //   [banks]
  // );

  // console.log("accounts", accounts);

  const renderCardChildren = (bank: any) => (
    <div style={{ position: "relative" }}>
      {/* Header */}
      <div className="flex-direction-row">
        <figure className="image">
          <img
            // src={account.bank.logo || IMAGES.bank_icon}
            src={IMAGES.bank_logo}
            alt="bank_icon"
            style={{
              width: 40,
              height: 40,
              // backgroundColor: account.bank.mainColor,
              borderRadius: 5,
            }}
          />
        </figure>
        {/* <div className="is-flex is-flex-direction-column"> */}
        <div className="subtitle ml-2 mt-1 is-5" style={{ fontWeight: "700" }}>
          {bank?.bankName}
        </div>
      </div>
      <div className="ml-6">
        <div style={{ fontSize: 11 }}>
          {bank?.currency ? (
            <span>
              Currency{" "}
              <strong style={{ fontSize: 11 }}>{bank?.currency}</strong> {", "}
            </span>
          ) : (
            <></>
          )}
          Source
          <strong style={{ fontSize: 11 }}> {bank?.source}</strong>
        </div>
      </div>
    </div>
  );

  const onLink = () => {
    // setBankConnected(true);
    //@ts-ignore
    Lean.connect({
      app_token: LEAN_APP_TOKEN,
      customer_id: customerId,
      permissions: ["identity", "accounts", "balance", "transactions"],
      sandbox: IS_LEAN_SANDBOX,
      callback: (payload: any) => {
        if (payload.status === "ERROR" || payload.status === "CANCELLED") {
          showNotification({
            color: "orange",
            title: payload.status,
            message: `${payload.message ? payload.message : ""}`,
            icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
            autoClose: 2000,
          });
        } else {
          showNotification({
            color: "teal",
            title: payload.status,
            message: payload.message,
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
          fetchLinkedBanks();
        }
      },
    });
  };

  return (
    <PageLayout
      leftStyles={{
        maxWidth: "30%",
        minWidth: "30%",
        borderTop: "1px solid #ccc",
        paddingTop: 15,
      }}
      left={
        <List
          multiselect={false}
          isCashflowHistory={true}
          style={{
            height: "calc(100vh - 250px)",
            overflowY: "scroll",
          }}
          list={banks}
          renderItem={renderCardChildren}
          listLoading={false}
          type="bank-accounts"
          selectItemId={searchParams.get("selectedAccountId" || "")}
          compareId="bankId"
          onCardClick={(clickedItem: any) => {
            setSearchParams({
              tab: searchParams.get("tab") || "history",
              selectedAccountId: clickedItem.bankId,
            });
          }}
          renderSubHeader={() => (
            <div className="is-flex flex-direction-row is-justify-content-space-between mx-3 mr-6">
              <div className="title is-4">All accounts</div>
              {/* <Tooltip
                label="Connect other bank"
                transition="scale-y"
                transitionDuration={300}
                offset={10}
              >
                <button
                  className="button"
                  style={{
                    width: "auto",
                    backgroundColor: COLORS.greenBlue,
                    color: "white",
                  }}
                  onClick={onLink}
                >
                  <strong className="is-size-5">+</strong>
                </button>
              </Tooltip> */}
            </div>
          )}
        />
      }
      rightStyles={{
        maxWidth: "66%",
        minWidth: "66%",
        borderTop: "1px solid #ccc",
        borderLeft: "1px solid #ccc",
        padding: 0,
      }}
      // right={<TransactionsTable transactions={transactions} />}
    />
  );
}

export default CashflowHistory;
