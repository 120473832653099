import { Checkbox, Switch } from "@mantine/core";
import { IconButton } from "rsuite";
import styled, { css } from "styled-components";
import { COLORS } from "./theme";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameOnListItemCard = styled.div`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const ImageOnListItemCard = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 20px;
`;

const ListItemCardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Badge = styled.span`
  background-color: #98807f;
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  line-height: 20px;
  margin-left: 5px;
  text-align: center;
  width: 20px;
`;

const CustomCheckbox = styled(Checkbox)`
  .mantine-Checkbox-input {
    :checked {
      background-color: ${COLORS.greenPrimary};
      border: 1px solid ${COLORS.greenPrimary};
    }
    color: ${COLORS.greenPrimary};
    border-radius: 10px;
    cursor: pointer;
  }
`;

const CustomSwitch = styled(Switch)`
  .mantine-Switch-input {
    &:checked + .mantine-1pskmg3 {
      background-color: ${COLORS.greenPrimary};
      border: 1px solid ${COLORS.greenPrimary};
    }
  }
  .mantine-Switch-track {
    cursor: pointer;
  }
  .mantine-1o1bcfs {
    font-size: 13px;
    font-weight: 600;
    color: #707070;
  }
`;

const EditButton = styled.div`
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: large;
  color: #00d28f;
  font-weight: 700;
  text-decoration: underline;
  :hover {
    color: #00d28f99;
  }
`;

const DrawerContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: #fafafa;
  padding-right: 15px;
  padding-left: 15px;
`;

const DrawerModalTitle = styled.h4`
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  margin: 20px;
  @media screen and (max-width: 1600px) {
    font-size: 30px;
  }
`;

export const SelectStyles: any = {
  item: {
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  input: {
    color: "black",
    fontWeight: "bold",
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    borderBottom: "1px solid #707070",
    height: 40,
    minHeight: 40,
    padding: 0,
    fontSize: 18,
    backgroundColor: "transparent",
    "&:hover": {
      border: "none",
      boxShadow: "none",
      borderBottom: "1px solid #707070",
    },
  },
};

const DrawerButtonContainer = styled(IconButton)`
  border-radius: 50%;
`;
const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #fafafa;
  z-index: 9;
`;

const DrawerContent = styled.div`
  width: 100%;
  padding: 15px;
`;

export {
  CardContainer,
  NameOnListItemCard,
  ImageOnListItemCard,
  ListItemCardHeaderContainer,
  Badge,
  CustomCheckbox,
  CustomSwitch,
  EditButton,
  DrawerContainer,
  DrawerModalTitle,
  DrawerButtonContainer,
  DrawerHeader,
  DrawerContent,
};
