import { Modal, Group, GroupPosition } from "@mantine/core";

interface CustomModalInterface {
  title: string;
  isOpen: boolean;
  handleClose: any;
  children: any;
  buttonText?: string;
  handleButtonClick: any;
  buttonColor?: string;
  className?: string;
  withCloseButton?: boolean;
  buttonPosition?: GroupPosition;
}
export const CustomModal = ({
  title,
  isOpen,
  handleClose,
  children,
  buttonText,
  handleButtonClick,
  className,
  withCloseButton,
  buttonPosition,
}: CustomModalInterface) => {
  return (
    <Modal
      className={className}
      centered={true}
      size="auto"
      opened={isOpen}
      withCloseButton={withCloseButton === false ? false : true}
      onClose={handleClose}
      title={title}
    >
      {children}
      {buttonText && (
        <Group position={buttonPosition || "right"}>
          <button
            type="submit"
            className="button-image no-border"
            onClick={handleButtonClick}
            autoFocus={false}
            style={{ width: "78%", maxWidth: "78%" }}
          >
            <span>{buttonText}</span>
          </button>
        </Group>
      )}
      <br />
    </Modal>
  );
};
