import axios from "axios";
import { configs } from "../constants";

export const getAllForecasts = async (
  filterObject?: any,
  sortOrder?: string,
  sortField?: string
) => {
  try {
    const { searchText, contacts, startDate, endDate, status, buckets } =
      filterObject || {};
    const { data } = await axios.get(
      configs.urls.BASE_URL +
        `/financial_projection/getAllForecasts?searchText=${searchText}&contacts=${contacts}&startDate=${startDate}&endDate=${endDate}&status=${status}&buckets=${buckets}&sortOrder=${sortOrder}&sortField=${sortField}`,
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
