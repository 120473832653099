import React, { Fragment } from "react";

function DetailsCardFooter({ left, right, style }: any) {
  return (
    <Fragment>
      {/* Footer */}
      <div
        className="flex-direction-row-space-between open-balance-container"
        style={{
          ...style,
        }}
      >
        {left()}
        {right()}
      </div>
    </Fragment>
  );
}

export default DetailsCardFooter;
