import "./styles.css";
import React from "react";
import { StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";

function ListItem({
  isViewOnly,
  data,
  isSelected,
  onCardClick,
  renderItem,
  onChange,
  checkedKeys,
  selectable,
  isChecked,
}: any) {
  const [hasBorder, showBorder] = React.useState(false);

  return (
    <div
      id={data?.id}
      className="is-flex is-flex-direction-row is-align-items-center"
      onMouseOver={() => showBorder(true)}
      onMouseOut={() => showBorder(false)}
    >
      {selectable && (
        <div style={{ height: 20, width: 20 }}>
          {/* {hasBorder ? <Checkbox /> : null} */}
          <StyledComponents.CustomCheckbox
            disabled={isViewOnly}
            value={data.id}
            onChange={onChange}
            checked={checkedKeys.some((item: any) => item == data.id)}
          />
        </div>
      )}
      <div
        onClick={onCardClick}
        className="list-item"
        style={{
          cursor: "pointer",
          backgroundColor: "white",
          margin: 10,
          border:
            isSelected || isChecked
              ? // ? `3px ${isSelected ? "solid" : "dashed"} ${COLORS.greenPrimary}`
                `3px solid ${COLORS.greenPrimary}`
              : hasBorder
              ? `1px solid ${COLORS.greenPrimary + "77"}`
              : `1px solid #9E9E9E`,

          boxShadow: `0px 3px 5px ${
            hasBorder ? COLORS.greenPrimary + "77" : "#9E9E9E"
          }`,
          borderRadius: 10,
          flex: 1,
        }}
      >
        {renderItem(data)}
      </div>
    </div>
  );
}

export default ListItem;
