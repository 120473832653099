import React, { useEffect, useState } from "react";
import { congratsIcon } from "../../constants/images";
import { getUserFirstNameFromLocalStorage } from "../../modules/getUserFirstNameFromLocalStorage";
import { getUserLS } from "../../modules/localStorage";
import { setActiveOrganization } from "../../repositories/organizationRepository";
import { decodeJWTToken } from "../../repositories/tokenRepository";
import { updateUser, userLogin } from "../../repositories/userRepository";
import SubmitButton from "./components/SubmitButton";

function YouAreAllSetUp() {
  const [hasShadow, showShadow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingWithExisting, setLoadingWithExisting] = useState(false);
  const [addMoreOrgs, setAddMoreOrgs] = useState(false);

  const handleExplore = async () => {
    setLoading(true);
    const token = localStorage.getItem("token") || "";
    await updateUser({ lastStage: "you-are-all-set-up" });

    if (token) {
      const { email, password } = await decodeJWTToken(token);
      await userLogin(email, password);
    }
    if (addMoreOrgs) {
      window.location.href = "companies?tab=all";
    } else {
      window.location.href = "cashflow?tab=forecast";
    }

    setLoading(false);
  };

  useEffect(() => {
    const user = getUserLS();
    const addMore = user.addMore || false;
    setAddMoreOrgs(addMore);
  }, []);

  const continueWithExistingCompany = async () => {
    setLoadingWithExisting(true);
    const { isDemo } = getUserLS();
    await updateUser({ lastStage: "you-are-all-set-up" });
    if (isDemo) {
      window.location.href = "cashflow?tab=forecast";
    } else {
      window.location.href = "get-started";
    }
    setLoadingWithExisting(false);
  };

  const swithToNewCompany = async () => {
    setLoading(true);

    const user = getUserLS();
    await setActiveOrganization(user.organizationId);
    await updateUser({ lastStage: "you-are-all-set-up" });

    window.location.href = "companies";
    setLoading(false);
  };

  const user = getUserLS();
  return (
    <>
      <div
        className="card-outer-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p className="what-we-do-title">
          {addMoreOrgs ? (
            <>
              You have successfully added
              <br /> a new company
            </>
          ) : (
            <>You are all set up</>
          )}
        </p>

        <br />

        <div
          onMouseOver={() => showShadow(true)}
          onMouseOut={() => showShadow(false)}
          className={`card-inner-container ${hasShadow ? "card" : ""}`}
          style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
        >
          <div className="card-content congratulations-card-container card-padding onboarding">
            <img className="congratulations-image" src={congratsIcon}></img>
            <p className="congratulations-title">Congratulations!</p>

            {addMoreOrgs ? (
              <p
                className="congratulations-content"
                style={{ textAlign: "center" }}
              >
                Thank you {getUserFirstNameFromLocalStorage()} for adding a new
                company <br /> to manage. You are now ready to start organizing{" "}
                <br />
                {user.organizationName}'s back office.
              </p>
            ) : (
              <p
                className="congratulations-content"
                style={{ textAlign: "center" }}
              >
                Thank you {getUserFirstNameFromLocalStorage()} for setting up.
                You are now <br /> ready to start organizing your back office.
              </p>
            )}
          </div>
        </div>
        {addMoreOrgs ? (
          <>
            {/* <button
              className="button-image switch-new-company"
              onClick={swithToNewCompany}
            >
              <span>Switch to new company</span>
            </button> */}
            <SubmitButton
              loading={loading}
              disabled={loadingWithExisting}
              style={{
                width: "300px",
              }}
              className="mt-6"
              onClick={swithToNewCompany}
            >
              Switch to new company
            </SubmitButton>

            <SubmitButton
              style={{
                width: "300px",
                background: "#fafafa",
                color: "#4F4F4F",
              }}
              loading={loadingWithExisting}
              disabled={loading}
              className="is-text mt-2"
              onClick={continueWithExistingCompany}
            >
              Continue with your existing company
            </SubmitButton>

            {/* <button
              className="skip-button"
              onClick={continueWithExistingCompany}
            >
              Continue with your existing company
            </button> */}
          </>
        ) : (
          <SubmitButton
            style={{
              width: "300px",
            }}
            loading={loading}
            className="mt-6"
            onClick={handleExplore}
          >
            Explore
          </SubmitButton>
        )}
      </div>
    </>
  );
}

export default YouAreAllSetUp;
