export const ascendingSortByLetters = (
  array: any[],
  field: string,
  subField?: string
) => {
  if (subField) {
    return array.sort(function (a: any, b: any) {
      return a[field][subField].localeCompare(b[field][subField], "en", {
        sensitivity: "base",
      });
    });
  }
  return array.sort(function (a: any, b: any) {
    return a[field].localeCompare(b[field], "en", { sensitivity: "base" });
  });
};

export const descendingSortByLetters = (
  array: any[],
  field: string,
  subField?: string
) => {
  if (subField) {
    return array.sort(function (a: any, b: any) {
      return b[field][subField].localeCompare(a[field][subField], "en", {
        sensitivity: "base",
      });
    });
  }
  return array.sort(function (a: any, b: any) {
    return b[field].localeCompare(a[field], "en", { sensitivity: "base" });
  });
};
