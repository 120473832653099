import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import {
  getAllOrganizationsOfCurrentUser,
  isEmailVerified,
} from "../repositories/userRepository";
import { useEffect, useState } from "react";
import Loader from "../common/Loader";
import { NEXT_STAGE } from "../constants/globalConstants";

const ProtectedRoutes = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getRoute();
  }, []);

  const getRoute = async () => {
    const verified = await isEmailVerified();

    let _user = await getAllOrganizationsOfCurrentUser();

    setLoading(false);

    if (!_user.lastStage && !verified) {
      window.location.href = "/verify-email";
      return;
    }
    if (_user.lastStage && _user.lastStage !== "you-are-all-set-up") {
      const url = NEXT_STAGE[_user.lastStage];
      window.location.href = url;
    }
  };
  if (loading) return <Loader />;

  return user && user?.isEmailVerified ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
