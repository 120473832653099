import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Select, { MultiValue, SingleValue } from "react-select";

import { COUNTRIES_LIST } from "../../constants/globalConstants";
import {
  getOrganizationById,
  updateOrganization,
} from "../../repositories/organizationRepository";
import { ProgressBar } from "../../common/ProgressBar/ProgressBar";
import BackChevron from "../../common/BackChevron/BackChevron";
import {
  getAllOrganizationsOfCurrentUser,
  updateUser,
} from "../../repositories/userRepository";
import SubmitButton from "./components/SubmitButton";

interface AdditionalCountriesType {
  code: string;
  label: string;
  value: string;
}

interface CountryType {
  label: string;
  value: string;
  id: string;
}

interface State {
  country: SingleValue<CountryType> | string;
  city: string;
  address: string;
  additionalCountries: MultiValue<AdditionalCountriesType>;
}

function WhereIsItLocated() {
  const navigate = useNavigate();
  const [hasShadow, showShadow] = useState(false);
  const [selectedCountry, setselectedCountry] = useState<
    SingleValue<CountryType> | {}
  >();
  const [selectedAdditionalCounties, setSelectedAdditionalCounties] = useState<
    MultiValue<AdditionalCountriesType>
  >([]);

  const formik = useFormik<State>({
    initialValues: {
      country: "",
      city: "",
      address: "",
      additionalCountries: [],
    },
    onSubmit: async (values) => {
      const additionalCountries = formik.values.additionalCountries?.map(
        (obj) => obj?.value
      );
      await updateUser({ lastStage: "where-is-it-located" });
      await updateOrganization({
        ...values,
        additionalCountries,
      });
      navigate("/estimated-monthly-expense");
    },
  });

  const { country, city, address } = formik.values;

  const isValid = () => {
    if (!country || !city || !address) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    getUsersOrgInfo();
  }, []);

  const getUsersOrgInfo = async () => {
    const organizationId = localStorage.getItem("organizationId") || "";
    const org = await getOrganizationById(organizationId);
    if (!org?.country) {
      return;
    }
    const { country, city, address, additionalCountries } = org;
    setselectedCountry({ label: country, value: country });

    formik.setFieldValue("country", country);
    formik.setFieldValue("city", city);
    formik.setFieldValue("address", address);
    const _additional = additionalCountries.map((value: string) => ({
      value,
      label: value,
    }));
    formik.setFieldValue("additionalCountries", _additional);
    setSelectedAdditionalCounties(_additional);
  };

  const handleChange = (selectedCountry: SingleValue<CountryType> | {}) => {
    setselectedCountry(selectedCountry);
    formik.setFieldValue("country", (selectedCountry as CountryType)?.value);
  };
  const handleAdditionalCountries = (
    selected: MultiValue<AdditionalCountriesType>
  ) => {
    setSelectedAdditionalCounties(selected);
    formik.setFieldValue("additionalCountries", selected);
  };

  return (
    <div>
      <BackChevron />

      <div
        className="card-outer-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <form onSubmit={formik.handleSubmit} className="form-container">
          <ProgressBar />
          <div className="form-button-container">
            <div
              onMouseOver={() => showShadow(true)}
              onMouseOut={() => showShadow(false)}
              className={`card-inner-container ${hasShadow ? "card" : ""}`}
              style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
            >
              <div className="card-content card-padding onboarding">
                <div className="content">
                  <div className="logo-container">
                    <p className="register-form-title">Where is it located</p>
                  </div>
                  <div className="field">
                    <label className="label">Country</label>
                    <div className="control">
                      <Select
                        className="select-field"
                        value={selectedCountry}
                        isClearable={true}
                        onChange={handleChange}
                        options={COUNTRIES_LIST}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">City</label>
                    <div className="control">
                      <input
                        required
                        className="input"
                        type="text"
                        id="city"
                        name="city"
                        placeholder="Dubai"
                        onChange={formik.handleChange}
                        value={city}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Address</label>
                    <div className="control">
                      <input
                        required
                        className="input"
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Street, Building, Floor"
                        onChange={formik.handleChange}
                        value={address}
                      />
                    </div>
                  </div>
                  <div className="field max-width-24">
                    <label className="label">
                      Additional Countries (optional)
                    </label>
                    <div className="control">
                      <Select
                        className="select-field"
                        value={selectedAdditionalCounties}
                        isClearable={true}
                        isMulti={true}
                        onChange={handleAdditionalCountries}
                        options={COUNTRIES_LIST}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SubmitButton
              loading={formik.isSubmitting}
              type="submit"
              className="mt-6"
              disabled={!isValid()}
            >
              Continue
            </SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WhereIsItLocated;
