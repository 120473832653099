import axios from "axios";
import { configs } from "../../../constants";
import { CashflowConfigs } from "../../../constants/globalConstants";

export const getCashflowConfigs = async () => {
  try {
    const { data } = await axios.get(
      configs.urls.BASE_URL + "/cashflow/getConfigs",
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const updateCashflowConfigs = async (
  configsToUpdate: CashflowConfigs
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow/updateConfigs",
      {
        configs: configsToUpdate,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const getCashflowReport = async () => {
  try {
    const { data } = await axios.get(
      configs.urls.BASE_URL + "/cashflow/generateReport",
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const upsertBalanceHistoryBank = async (upsertData: any) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow/balanceHistoryBank/upsert",
      {
        ...upsertData,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const removeBalanceHistoryBank = async (id: string) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow/balanceHistoryBank/remove",
      {
        id,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const disableBalanceHistoryBank = async (id: string) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow/balanceHistoryBank/disable",
      {
        id,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const calculateOpeningBalanceFromBankStatement = async (
  dataToSave: any
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/cashflow/balanceHistoryBank/calculate",
      {
        ...dataToSave,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
