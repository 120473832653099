import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import List from "../../../common/PageLayout/components/List";
import ListContainer from "../../../common/PageLayout/components/ListContainer";
import ListHeader from "../../../common/PageLayout/components/ListHeader";
import { StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import { cutLengthyString } from "../../../modules/cutLengthyString";

export const SETTINGS = [
  {
    id: 1,
    key: "profile",
    title: "Profile Settings",
    description: "Update your email, password, and your personal information.",
  },
  {
    id: 2,
    key: "company",
    title: "Company Settings",
    description: `Update your business name, address, phone number, along with any legal documents.`,
  },
  {
    id: 3,
    key: "sync",
    title: "Accounting Settings",
    description:
      "Connect Quickbooks, Xero or Zoho and take better control over your finances.",
  },
  {
    id: 4,
    key: "connect",
    title: "Bank Settings",
    description:
      "Connect your bank account to make payments from the software.",
  },
  {
    id: 5,
    key: "currencies",
    title: "Currency Settings",
    description: "Set the currency you need to view your cashflow.",
  },
  {
    id: 6,
    key: "collabrator",
    title: "Team Settings",
    description:
      "Invite team members and set permissions to help you with your business.",
  },

  {
    id: 7,
    key: "email",
    title: "Notification Settings",
    description:
      "Manage email notifications and be on top of the ones that matter most to you.",
  },
  {
    id: 8,
    key: "pricing_plan",
    title: "Pricing Plan Settings",
    description: "Subscribe and manage your subscription on Madi.",
  },
];

export const VIEWONLY_SETTINGS = [
  {
    id: 1,
    key: "profile",
    title: "Profile Settings",
    description: "Update your email, password, and your personal information.",
  },
];

const LeftView = ({ isViewOnly }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("active");
  const [active, setActive] = useState<string>(activeTab || "profile");

  useEffect(() => {
    if (activeTab) {
      // let activeTabSaved = localStorage.getItem("activeSetting");
      // if (activeTabSaved !== activeTab) {
      //   localStorage.setItem("activeTab", activeTab);
      // }
      setActive(activeTab);
    }
  }, [activeTab]);

  const renderCardChildren = (setting: any) => (
    <StyledComponents.CardContainer>
      <StyledComponents.ListItemCardHeaderContainer>
        <StyledComponents.NameOnListItemCard>
          {cutLengthyString(setting.title, 25)}
        </StyledComponents.NameOnListItemCard>
      </StyledComponents.ListItemCardHeaderContainer>
      <SettingsDescription>{setting.description}</SettingsDescription>
    </StyledComponents.CardContainer>
  );

  return (
    <ListContainer>
      <ListHeader title="Settings" />
      <div>
        <List
          style={{
            height: "calc(100vh - 195px)",
          }}
          multiselect={false}
          list={isViewOnly ? VIEWONLY_SETTINGS : SETTINGS}
          renderItem={renderCardChildren}
          selectItemId={SETTINGS.find((setting) => setting.key === active)?.id}
          onCardClick={(clickedItem: any) => {
            setSearchParams({ active: clickedItem.key });
            localStorage.setItem("activeSetting", clickedItem.key);
          }}
        />
      </div>
    </ListContainer>
  );
};

export default LeftView;

const SettingsDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.brownishGrey};
  word-wrap: break;
  font-weight: normal;
`;
