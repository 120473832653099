import React from "react";
import styled from "styled-components";
import { IMAGES } from "../../../constants";
import { Popover, Whisper } from "rsuite";

function ColorCoding() {
  return (
    <div>
      <Whisper
        // ref={whisperRef}
        placement="bottomStart"
        trigger="click"
        speaker={({ onClose, left, top, className }: any, ref: any) => {
          return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
              <ColorCodesContainer>
                <div>
                  <ColorCode title="Opening Balance in the past">
                    <Color
                      style={{ backgroundColor: "#408180" }}
                      title="Green"
                    />
                    <Title>Opening Balance in the past</Title>
                  </ColorCode>
                  <ColorCode title="Opening Balance in the future">
                    <Color
                      style={{ backgroundColor: "purple" }}
                      title="Purple"
                    />
                    <Title>Opening Balance in the future</Title>
                  </ColorCode>
                </div>
                <div>
                  <ColorCode title="Opening Balance from Uploaded Bank Statements">
                    <Color
                      style={{ backgroundColor: "orange" }}
                      title="Orange"
                    />
                    <Title>Opening Balance from Uploaded Bank Statements</Title>
                  </ColorCode>
                  <ColorCode title="Calculated Opening Balance">
                    <Color style={{ backgroundColor: "black" }} title="Black" />
                    <Title>Calculated Opening Balance</Title>
                  </ColorCode>
                </div>
              </ColorCodesContainer>
            </Popover>
          );
        }}
      >
        <ColorCodeImage src={IMAGES.color_coding} />
      </Whisper>
    </div>
  );
}

export default ColorCoding;

const ColorCodesContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 10px;

  & > div {
    @media screen and (max-width: 1100px) {
      display: none;
    }
  }
`;

const ColorCodesContainerSmallerScreen = styled.div`
  display: none;
  /* for width less that 1001 show */
  @media screen and (max-width: 1100px) {
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: center;
  }
`;

const Title = styled.div`
  font-size: 10px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ColorCodeImage = styled.img`
  width: 40px;
  height: 40px;
  margin: 0 8px;
  cursor: pointer;
`;

const ColorCode = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 20px;
  margin-right: 5px;
`;
const Color = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 1px;
  margin-right: 5px;

  :hover {
    width: 20px;
    height: 20px;
    border-radius: 3px;

    /* animate */
    transition: width 0.3s, height 0.3s, border-radius 0.3s;
  }
`;
