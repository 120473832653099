import {
  faBan,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faPaperclip,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@mantine/core";
import React, { Fragment, useState } from "react";
import { IconButton } from "rsuite";
import styled from "styled-components";
import { COLORS } from "../../../../constants/theme";

interface RowActionsProps {
  editable: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  onDisable?: () => void;
  onUpload?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
  disableSave?: boolean;
  isViewOnly?: boolean;
}

function RowActions({
  editable,
  onSave,
  onCancel,
  onDisable,
  onUpload,
  onDelete,
  disabled,
  disableSave,
  isViewOnly,
}: RowActionsProps) {
  const [showActions, setShowActions] = useState(false);

  return (
    <Actions>
      <IconsContainer>
        {editable ? (
          <Fragment>
            <IconButton
              disabled={disabled || isViewOnly}
              onClick={onCancel}
              size="xs"
              icon={<FontAwesomeIcon icon={faTimes} />}
              title="Cancel"
            />
            <IconButton
              disabled={disabled || disableSave || isViewOnly}
              onClick={onSave}
              size="xs"
              style={{
                backgroundColor: disableSave
                  ? COLORS.greenBlue + "99"
                  : COLORS.greenBlue,
              }}
              icon={<FontAwesomeIcon icon={faCheck} color={"white"} />}
              title={disableSave ? "Change data to save" : "Save"}
            />
          </Fragment>
        ) : (
          <Fragment>
            <IconButton
              disabled={isViewOnly}
              size="xs"
              icon={
                <FontAwesomeIcon
                  icon={!showActions ? faChevronLeft : faChevronRight}
                />
              }
              onClick={() => setShowActions(!showActions)}
              title={!showActions ? "Show actions" : "Hide actions"}
            />
            <Transition
              mounted={showActions}
              transition="slide-left"
              duration={100}
              timingFunction="ease"
            >
              {(styles) => (
                <div style={styles}>
                  <IconButton
                    disabled={disabled || isViewOnly}
                    size="xs"
                    icon={<FontAwesomeIcon icon={faPaperclip} />}
                    onClick={onUpload}
                    title="Upload bank statement"
                  />
                  <IconButton
                    disabled={isViewOnly}
                    size="xs"
                    style={
                      {
                        // backgroundColor: disabled ? COLORS.error : undefined,
                      }
                    }
                    icon={<FontAwesomeIcon icon={faBan} />}
                    onClick={onDisable}
                    title={!disabled ? "Disable row" : "Enable row"}
                  />
                  <IconButton
                    disabled={isViewOnly}
                    // disabled={disabled}
                    size="xs"
                    icon={
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={"red"}
                        opacity={disabled ? 0.5 : 1}
                      />
                    }
                    onClick={onDelete}
                    title="Delete row"
                  />
                </div>
              )}
            </Transition>
          </Fragment>
        )}
      </IconsContainer>
    </Actions>
  );
}

export default RowActions;

const Actions = styled.div`
  flex-direction: row;
  opacity: 1;
  position: absolute;
  right: 12px;
  background-color: white;
`;
const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  gap: 5px;
`;
