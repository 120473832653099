import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../contexts";
import { Card, Checkbox, Table } from "@mantine/core";
import styled from "styled-components";
import moment from "moment";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { deleteSnapshot } from "../../../repositories/cashflowScenario";
import { showNotification } from "@mantine/notifications";
import AbsolutelyCentered from "../../AbsolutelyCentered/AbsolutelyCentered";

interface ViewSnapshotsProps {
  close: () => void;
}
const ViewSnapshots: React.FC<ViewSnapshotsProps> = (props) => {
  const { close } = props;
  const { allScenarios, fetchScenarios } = useAuth();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedVersions, setSelectedVersions] = useState<string[]>([]);

  // console.log("allScenarios", allScenarios);

  let selectedScenario = allScenarios.find((scene) => scene.isActive);
  let selectedScenarioLength = selectedScenario?.snapshots.length || 0;

  useEffect(() => {
    // Update the selectAll state when the selectedScenarios change
    setSelectAll(selectedVersions.length === selectedScenarioLength);
  }, [selectedVersions, selectedScenarioLength]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    versionId: string
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedVersions([...selectedVersions, versionId]);
    } else {
      setSelectedVersions(selectedVersions.filter((id) => id !== versionId));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedVersions(
        selectedScenario?.snapshots.map((scenario) => scenario.id) || []
      );
    } else {
      setSelectedVersions([]);
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm("Are you sure you want to delete the selected version(s)?")
    ) {
      const { success } = await deleteSnapshot({ ids: selectedVersions });
      if (success) {
        showNotification({
          title: "Success",
          message: "Version(s) deleted successfully",
          color: "green",
        });
        fetchScenarios();
        close();
      } else {
        showNotification({
          title: "Error",
          message: "Could not delete version(s)",
          color: "red",
        });
      }
    }
  };

  return (
    <Fragment>
      <Card
        style={{
          width: "70%",
          height: "60vh",
          overflowY: "scroll",
          padding: 0,
        }}
        className="custom-scrollbar"
      >
        {!selectedScenario?.snapshots.length ? (
          <AbsolutelyCentered>
            <div>Nothing here.</div>
          </AbsolutelyCentered>
        ) : null}

        <Table withBorder>
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
            }}
          >
            <tr>
              <th>
                <Checkbox
                  indeterminate={
                    selectedVersions.length > 0 &&
                    selectedVersions.length < selectedScenarioLength
                  }
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </th>
              <th>Version Name</th>
              <th>Scenario Name</th>
              <th>Description</th>
              <th>Date</th>
              <th>Created</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {selectedScenario?.snapshots.map((snap) => {
              return (
                <Fragment key={snap.id}>
                  <tr>
                    <td>
                      <Checkbox
                        checked={selectedVersions.includes(snap.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, snap.id)
                        }
                      />
                    </td>
                    <td>{snap.version}</td>
                    <td>{selectedScenario?.name}</td>
                    <td> {snap.description}</td>
                    <td>{moment(snap.date).format("DD-MMM-YYYY hh:mm")}</td>
                    <td>{moment(snap.createdAt).fromNow()}</td>
                    {/* <td>
                      <div className="is-flex is-gap-1">
                        <button>View</button>
                        <button>Compare</button>
                      </div>
                    </td> */}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Card>
      <PrimaryButton
        onClick={handleDelete}
        disabled={selectedVersions.length === 0}
        style={{ width: "20%", marginTop: 15 }}
      >
        Delete
      </PrimaryButton>
    </Fragment>
  );
};

export default ViewSnapshots;

const Delete = styled.button`
  background-color: red;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #ff0000;
  }

  &:disabled {
    background-color: #ff000055;
    cursor: not-allowed;
  }
`;
