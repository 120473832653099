import axios from "axios";
import { configs } from "../constants";
import { InvoiceTypes, MENU } from "../constants/globalConstants";

export const deleteInvoiceByKeys = (checkedKeys: string[]) => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  return axios.post(
    configs.urls.BASE_URL + "/invoice/deleteByKeys",
    {
      keys: checkedKeys,
    },
    options
  );
};

export const saveInvoice = async (data: any) => {
  axios.defaults.withCredentials = true;
  return await axios.post(configs.urls.BASE_URL + "/invoice/save", data);
};
export const updateInvoice = async (
  data: any,
  id: any,
  linkedTransactions?: Record<string, any>,
  deleteLinkedTransactionIds?: any
) => {
  console.log(
    " <<<< updateInvoice linkedTransactions >>>> ",
    linkedTransactions
  );
  
  const fieldsToUpdate: any =
    data instanceof FormData ? Object.fromEntries(data.entries()) : data;
  return await axios.patch(
    configs.urls.BASE_URL + `/invoice/update/${id}`,
    {
      fieldsToUpdate:
        typeof fieldsToUpdate.inv === "string"
          ? JSON.parse(fieldsToUpdate.inv)
          : fieldsToUpdate,
      linkedTransactions,
      deleteLinkedTransactionIds,
    },

    {
      withCredentials: true,
    }
  );
};
export const deleteAllPayables = () => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  return axios.delete(
    configs.urls.BASE_URL + `/invoice/deleteAll?type=${InvoiceTypes.Payable}`,
    options
  );
};

export const deleteAllReceivables = () => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  return axios.delete(
    configs.urls.BASE_URL +
      `/invoice/deleteAll?type=${InvoiceTypes.Receivable}`,
    options
  );
};

export const deleteForecasts = (keys: string[]) => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  return axios.delete(
    configs.urls.BASE_URL +
      `/financial_projection/forecasts/delete?keys=${keys.join(",")}`,
    options
  );
};

export const deleteAllForecasts = () => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  return axios.delete(
    configs.urls.BASE_URL + `/financial_projection/forecasts/delete/all`,
    options
  );
};

export const filterPayables = async (
  searchText?: string,
  contacts?: string[],
  startDate: string = "",
  endDate: string = ""
) => {
  const { data: recData } = await axios.get(
    configs.urls.BASE_URL +
      `/invoice/filterPayable?searchText=${searchText}&&contacts=${contacts}&&startDate=${startDate}&&endDate=${endDate}`,
    {
      withCredentials: true,
    }
  );
  const { bills, total } = recData.response;
  return bills;
};

export const filterReceivables = async (
  searchText?: string,
  contacts?: string[],
  startDate: string = "",
  endDate: string = ""
) => {
  const { data: recData } = await axios.get(
    configs.urls.BASE_URL +
      `/invoice/filterReceivable?searchText=${searchText}&&contacts=${contacts}&&startDate=${startDate}&&endDate=${endDate}`,
    {
      withCredentials: true,
    }
  );
  const { invoices } = recData.response;
  return invoices;
};

export const getAllPayablesContactNames = async () => {
  const {
    data: {
      response: { bills },
    },
  } = await axios.post(
    configs.urls.BASE_URL + "/invoice/getAllPayables",
    {
      skip: 0,
      noPagination: true,
    },
    {
      withCredentials: true,
    }
  );
  return bills.map(({ contact }: any) => ({
    value: `${MENU.BY_CONTACT}/${contact?.displayName}`,
    label: contact?.displayName,
  }));
};

export const getAllReceivablesContactNames = async () => {
  const {
    data: {
      response: { invoices },
    },
  } = await axios.post(
    configs.urls.BASE_URL + "/invoice/getAllReceivables",
    {
      skip: 0,
      noPagination: true,
    },
    {
      withCredentials: true,
    }
  );
  return invoices.map(({ contact }: any) => ({
    value: `${MENU.BY_CONTACT}/${contact?.displayName}`,
    label: contact?.displayName,
  }));
};

export const getBuckets = async ({
  type,
}: {
  type: "Payable" | "Receivable";
}) => {
  const { data } = await axios.get(configs.urls.BASE_URL + "/getReportLayout", {
    withCredentials: true,
  });

  if (data !== "error") {
    let cashInBuckets = data
      .filter((item: any) => item.name === type)
      .map((item: any) => ({
        value: `${MENU.BY_BUCKET}/${item.value}`,
        label: item.value,
      }));

    return cashInBuckets;
  }
  return [];
};
