import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IMAGES } from "../../../constants";
import { IS_LEAN_SANDBOX, LEAN_APP_TOKEN } from "../../../constants/configs";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { usePaymentsContext } from "../contexts/PaymentsContext";

function ConnectBank() {
  const {
    customerId,
    stepUp,
    ifBankConnected,
    fetchPaymentSource,
    paymentSource,
    leanCallback,
    selectedRecord,
    endUserId,
  } = usePaymentsContext();

  const [destinationId, setDestinationId] = useState<any>("");
  const { setGlobalLoading } = useAuth();

  useEffect(() => {
    setGlobalLoading(true);
    // console.log("paymentSource>>>", paymentSource);
    // console.log("setDestinationId>>>", selectedRecord);
    setDestinationId(selectedRecord?.contact?.destinationId);
    (async () => {
      setTimeout(async () => {
        setGlobalLoading(false);
      }, 1000);
    })();
  }, [paymentSource, selectedRecord]);

  const checkIfPaymentSourceForBeneficiaryExists = (destinationId: string) =>
    paymentSource.beneficiaries.find(
      (b: any) => b.destinationId === destinationId
    );

  const createPaymentSource = async () => {
    if (paymentSource) {
      if (!checkIfPaymentSourceForBeneficiaryExists(destinationId)) {
        // console.info("-----------createBeneficiary-----------");
        // console.info("LEAN_APP_TOKEN", LEAN_APP_TOKEN);
        // console.info("customerId", customerId);
        // console.info("endUserId", endUserId);
        // console.info("payment_destination_id", destinationId);
        // console.info("paymentSourceId", paymentSource.paymentSourceId);
        // console.info("sandbox", IS_LEAN_SANDBOX);
        // console.info("----------------------");
        // @ts-ignore
        Lean.createBeneficiary({
          app_token: LEAN_APP_TOKEN,
          permissions: [
            "identity",
            "balance",
            "accounts",
            "transactions",
            "payments",
          ],
          customer_id: customerId,
          end_user_id: endUserId,
          payment_source_id: paymentSource.paymentSourceId,
          payment_destination_id: destinationId,
          sandbox: IS_LEAN_SANDBOX,
          callback: async (payload: any) => {
            leanCallback(payload);
            if (payload.status !== "ERROR" && payload.status !== "CANCELLED") {
              fetchPaymentSource();
            }
            setGlobalLoading(true);
            setTimeout(() => {
              setGlobalLoading(false);
            }, 1000);
          },
        });
        return;
      } else {
        return stepUp();
      }
    }
    // console.log("endUserId3232", endUserId);
    // console.log("destinationId23121321", destinationId);

    //@ts-ignore
    Lean.connect({
      app_token: LEAN_APP_TOKEN,
      customer_id: customerId,
      end_user_id: endUserId,
      payment_destination_id: destinationId,
      permissions: [
        "identity",
        "balance",
        "accounts",
        "transactions",
        "payments",
      ],
      sandbox: IS_LEAN_SANDBOX,
      callback: async (payload: any) => {
        if (payload.status !== "ERROR" && payload.status !== "CANCELLED") {
          setGlobalLoading(true);
          setTimeout(async () => {
            await fetchPaymentSource();
            setGlobalLoading(false);
          }, 4000);
        }
      },
    });
  };

  return (
    <Container>
      <CustomCard>
        <Image
          src={
            ifBankConnected &&
            checkIfPaymentSourceForBeneficiaryExists(destinationId)
              ? IMAGES.bank_connected
              : IMAGES.bank_circular_logo
          }
        />
        <Title>Bank Account</Title>
        <Subtitle>
          {ifBankConnected &&
          checkIfPaymentSourceForBeneficiaryExists(destinationId)
            ? "Bank connected"
            : "Free"}
        </Subtitle>
      </CustomCard>
      <button
        disabled={destinationId === ""}
        onClick={() => {
          // stepUp();
          createPaymentSource();
        }}
        style={{
          backgroundColor: COLORS.greenPrimary,
          color: "white",
          width: "23rem",
        }}
        className="button mt-2 is-bold"
      >
        {ifBankConnected &&
        checkIfPaymentSourceForBeneficiaryExists(destinationId)
          ? "Continue"
          : ifBankConnected
          ? "Update connection"
          : "Connect to Your Bank"}
      </button>
    </Container>
  );
}

export default ConnectBank;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
`;

const CustomCard = styled.div`
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 23rem;
  height: 180px;
  box-shadow: 0px 1px 5px #9e9e9e;
  background-color: white;
  border: 1px solid #0005;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  margin: 10px;
  width: 75px;
  height: 75px;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 1.21;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1.14;
  color: #707070;
`;
