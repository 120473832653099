import moment from "moment";
import { PeriodFilter } from "../constants/globalConstants";
import { AccountingTransaction } from "../constants/types";

export const getLatestTransaction = (
  transactions: AccountingTransaction[]
): AccountingTransaction | null => {
  if (!transactions || transactions.length === 0) {
    return null;
  }

  return transactions.reduce((prev, current) =>
    moment(prev.paidOrFailedAt).isAfter(current.paidOrFailedAt) ? prev : current
  );
};

export const getSumOfAllTransaction = (
  transactions: AccountingTransaction[]
): number => {
  if (!transactions || transactions.length === 0) {
    return 0;
  }

  const amounts = transactions.map((t) => parseFloat(t.amountPaid));
  const sum = amounts.reduce((prev, current) => prev + current, 0);

  return sum;
};

export type CashflowConfigs = {
  periodFilter: PeriodFilter;
  startDate: Date;
  endDate: Date;
  groupByContacts: boolean;
  filterByExpectedDueDate: boolean;
  sourceType: string;
};
