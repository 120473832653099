import React from "react";
import { map } from "lodash";
import { MultiCascader } from "rsuite";
import {
  MapSortFilterKeys,
  MENU,
  noCheckboxList,
  noCheckboxListFilter,
} from "../../../../constants/globalConstants";
import { filterIcon, sortIcon } from "../../../../constants/images";

interface DropdownComponentsInterface {
  isSortSelected: boolean;
  sortMenu: any;
  isFilterSelected: Boolean;
  filterMenu: any;
  contacts: string[];
  buckets?: string[];
  handleFilterMenuClose: any;
  handleSortMenuClose: any;
  onSortSelect: any;
  onFilterSelect: any;
  filterDropdownList: any;
  sortDropdown?: any;
}

export const DropdownComponents = ({
  isSortSelected,
  sortMenu,
  handleSortMenuClose,
  onSortSelect,
  onFilterSelect,
  isFilterSelected,
  filterMenu,
  contacts,
  buckets,
  handleFilterMenuClose,
  filterDropdownList,
  sortDropdown,
}: DropdownComponentsInterface) => {
  return (
    <div style={{ display: "flex" }}>
      {isSortSelected && (
        <MultiCascader
          style={{ flex: 10 }}
          data={sortDropdown}
          searchable={false}
          size={"lg"}
          block
          value={[
            `${sortMenu.mainmenu}${
              sortMenu.submenu ? `/${sortMenu.submenu}` : "-"
            }`,
          ]}
          onClean={handleSortMenuClose}
          renderValue={(value) => {
            return (
              <span>
                <span style={{ color: "#575757", padding: "0px 2px 2px 2px" }}>
                  <img
                    src={sortIcon}
                    height="30px"
                    width="30px"
                    style={{ padding: "3px" }}
                  />
                  {!sortMenu.mainmenu ? "Select" : ""}
                </span>
                {`${MapSortFilterKeys[sortMenu.mainmenu]}${
                  sortMenu.submenu
                    ? `/${MapSortFilterKeys[sortMenu.submenu]}`
                    : ""
                }`}
              </span>
            );
          }}
          // searchable={false}
          onSelect={onSortSelect}
          uncheckableItemValues={noCheckboxList}
        />
      )}
      {isFilterSelected && isSortSelected && <div style={{ flex: 1 }}></div>}
      {isFilterSelected && (
        <MultiCascader
          style={{ flex: 10 }}
          data={filterDropdownList}
          searchable={true}
          menuWidth={200}
          menuHeight={325}
          size={"lg"}
          block
          value={
            filterMenu.mainmenu === MENU.BY_CONTACT
              ? map(contacts, (contact) => {
                  return `${MENU.BY_CONTACT}/${contact}`;
                })
              : filterMenu.mainmenu === MENU.BY_BUCKET
              ? map(buckets, (bucket) => {
                  return `${MENU.BY_BUCKET}/${bucket}`;
                })
              : [
                  `${filterMenu.mainmenu}${
                    filterMenu.submenu ? `/${filterMenu.submenu}` : "-"
                  }`,
                ]
          }
          onClean={handleFilterMenuClose}
          renderValue={(value) => {
            return (
              <span>
                <span style={{ color: "#575757" }}>
                  <img style={{ maxHeight: "1.8rem" }} src={filterIcon} />
                  {!filterMenu.mainmenu ? "Select" : ""}
                </span>
                {`${MapSortFilterKeys[filterMenu.mainmenu]}${
                  filterMenu.submenu && MapSortFilterKeys[filterMenu.submenu]
                    ? `/${MapSortFilterKeys[filterMenu.submenu]}`
                    : ""
                }`}
              </span>
            );
          }}
          onSelect={onFilterSelect}
          uncheckableItemValues={noCheckboxListFilter}
        />
      )}
    </div>
  );
};

export default DropdownComponents;
