import { Select, SelectItem } from "@mantine/core";
import React from "react";
import { SelectPicker } from "rsuite";
import styled from "styled-components";

interface CustomSelectProps {
  data: SelectItem[];
  value?: string;
  placeholder?: string;
  readOnly?: boolean;
  onSelect?: (value: string) => void;
  label?: string;
}

export const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const {
    data,
    value,
    placeholder,
    readOnly,
    onSelect = undefined,
    label,
  } = props || {};

  return (
    <StyledSelect
      data={data}
      value={value}
      placeholder={placeholder}
      readOnly={readOnly}
      onChange={onSelect}
      label={label}
    />
  );
};

const StyledSelect = styled(Select)`
  /* remove border and everything */
  border: none;
  border-radius: 0;
  width: auto;

  .mantine-Select-input {
    border: 1px solid #000;
    border-radius: 0;
    font-size: 15px;
  }
  /* .mantine-Select-rightSection {
    display: none;
  } */
  .mantine-Select-label {
    font-size: 15px;
    margin: 5px;
  }
`;
