import axios from "axios";
import { useEffect, useState } from "react";
import { configs } from "../../constants";

import { useAuth } from "../../contexts";

export default function Home() {
  const user = useAuth();

  const [org, setOrg] = useState("");

  useEffect(() => {
    const getOrganization = async () => {
      const res = await axios.get(configs.urls.BASE_URL + "/getOrganization", {
        withCredentials: true,
      });
      setOrg(res.data);
    };

    getOrganization();
  }, []);

  return (
    <>
      <section className="">
        <div className="hero-body">
          <h1 className="title">
            Welcome, {user.username} from {org}
          </h1>
        </div>
      </section>
    </>
  );
}
