import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

const PublicRoutes = (props: any) => {
  const { user } = useAuth();
  // const from =
  //   window.history.state.usr.from?.pathname +
  //   window.history.state.usr.from?.search;

  return user && user?.lastStage === "you-are-all-set-up" ? (
    <Navigate to={"/"} />
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;

//
