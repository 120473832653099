const xero_logo = require("../assets/images/xero_logo.jpg");
const qb_logo = require("../assets/images/qb_logo.png");
const tsc_logo = require("../assets/images/tsc_logo.png");
const company_logo_placeholder = require("../assets/images/company_logo_placeholder.png");
const bill = require("../assets/images/bill.png");
const upload_bill = require("../assets/images/upload_bill.png");
const qbo_round_logo = require("../assets/images/qbo_round_logo.png");
const xero_round_logo = require("../assets/images/xero_round_logo.png");
const bank = require("../assets/images/bank.png");
const connected_bank = require("../assets/images/connected_bank.png");
const payment_schedule = require("../assets/images/payment_schedule.png");
const sign_up_button = require("../assets/images/form_button.png");
const sign_in_button = require("../assets/images/form_login_button.png");
const verify_email_icon = require("../assets/images/verify-email-icon.png");
const approval_icon = require("../assets/images/approval_icon.png");
const bank_icon = require("../assets/images/bank_icon.png");
const cashflow_icon = require("../assets/images/cashflow-icon.png");
const digitize_bill = require("../assets/images/digitize_bill.png");
const connect_with_CFO = require("../assets/images/connect_with_CFO.png");
const cfo = require("../assets/images/cfo.png");

const accounting_firm = require("../assets/images/accounting_firm.png");
const company_icon = require("../assets/images/company_icon.png");
const sync_accounting = require("../assets/images/sync-accounting.png");
const cashflow = require("../assets/images/cashflow.png");
const contacts = require("../assets/images/contacts.png");
const get_paid = require("../assets/images/get_paid.png");
const pay = require("../assets/images/pay.png");
const hire = require("../assets/images/hire.png");
const settings = require("../assets/images/settings.png");
const company = require("../assets/images/company.png");
const congratsIcon = require("../assets/images/congrats-icon.png");
const add_vendor = require("../assets/images/add_vendor.png");
const add_customer = require("../assets/images/add_customer.png");
const financialHealth = require("../assets/icons/financial-health.png");
const tscLogoWithName = require("../assets/images/tsc-logo-full.png");
const tickCircle = require("../assets/images/tickCircle.png");
const sync_accounting_softwares = require("../assets/images/sync_accounting_softwares.png");
const connect_bank_account = require("../assets/images/connect_bank_account.png");
const bank_connected = require("../assets/images/bank_connected.png");
const bank_circular_logo = require("../assets/images/bank-circular-logo.png");

const xero_connected = require("../assets/images/xero_connected.png");
const qbo_connected = require("../assets/images/qbo_connected.png");
const zoho_connected = require("../assets/images/zoho_connected.png");
const zoho_round_logo = require("../assets/images/zoho_round_logo.png");
const bank_logo = require("../assets/images/bank_logo.png");
const financial_diagnostic = require("../assets/images/financial_diagnostic.png");
const currency_logo = require("../assets/images/currency_logo.png");
const bank_cashflow = require("../assets/images/bank_cashflow.png");
const cash_out = require("../assets/images/cash_out.png");
const cash_in = require("../assets/images/cash_in.png");
const sortIcon = require("../assets/icons/sort-icon.png");
const searchIcon = require("../assets/icons/search-icon.png");
const deleteIcon = require("../assets/icons/delete-icon.png");
const deleteBlackIcon = require("../assets/icons/delete-black.png");
const searchBlackIcon = require("../assets/icons/search-black.png");
// const syncing_progress = require("../assets/images/syncing_progress.png");
const filterIcon = require("../assets/icons/filter-icon.png");
const qbo_in_progress = require("../assets/images/qbo_in_progress.png");
const xero_in_progress = require("../assets/images/xero_in_progress.png");
const zoho_in_progress = require("../assets/images/zoho_in_progress.png");
const tick = require("../assets/images/tick.png");
const sortBlackIcon = require("../assets/icons/sort-black.png");
const filterBlackIcon = require("../assets/icons/filter-black.png");
const sortSelectedButton = require("../assets/icons/sort-selected.png");
const filterSelectedButton = require("../assets/icons/filter-selected.png");
const add_accounting_software = require("../assets/images/add_accounting_software.png");
const add_beneficiary = require("../assets/images/add_beneficiary.png");
const mini_bank_logo = require("../assets/images/mini_bank_logo.png");
const card_payment = require("../assets/images/card_payment.png");
const cheque = require("../assets/images/cheque.png");
const petty_cash = require("../assets/images/petty_cash.png");
const digital_wallet = require("../assets/images/digital_wallet.png");
const profile = require("../assets/images/profile.png");
const layer = require("../assets/images/layer.png");
const cashflowValues = require("../assets/images/cashflow-values.png");
const openingBalanceTutorial = require("../assets/images/opening_balance.png");
const filterCashflowBy = require("../assets/images/filter_cashflow_by.png");
const addOrganization = require("../assets/images/add_organization.png");
const connectAccountingSoftware = require("../assets/images/connect_accounting_software.png");
const tutorialCashIn = require("../assets/images/tutorial-cash-in.png");
const color_coding = require("../assets/images/color_coding.png");

export {
  xero_logo,
  qb_logo,
  card_payment,
  cheque,
  petty_cash,
  digital_wallet,
  tsc_logo,
  company_logo_placeholder,
  bill,
  upload_bill,
  qbo_round_logo,
  xero_round_logo,
  bank,
  connected_bank,
  payment_schedule,
  sign_up_button,
  sign_in_button,
  verify_email_icon,
  approval_icon,
  bank_icon,
  cashflow_icon,
  digitize_bill,
  connect_with_CFO,
  accounting_firm,
  company_icon,
  sync_accounting,
  cashflow,
  contacts,
  get_paid,
  pay,
  settings,
  hire,
  company,
  congratsIcon,
  add_vendor,
  add_customer,
  financialHealth,
  tscLogoWithName,
  tickCircle,
  sync_accounting_softwares,
  connect_bank_account,
  bank_connected,
  xero_connected,
  qbo_connected,
  bank_logo,
  zoho_round_logo,
  zoho_connected,
  financial_diagnostic,
  currency_logo,
  bank_cashflow,
  sortIcon,
  filterIcon,
  sortBlackIcon,
  filterBlackIcon,
  sortSelectedButton,
  filterSelectedButton,
  add_accounting_software,
  add_beneficiary,
  mini_bank_logo,
  profile,
  cash_out,
  cash_in,
  searchIcon,
  searchBlackIcon,
  deleteIcon,
  deleteBlackIcon,
  qbo_in_progress,
  xero_in_progress,
  zoho_in_progress,
  // syncing_progress,
  tick,
  cfo,
  bank_circular_logo,
  layer,
  cashflowValues,
  openingBalanceTutorial,
  filterCashflowBy,
  addOrganization,
  connectAccountingSoftware,
  tutorialCashIn,
  color_coding,
};
