import "./styles.css";
import { Menu, Button, Text, Group, Avatar } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  IconSettings,
  IconPlus,
  IconArrowsSort,
  IconHelp,
} from "@tabler/icons";

import { IMAGES, StyledComponents } from "../../constants";
import React, { Fragment, MutableRefObject, useEffect, useState } from "react";
import { getUserWithActiveOrganization } from "../../repositories/userRepository";
import { getUserLS, setUserLS } from "../../modules/localStorage";
import { CustomDrawer } from "../CustomDrawer";
import SwitchCompany from "../../pages/Companies/SwitchCompany";
import { Popover, Whisper } from "rsuite";
import SkipButton from "../SkipButton/SkipButton";
import CustomButtonPrimary from "../CustomButtonPrimary/CustomButtonPrimary";
import { useAuth } from "../../contexts";
import { updateOrganization } from "../../repositories/organizationRepository";
import { unusedSubscription } from "../../repositories/subscription";
import { getAvatarPlaceholder } from "../../modules/getAvatarPlaceholder";
import styled from "styled-components";
import { isUserViewOnly } from "../../modules/localStorage";

function CompanyToggleMenu() {
  const location = useLocation();

  const {
    cashflowStage,
    setCashflowStage,
    organization: orgFromContext,
    currentPlan,
  } = useAuth();
  // const { organization, fetchOrganization } = useAuth();
  const menuRef = React.useRef<any>(null);

  const [organization, setOrganization] = useState({ id: "", name: "" });
  const [openSwitchCompanyDrawer, setOpenSwitchCompanyDrawer] = useState(false);

  const isViewOnly = isUserViewOnly();

  const getActiveOrganization = async () => {
    const { name, id } = await getUserWithActiveOrganization();
    setOrganization({
      id,
      name,
    });
  };
  useEffect(() => {
    getActiveOrganization();
  }, []);

  const navigate = useNavigate();
  const addMoreCompanies = async () => {
    localStorage.removeItem("organizationId");
    setUserLS({ addMore: true, url: `${location.pathname}${location.search}` });
    const { success, data } = await unusedSubscription();
    // console.log("unusedSubscription", success, data);
    if (success) {
      navigate("/tell-us-about-your-business", { state: { addMore: true } });
    } else {
      navigate("/pricing");
    }
  };

  const companySettings = () => {
    navigate("settings?active=company");
  };

  const closeDrawer = () => {
    setOpenSwitchCompanyDrawer(false);
  };

  const switchCompany = () => {
    setOpenSwitchCompanyDrawer(true);
  };

  const handleOnSkip = async () => {
    setCashflowStage(8);
    setUserLS({ completedTutorial: true });
    await updateOrganization({ completedTutorial: true });
  };

  const clippedName = (name: string) => {
    if (name) {
      if (name.length > 30) {
        return name.slice(0, 30) + "...";
      } else {
        return name;
      }
    } else return "";
  };

  const speaker = (
    <Popover
      title="Add new organizations"
      className="tutorial-popover"
      arrow={false}
    >
      <div className="popover-container">
        <p className="tutorial-popover-subtitle">
          You can add multiple organizations here and manage
          <br /> their cash.
        </p>
        <img
          src={IMAGES.addOrganization}
          className="tutorial-popover-img"
          alt="tutorial-popover-img"
        />

        <CustomButtonPrimary
          handleOnClick={() => {
            setCashflowStage(6);
          }}
        />
        <SkipButton handleOnClick={handleOnSkip} />
      </div>
    </Popover>
  );
  return (
    <>
      <CustomDrawer
        opened={openSwitchCompanyDrawer}
        onBack={closeDrawer}
        onClose={closeDrawer}
        title=""
      >
        <SwitchCompany />
      </CustomDrawer>

      <Menu
        // add prop only when tutorial is active
        opened={cashflowStage === 5 ? false : undefined}
        shadow="md"
        width={250}
        position="bottom-end"
      >
        <Menu.Target>
          <Button
            className={`chevron-button `}
            style={{
              marginRight: location.pathname.includes("cashflow")
                ? "4.3rem"
                : undefined,
              marginTop: 0,
            }}
          >
            <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
              <Whisper
                placement="bottomStart"
                open={cashflowStage === 5}
                speaker={speaker}
              >
                <StyledComponents.ListItemCardHeaderContainer
                  style={{ gap: 20 }}
                >
                  <CompanyLogoContainer>
                    <StyledComponents.ImageOnListItemCard
                      style={{ marginRight: 5 }}
                      className="navbar-org-icon"
                      src={getAvatarPlaceholder(organization?.name)}
                    />
                    <OnlineDot />
                  </CompanyLogoContainer>
                  <StyledComponents.NameOnListItemCard
                    style={{ flex: 1, fontSize: 17 }}
                    title={orgFromContext?.name}
                    className="navbar-org-name"
                  >
                    {clippedName(orgFromContext?.name)}
                  </StyledComponents.NameOnListItemCard>
                </StyledComponents.ListItemCardHeaderContainer>
              </Whisper>
              <FontAwesomeIcon icon={faChevronDown} onClick={() => {}} />
            </div>
          </Button>
        </Menu.Target>

        <Menu.Dropdown className="company-dropdown-menu">
          <Menu.Label>ACTIVE COMPANY</Menu.Label>
          <Menu.Item>
            <Group noWrap>
              <StyledComponents.ImageOnListItemCard
                style={{
                  marginRight: 5,
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                }}
                alt="navbar-org-icon"
                // className="navbar-org-icon"
                src={
                  getAvatarPlaceholder(organization?.name) ||
                  IMAGES.company_logo_placeholder
                }
              />

              <div>
                <Text size="md">{organization.name}</Text>
                <div
                  style={{ fontSize: 10, textTransform: "capitalize" }}
                  className="tag is-small is-success is-light"
                >
                  {currentPlan?.tier}
                </div>
              </div>
            </Group>
          </Menu.Item>
          <Menu.Item icon={<IconPlus size={9} />} onClick={addMoreCompanies}>
            Add more companies
          </Menu.Item>

          <Fragment>
            <Menu.Item
              icon={<IconArrowsSort size={9} />}
              onClick={switchCompany}
            >
              Switch to other companies
            </Menu.Item>
            {!isViewOnly && (
              <Menu.Item
                icon={<IconSettings size={9} />}
                onClick={companySettings}
              >
                Company Settings
              </Menu.Item>
            )}
          </Fragment>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

export default CompanyToggleMenu;

const CompanyLogoContainer = styled.div`
  position: relative;
`;

const OnlineDot = styled.div`
  position: absolute;
  bottom: 0;
  right: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00ff00;
  border: 2px solid #ffffff;
`;
