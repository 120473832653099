import { STEPS } from "../constants";

export const flattenBeneficiary = (beneficiary: any) => {
  return {
    name: beneficiary.fullName,
    email: beneficiary.email || "",
    type: beneficiary.type.toLowerCase(),
    country: beneficiary.addresses[0].country || "",
    state: beneficiary.addresses[0].state || "",
    street: beneficiary.addresses[0].street || "",
    poBox: beneficiary.addresses[0].poBox || "",
    id: beneficiary.id,
  };
};

export const flattenBank = (bank: any) => {
  let account = {
    currency: "",
    accountNumber: "",
    routingNumber: "",
    name: "",
    sortCode: "",
    iban: "",
  };
  if (bank.accounts && bank.accounts.length > 0) {
    account = {
      currency: bank.accounts[0].currency,
      accountNumber: bank.accounts[0].accountNumber,
      routingNumber: bank.accounts[0].routingNumber,
      name: bank.accounts[0].name,
      sortCode: bank.accounts[0].sortCode,
      iban: bank.accounts[0].iban,
    };
  }
  return {
    country: bank?.address?.country,
    state: bank?.address?.state,
    street: bank?.address?.street,
    poBox: bank?.address?.poBox,
    bankName: bank.bankName,
    swiftCode: bank.swiftCode,
    id: bank.id,
    ...account,
  };
};

export const flattenContact = (contact: any) => {
  return {
    name: contact?.displayName || "",
    email: contact?.email || "",
    types: {
      vendor: contact?.types.includes("Vendor"),
      customer: contact?.types.includes("Customer"),
      employee: contact?.types.includes("Employee"),
    },
    id: contact?.id,
  };
};

export const whichTitle = (
  step: string,
  contact: any,
  isEditMode: any,
  beneficiaryName: any
) => {
  let title = isEditMode ? "Edit" : "Add";

  switch (step) {
    case STEPS.ADD_CONTACT:
      return title + " new contact";
    case STEPS.SYNC:
      return "Connect your accounting software";
    case STEPS.SELECT_METHOD:
      return "How would you like to pay your contact?";
    case STEPS.ADD_BENEFICIARY_DETAILS:
      return `${title} beneficiary details ${
        contact.name ? 'for "' + contact.name + '"' : ""
      }`;
    case STEPS.ADD_BANK_DETAILS:
      return `${title} bank details ${
        contact.name
          ? "for " + contact.name + "'s beneficiary " + beneficiaryName
          : ""
      }`;
    case STEPS.EDIT_BANKS:
      return "Select a bank to edit";

    case STEPS.REVIEW_AND_CONFIRM:
      return "Review and confirm";
    default:
      return title + " Contact";
  }
};
