const bars = require("../assets/icons/bars.png");
const booking = require("../assets/icons/booking.png");
const notification = require("../assets/icons/notification.png");
const notificationRead = require("../assets/icons/notificationRead.png");
const treeIcon = require("../assets/icons/tree_icon.png");
const info = require("../assets/icons/info.png");
const info_icon = require("../assets/images/info_icon.png");
const celebrations = require("../assets/icons/celebrations.png");
const upload = require("../assets/icons/upload.png");

export {
  bars,
  booking,
  notification,
  notificationRead,
  treeIcon,
  info,
  info_icon,
  celebrations,
  upload,
};
