import "../../Cashflow/styles.css";

import {
  faPlus,
  faSave,
  faUpload,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { Button, Dropdown, Popover, Whisper } from "rsuite";
import CustomButtonPrimary from "../../../common/CustomButtonPrimary/CustomButtonPrimary";
import SkipButton from "../../../common/SkipButton/SkipButton";
import { ICONS, IMAGES } from "../../../constants";
import { InvoiceTypes, SourceType } from "../../../constants/globalConstants";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import styled from "styled-components";
import { setUserLS } from "../../../modules/localStorage";
import { updateOrganization } from "../../../repositories/organizationRepository";
import AddEditForecastBudgetModal from "./ForecastBudget/AddEditForecastBudgetModal";
import AddActionPopover from "../../../common/AddActionPopover";
import { IconScreenshot } from "@tabler/icons";
import { openConfirmModal } from "@mantine/modals";
import { Modal, Text, Textarea } from "@mantine/core";
import { createSnapshot } from "../../../repositories/cashflowScenario";
import { showNotification } from "@mantine/notifications";

interface ActionsMenuType {
  setShowEditLayoutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoiceType: React.Dispatch<React.SetStateAction<InvoiceTypes>>;
  showAddRecordsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  isViewOnly: boolean;
}

function ActionsMenu({
  setShowEditLayoutModal,
  setInvoiceType,
  showAddRecordsDrawer,
  isViewOnly,
}: ActionsMenuType) {
  const {
    cashflowConfigs,
    setCashflowConfigs,
    connectedToXero,
    connectedToQuickBooks,
    connectedToZoho,
    cashflowStage,
    setCashflowStage,
    selectedScenario,
    fetchScenarios,
  } = useAuth();
  // const whisperRef = React.useRef(null);
  const [drawer, setDrawer] = React.useState(false);
  const [savingSnapshot, setSavingSnapshot] = React.useState(false);
  const [section, setSection] = React.useState<"Payable" | "Receivable">(
    "Payable"
  );
  const [drawerType, setDrawerType] = React.useState<"forecast" | "budget">(
    "forecast"
  );
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    setDescriptionError(""); // Clear description error on change
  };

  const connectedProvider = () => {
    if (connectedToXero) {
      return "Xero";
    } else if (connectedToQuickBooks) {
      return "QuickBooks";
    } else if (connectedToZoho) {
      return "Zoho";
    } else {
      return "";
    }
  };
  const handleOnSkip = async () => {
    setCashflowStage(8);
    setUserLS({ completedTutorial: true });
    await updateOrganization({ completedTutorial: true });
  };
  const speaker = (
    <Popover
      title={`Add "cash-in" and "cash-out" records`}
      className="tutorial-popover"
      arrow={false}
    >
      <div className="popover-container">
        <p className="tutorial-popover-subtitle">
          <p>
            <b>1- Add Records:</b> This is where you can add bills,
            <br /> expenses, invoices, receipts or estimates.
          </p>
          <p>
            <b>2- Add Categories:</b> You can group those records <br /> into
            “categories” such as Capex, Operations,
            <br /> Salaries, or anything that is relevant to your needs.
          </p>
        </p>
        <img
          style={{ height: "210px" }}
          className="tutorial-popover-img"
          alt="tutorial-popover-img"
          src={IMAGES.tutorialCashIn}
        ></img>

        <CustomButtonPrimary handleOnClick={() => setCashflowStage(4)} />
        <SkipButton handleOnClick={handleOnSkip} />
      </div>
    </Popover>
  );

  // screen height
  const height = window.innerHeight;
  const breakpoint = 630;

  const confirmSaveSnapshot = () => {
    setIsModalOpen(true);
  };

  const handleSaveDescription = async () => {
    if (description.trim() === "") {
      setDescriptionError("Description is required");
      return;
    }
    setSavingSnapshot(true);
    try {
      const response = await createSnapshot({
        cashflowScenarioId: selectedScenario.id,
        description: description,
      });
      console.log("RESPONSE .... ", response);
      if (response.success) {
        showNotification({
          message: "Cashflow snapshot saved!",
          color: "teal",
          icon: <FontAwesomeIcon icon={faCheck} />,
        });
      }
    } catch (error) {
      console.log("Error while saving cashflow: ", error);
    } finally {
      await fetchScenarios();
      setSavingSnapshot(false);
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDescription("");
    setDescriptionError("");
  };

  return (
    <div style={{ marginLeft: "1rem", display: "flex", gap: 5 }}>
      <Modal
        opened={isModalOpen}
        onClose={handleCloseModal}
        title="Please confirm your action"
      >
        <div>
          <Text size="sm">
            Would you like to take a snapshot of this cashflow scenario for this
            specific date?
          </Text>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <Textarea
              label="Enter description"
              placeholder="Description"
              value={description}
              onChange={(event) => handleDescriptionChange(event.target.value)}
              error={descriptionError}
            />
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              disabled={savingSnapshot}
              onClick={handleSaveDescription}
              style={{
                color: "#fff",
                backgroundColor: COLORS.greenPrimary,
              }}
            >
              Yes
            </Button>
            <Button
              disabled={savingSnapshot}
              onClick={handleCloseModal}
              style={{
                backgroundColor: "#fff",
                border: "1px solid #ced4da",
                color: "#000",
              }}
            >
              No, cancel
            </Button>
          </div>
        </div>
      </Modal>

      <Whisper
        // ref={whisperRef}
        placement={
          height <= breakpoint ? "autoHorizontalEnd" : "autoHorizontalStart"
        }
        trigger="click"
        {...(cashflowStage === 2 ? { open: true } : {})}
        speaker={
          cashflowStage === 2
            ? speaker
            : ({ onClose, left, top, className }: any, ref: any) => {
                const handleSelect = (eventKey: any) => {
                  if (eventKey === 1) {
                    setShowEditLayoutModal(true);
                  } else if (eventKey === 2) {
                    setInvoiceType(InvoiceTypes.Receivable);
                    showAddRecordsDrawer(true);
                  } else if (eventKey === 3) {
                    // setDrawer(true);
                    // setDrawerType("budget");
                  } else if (eventKey === 5) {
                    setDrawer(true);
                    setDrawerType("forecast");
                    setSection(InvoiceTypes.Receivable);
                  } else if (eventKey === 6) {
                    setSection(InvoiceTypes.Payable);
                    setDrawer(true);
                    setDrawerType("forecast");
                  } else if (eventKey === 4) {
                    setInvoiceType(InvoiceTypes.Payable);
                    showAddRecordsDrawer(true);
                  } else {
                    alert("Coming soon!");
                  }
                  onClose();
                };
                return (
                  <Popover
                    ref={ref}
                    className={className}
                    style={{ left, top }}
                    full
                  >
                    <AddActionPopover handleSelect={handleSelect} />
                  </Popover>
                );
              }
        }
      >
        <div className={cashflowStage === 2 ? "tutorial-btn-container" : ""}>
          <Button
            disabled={isViewOnly}
            className="cashflow-actions-btn"
            style={{
              backgroundColor: isViewOnly
                ? COLORS.btnDisabled
                : COLORS.greenBlue,
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </Whisper>

      <Button
        disabled={isViewOnly}
        loading={savingSnapshot}
        onClick={confirmSaveSnapshot}
        className="cashflow-actions-btn"
        style={{
          backgroundColor: isViewOnly ? COLORS.btnDisabled : COLORS.greenBlue,
          color: "white",
        }}
      >
        <FontAwesomeIcon icon={faSave} />
      </Button>

      <AddEditForecastBudgetModal
        opened={drawer}
        onClose={() => {
          setDrawer(false);
        }}
        type={drawerType}
        section={section}
      />
    </div>
  );
}

export default ActionsMenu;

const UploadIcon = styled.img`
  width: 17px;
  height: 20px;
`;
