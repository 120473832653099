import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification, updateNotification } from "@mantine/notifications";
import axios from "axios";
import { configs } from "../constants";

export const getQBCashSummary = async (minDate: string, maxDate: string) => {
  axios.defaults.withCredentials = true;
  const { data } = await axios.post(
    configs.urls.BASE_URL + "/quickbooksCashSummaryChart",
    {
      minDate,
      maxDate,
    }
  );

  const { receivableInvoices: cashIn, payableInvoices: cashOut } = data;
  const dataSet = cashIn.map((cashInv: any, index: number) => {
    return cashInv - cashOut[index];
  });

  return {
    receivableInvoices: cashIn,
    payableInvoices: cashOut,
    net: dataSet,
  };
};

export const qbPayChartRequest = async (minDate: string, maxDate: string) => {
  axios.defaults.withCredentials = true;
  const res = await axios.post(configs.urls.BASE_URL + "/qbRecPayChart", {
    minDate,
    maxDate,
  });
  return res.data;
};

export const getQbProfitAndLossReport = async (
  startDate: string,
  endDate: string
) => {
  const { data } = await axios.post(
    configs.urls.BASE_URL + "/profitAndLoss",
    {
      fromDate: startDate,
      toDate: endDate,
    },
    { withCredentials: true }
  );
  return data;
};

export const getQbProfitAndLossSummaryReport = async (
  startDate: string,
  endDate: string
) => {
  const { data } = await axios.post(
    configs.urls.BASE_URL + "/profitAndLossSummary",
    {
      fromDate: startDate,
      toDate: endDate,
    },
    { withCredentials: true }
  );
  return data;
};

export const disconnectFromQbo = () => {
  showNotification({
    id: "disconnect",
    loading: true,
    title: "Disconnecting...",
    message: "Please wait while we disconnect you from QuickBooks",
    autoClose: false,
    disallowClose: true,
  });
  axios
    .get(configs.urls.BASE_URL + "/disconnectQB", {
      withCredentials: true,
    })
    .then(
      (res) => {
        if (res.data) {
          updateNotification({
            id: "disconnect",
            color: "teal",
            message: "Disconnected Successfully!",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      (err) => {
        updateNotification({
          id: "disconnect",
          color: "red",
          message: "Cannot Disconnect",
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      }
    );
};

export const connectQbo = () => {
  axios.get(configs.urls.BASE_URL + "/quickbooks").then((res) => {
    const url = res.data;
    window.open(url, "_self");
  });
};
