import "./styles.css";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { Field, Formik } from "formik";
import _ from "lodash";
import React, { Fragment, useState } from "react";
import { IconButton } from "rsuite";
import styled, { css } from "styled-components";
import { LoadingPlaceholders } from "../../../common/LoadingPlaceholders";
import { ScrollToFormikFieldError } from "../../../common/ScrollToFormikFieldError/ScrollToFormikFieldError";
import { configs } from "../../../constants";
import { COLORS } from "../../../constants/theme";

function EditProfile({
  selectedProfile,
  withEditButton = true,
  fetchUser = () => {},
}: any) {
  const [editable, setEditable] = useState<boolean>(false);
  const saveProfile = (values: any, setSubmitting: any) => {
    axios
      .patch(
        configs.urls.BASE_URL + "/user/update/" + selectedProfile.id,
        {
          ...values,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          // show success notification
          setTimeout(() => {
            showNotification({
              color: "teal",
              message: "Profile Settings are saved!",
              icon: <FontAwesomeIcon icon={faCheck} />,
              autoClose: 2000,
            });
            fetchUser();
          }, 500);
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data?.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err?.response?.data?.errors || err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setEditable(false);
          setSubmitting(false);
        }, 500);
      });
  };

  const FormItem = ({
    name,
    label,
    detailsWidth100,
    formik: { errors, touched, values },
    disabled,
  }: any) => {
    return (
      <Fragment>
        {_.isEmpty(selectedProfile) ? (
          <LoadingPlaceholders.Details
            style={{ marginTop: 15 }}
            detailsWidth={detailsWidth100 ? "100%" : undefined}
          />
        ) : (
          <InputContainer>
            <Label>{label}</Label>
            {!editable &&
            (_.isEmpty(values[name]) ||
              (typeof values[name] === "string" && !values[name].trim())) ? (
              <i style={{ fontSize: 12 }}>No {label.toLowerCase()}.</i>
            ) : (
              <Fragment>
                <Input
                  disabled={!editable || disabled}
                  name={name}
                  error={errors[name] && touched[name]}
                />
                {errors[name] && touched[name] && (
                  <FieldError
                    dangerouslySetInnerHTML={{
                      __html: errors[name],
                    }}
                  />
                )}
              </Fragment>
            )}
          </InputContainer>
        )}
      </Fragment>
    );
  };
  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          ...selectedProfile,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          saveProfile(values, setSubmitting);
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          values,
        }: /* and other goodies */
        any) => {
          return (
            <div>
              <ScrollToFormikFieldError />
              <Fragment>
                <div className="columns mt-4">
                  <div className="column is-half">
                    <FormItem
                      formik={{ errors, touched, values }}
                      name="firstName"
                      label="First Name"
                    />
                  </div>
                  <div className="column is-half">
                    <FormItem
                      formik={{ errors, touched, values }}
                      name="lastName"
                      label="Last Name"
                    />
                  </div>
                </div>
                <FormItem
                  formik={{ errors, touched, values }}
                  name="email"
                  label="Email address"
                  detailsWidth100
                />
                <FormItem
                  formik={{ errors, touched, values }}
                  name="mobileNumber"
                  label="Phone number"
                  detailsWidth100
                />
              </Fragment>
              {withEditButton &&
                (editable ? (
                  <div style={{ position: "absolute", right: 20, top: 20 }}>
                    <IconButton
                      appearance="default"
                      onClick={() => setEditable(false)}
                      icon={<FontAwesomeIcon icon={faTimes} />}
                      style={{ marginRight: 5 }}
                    />
                    <button
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="button is-small"
                      style={{
                        backgroundColor: "#2C6C76",
                        color: COLORS.white,
                        borderRadius: 8,
                        width: 132,
                        height: 36,
                      }}
                    >
                      {isSubmitting ? "Saving..." : "Save"}
                    </button>
                  </div>
                ) : (
                  <button
                    disabled={_.isEmpty(selectedProfile)}
                    className="button is-small"
                    style={{
                      backgroundColor: "#2C6C76",
                      color: COLORS.white,
                      borderRadius: 8,
                      width: 132,
                      height: 36,
                      position: "absolute",
                      right: 20,
                      top: 20,
                    }}
                    onClick={() => setEditable(true)}
                  >
                    Edit
                  </button>
                ))}
            </div>
          );
        }}
      </Formik>
    </Container>
  );
}

export default EditProfile;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  position: relative;
  margin-top: 18px;
  font-weight: 700;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled(Field)`
  height: 40px;
  width: 100%;
  border: none;
  font-size: 24px;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 500;
  background-color: #eeee;
  ${(props) =>
    props.error &&
    css`
      border-color: red;
      color: red;
    `}
  ${(props) =>
    props.disabled &&
    css`
      border-bottom: none;
    `}
  :disabled {
    cursor: text;
    background-color: #fff;

    @media screen and (max-width: 1600px) {
      font-size: 20px;
    }
  }
`;

const FieldError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;
