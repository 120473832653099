import React from "react";
import styled from "styled-components";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { useFormik } from "formik";
import { If } from "../../Utils/If";
import { COLORS } from "../../../constants/theme";
import { upsertScenario } from "../../../repositories/cashflowScenario";
import { showNotification } from "@mantine/notifications";
import { useAuth } from "../../../contexts";

interface CreateScenarioProps {
  onSubmit: () => void;
  mode: "add" | "edit";
}

interface CreateScenarioFormValues {
  name: string;
  description: string;
  switchToNewScenario?: boolean;
}

function CreateScenario(props: CreateScenarioProps) {
  const { onSubmit, mode } = props;
  const {
    allScenarios,
    selectedScenario,
    fetchCashflowReport,
    fetchScenarios,
    fetchForecasts,
    fetchExternalAccounts,
    getRecords,
    getReportLayout,
  } = useAuth();

  const formik = useFormik<CreateScenarioFormValues>({
    initialValues: {
      name: "",
      description: "",
      switchToNewScenario: false,
      // spread selected scenario values if mode is edit
      ...(mode === "edit" && {
        name: selectedScenario.name,
        description: selectedScenario.description,
      }),
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      // let switchToNewScenario = window.confirm(
      //   "Do you want to switch to the new scenario?"
      // );

      const { success } = await upsertScenario({
        name: values.name,
        description: values.description,
        switchToNewScenario: values.switchToNewScenario,
        scenarioId: mode === "edit" ? selectedScenario.id : undefined,
      });
      setSubmitting(false);

      if (success) {
        showNotification({
          title: "Success",
          message: "Scenario created successfully",
          color: "green",
        });
        onSubmit();
        fetchCashflowReport();
        fetchScenarios();

        fetchForecasts();
        fetchExternalAccounts();
        getRecords();
        getReportLayout();
      } else {
        showNotification({
          title: "Error",
          message: "Could not create scenario",
          color: "red",
        });
      }
    },

    validate: (values) => {
      const errors: CreateScenarioFormValues = {} as CreateScenarioFormValues;

      if (!values.name) {
        errors.name = "Required";
      } else if (
        allScenarios.some(
          (scenario) =>
            scenario.name.toLowerCase() === values.name.toLowerCase()
        )
      ) {
        errors.name = "Scenario name already exists";
      }

      return errors;
    },
  });

  const isError = (field: keyof CreateScenarioFormValues) => {
    return formik.errors[field];
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Field>
        <Label
          style={{
            color: isError("name") ? "red" : "#707070",
          }}
        >
          Name your Scenario *
        </Label>
        <Input
          value={formik.values.name}
          onChange={formik.handleChange}
          name="name"
          placeholder="i.e. Best case cashflow scenario"
          style={{
            border: isError("name") ? "1px solid red" : "1px solid #0005",
          }}
        />
        <If condition={isError("name")}>
          <div style={{ color: "red" }}>{formik.errors.name}</div>
        </If>
      </Field>
      <Field>
        <Label>Description</Label>
        <TextArea
          value={formik.values.description}
          onChange={formik.handleChange}
          name="description"
          placeholder={`Example of descriptions:

"What if we want to hire our next employee" - The intention of this scenario is to simulate what our future cash position should be so that we can hire our next employee

"What if we loose a big client" - The intention of this scenario is to simulate what our future cash position would be if we loose a big client and the strategies we need to do to offset this loss.
 
"What if we take a business loan" - The intention of this scenario is to simulate what our future cash position would be if we take a business loan and how much of it will be spent on marketing, sales, and product to increase our revenue.
          `}
          rows={16}
        />
      </Field>

      <If condition={mode === "add"}>
        <Button
          type="submit"
          className={`is-align-self-center ${
            formik.isSubmitting ? "button is-loading" : ""
          }`}
          onClick={() => {
            formik.setFieldValue("switchToNewScenario", true);
          }}
        >
          Save and Switch to New Scenario
        </Button>
        <Button
          type="submit"
          className={`button is-text is-align-self-center ${
            formik.isSubmitting ? " is-loading" : ""
          }`}
          onClick={() => {
            formik.setFieldValue("switchToNewScenario", false);
          }}
        >
          Save and Stay in Current Scenario
        </Button>
      </If>

      <If condition={mode === "edit"}>
        <Button
          type="submit"
          className={`is-align-self-center ${
            formik.isSubmitting ? "button is-loading" : ""
          }`}
        >
          Update
        </Button>
      </If>
    </Form>
  );
}

export default CreateScenario;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 40%;
`;
const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0005;
  outline: none;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  ::placeholder {
    color: #0005;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0005;
  outline: none;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  /* multiline placeholder */
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: break-spaces;
  text-align: left;

  ::placeholder {
    color: #0005;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  background-color: ${COLORS.greenBlue};
  color: white;
`;
