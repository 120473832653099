import axios from "axios";
import { configs } from "../constants";
import { MENU } from "../constants/globalConstants";

export const deleteContactByKeys = (checkedKeys: string[]) => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  return axios.post(
    configs.urls.BASE_URL + "/contact/deleteByKeys",
    {
      keys: checkedKeys,
    },
    options
  );
};

export const filterContacts = async (
  searchText?: string,
  contacts?: string[]
) => {
  const { data: { success, response } = {} } = await axios.get(
    configs.urls.BASE_URL +
      `/contact/filter?searchText=${searchText}&contacts=${contacts}`,
    {
      withCredentials: true,
    }
  );
  return response?.contacts;
  // setContactsLoading(false);
};

export const getAllContactsByName = async () => {
  const { data: { success, response } = {} } = await axios.get(
    configs.urls.BASE_URL + `/contact/getAll/`,
    {
      withCredentials: true,
    }
  );
  return response.contacts.map(({ displayName }: any) => ({
    value: `${MENU.BY_CONTACT}/${displayName}`,
    label: displayName,
  }));
};

export const deleteAllContacts = () => {
  const options = {
    headers: { "content-type": "application/json" },
  };
  axios.defaults.withCredentials = true;
  return axios.delete(configs.urls.BASE_URL + `/contact/deleteAll`, options);
};

export const deleteBank = async ({
  bankId,
  beneficiaryId,
  contactId,
  onComplete = () => {},
  onError = () => {},
}: any) => {
  try {
    const { data: { success, response, errors } = {} } = await axios.post(
      configs.urls.BASE_URL + `/contact/deleteBank`,
      {
        data: {
          bankId,
          beneficiaryId,
          contactId,
        },
      },
      {
        withCredentials: true,
      }
    );
    if (success) {
      onComplete(response);
    } else {
      onError(errors);
    }
  } catch (e) {
    onError(e);
  }
};
