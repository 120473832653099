import "../styles.css";
import Card from "../../../common/Card/Card";
import { CardTitleBlack, CardSubtilteSmall } from "../../../common/CardTitles";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";
import { financial_diagnostic } from "../../../constants/images";
import { useAuth } from "../../../contexts";

function FinancialHealth() {
  const { financialUrl } = useAuth();

  const openLink = (
    url = "https://calendly.com/thescalablecfo/financial-diagnostic-consultation?month=2022-12"
  ) => {
    window.open(url);
  };

  return (
    <div className="financial-health-container">
      <Card className="financial-health-card" isNormal>
        <img
          className="financial-health-image"
          alt="financial-health"
          src={financial_diagnostic}
        />
        <CardTitleBlack title="Explore Financial Health Diagnostic" />
        <CardSubtilteSmall
          title={`Losing money because of weak finance operations, the financials diagnostic will identify the gaps in your business, benchmark it against your industry and provide you with actionable insights.`}
        />
        {financialUrl ? (
          <>
            <PrimaryButton onClick={() => openLink(financialUrl)}>
              Open Financial Diagnostic Report
            </PrimaryButton>
            <a
              className="link-button"
              href={
                "https://calendly.com/thescalablecfo/financial-diagnostic-consultation?month=2022-12"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Book a call with an expert
            </a>
          </>
        ) : (
          <PrimaryButton
            style={{ marginBottom: "15px" }}
            onClick={() => openLink()}
          >
            Book a call with an expert
          </PrimaryButton>
        )}
      </Card>
    </div>
  );
}

export default FinancialHealth;
