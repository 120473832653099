import { useState, useEffect } from "react";

import { useWindowScroll } from "@mantine/hooks";
import {
  Affix,
  Button,
  Loader,
  LoadingOverlay,
  Transition,
} from "@mantine/core";
import { useLocation } from "react-router-dom";

import "bulma/css/bulma.css";
import MainRoutes from "./routes/MainRoutes";
import { Navigation } from "./common/Navigation";
import { useAuth } from "./contexts";
import { Sidebar } from "./common/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { appTheme } from "./constants";
import { SIGN_UP_ROUTES } from "./constants/globalConstants";
import { Helmet } from "react-helmet";
import { useScrollBlock } from "./hooks/useScrollBlock";

function App() {
  useScrollBlock();
  const location = useLocation();
  const [scroll, scrollTo] = useWindowScroll();

  const { user, globalLoading } = useAuth();
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(
    // false
    JSON.parse(localStorage.getItem("sidebarCollapsed") || "true")
  );

  const isPricingPage = location.pathname === "/pricing";

  const isAcceptInvitation = location.pathname.includes("/accept_invitation");

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        backgroundColor: isPricingPage ? "#001736" : undefined,
      }}
    >
      {/* <PricingPage /> */}
      <Helmet>
        <title>
          {process.env.REACT_APP_NODE_ENV === "development" ? "[DEV]" : ""} The
          Scalable CFO
        </title>
      </Helmet>
      <LoadingOverlay
        loaderProps={{
          size: "xl",
          color: appTheme.COLORS.greenBlue,
          variant: "oval",
        }}
        overlayOpacity={0.5}
        overlayBlur={2}
        visible={globalLoading}
      />

      {user && !SIGN_UP_ROUTES.includes(location.pathname) && !isPricingPage && !isAcceptInvitation ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            //  className="columns is-gapless"
          >
            <aside className="aside">
              <Sidebar
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
              />
            </aside>
            <div
              // className={`column ${sidebarCollapsed ? "is-11" : "is-10"}`}
              style={{
                backgroundColor: appTheme.COLORS.background,
                height: "100%",
                width: "100%",
              }}
              className="main"
            >
              <Navigation
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
              />
              <MainRoutes />
            </div>
          </div>
          {/* affix */}
          <Affix position={{ bottom: 20, right: 20 }}>
            <Transition transition="slide-up" mounted={scroll.y > 0}>
              {(transitionStyles) => (
                <Button
                  style={{
                    backgroundColor: appTheme.COLORS.primary,
                    ...transitionStyles,
                  }}
                  onClick={() => scrollTo({ y: 0 })}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                </Button>
              )}
            </Transition>
          </Affix>
        </>
      ) : (
        <>
          <MainRoutes />
        </>
      )}
    </div>
  );
}

export default App;
