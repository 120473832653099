import { Form, Formik } from "formik";
import React, { Fragment } from "react";
import { CardForm } from "../../../common/CardForm";
import { CardTitle } from "../../../common/CardForm/CardForm";
import { CustomInput } from "../../../common/CustomInput";
import { configs, StyledComponents } from "../../../constants";
import { COUNTRIES_LIST } from "../../../constants/globalConstants";
import { COLORS } from "../../../constants/theme";
import Select from "react-select";
import HelpFooter from "./HelpFooter";
import styled from "styled-components";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatIBAN } from "../../../modules/formatIBAN";
import currencies from "../../../assets/files/currencies.json";
import { INITIAL_BANK } from "../constants";
import _ from "lodash";
import { useContactsContext } from "../contexts/ContactsContext";

function AddBankDetails({
  onClose,
  onSubmit,
  bank,
  contactName,
  beneficiaryName,
  onNext,
}: any) {
  const { isEditMode } = useContactsContext();
  const saveContact = (bankChanged: any, setSubmitting: Function) => {
    // console.log("bankChanged", bankChanged);
    // console.log("bank", bank);
    // console.log("mode", mode);

    axios
      .post(
        configs.urls.BASE_URL + "/contact/saveBank",
        {
          ...bankChanged,
          contactName: contactName,
          beneficiaryName: beneficiaryName,
          id: isEditMode() ? bank.id : undefined,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          showNotification({
            color: "teal",
            message: res.data.response,
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      })
      .finally(() => {
        setSubmitting(false);
        onSubmit(bankChanged);
      });
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          ...bank,
        }}
        validate={(values: any) => {
          const errors: any = {};
          if (!values.bankName) {
            errors.bankName = "Name Required";
          } else if (!/^[a-zA-Z0-9\s_]*$/g.test(values.name)) {
            errors.bankName = "Name should be only alphanumeric.";
          }

          if (!values.country) {
            errors.country = "Country required.";
          }

          if (!values.state) {
            errors.state = "State required.";
            errors.country = `&nbsp;`;
          }

          if (!values.street) {
            errors.street = "Street required.";
          }

          if (!values.poBox) {
            errors.poBox = "P.O. Box required.";
          } else if (!/^[0-9]*$/g.test(values.poBox)) {
            errors.poBox = "P.O. Box should be only numeric.";
          } else if (values.poBox.length > 5 || values.poBox.length < 2) {
            errors.poBox = "P.O. Box should be 2-5 digits.";
          }

          if (!values.currency) {
            errors.currency = "Street required.";
          }

          if (
            !/^[0-9]+$/i.test(values.accountNumber) &&
            values.accountNumber !== ""
          ) {
            errors.accountNumber = "Account Number must be a number";
          }

          if (!values.iban) {
            errors.iban = "Invalid IBAN";
          }
          //   swiftCode: string; /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
          if (
            !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(values.swiftCode) &&
            values.swiftCode !== ""
          ) {
            errors.swiftCode = `<div style="width: 70%">Swift Code must be 8 or 11 upper case alphanumeric characters & comprised of:
                  <div style="font-weight: 700">* 4 alpha character bank code then </div>
                  <div style="font-weight: 700">* 2 character ISO 3166-1 country code then</div>
                  <div  style="font-weight: 700">* 2 character alphanumeric location code and an optional</div>
                  <div  style="font-weight: 700">* 3 character alphanumeric branch code</div></div>`;
          }

          //   iban: string;
          // if (
          //   !/^\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})\b$/.test(
          //     values.iban
          //   ) &&
          //   values.iban !== ""
          // ) {
          //   //   errors.iban = `<div>Invalid IBAN, follow one of these formats:
          //   //     <div style="font-weight: 700">* XX## #### #### #### #### </div>
          //   //     <div style="font-weight: 700">* XX## #### #### #### #### ## </div>
          //   //     <div style="font-weight: 700">* XX##################</div></div>`;
          //   errors.iban = "Invalid IBAN";
          // }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }: any) => {
          setSubmitting(true);

          setTimeout(() => {
            saveContact(values, setSubmitting);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          handleChange,
          values,
          setValues,
        }: any) => (
          <Form>
            <CardForm
              title={"Bank address"}
              cardStyles={{
                position: "relative",
                width: "90%",
              }}
            >
              <div className="flex-direction-row mt-3 is-justify-content-space-between">
                <div
                  className="is-flex is-flex-direction-column  is-justify-content-flex-end"
                  style={{ width: "50%" }}
                >
                  <CustomInput
                    label="Country"
                    formikErrors={{ errors, touched }}
                    labelStyles={{
                      position: "absolute",
                      top: 0,
                    }}
                    value={values.country}
                  />
                  <Select
                    className="select-field mr-2 ml-2 mb-2 "
                    placeholder="Select country"
                    value={{
                      value: values.country,
                      label: values.country,
                    }}
                    // isClearable={true}
                    onChange={(newValue: any) => {
                      setValues({
                        ...values,
                        country: newValue.value,
                      });
                    }}
                    options={COUNTRIES_LIST}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        fontSize: "17px",
                      }),
                    }}
                    name="country"
                  />
                  {errors.country && touched.country && (
                    <FieldError
                      dangerouslySetInnerHTML={{
                        __html: errors.country,
                      }}
                    />
                  )}
                </div>
                <CustomInput
                  label="State"
                  onChange={handleChange}
                  name="state"
                  placeholder="Dubai"
                  formikErrors={{ errors, touched }}
                  value={values.state}
                />
              </div>
              <div className="flex-direction-row ">
                <CustomInput
                  label="Street"
                  onChange={handleChange}
                  name="street"
                  placeholder="45 Landing Street"
                  formikErrors={{ errors, touched }}
                  value={values.street}
                />
                <CustomInput
                  label="P.O. Box"
                  onChange={handleChange}
                  name="poBox"
                  placeholder="43243"
                  formikErrors={{ errors, touched }}
                  value={values.poBox}
                />
              </div>

              <CardTitle className="mt-5">Banking details</CardTitle>
              <CustomInput label="Currency" />

              <Select
                className="select-field mr-2 ml-2 mb-2"
                name="currency"
                value={{
                  value: values.currency,
                  label: values.currency,
                }}
                placeholder="Select currency"
                onChange={(newValue: any) => {
                  setValues({
                    ...values,
                    currency: newValue.value,
                  });
                }}
                options={currencies}
              />
              {errors.currency && touched.currency && (
                <FieldError
                  dangerouslySetInnerHTML={{
                    __html: errors.currency,
                  }}
                />
              )}
              <CustomInput
                label="Bank name"
                onChange={handleChange}
                name="bankName"
                placeholder="Emirates NBD Business"
                formikErrors={{ errors, touched }}
                value={values.bankName}
              />
              <CustomInput
                label="IBAN (23-digits)"
                onChange={(e: any) => {
                  // format iban
                  const iban = e.target.value.replace(/\s/g, "");
                  const formattedIban = formatIBAN(iban);
                  setValues({
                    ...values,
                    iban: formattedIban,
                  });
                }}
                name="iban"
                placeholder="AE12 3456 7100 0822 3921 021"
                formikErrors={{ errors, touched }}
                value={values.iban}
              />
              <CustomInput
                label="Account number (13-digits)"
                onChange={handleChange}
                name="accountNumber"
                placeholder="1008223921021"
                formikErrors={{ errors, touched }}
                value={values.accountNumber}
              />
              <CustomInput
                label="Swift code"
                onChange={handleChange}
                name="swiftCode"
                placeholder="EBILAEAD"
                formikErrors={{ errors, touched }}
                value={values.swiftCode}
              />
              <HelpFooter />
            </CardForm>

            <div
              className="flex-direction-column is-align-items-center"
              style={{
                color: "white",
              }}
            >
              {_.isEqual(values, bank) && !_.isEqual(values, INITIAL_BANK) ? (
                <button
                  type="button"
                  onClick={onNext}
                  style={{
                    backgroundColor: COLORS.greenPrimary,
                    color: "white",
                    width: "70%",
                  }}
                  className={`button mt-4 is-bold mr-2 `}
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    backgroundColor: COLORS.greenPrimary,
                    color: "white",
                    width: "70%",
                  }}
                  className={`button mt-4 is-bold mr-2 ${
                    isSubmitting && "is-loading"
                  }`}
                >
                  {isEditMode() ? "Update" : "Save"} & Continue
                </button>
              )}

              {/* <button
                type="button"
                onClick={onClose}
                style={{
                  width: "60%",
                  fontSize: 12,
                }}
                className="button mt-4 mb-4 is-bold mr-2 is-text"
              >
                Save & continue later
              </button> */}
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}

export default AddBankDetails;

const FieldError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
  margin-left: 8px;
`;
