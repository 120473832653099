import { InvoiceTypes } from "../constants/globalConstants";

/**
 * @param type type of invoice
 * @param isLowerCase if you want value returned in lowercase
 * @returns string
 */
export const decideRecordNameLabelByInvoiceType = (
  type: InvoiceTypes,
  isLowerCase?: boolean
): string => {
  if (type === InvoiceTypes.Receivable) {
    return isLowerCase ? "invoice" : "Invoice";
  } else {
    return isLowerCase ? "bill" : "Bill";
  }
};

/**
 * @param type type of invoice
 * @param isLowerCase if you want value returned in lowercase
 * @returns string
 */
export const decideContactNameLabelByInvoiceType = (
  type: InvoiceTypes,
  isLowerCase?: boolean
): string => {
  if (type === InvoiceTypes.Receivable) {
    return isLowerCase ? "customer" : "Customer";
  } else {
    return isLowerCase ? "vendor" : "Vendor";
  }
};
