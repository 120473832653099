import { Field } from "formik";
import React from "react";
import styled from "styled-components";
import { COLORS } from "../../constants/theme";

function CustomInput({
  value,
  label,
  onChange,
  placeholder,
  error,
  containerStyles,
  labelStyles,
  formikErrors,
  name,
  ...props
}: any) {
  return (
    <InputContainer style={{ ...containerStyles }}>
      <InputLabel style={labelStyles}>{label}</InputLabel>
      {onChange && (
        <Input
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          {...props}
        />
      )}

      {formikErrors &&
        formikErrors.errors[name] &&
        formikErrors.touched[name] && (
          <FieldError
            dangerouslySetInnerHTML={{
              __html: formikErrors.errors[name],
            }}
          />
        )}
    </InputContainer>
  );
}

export default CustomInput;

const InputContainer = styled.div`
  position: relative;
  margin: 8px;
  margin-top: 18px;
  flex: 1;
`;
const InputLabel = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled(Field)`
  width: 100%;
  height: 40px;
  border: none;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 600;
  font-size: 20px;
  ::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: ${COLORS.veryLightPink};
  }
`;

const FieldError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;
