import "./styles.css";
import React from "react";

interface CardTitlePropType {
  title: string;
}

function CardTitle({ title }: CardTitlePropType) {
  return <p className="card-title-heading">{title}</p>;
}

export default CardTitle;
