import "./styles.css";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { Fragment } from "react";
import { IconButton } from "rsuite";
import styled, { css } from "styled-components";
import { LoadingPlaceholders } from "../../../common/LoadingPlaceholders";
import { ScrollToFormikFieldError } from "../../../common/ScrollToFormikFieldError/ScrollToFormikFieldError";
import { configs, StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";

function EditBeneficiary({
  editable,
  selectedContact,
  setEditable,
  isContactLoading,
  reFetchExternalAccounts = () => {},
  withEditButton = true,
}: any) {
  const saveContact = (values: any, setSubmitting: any) => {
    axios
      .post(
        configs.urls.BASE_URL + "/contact/update/" + selectedContact.id,
        {
          ...values,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          // show success notification
          setTimeout(() => {
            showNotification({
              color: "teal",
              message: "Contact was saved!",
              icon: <FontAwesomeIcon icon={faCheck} />,
              autoClose: 2000,
            });
            reFetchExternalAccounts();
          }, 500);
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data?.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setEditable(false);
          setSubmitting(false);
        }, 500);
      });
  };

  const FormItem = ({
    name,
    label,
    detailsWidth100,
    formik: { errors, touched, values },
    disabled,
    customComponent,
  }: any) => {
    return (
      <Fragment>
        {_.isEmpty(selectedContact) || isContactLoading ? (
          <LoadingPlaceholders.Details
            style={{ marginTop: 15 }}
            detailsWidth={detailsWidth100 ? "100%" : undefined}
            active={isContactLoading}
          />
        ) : (
          <InputContainer>
            <Label>{label}</Label>
            {!customComponent ? (
              !editable &&
              _.isEmpty(values[name]) &&
              typeof values[name] === "string" &&
              !values[name].trim() ? (
                <i style={{ fontSize: 12 }}>No {label.toLowerCase()}.</i>
              ) : (
                <Fragment>
                  <Input
                    className="form-edit-field"
                    disabled={!editable || disabled}
                    name={name}
                    error={errors[name] && touched[name]}
                  />
                  {errors[name] && touched[name] && (
                    <FieldError
                      dangerouslySetInnerHTML={{
                        __html: errors[name],
                      }}
                    />
                  )}
                </Fragment>
              )
            ) : (
              customComponent()
            )}
          </InputContainer>
        )}
      </Fragment>
    );
  };
  const initial_values = {
    ...selectedContact,
    types: {
      vendor: selectedContact?.types.includes("Vendor"),
      customer: selectedContact?.types.includes("Customer"),
      employee: selectedContact?.types.includes("Employee"),
    },
  };
  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={initial_values}
        validate={(values: any) => {
          const errors: any = {};

          //   accountNumber: number; (/^[0-9]+$/)

          if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (
            !values.types.vendor &&
            !values.types.customer &&
            !values.types.employee
          ) {
            errors.types = "At least one type should be selected";
          }

          if (values.types.employee && values.types.vendor) {
            errors.types =
              "Contact cannot be a vendor and an employee at a time.";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          saveContact(values, setSubmitting);
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          values,
          setValues,
          setErrors,
        }: /* and other goodies */
        any) => {
          return (
            <Form>
              <ScrollToFormikFieldError />
              {/* {selectedContact?.types !== "Customer" && ( */}
              <Fragment>
                <FormItem
                  formik={{ errors, touched, values }}
                  name="displayName"
                  label={`Contact name`}
                  detailsWidth100
                  disabled={true}
                />
                <FormItem
                  formik={{ errors, touched, values }}
                  name="email"
                  label="Email"
                  detailsWidth100
                />

                <FormItem
                  formik={{ errors, touched, values }}
                  label="Type"
                  detailsWidth100
                  customComponent={() =>
                    editable ? (
                      <div className="flex-direction-row ml-2 is-justify-content-flex-start mt-2">
                        <StyledComponents.CustomCheckbox
                          className="mr-3"
                          checked={values.types.vendor}
                          onChange={(event) => {
                            setValues({
                              ...values,
                              types: {
                                ...values.types,
                                vendor: event.currentTarget.checked,
                              },
                            });
                          }}
                          label="Vendor"
                          size="sm"
                          labelPosition="right"
                          color={COLORS.greenBlue}
                        />

                        <StyledComponents.CustomCheckbox
                          className="mr-3"
                          checked={values.types.customer}
                          onChange={(event) => {
                            setValues({
                              ...values,
                              types: {
                                ...values.types,
                                customer: event.currentTarget.checked,
                              },
                            });
                          }}
                          label="Customer"
                          size="sm"
                          labelPosition="right"
                          color={COLORS.greenBlue}
                        />

                        <StyledComponents.CustomCheckbox
                          className="mr-3"
                          checked={values.types.employee}
                          onChange={(event) => {
                            setValues({
                              ...values,
                              types: {
                                ...values.types,
                                employee: event.currentTarget.checked,
                              },
                            });
                          }}
                          label="Employee"
                          size="sm"
                          labelPosition="right"
                          color={COLORS.greenBlue}
                        />
                      </div>
                    ) : (
                      selectedContact?.types.map((type: any, index: number) => {
                        return (
                          <span
                            style={{ border: "1px solid black" }}
                            key={type}
                            className="tag mt-2 ml-1"
                          >
                            {type}
                          </span>
                        );
                      })
                    )
                  }
                />
                {errors["types"] && touched["types"] && (
                  <FieldError
                    className="mt-2 ml-2"
                    dangerouslySetInnerHTML={{
                      __html: errors["types"],
                    }}
                  />
                )}

                <FormItem
                  label={`Status`}
                  formik={{ errors, touched, values }}
                  detailsWidth100
                  customComponent={() =>
                    selectedContact?.addedFrom === "Manual" ? (
                      <Input
                        value={`Added ${selectedContact?.addedFrom}ly`}
                        disabled={true}
                      />
                    ) : (
                      <Input
                        value={`Added from ${selectedContact?.addedFrom}`}
                        disabled={true}
                      />
                    )
                  }
                />
                {/* <div className="columns mt-4">
                  <div className="column is-half">
                    <FormItem
                      formik={{ errors, touched, values }}
                      name="bankIdentifier"
                      label="Bank Identifier"
                    />
                  </div>
                  <div className="column is-half">
                    <FormItem
                      formik={{ errors, touched, values }}
                      name="accountNumber"
                      label="Account Number"
                    />
                  </div>
                </div>

                <FormItem
                  formik={{ errors, touched, values }}
                  name="swiftCode"
                  label="Swift Code"
                  detailsWidth100
                />
                <FormItem
                  formik={{ errors, touched, values }}
                  name="iban"
                  label="IBAN"
                  detailsWidth100
                /> */}

                {/* <DashedSeparator /> */}
              </Fragment>
              {/* )} */}
              {/* <FormItem
                formik={{ errors, touched, values }}
                name="displayName"
                label={`Contact name`}
                detailsWidth100
                disabled={true}
              />
              <FormItem
                formik={{ errors, touched, values }}
                name="name"
                label="Name"
                detailsWidth100
              />

              <div className="columns">
                <div className="column is-half">
                  <FormItem
                    formik={{ errors, touched, values }}
                    name="city"
                    label="City"
                  />
                </div>
                <div className="column is-half">
                  <FormItem
                    formik={{ errors, touched, values }}
                    name="country"
                    label="Country"
                  />
                </div>
              </div>
              <FormItem
                formik={{ errors, touched, values }}
                name="address"
                label="Address"
                detailsWidth100
              /> */}

              {/* {!readOnly && (
                <button
                  type="submit"
                  disabled={isSubmitting}
                  style={{
                    backgroundColor: COLORS.greenPrimary,
                    color: "white",
                    width: "100%",
                  }}
                  className="button mt-6 is-bold"
                >
                  Edit
                </button>
              )} */}
              {withEditButton &&
                (editable ? (
                  <div style={{ position: "absolute", right: 20, top: 20 }}>
                    <IconButton
                      appearance="default"
                      onClick={() => {
                        setErrors({});
                        setValues(initial_values);
                        setEditable(false);
                      }}
                      icon={<FontAwesomeIcon icon={faTimes} />}
                      style={{ marginRight: 5 }}
                    />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="button is-small"
                      style={{
                        backgroundColor: "#2C6C76",
                        color: COLORS.white,
                        borderRadius: 8,
                        width: 132,
                        height: 36,
                      }}
                    >
                      {isSubmitting ? "Saving..." : "Save"}
                    </button>
                  </div>
                ) : (
                  <button
                    disabled={_.isEmpty(selectedContact) || isContactLoading}
                    className="button is-small"
                    style={{
                      backgroundColor: "#2C6C76",
                      color: COLORS.white,
                      borderRadius: 8,
                      width: 132,
                      height: 36,
                      position: "absolute",
                      right: 20,
                      top: 20,
                    }}
                    onClick={() => setEditable(true)}
                  >
                    Edit
                  </button>
                ))}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}

export default EditBeneficiary;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 8px;
  margin-left: 0px;
  margin-top: 18px;
  font-weight: 700;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled(Field)`
  height: 40px;
  width: 100%;
  border: none;
  font-size: 24px;
  outline-width: 0;
  border-bottom: 1px solid #707070;
  font-weight: 500;
  padding-left: 0px;
  background-color: #eeee;
  ${(props) =>
    props.error &&
    css`
      border-color: red;
      color: red;
    `}
  ${(props) =>
    props.disabled &&
    css`
      border-bottom: none;
    `}
  :disabled {
    cursor: text;
    background-color: #fff;
    @media screen and (max-width: 1600px) {
      font-size: 20px;
    }
  }
`;

const DashedSeparator = styled.div`
  height: 1px;
  border: dashed 1px #707070;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const FieldError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;
