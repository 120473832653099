import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingOverlay, Modal, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Checkbox, Input } from "rsuite";
import { configs, GLOBAL_CONSTANTS } from "../../constants";
import EditLayout from "../../pages/Cashflow/components/EditLayout";

const RECORDS_INITIAL_VALUE = {
  id: 0,
  referenceNumber: "",
  name: "",
  description: "",
  amountPaid: "",
  amountDue: "",
  issueDate: moment(new Date()).format("YYYY-MM-DD"),
  dueDate: moment(new Date()).format("YYYY-MM-DD"),
  type: "",
  tags: "",
  currency: "AED",
};

function AddEditRecordModal({
  showModal,
  onCloseModal,
  record,
  modalLoading,
  onCompleted,
  modalType,
  onlyReceivables,
}: any) {
  // Add record Modal
  const [selectedRecord, setSelectedRecord] = useState<any>({
    issueDate: moment(new Date()).format("YYYY-MM-DD"),
    dueDate: moment(new Date()).format("YYYY-MM-DD"),
    expectedDueDate: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [showEditLayoutModal, setShowEditLayoutModal] =
    useState<boolean>(false);

  function camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  const [recordTypes, setRecordTypes] = useState<any[]>([]);

  const getReportLayout = () => {
    //getReportLayout
    axios.defaults.withCredentials = true;
    axios.get(configs.urls.BASE_URL + "/getReportLayout").then((res) => {
      if (res.data !== "error") {
        setRecordTypes(res.data);
      }
    });
  };

  useEffect(() => {
    getReportLayout();
  }, [showModal]);

  const handleChange = (e: any) => {
    setSelectedRecord({
      ...selectedRecord,
      [camelize(e.target.name)]: e.target.value,
    });
  };

  const renderDetailsFields = (
    label: string,
    value: string,
    multiline: boolean = false
  ) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginRight: 10,
        marginBottom: 10,
      }}
    >
      {multiline ? (
        <>
          <label style={{ fontWeight: "600" }}>{label}:</label>
          <Input
            onChange={(_, e) => handleChange(e)}
            as="textarea"
            rows={3}
            value={value}
            name={label}
          />
        </>
      ) : label === "Type" ? (
        <div className="is-fullwidth">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <label style={{ fontWeight: "600" }}>{label}:</label>
            <button
              style={{ fontSize: 10, fontWeight: "700" }}
              className="button is-small is-success mb-2"
              onClick={() => setShowEditLayoutModal(true)}
            >
              Manage
            </button>
          </div>

          <Select
            value={value}
            name={label}
            onChange={(value) => {
              setSelectedRecord({
                ...selectedRecord,
                type: value,
              });
            }}
            placeholder="Pick One"
            data={
              recordTypes &&
              recordTypes
                ?.map((recordType: any) => ({
                  label: recordType.value,
                  value: recordType.value,
                  group: recordType.name,
                }))
                .filter((recordType: any) => {
                  return recordType.group === "Receivable";
                })
            }
          />

          {/* <select value={value} name={label} onChange={handleChange}>
            <option>Select dropdown</option>
            {recordTypes &&
              recordTypes?.map((recordType: any) => (
                <option>{recordType?.value}</option>
              ))}
          </select> */}
        </div>
      ) : (
        <>
          <label style={{ fontWeight: "600" }}>{label}:</label>
          <Input
            name={label === "Amount" ? "Amount Due" : label}
            onChange={(_, e) => handleChange(e)}
            value={value}
          />
        </>
      )}
    </div>
  );

  const resetForm = () => {
    setSelectedRecord(RECORDS_INITIAL_VALUE);
  };

  const renderModalForm = (selectedRecord: any) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>
            {renderDetailsFields("Name", selectedRecord?.name)}
          </div>
          <div style={{ flex: 1 }}>
            {renderDetailsFields(
              "Reference Number",
              selectedRecord?.referenceNumber
            )}
          </div>
        </div>

        {renderDetailsFields("Type", selectedRecord?.type)}
        {renderDetailsFields("Description", selectedRecord?.description, true)}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {selectedRecord?.type === GLOBAL_CONSTANTS.RECORD_TYPES.FINANCING ? (
            <div style={{ flex: 1 }}>
              {renderDetailsFields("Amount", selectedRecord?.amountDue)}
            </div>
          ) : (
            <>
              <div style={{ flex: 1 }}>
                {renderDetailsFields("Amount Paid", selectedRecord?.amountPaid)}
              </div>
              <div style={{ flex: 1 }}>
                {renderDetailsFields("Amount Due", selectedRecord?.amountDue)}
              </div>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 10,
                marginBottom: 10,
              }}
            >
              <label style={{ fontWeight: "600" }}>Issue Date:</label>
              <DatePicker
                // placement="auto"
                clearable={false}
                value={moment(selectedRecord?.issueDate || new Date()).toDate()}
                onChange={(date: any) => {
                  setSelectedRecord((prev: Date) => ({
                    ...prev,
                    issueDate: moment(date).format("YYYY-MM-DD"),
                  }));
                }}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 10,
                marginBottom: 10,
              }}
            >
              <label style={{ fontWeight: "600" }}>Due Date:</label>

              <DatePicker
                clearable={false}
                value={moment(selectedRecord?.dueDate || new Date()).toDate()}
                onChange={(date: any) => {
                  setSelectedRecord((prev: Date) => ({
                    ...prev,
                    dueDate: moment(date).format("YYYY-MM-DD"),
                  }));
                }}
                minDate={new Date()}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: 10,
          }}
        >
          <label style={{ fontWeight: "600", marginBottom: 5 }}>
            Expected Due Date
          </label>
          <DatePicker
            clearable={false}
            value={
              selectedRecord?.expectedDueDate &&
              moment(selectedRecord?.expectedDueDate).toDate()
            }
            onChange={(date: any) => {
              setSelectedRecord((prev: Date) => ({
                ...prev,
                expectedDueDate: moment(date).format("YYYY-MM-DD"),
              }));
            }}
            minDate={new Date()}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        closeOnClickOutside={false}
        opened={showModal}
        onClose={onCloseModal}
        size="lg"
        title={modalType[0].toUpperCase() + modalType.slice(1) + " Record"}
        overflow="inside"
        overlayBlur={2}
        transitionDuration={0}
      >
        <LoadingOverlay overlayOpacity={0.8} visible={modalLoading} />
        {modalType === "edit" ? (
          selectedRecord?.provider === "None" ? (
            renderModalForm(selectedRecord)
          ) : (
            <div>
              <div>Provider: {selectedRecord?.provider}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 15,
                }}
              >
                <label style={{ fontWeight: "600", marginBottom: 5 }}>
                  Expected Due Date
                </label>
                <DatePicker
                  clearable={false}
                  value={
                    selectedRecord?.expectedDueDate &&
                    moment(selectedRecord?.expectedDueDate).toDate()
                  }
                  onChange={(date: any) => {
                    setSelectedRecord((prev: Date) => ({
                      ...prev,
                      expectedDueDate: moment(date).format("YYYY-MM-DD"),
                    }));
                  }}
                  minDate={new Date()}
                />
              </div>
            </div>
          )
        ) : (
          renderModalForm(selectedRecord)
        )}
        <div
          className="mt-3 flex-direction-row"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            appearance="default"
            onClick={() => {
              onCloseModal();
            }}
            style={{ marginRight: "1rem" }}
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            onClick={() => {
              if (selectedRecord.name === "") {
                return showNotification({
                  color: "red",
                  message: `Please enter an Name!`,
                  icon: <FontAwesomeIcon icon={faTimes} />,
                  autoClose: 2000,
                });
              }
              if (selectedRecord.type === "Select dropdown") {
                return showNotification({
                  color: "red",
                  message: `Please select a Type!`,
                  icon: <FontAwesomeIcon icon={faTimes} />,
                  autoClose: 2000,
                });
              }
              if (selectedRecord.amountDue === "") {
                return showNotification({
                  color: "red",
                  message: `Please enter an Amount Due!`,
                  icon: <FontAwesomeIcon icon={faTimes} />,
                  autoClose: 2000,
                });
              }
              if (
                selectedRecord.type !== GLOBAL_CONSTANTS.RECORD_TYPES.FINANCING
              ) {
                if (selectedRecord.amountPaid === "") {
                  return showNotification({
                    color: "red",
                    message: `Please enter an Amount Paid!`,
                    icon: <FontAwesomeIcon icon={faTimes} />,
                    autoClose: 2000,
                  });
                }
              }

              if (selectedRecord.dueDate === "") {
                return showNotification({
                  color: "red",
                  message: `Please enter an Due Date!`,
                  icon: <FontAwesomeIcon icon={faTimes} />,
                  autoClose: 2000,
                });
              }

              // axios call
              let route: string;
              if (modalType === "add") {
                route = "/invoice/save";
              } else {
                route = "/updateRecord";
              }
              const options = {
                headers: { "content-type": "application/json" },
              };
              axios.defaults.withCredentials = true;
              axios
                .post(
                  configs.urls.BASE_URL + route,
                  {
                    inv: JSON.stringify(selectedRecord),
                  },
                  options
                )
                .then(
                  (res: any) => {
                    if (res.data.success) {
                      showNotification({
                        message: "Record saved!",
                        color: "teal",
                        icon: <FontAwesomeIcon icon={faCheck} />,
                      });
                      onCompleted();
                    } else {
                      showNotification({
                        color: "red",
                        message: `Cannot save record!`,
                        icon: <FontAwesomeIcon icon={faTimes} />,
                        autoClose: 2000,
                      });
                    }
                  },
                  (err) => {
                    showNotification({
                      color: "red",
                      message: `${err}`,
                      icon: <FontAwesomeIcon icon={faTimes} />,
                      autoClose: 2000,
                    });
                  }
                );
              resetForm();
              onCompleted();
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
      <EditLayout
        showModal={showEditLayoutModal}
        setShowModal={setShowEditLayoutModal}
        onCloseModal={() => {}}
        onCompleted={() => {
          getReportLayout();
          // setReportLoading(true);
          // getOpeningBalance();
        }}
      />
    </>
  );
}

export default AddEditRecordModal;
