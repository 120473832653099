import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";
import { appTheme, configs, IMAGES } from "../../../constants";
import { useAuth } from "../../../contexts";
import TableRow from "./TableRow";
import ExpandableTableRow from "./ExpandableTableRow";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  PeriodFilter,
  ReportViewTypes,
  SourceType,
} from "../../../constants/globalConstants";
import "../styles.css";
import { CashflowTitles } from "../constants";
import BlankRow from "./BlankRow";
import ConfigsHeader from "./ConfigsHeader";
import CashflowAlert from "./CashflowAlert";
import EditableTableRow from "./EditableTableRow/EditableTableRow";
import {
  disableBalanceHistoryBank,
  removeBalanceHistoryBank,
  upsertBalanceHistoryBank,
} from "../repository/cashflowConfigs";
import moment from "moment";
import { notify } from "../../../modules/showNotification";
import { CustomModal } from "../../../common/CustomModal/CustomModal";
import { COLORS } from "../../../constants/theme";
import { getUserLS } from "../../../modules/localStorage";
import ShowDetailsDrawer from "./ShowDetails/ShowDetailsDrawer";
import axios from "axios";

type Base = moment.unitOfTime.Base;

type OpeningBalanceBanks = {
  id: string;
  name: string;
  values: number[];
  disabled: boolean;
  editable: boolean;
  isNew?: boolean;
};

function CashForecastReport() {
  const {
    reportRows,
    setReportRows,
    cashflowConfigs,
    reportLoading,
    fetchCashflowReport,
    setCashflowStage,
    setReportLoading,
  } = useAuth();
  const { isDemo, completedTutorial } = getUserLS();

  const [openTutorialModel, setOpenTutorialModal] = useState<boolean>(
    isDemo && !completedTutorial ? true : false
    // true
  );

  const [openingBalanceBanks, setOpeningBalanceBanks] = useState<
    OpeningBalanceBanks[]
  >([]);

  const addBank = () => {
    const isAnyOtherRowEditable = openingBalanceBanks.some(
      (bank) => bank.editable
    );

    if (isAnyOtherRowEditable) {
      return alert("Please save or cancel the current edit");
    }
    const rowLength = getRowValues(CashflowTitles.CashflowStatement)?.length;

    setOpeningBalanceBanks((prev) => {
      return [
        ...prev,
        {
          id: Math.random().toString(36).substr(2, 9),
          name: "Bank " + (openingBalanceBanks.length + 1),
          values: [...Array(rowLength).fill(undefined)],
          valuesType: [...Array(rowLength).fill("Manual")],
          editable: true,
          disabled: false,
          isNew: true,
        },
      ];
    });
  };

  const toggleBankEditable = (id: string, cancel?: boolean) => {
    if (!cancel) {
      // check if any row is editable some
      const isAnyOtherRowEditable = openingBalanceBanks.some(
        (bank) => bank.editable && bank.id !== id
      );

      if (isAnyOtherRowEditable) {
        return alert("Please save or cancel the current edit");
      }
    }

    // was new row
    if (cancel && openingBalanceBanks.find((bank) => bank.id === id)?.isNew) {
      return setOpeningBalanceBanks((prev) => {
        return prev.filter((bank) => bank.id !== id);
      });
    } else {
      setOpeningBalanceBanks((prev) => {
        return prev.map((bank) => {
          if (bank.id === id) {
            return {
              ...bank,
              editable: !bank.editable,
            };
          } else {
            return {
              ...bank,
              editable: false,
            };
          }
        });
      });
    }
  };

  const removeBank = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this bank?")) {
      setOpeningBalanceBanks((prev) => {
        return prev.filter((bank) => bank.id !== id);
      });

      const { success } = await removeBalanceHistoryBank(id);
      notify({
        success: success && "Bank updated successfully",
        error: !success && "Error while updating bank",
        // onSuccess: () => fetchCashflowReport(),
      });
    }
  };

  const disableBank = async (id: string) => {
    setOpeningBalanceBanks((prev) => {
      return prev.map((bank) => {
        if (bank.id === id) {
          return {
            ...bank,
            disabled: !bank.disabled,
          };
        }
        return bank;
      });
    });
    const { success } = await disableBalanceHistoryBank(id);
    notify({
      success: success && "Bank updated successfully",
      error: !success && "Error while updating bank",
      // onSuccess: () => fetchCashflowReport(),
    });
  };

  const onChangeBankName = (id: string, name: string) => {
    setOpeningBalanceBanks((prev) => {
      return prev.map((bank) => {
        if (bank.id === id) {
          return {
            ...bank,
            name,
          };
        }
        return bank;
      });
    });
  };

  const onUpsertBank = async ({
    id,
    name,
    values,
    editedIndices,
  }: {
    id: string;
    name: string;
    values: number[];
    editedIndices: number[];
  }) => {
    // decide date range based on period filter
    let dateRange = getRowValues(CashflowTitles.CashflowStatement);
    // filter if any value is edited
    if (editedIndices.length > 0) {
      dateRange = dateRange.filter((_: any, index: number) =>
        editedIndices.includes(index)
      );
    }

    if (selectedViewIndex === 0) {
      // get first day of month instead if monthly view
      dateRange = dateRange.map((date: string) => {
        return moment(date).startOf("month").format("YYYY-MM-DD");
      });
    }

    const { success } = await upsertBalanceHistoryBank({
      id,
      name,
      values,
      dateRange,
      sourceType: cashflowConfigs?.sourceType,
    });

    notify({
      success: success && "Bank updated successfully",
      error: !success && "Error while updating bank",
      // onSuccess: () => fetchCashflowReport(),
    });
  };

  const onChangeBankValues = (id: string, values: number[]) => {
    setOpeningBalanceBanks((prev) => {
      return prev.map((bank) => {
        if (bank.id === id) {
          return {
            ...bank,
            values,
          };
        }
        return bank;
      });
    });
  };

  useEffect(() => {
    if (reportRows && reportRows.length > 0) {
      let expData = reportRows?.find(
        (row: any) => row.name === CashflowTitles.OpeningBalance
      )?.expandableData;

      // console.log("reportRows", reportRows);
      // console.log("expData", expData);

      setOpeningBalanceBanks(expData);
    }
  }, [reportRows]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [error, setError] = useState("");
  type WhichSectionClicked = {
    type: string;
    bucket: string;
  };
  const [whichSectionClicked, setWhichSectionClicked] =
    useState<WhichSectionClicked>({} as WhichSectionClicked);

  const [selectedViewIndex, setSelectedViewIndex] = useState(0);

  // Manage accordians

  type Accordians = {
    isCashInOpen: boolean;
    isCashOutOpen: boolean;
    isOpeningBalanceOpen: boolean;
  };

  type AccordianKeys = keyof Accordians;

  const [accordians, setAccordians] = useState<Accordians>({
    isCashInOpen: JSON.parse(localStorage.getItem("isCashInOpen") || "true"),
    isCashOutOpen: JSON.parse(localStorage.getItem("isCashOutOpen") || "true"),
    isOpeningBalanceOpen: JSON.parse(
      localStorage.getItem("isOpeningBalanceOpen") || "true"
    ),
  });

  useEffect(() => {
    // setReportLoading(true);
    if (cashflowConfigs?.periodFilter) {
      setSelectedViewIndex(
        Object.keys(PeriodFilter).indexOf(cashflowConfigs?.periodFilter)
      );
    } else {
      setError("Error while fetching cashflow metadata");
    }
  }, [cashflowConfigs]);

  const getTooltipText = (sourceType: SourceType) => {
    if (sourceType === SourceType.Bank) {
      return `Opening balance is captured from the connected bank accounts`;
    } else if (sourceType === SourceType.AccountingSoftware) {
      return `Opening balance is captured from the connected accounting software`;
    } else if (sourceType === SourceType.Manual) {
      return `Opening balance is captured from manual entry`;
    }
  };

  const handleSetReportRows = (row: any, type: "Payable" | "Receivable") => {
    setReportRows((prev: any) => {
      let newData = [...prev];
      newData = newData.map((element) => {
        if (element.name === row.name && element.type === type) {
          if (element.isOpen) {
            localStorage.setItem(row.name, "false");
          } else {
            localStorage.setItem(row.name, "true");
          }
          return {
            ...element,
            isOpen: !element.isOpen,
          };
        }
        return element;
      });
      return newData;
    });
  };

  const handleOnTitleClick = (row: any) => {
    setWhichSectionClicked({
      type: row.mainCategoryType,
      bucket: row.type,
    });
    setSearchParams({
      tab: searchParams.get("tab") || "forcast",
      selectedContactId: row.id,
    });
  };

  const getRowValues = (title: string) =>
    reportRows.find((element: { name: string }) => element.name === title)
      ?.values;

  const getRowValuesTypes = (title: string) =>
    reportRows.find((element: { name: string }) => element.name === title)
      ?.valuesType;

  const handleAccordiansToggle = (title: AccordianKeys) => {
    setAccordians((prev: any) => ({
      ...prev,
      [title]: !prev[title],
    }));
    localStorage.setItem(title, !accordians[title] ? "true" : "false");
  };

  const getCurrentPeriodIndex = () => {
    const dates = getRowValues(CashflowTitles.CashflowStatement);

    return dates?.findIndex((date: any) =>
      moment(date, ReportViewTypes[selectedViewIndex].format).isSame(
        moment(),
        ReportViewTypes[selectedViewIndex].value as Base
      )
    );
  };

  const isFutureDate = () => {
    let index = getCurrentPeriodIndex();
    let data = getRowValues(CashflowTitles.CashflowStatement);

    return (
      moment(data, ReportViewTypes[index].format).isValid() &&
      moment(
        moment(data, ReportViewTypes[index].format).format("YYYY-MM-DD")
      ).isBefore(new Date())
    );
  };

  const toggleHiddenData = (type: "Payable" | "Receivable") =>
    reportRows
      .filter(
        (row: { hasExpandableData: any; type: string }) =>
          row.hasExpandableData && row.type === type
      )
      .map(
        (row: {
          id: any;
          name: string;
          values: any;
          isOpen: any;
          expandableData: any[];
        }) => (
          <Fragment key={row.id}>
            <ExpandableTableRow
              loading={reportLoading}
              rowHeader={row.name}
              rowData={row.values}
              setIsOpen={() => handleSetReportRows(row, type)}
              hasTreeIcon
              isOpen={row.isOpen}
              iconClosed={faPlusSquare}
              iconOpened={faMinusSquare}
              bgColor={appTheme.COLORS.white}
            />
            {row.isOpen &&
              (row.expandableData.length > 0 ? (
                row.expandableData.map((row: any) => (
                  <TableRow
                    key={row.id}
                    loading={reportLoading}
                    id={row.id}
                    rowHeader={row.name}
                    rowData={row.values}
                    paddingLeft="50px"
                    bgColor={appTheme.COLORS.lightGray}
                    onTitleClick={() => handleOnTitleClick(row)}
                  />
                ))
              ) : (
                <BlankRow message="No data under this bucket" />
              ))}
          </Fragment>
        )
      );

  //----------------------//
  return (
    <Fragment>
      <CashflowAlert errorState={{ error, setError }} />
      <Container>
        <ConfigsHeader
          selectedViewState={{ selectedViewIndex, setSelectedViewIndex }}
          searchParams={searchParams}
        />
        {/* <button
          className="button is-primary"
          onClick={() => {
            axios.post(
              configs.urls.BASE_URL + "/cashflow_scenario/calculate/queue",
              {},
              {
                withCredentials: true,
              }
            );
          }}
        >
          Test Cashflow
        </button> */}

        <CustomModal
          isOpen={openTutorialModel}
          title={"Welcome to Madi!"}
          buttonText={"Explore Cashflow"}
          handleClose={() => {}}
          handleButtonClick={() => {
            setCashflowStage(1);
            setOpenTutorialModal(false);
          }}
          buttonColor={COLORS.greenPrimary}
          className="cashflow-tutorial"
          withCloseButton={false}
          buttonPosition={"center"}
        >
          <div className="cashflow-tutorial-subtitle">
            Let's explore the cashflow tool
          </div>
          <img
            className="cashflow-tutorial-img"
            src={IMAGES.cashflowValues}
            alt="cashflow-tutorial-img"
          />
        </CustomModal>

        <ReportContainer className="custom-scrollbar-cashflow">
          <Table className="table is-hoverable is-bordered">
            <Header>
              <TableRow
                loading={reportLoading}
                rowHeader={CashflowTitles.CashflowStatement}
                rowData={getRowValues(CashflowTitles.CashflowStatement)}
                isWeeklyView={selectedViewIndex === 1}
                currentPeriod={selectedViewIndex}
              />
            </Header>
            <Body>
              <BlankRow />

              <ExpandableTableRow
                loading={reportLoading}
                rowHeader={CashflowTitles.OpeningBalance}
                rowData={getRowValues(CashflowTitles.OpeningBalance)}
                valuesType={getRowValuesTypes(CashflowTitles.OpeningBalance)}
                color="#408180"
                isBold
                setIsOpen={() => handleAccordiansToggle("isOpeningBalanceOpen")}
                isOpen={accordians.isOpeningBalanceOpen}
                isRowEditable
                onAddBank={() => {
                  addBank();
                  if (!accordians.isOpeningBalanceOpen) {
                    handleAccordiansToggle("isOpeningBalanceOpen");
                  }
                }}
                presentDateIndex={getCurrentPeriodIndex()}
              />

              {accordians.isOpeningBalanceOpen &&
                openingBalanceBanks?.map((data: any) => (
                  <EditableTableRow
                    key={data.id}
                    loading={reportLoading}
                    rowHeader={data.name}
                    rowData={data.values}
                    valuesType={data.valuesType}
                    id={data.id}
                    color="#408180"
                    isBold
                    isRowEditable
                    editable={data.editable}
                    disabled={data.disabled}
                    toggleRowEditable={toggleBankEditable}
                    removeRow={removeBank}
                    disableRow={disableBank}
                    onChangeBankName={onChangeBankName}
                    onChangeBankValues={onChangeBankValues}
                    onUpsertBank={onUpsertBank}
                    presentDateIndex={getCurrentPeriodIndex()}
                  />
                ))}

              <BlankRow />

              <ExpandableTableRow
                loading={reportLoading}
                rowHeader={CashflowTitles.CashIn}
                rowData={getRowValues(CashflowTitles.CashIn)}
                setIsOpen={() => handleAccordiansToggle("isCashInOpen")}
                isOpen={accordians.isCashInOpen}
                isBold
                bgColor={appTheme.COLORS.white}
                color="#10B480"
              />

              {accordians.isCashInOpen && toggleHiddenData("Receivable")}

              <BlankRow />

              <ExpandableTableRow
                loading={reportLoading}
                rowHeader={CashflowTitles.CashOut}
                rowData={getRowValues(CashflowTitles.CashOut)}
                setIsOpen={() => handleAccordiansToggle("isCashOutOpen")}
                isOpen={accordians.isCashOutOpen}
                isBold
                bgColor={appTheme.COLORS.white}
                color="#C12E5B"
              />

              {accordians.isCashOutOpen && toggleHiddenData("Payable")}

              <BlankRow />

              <TableRow
                loading={reportLoading}
                rowHeader={CashflowTitles.NetCashflow}
                rowData={getRowValues(CashflowTitles.NetCashflow)}
                color="#10B480"
                isBold
                currentPeriod={selectedViewIndex}
              />

              <BlankRow />

              <TableRow
                loading={reportLoading}
                rowHeader={CashflowTitles.Unreconciled}
                rowData={getRowValues(CashflowTitles.Unreconciled)}
                isBold
                currentPeriod={selectedViewIndex}
                color="#10B480"
              />

              <BlankRow />

              <TableRow
                loading={reportLoading}
                rowHeader={CashflowTitles.ClosingBalance}
                rowData={getRowValues(CashflowTitles.ClosingBalance)}
                valuesType={getRowValuesTypes(CashflowTitles.ClosingBalance)}
                color="black"
                isBold
                currentPeriod={selectedViewIndex}
                presentDateIndex={getCurrentPeriodIndex()}
              />
            </Body>
          </Table>
        </ReportContainer>
      </Container>

      <ShowDetailsDrawer
        opened={searchParams.get("selectedContactId")}
        reloadReport={async () => {
          setReportLoading(true);
          // await fetchCashflowReport();
        }}
        type={whichSectionClicked}
      />
    </Fragment>
  );
}

export default CashForecastReport;

const Table = styled.table`
  tr > :first-child {
    padding: 0;
  }
  border-collapse: separate;
`;

const Container = styled.div`
  max-width: 93vw;
  min-width: 93vw;
  position: relative;
`;

const ReportContainer = styled.div`
  overflow-x: scroll;
  height: 90vh;
`;

const Header = styled.thead`
  th {
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th::after {
    content: "";
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 10px;
    cursor: col-resize;
  }
`;

const Body = styled.tbody`
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
