import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "@mantine/dates";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Select } from "@mantine/core";
import { IconButton } from "rsuite";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { updateRecord } from "../../../pages/Payments/repository";
import Card from "../../Card/Card";
import DetailsCardHeader from "./DetailsCardHeader";
import LabelValue from "./LabelValue";
import { SelectStyles } from "../../../constants/styledComponents";
import axios, { AxiosResponse } from "axios";
import { configs } from "../../../constants";
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import { InvoiceStatus } from "../../../constants/types";
import { getUserLS } from "../../../modules/localStorage";
import { If } from "../../Utils/If";
import { MapCategory } from "../../../pages/Contacts/components/MapCategory";

function ManageRunwayCard({
  isViewOnly,
  record,
  loading,
  placeholder,
  cardStyles = {},
  onClose = () => {},
}: any) {
  const user = getUserLS();
  const [editable, setEditable] = useState<boolean>(false);
  const [editedExpectedDueDate, setEditedExpectedDueDate] =
    React.useState<any>();
  const { cashflowBuckets, getRecords, fetchCashflowReport, getReportLayout } =
    useAuth();
  const [loadingSaving, setLoadingSaving] = useState<boolean>(false);
  const [cashflowBucketOptions, setCashflowBucketOptions] = useState<any>([]);

  useEffect(() => {
    setCashflowBucketOptions(
      cashflowBuckets
        ?.filter((bucket: any) => bucket.name === record?.type)
        .map((bucket: any) => {
          return {
            value: bucket.value,
            label: bucket.value,
          };
        })
    );
  }, [cashflowBuckets, record]);

  const [selectedCashflowBucket, setSelectedCashflowBucket] = useState<any>(
    record?.cashflowBucket
  );

  // set editable false
  useEffect(() => {
    return () => {
      setSelectedCashflowBucket(undefined);
      setEditedExpectedDueDate(undefined);
      setEditable(false);
    };
  }, [record]);

  //   console.log("selectedCashflowBucket", selectedCashflowBucket);
  //   console.log("cashflowBucketOptions", cashflowBucketOptions);

  const onCreateBucket = (bucketToCreate: any) => {
    // alert(JSON.stringify(layout));

    axios.defaults.withCredentials = true;
    axios
      .post(configs.urls.BASE_URL + "/saveCashflowBucket", {
        type: record?.type,
        bucket: bucketToCreate,
      })
      .then(
        (res: AxiosResponse) => {
          if (res.data === "success") {
            showNotification({
              color: "green",
              message: "Saved!",
              icon: <FontAwesomeIcon icon={faCheck} />,
              autoClose: 2000,
            });
            // setSavingBuckets(false);
            // showDrawer(false);
            getReportLayout();
          } else {
            showNotification({
              color: "red",
              message: "Error saving settings!",
              icon: <FontAwesomeIcon icon={faTimes} />,
              autoClose: 2000,
            });
          }
        },
        (err) => {
          showNotification({
            color: "red",
            message: `${err}`,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      );
  };

  const EditableView = () => {
    return (
      <Fragment>
        <div>
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: COLORS.brownishGrey,
            }}
          >
            Expected Due Date
          </div>
          <DatePicker
            clearable={false}
            disabled={record?.status == InvoiceStatus.Paid}
            minDate={
              // user.isDemo
              //   ?
              new Date(
                moment(user?.createdAt)
                  .subtract(6, "M")
                  .startOf("month")
                  .format("YYYY-MM-DD hh:mm")
              )
              // : new Date(user.createdAt)
            }
            styles={{
              input: {
                border: "none",
                fontSize: "24px",
                marginBottom: "18px",
                borderBottom: "2px solid #ccc",
                paddingLeft: 0,
              },
            }}
            // defaultValue={new Date(record?.expectedDueDate)}
            value={moment(
              editedExpectedDueDate || record?.expectedDueDate
            ).toDate()}
            onChange={(date: Date) => {
              // add offset
              let offset = date.getTimezoneOffset();
              let offsetInHours = offset / 60;
              let offsetInMilliseconds = offsetInHours * 60 * 60 * 1000;
              let newDate = new Date(date.getTime() - offsetInMilliseconds);

              setEditedExpectedDueDate(moment(newDate).toDate());
            }}
          />
        </div>

        <Fragment>
          {/* <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: COLORS.brownishGrey,
              marginTop: 10,
            }}
          >
            Cashflow category
          </div> */}
          {/* <Select
            // className="select-field mb-6"
            defaultValue={record?.cashflowBucket}
            data={cashflowBucketOptions}
            className="mb-6"
            nothingFound={"Nothing found"}
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query };
              setSelectedCashflowBucket(query);
              setCashflowBucketOptions([...cashflowBucketOptions, item]);
              onCreateBucket(query);
              return item;
            }}
            value={selectedCashflowBucket}
            onChange={(value: string) => {
              setSelectedCashflowBucket(value);
            }}
            // onSearchChange={(query: string) => {
            //   setSelectedCashflowBucket(query);
            // }}
            styles={SelectStyles}
          /> */}

          {/* <If condition={record?.cashflowCategoryMappings?.length > 0}>
            <MapCategory
              mode="edit"
              mappings={record?.cashflowCategoryMappings}
              contactTypes={record?.types}
              showTitle={false}
            />
          </If> */}
        </Fragment>

        <div
          className="edit-cashflow"
          style={{ position: "absolute", right: 20, top: 20 }}
        >
          <IconButton
            appearance="default"
            onClick={() => setEditable(false)}
            icon={<FontAwesomeIcon icon={faTimes} />}
            style={{ marginRight: 5 }}
          />
          <button
            onClick={() => {
              setLoadingSaving(true);
              onClose();
              updateRecord(
                record?.id,
                {
                  cashflowBucket:
                    selectedCashflowBucket || record?.cashflowBucket,
                  expectedDueDate: editedExpectedDueDate,
                },
                async () => {
                  getRecords();
                  // await fetchCashflowReport();
                  setEditable(false);
                  setSelectedCashflowBucket(undefined);
                  setLoadingSaving(false);
                }
              );
            }}
            disabled={
              loadingSaving ||
              ((_.isUndefined(selectedCashflowBucket) ||
                _.isEqual(selectedCashflowBucket, record.cashflowBucket)) &&
                _.isUndefined(editedExpectedDueDate)) ||
              _.isEqual(editedExpectedDueDate, new Date(record.expectedDueDate))
            }
            className="button is-small"
            style={{
              backgroundColor: "#2C6C76",
              color: COLORS.white,
              borderRadius: 8,
              height: 36,
            }}
          >
            {loadingSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </Fragment>
    );
  };

  const GeneralView = ({ isViewOnly }: any) => {
    return (
      <Fragment>
        <LabelValue
          label="Expected Due Date"
          // check if due date is in past (for ref check TSC-254)
          value={moment(
            editedExpectedDueDate || record?.expectedDueDate
          ).format("MMM DD, YYYY")}
          loading={loading}
          placeholder={placeholder}
        />

        {/* <LabelValue
          label="Cashflow category"
          value={record?.cashflowBucket}
          loading={loading}
          placeholder={placeholder}
        /> */}
        <If condition={record?.cashflowCategoryMappings?.length > 0}>
          <MapCategory
            mode="view"
            mappings={record?.cashflowCategoryMappings}
            contactTypes={record?.types}
            showTitle={false}
          />
        </If>

        <button
          disabled={_.isEmpty(record) || isViewOnly}
          className="button is-small edit-cashflow"
          style={{
            backgroundColor: "#2C6C76",
            color: COLORS.white,
            borderRadius: 8,
            height: 36,
            position: "absolute",
            right: 20,
            top: 20,
          }}
          onClick={() => setEditable(true)}
        >
          Edit
        </button>
      </Fragment>
    );
  };
  //   return editable ? <EditableView /> : <GeneralView />;

  return (
    <Card
      className="details-card-container runway"
      style={{
        ...cardStyles,
      }}
    >
      <DetailsCardHeader
        hasButton={false}
        title="Manage Cashflow"
        titleInfo="Manage your runway by editing the details below."
      />
      <div className="mt-5" />
      {editable ? <EditableView /> : <GeneralView isViewOnly={isViewOnly} />}
    </Card>
  );
}

export default ManageRunwayCard;
