import { MENU } from "../../../../constants/globalConstants";
import { getStartAndEndDates } from "../../../../modules/getDates";

export const getFilterObj = (
  filterMenu: Record<string, any>,
  activeTab: string,
  datePickerValue: any[],
  searchText: string,
  contacts: string[],
  buckets?: string[]
) => {
  let filterObj: any = {
    status: activeTab,
  };
  if (searchText) {
    filterObj = {
      ...filterObj,
      searchText,
    };
  }
  if (filterMenu.mainmenu === MENU.BY_CONTACT) {
    filterObj = { ...filterObj, contacts };
  }

  if (filterMenu.mainmenu === MENU.BY_BUCKET) {
    filterObj = { ...filterObj, buckets };
  }

  if (filterMenu.mainmenu === MENU.BY_DATE_FILTER) {
    let startDate: any = "";
    let endDate: any = "";
    if (filterMenu.submenu === MENU.DATE_RANGE) {
      startDate = datePickerValue[0]?.toISOString();
      endDate = datePickerValue[1]?.toISOString();
    } else {
      const { start, end } = getStartAndEndDates(filterMenu.submenu) || {};
      startDate = start;
      endDate = end;
    }

    filterObj = {
      ...filterObj,
      contacts: [],
      startDate,
      endDate,
    };
  }
  return filterObj;
};
