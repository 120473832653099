import React from "react";
import Card from "../Card/Card";
import SyncAccountingSoftware from "./SyncAccountingSoftware";

function SyncInDrawer() {
  return (
    <div className="is-flex is-flex-direction-column is-align-items-center mt-6">
      <Card style={{ width: "50%" }}>
        <SyncAccountingSoftware />
      </Card>
    </div>
  );
}

export default SyncInDrawer;
