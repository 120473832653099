import moment from "moment";

export default function formatEstimates(estimates: any[]) {
  let records: any[] = [];
  estimates?.forEach((estimate: any) => {
    estimate.estimates.forEach((record: any) => {
      let repeats = "";

      if (estimate.repeatFrequency === "Monthly") {
        repeats = `Repeats on the ${moment(record.currentForecastDate).format(
          "Do"
        )} of each month`;
      } else if (estimate.repeatFrequency === "Weekly") {
        repeats = `Repeats on the ${moment(record.currentForecastDate).format(
          "dddd"
        )} of each week`;
      } else {
        repeats = "Does not repeat";
      }

      records.push({
        id: record.id,
        dueDate: record.currentForecastDate,
        amount: record.amount,
        currency: record.currency,
        name: estimate.name,
        repeats: repeats,
        settings: {
          id: estimate.id,
          amount: estimate.amount,
          startForecastDate: estimate.dueDate,
          endForecastDate: estimate.repeatUntil,
          repeats: repeats,
          valueForChangeInAmount: estimate.valueForChangeInAmount,
          type: estimate.type,
          cashflowCategory: estimate.cashflowCategory,
          cashflowCategoryMappings: estimate.cashflowCategoryMappings,
        },
      });
    });
  });

  return records;
}
