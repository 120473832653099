import React from "react";
import axios, { AxiosResponse } from "axios";
import { configs } from "../../constants";

const XeroSignUp = () => {
  const handleSignUp = async () => {
    try {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axios.defaults.withCredentials = true;
      axios.post(configs.urls.BASE_URL + "/xero/signup", {}, options).then(
        async (res: AxiosResponse) => {
          if (res?.status === 200) {
            window.location.href = res?.data;
          }
        },
        (err) => {
          console.log("Sign up error: ", err);
        }
      );
    } catch (error) {
      console.error("Error signing up with Xero:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <button className="button-image" onClick={handleSignUp}>
        Sign Up with Xero
      </button>
    </div>
  );
};

export default XeroSignUp;
