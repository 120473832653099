import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomTabs from "../../../common/PageLayout/components/CustomTabs";
import List from "../../../common/PageLayout/components/List";
import ListContainer from "../../../common/PageLayout/components/ListContainer";
import ListHeader from "../../../common/PageLayout/components/ListHeader";
import { StyledComponents } from "../../../constants";
import { cutLengthyString } from "../../../modules/cutLengthyString";
import { getAvatarPlaceholder } from "../../../modules/getAvatarPlaceholder";
import AddContact from "../components/AddContact";
import { Tooltip } from "@mantine/core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "rsuite";
import styled from "styled-components";
import { showNotification, updateNotification } from "@mantine/notifications";
import { faCheck, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  getSearchTextContactsLS,
  setSearchTextContactsLS,
  removeSearchTextContactsLS,
  getSortByContactsLS,
  getFilterByContactsLS,
  getContactsFilterContactsLS,
  setSortByContactsLS,
  removeContactsFilterContactsLS,
  setContactsFilterContactsLS,
  setFilterByContactsLS,
  removeSortByContactsLS,
  removeFilterByContactsLS,
} from "../../../modules/localStorage";
import {
  deleteAllContacts,
  deleteContactByKeys,
  getAllContactsByName,
} from "../../../repositories/contactRepository";
import { getHighlightedText } from "../../../modules/getHighlightedText";
import {
  ascendingSortByLetters,
  descendingSortByLetters,
} from "../../../modules/sortByLetters";
import { renderSortIconButton } from "../../../common/IconButton/IconButton";
import DropdownComponents from "../../Payments/layouts/common/DropdownComponents";
import {
  MENU,
  sortDropdownContacts,
  filterDropdownContacts,
  SORT_BY,
  TAKE,
} from "../../../constants/globalConstants";
import { filterRecords } from "../../Payments/layouts/common";
import { renderFilterIconButton } from "../../../common/IconButton/FilterIconButton";
import { useAuth } from "../../../contexts";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { CustomModal } from "../../../common/CustomModal/CustomModal";
import moment from "moment";
import { ascendingSortByDate } from "../../../modules/sortByDate";
import {
  deleteBlackIcon,
  deleteIcon,
  searchBlackIcon,
  searchIcon,
} from "../../../constants/images";
import { getStartAndEndDates } from "../../../modules/getDates";
import { useContactsContext } from "../contexts/ContactsContext";

const LeftView = ({ onAddContact }: any) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const {
    totalRecords,
    externalAccounts: _externalAccounts,
    fetchExternalAccounts,
    contactsLoading,
    getRecords,
    fetchCashflowReport,
  } = useAuth();
  const { setShowDrawer } = useContactsContext();

  useEffect(() => {
    if (externalAccounts.length === 0) {
      fetchExternalAccounts();
    }
    let id = searchParams.get("selectedContactId")
      ? searchParams.get("selectedContactId")
      : filterExternalAccounts?.length
      ? filterExternalAccounts[0].id
      : "";
    localStorage.setItem("selectedContactId", id);
  }, []);
  const [skip, setSkip] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  let activeTab = searchParams.get("active");
  const [externalAccounts, setExternalAccounts] =
    useState<any[]>(_externalAccounts);
  const [backendValue, setBackendValue] = useState("");
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [active, setActive] = useState<string>(activeTab || "all");
  const [searchText, setSearchText] = useState(getSearchTextContactsLS() || "");
  const [openSearch, setOpenSearch] = useState<Boolean>(
    getSearchTextContactsLS() ? true : false
  );

  const [filterDropdownList, setfilterDropdownList] = useState(
    filterDropdownContacts
  );
  const sortBy = getSortByContactsLS()
    ? getSortByContactsLS().split("/")
    : ["", ""];
  const filterBy = getFilterByContactsLS()
    ? getFilterByContactsLS().split("/")
    : ["", ""];
  const [contacts, setContacts] = useState<string[]>(
    getContactsFilterContactsLS()
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSortSelected, setIsSortSelected] = useState(
    getSortByContactsLS() ? true : false
  );

  const [isFilterSelected, setIsfilterSelected] = useState(
    getFilterByContactsLS() ? true : false
  );

  const [datePickerValue, setDatePickerValue] = useState<DateRangePickerValue>([
    new Date(2022, 11, 1),
    new Date(2022, 11, 5),
  ]);
  const [sortMenu, setSortMenu] = useState({
    mainmenu: sortBy[0],
    submenu: sortBy[1],
  });
  const [filterMenu, setfilterMenu] = useState({
    mainmenu: filterBy[0],
    submenu: filterBy[1],
  });

  useEffect(() => {
    setExternalAccounts(_externalAccounts);
  }, [_externalAccounts]);

  useEffect(() => {
    const sortOrder =
      sortMenu.submenu === (MENU.DESCENDING || MENU.OLDEST_TO_NEWEST)
        ? SORT_BY.DESC
        : SORT_BY.ASC;
    if (!searchText) {
      fetchExternalAccounts(
        0,
        sortOrder,
        backendValue,
        searchText,
        contacts,
        active
      );
      return;
    }

    const delayDebounceFn = setTimeout(async () => {
      await fetchExternalAccounts(
        0,
        sortOrder,
        backendValue,
        searchText,
        contacts,
        active
      );
      setSkip(0);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  useEffect(() => {
    (async () => {
      const sortOrder =
        sortMenu.submenu === (MENU.DESCENDING || MENU.OLDEST_TO_NEWEST)
          ? SORT_BY.DESC
          : SORT_BY.ASC;
      if (filterMenu.mainmenu === MENU.BY_CONTACT) {
        await fetchExternalAccounts(
          0,
          sortOrder,
          backendValue,
          searchText,
          contacts,
          active
        );
        setSkip(0);
      } else {
        const { start, end } = getStartAndEndDates(filterMenu.submenu) || {};

        const filterObj = {
          startDate: start,
          endDate: end,
          filterType: filterMenu.mainmenu,
        };

        await fetchExternalAccounts(
          0,
          sortOrder,
          backendValue,
          searchText,
          contacts,
          active,
          filterObj
        );
      }
    })();
  }, [filterMenu.mainmenu, filterMenu.submenu]);

  useEffect(() => {
    (async () => {
      const result = await getAllContactsByName();
      const uniqueResults: any = _.uniqBy(result, "label");
      filterDropdownList[0].children = uniqueResults;
      setfilterDropdownList(filterDropdownList);
    })();
  }, [externalAccounts]);
  let filterExternalAccounts = useMemo(() => {
    let filteredRecords = externalAccounts.map((acct: any) => acct);
    let beforeFilter = _.cloneDeep(externalAccounts);
    if (searchText) {
      filteredRecords = filteredRecords.filter(
        (record: any) =>
          record?.displayName
            .toLowerCase()
            .includes(searchText.toLowerCase().trim()) ||
          record?.types[0]
            .toLowerCase()
            .includes(searchText.toLowerCase().trim())
      );
    }

    if (!openSearch) {
      filteredRecords = _.cloneDeep(beforeFilter);
    }

    const { mainmenu, submenu } = sortMenu;
    if (mainmenu) {
      if (mainmenu === MENU.BY_CONTACT && submenu === MENU.ASCENDING) {
        filteredRecords = ascendingSortByLetters(
          filteredRecords,
          "displayName"
        );
      }

      if (mainmenu === MENU.BY_CONTACT && submenu === MENU.DESCENDING) {
        filteredRecords = descendingSortByLetters(
          filteredRecords,
          "displayName"
        );
      }
    } else {
      filteredRecords =
        active === "all"
          ? _.sortBy(filteredRecords, [(o) => o.displayName.toLowerCase()])
          : _.sortBy(filteredRecords, [
              (o) => o.displayName.toLowerCase(),
            ]).filter(
              (item) =>
                item.types.findIndex(
                  (type: any) => type.toLowerCase() === active.toLowerCase()
                ) > -1
            );
    }
    if (filterMenu.mainmenu && isFilterSelected) {
      filteredRecords = filterRecords(
        filterMenu.submenu,
        filteredRecords,
        contacts,
        filterMenu.mainmenu,
        moment(datePickerValue[0]).toISOString(),
        moment(datePickerValue[1]).toISOString(),
        isModalOpen
      );

      filteredRecords = ascendingSortByDate(filteredRecords, "totalAmountPaid");
    }

    return _.cloneDeep(filteredRecords);
  }, [
    externalAccounts,
    active,
    searchText,
    openSearch,
    sortMenu.submenu,
    sortMenu.mainmenu,
    filterMenu.mainmenu,
    filterMenu.submenu,
    isSortSelected,
    isFilterSelected,
    isModalOpen,
    contacts.length,
  ]);

  const deleteContacts = async () => {
    if (!selectedKeys.length) {
      showNotification({
        color: "red",
        message: "Please select a record or more to delete",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
      return;
    }
    if (!window.confirm("Warning: Are you sure to delete?")) {
      return;
    }
    showNotification({
      id: "delete-data",
      loading: true,
      message: "Please wait while deletion is in process",
      autoClose: false,
      disallowClose: true,
    });
    try {
      if (
        allSelected &&
        selectedKeys.length === _externalAccounts.length &&
        !openSearch &&
        !isSortSelected &&
        !isFilterSelected
      ) {
        await deleteAllContacts();
      } else {
        await deleteContactByKeys(selectedKeys);
      }
      fetchExternalAccounts();
      getRecords();
      // fetchCashflowReport();
      // setTableLoading(false);
      updateNotification({
        id: "delete-data",
        color: "teal",
        message:
          (allSelected ? totalRecords?.totalContacts : selectedKeys.length) +
          " item(s) deleted!",
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 5000,
      });
    } catch (e) {
      updateNotification({
        id: "delete-data",
        color: "red",
        message: `Cannot delete contact(s)!`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const renderCardChildren = (contact: any) => (
    <StyledComponents.CardContainer>
      <StyledComponents.ListItemCardHeaderContainer>
        <StyledComponents.ImageOnListItemCard
          src={getAvatarPlaceholder(contact?.displayName)}
          style={{ opacity: 0.6 }}
        />
        <StyledComponents.NameOnListItemCard>
          {getHighlightedText(
            cutLengthyString(contact?.displayName, 25),
            searchText
          )}
          <div className="is-flex is-flex-direction-row" style={{ gap: "5px" }}>
            {active === "all" &&
              contact?.types.map((type: string, index: number) => (
                <TypeOnListItemCard key={type}>
                  {getHighlightedText(type || "", searchText)}
                  {index !== contact?.types.length - 1 ? ",   " : ""}{" "}
                </TypeOnListItemCard>
              ))}
          </div>
        </StyledComponents.NameOnListItemCard>
      </StyledComponents.ListItemCardHeaderContainer>
    </StyledComponents.CardContainer>
  );

  const handleChangeSearch = (e: any) => {
    setSearchTextContactsLS(e.target.value);
    setSearchText(e.target.value);
  };

  const handleChangeOpenSearch = async () => {
    if (openSearch) {
      const sortOrder =
        sortMenu.submenu === (MENU.ASCENDING || MENU.NEWEST_TO_OLDEST)
          ? SORT_BY.ASC
          : SORT_BY.DESC;
      removeSearchTextContactsLS();
      setSearchText("");
      await fetchExternalAccounts(
        0,
        sortOrder,
        backendValue,
        searchText,
        contacts,
        active
      );
      setSkip(0);
    }
    setOpenSearch(!openSearch);
  };

  useEffect(() => {
    (async () => {
      const sortOrder =
        sortMenu.submenu === (MENU.DESCENDING || MENU.OLDEST_TO_NEWEST)
          ? SORT_BY.DESC
          : SORT_BY.ASC;
      await fetchExternalAccounts(
        0,
        sortOrder,
        backendValue,
        searchText,
        contacts,
        active
      );

      setSkip(0);
    })();
  }, [active, sortMenu.mainmenu, sortMenu.submenu]);

  const handleSelectedKeys = (keys: string[]) => {
    setSelectedKeys(keys);
  };

  const onSortSelect = (item: any, selectedPaths: any, event: any) => {
    const items = item.value.split("/");
    const mainmenu = items[0];
    const submenu = items?.[1] || "";
    console.log({ item });
    setSortMenu({
      mainmenu,
      submenu,
    });
    const sortOrder =
      sortMenu.submenu === (MENU.DESCENDING || MENU.OLDEST_TO_NEWEST)
        ? SORT_BY.DESC
        : SORT_BY.ASC;
    setBackendValue(item.backendValue);
    fetchExternalAccounts(
      0,
      sortOrder,
      backendValue,
      searchText,
      contacts,
      active
    );
    setSkip(0);
    setSortByContactsLS(`${mainmenu}/${submenu}`);
    setIsSortSelected(true);
  };

  const handleLoadMore = async (skipVal: any) => {
    // fake delay
    const sortOrder =
      sortMenu.submenu === (MENU.DESCENDING || MENU.OLDEST_TO_NEWEST)
        ? SORT_BY.DESC
        : SORT_BY.ASC;
    await fetchExternalAccounts(
      skip + TAKE,
      sortOrder,
      backendValue,
      searchText,
      contacts,
      active
    );
    setSkip(skip + TAKE);
    await new Promise((resolve) => setTimeout(resolve, 300));
  };

  const onFilterSelect = (item: any, selectedPaths: any, event: any) => {
    const items = item.value.split("/");
    const mainmenu = items[0];
    const submenu = items[1];
    setfilterMenu({
      mainmenu,
      submenu,
    });
    if (submenu === MENU.DATE_RANGE) {
      setIsModalOpen(true);
    }
    if (mainmenu === MENU.BY_DATE_FILTER) {
      setContacts([]);
      removeContactsFilterContactsLS();
    }
    if (mainmenu === MENU.BY_CONTACT && submenu) {
      if (contacts.includes(submenu)) {
        const filteredContacts = contacts.filter(
          (contact) => contact !== submenu
        );
        setContacts(filteredContacts);
        setContactsFilterContactsLS(filteredContacts.join(","));
      } else {
        setContacts([...contacts, submenu]);
        setContactsFilterContactsLS([...contacts, submenu].join(","));
      }
    }

    setFilterByContactsLS(`${mainmenu}/${submenu}`);
    setIsfilterSelected(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleSortClick = () => {
    if (isSortSelected) {
      setSortMenu({
        mainmenu: "",
        submenu: "",
      });
      removeSortByContactsLS();
    }
    setIsSortSelected(!isSortSelected);
  };

  const handleFilterClick = () => {
    if (isFilterSelected) {
      setfilterMenu({
        mainmenu: "",
        submenu: "",
      });
      removeFilterByContactsLS();
      removeContactsFilterContactsLS();
      setContacts([]);
    }
    setIsfilterSelected(!isFilterSelected);
  };

  const handleSortMenuClose = () => {
    setSortMenu({
      mainmenu: "",
      submenu: "",
    });

    removeSortByContactsLS();
  };

  const handleFilterMenuClose = () => {
    setfilterMenu({
      mainmenu: "",
      submenu: "",
    });
    setContacts([]);
    removeContactsFilterContactsLS();
    removeFilterByContactsLS();
  };
  return (
    <ListContainer>
      <ListHeader
        hasButton
        buttonTitle="Add a contact"
        buttonAction={() => setShowDrawer(true)}
        title="Contacts"
      />
      <List
        style={{
          height: openSearch ? "calc(100vh - 360px)" : "calc(100vh - 245px)",
          overflowY: "scroll",
        }}
        setAllSelected={setAllSelected}
        list={filterExternalAccounts}
        searchText={searchText}
        openSearch={openSearch}
        handleSelectedKeys={handleSelectedKeys}
        handleChangeSearch={handleChangeSearch}
        renderItem={renderCardChildren}
        loaderItemHeight={80}
        listLoading={contactsLoading}
        paginate={totalRecords.totalContacts}
        activeTab={active}
        onLoadMore={async (skip: number) => {
          // fake delay
          handleLoadMore(skip);
        }}
        renderTabs={() => (
          <CustomTabs
            active={active}
            setActive={setActive}
            tabs={[
              { key: "all", title: "All" },
              { key: "vendor", title: "Vendor" },
              { key: "customer", title: "Customer" },
              { key: "employee", title: "Employee" },
            ]}
            list={filterExternalAccounts}
            totalRecords={totalRecords.totalContacts}
          />
        )}
        renderActions={() => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {renderSortIconButton(
              handleSortClick,
              isSortSelected,
              sortMenu.mainmenu
            )}
            {renderFilterIconButton(
              handleFilterClick,
              isFilterSelected,
              filterMenu.mainmenu
            )}

            <CustomModal
              isOpen={isModalOpen}
              handleClose={handleModalClose}
              title={"Choose Date Range"}
              buttonText="Submit"
              handleButtonClick={handleModalClose}
            >
              <DateRangePicker
                label=""
                placeholder="Pick dates range"
                value={datePickerValue}
                onChange={setDatePickerValue}
              />
              <br />
            </CustomModal>
            <Tooltip
              label="Delete records"
              transition="scale-y"
              transitionDuration={300}
            >
              <div style={{ display: "flex" }}>
                <IconButton
                  className="sort-icon-button"
                  onClick={deleteContacts}
                  icon={
                    <img
                      src={selectedKeys?.length ? deleteBlackIcon : deleteIcon}
                    />
                  }
                />
              </div>
            </Tooltip>
            <div style={{ display: "flex" }}>
              <IconButton
                className="sort-icon-button"
                onClick={handleChangeOpenSearch}
                icon={<img src={openSearch ? searchBlackIcon : searchIcon} />}
              />
            </div>
          </div>
        )}
        renderDropdown={() => (
          <DropdownComponents
            isSortSelected={isSortSelected}
            isFilterSelected={isFilterSelected}
            sortMenu={sortMenu}
            filterMenu={filterMenu}
            contacts={contacts}
            onSortSelect={onSortSelect}
            onFilterSelect={onFilterSelect}
            handleFilterMenuClose={handleFilterMenuClose}
            handleSortMenuClose={handleSortMenuClose}
            filterDropdownList={filterDropdownList}
            sortDropdown={sortDropdownContacts}
          />
        )}
        selectItemId={parseInt(
          searchParams.get("selectedContactId")
            ? searchParams.get("selectedContactId")
            : filterExternalAccounts?.length
            ? filterExternalAccounts[0].id
            : ""
        )}
        onCardClick={(clickedItem: any) => {
          setSearchParams({
            tab: searchParams.get("tab") || "vendor",
            selectedContactId: clickedItem.id,
          });
          localStorage.setItem("selectedContactId", clickedItem.id);
        }}
        type="contacts"
      />
    </ListContainer>
  );
};

export default LeftView;

const TypeOnListItemCard = styled.div`
  font-size: 12px;
  color: #777;
`;
