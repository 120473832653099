import { Group, HoverCard, Text } from "@mantine/core";
import _ from "lodash";
import React, { Fragment } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { COLORS } from "../../../constants/theme";
import { LoadingPlaceholders } from "../../LoadingPlaceholders";

function LabelValue({
  label,
  value,
  subscript,
  isValueBold,
  loading,
  placeholder,
  labelUnderValue,
  linkTo,
  fontSize = "24px",
  renderHoverableDropdown,
  className,
  isWidth50 = false,
}: {
  label: string;
  value: string | Element | JSX.Element;
  subscript?: string;
  isValueBold?: boolean;
  loading?: boolean;
  placeholder?: boolean;
  labelUnderValue?: string;
  linkTo?: string;
  fontSize?: string;
  className?: string;
  isWidth50?: boolean;
  renderHoverableDropdown?: () => JSX.Element;
}) {
  const navigate = useNavigate();

  const renderRecordDetails = (label: string, value: any) => (
    <div
      style={{ width: isWidth50 ? "50%" : undefined }}
      className={"label-value-mb " + className}
    >
      {loading || placeholder ? (
        <LoadingPlaceholders.Details active={loading} />
      ) : (
        <Fragment>
          <div
            style={{
              fontSize: 14,
              fontWeight: "600",
              color: COLORS.brownishGrey,
            }}
          >
            {label}
          </div>
          {linkTo ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              style={{
                fontWeight: isValueBold ? "700" : "normal",
              }}
              className="label-edit-value"
              onClick={() => {
                navigate(linkTo);
              }}
              // eslint-disable-next-line no-script-url
              href="javascript:void(0);"
            >
              {_.isEmpty(value) ||
              (typeof value === "string" && !value.trim()) ? (
                <i style={{ fontSize: 12 }}>No {label.toLowerCase()}.</i>
              ) : (
                <Fragment>
                  <span>{value}</span>{" "}
                  {subscript && (
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "normal",
                        color: COLORS.purple,
                      }}
                    >
                      {subscript}
                    </span>
                  )}
                </Fragment>
              )}
            </a>
          ) : (
            <div
              style={{
                fontSize: 24,
                fontWeight: isValueBold ? "700" : "normal",
              }}
            >
              {_.isEmpty(value) ||
              (typeof value === "string" && !value.trim()) ? (
                <i style={{ fontSize: 12 }}>No {label.toLowerCase()}.</i>
              ) : (
                <Fragment>
                  <span
                    style={{ fontSize }}
                    className="label-edit-value"
                    dangerouslySetInnerHTML={{
                      __html: value,
                    }}
                  ></span>{" "}
                  {subscript && (
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "normal",
                        color: COLORS.purple,
                      }}
                    >
                      {subscript}
                    </span>
                  )}
                </Fragment>
              )}
            </div>
          )}

          {renderHoverableDropdown ? (
            <Group position="left">
              <HoverCard width={280} shadow="md" position="top">
                <HoverCard.Target>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: COLORS.dustyOrange,
                      textDecoration: "underline",
                    }}
                  >
                    {labelUnderValue}
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">{renderHoverableDropdown()}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            </Group>
          ) : (
            <div
              style={{
                fontSize: 14,
                fontWeight: "600",
                color: COLORS.dustyOrange,
              }}
            >
              {labelUnderValue}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );

  return renderRecordDetails(label, value);
}

export default LabelValue;
