import { Tabs } from "@mantine/core";
import { COLORS } from "../../../constants/theme";

export function SwitchViewTabs({ setSelectedView, active }: any) {
  return (
    <Tabs
      // defaultValue={active || "0"}
      onTabChange={(value: string) => setSelectedView(Number(value))}
      value={active.toString()}
      styles={(theme) => ({
        tab: {
          "&[data-active]": {
            color: "black",
            // paddingRight: "6px",
            fontWeight: 700,
            borderBottom: `2px solid ${COLORS.black}`,
          },
          borderBottom: "none",
          fontSize: 12,
          paddingRight: "10px",
          paddingLeft: "10px",

          "&:hover": {
            borderBottom: `2px solid ${COLORS.black}`,
          },
        },
        tabLabel: {
          "&:hover": {
            fontWeight: 700,
          },
        },
        root: {
          flexWrap: "nowrap",
        },
      })}
    >
      <Tabs.List>
        <Tabs.Tab value="0">Monthly</Tabs.Tab>
        <Tabs.Tab value="1">Weekly</Tabs.Tab>
        <Tabs.Tab value="2">Daily</Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
}
