const height = window.innerHeight;
const width = window.innerWidth;

export const COLORS = {
  primary: "#001736",
  primaryLight: "#1A8871",
  primaryLight1: "#00173626",
  secondary: "#4C6C60",
  yellowLight: "#F2E8C7",
  yellow: "#D8B94B",
  redLight: "#E8CDCD",
  red: "#CB6767",
  greenLight: "#B7F4DD88",
  greenLight1: "#B7F4DD88",
  green: "#12E09A",
  btnPrimary: "#95B3AA",
  btnPrimaryLight: "#95B3AA4D",
  btnSubtle: "#00173626",
  btnDisabled: "#C4C4C4",
  btnDelete: "#c12e5b",
  btnEdit: "#66ADA7",
  success: "#4C6C60",
  successLight: "#DFE8E6CC",
  error: "#CB6767",
  errorLight: "#E8CDCD80",
  title: "#3A4132",
  subtitle: "#999999",
  white: "#fff",
  white2: "#F9F9F9",
  black: "#1E1E1E",
  blue: "#4096FE",
  gray: "#777777",
  gray2: "#B9B9C3",
  lightGray: "#F5F6FC",
  lightGray2: "#757575",
  background: "#F7F8F9",
  quickbooks: "#299c13",
  xero: "#04b4e3",
  transparentBlack1: "#FFFFFF0D",
  transparentBlack3: "#FFFFFF1A",
  transparentBlack5: "#FFFFFF33",
  transparentBlack7: "rgba(2, 2, 2, 0.7)",
  transparentBlack9: "rgba(2, 2, 2, 0.9)",
  transparentGray: "rgba(77,77,77, 0.8)",
  transparentDarkGray: "rgba(20,20,20, 0.9)",
  transparent: "transparent",
  greenPrimary: "#00D28F",

  greenBlue: "#00D28F",
  brownGrey: "#969696",
  veryLightPink: "#cbcbcb",
  pale: "#faded4",
  brownishGrey: "#707070",
  uglyBlue: "#2c6b76",
  iceBlue: "#eff6f9",
  paleTeal: "#91c3be",
  paleTeal2: "#d6ede8",
  charcoal90: "#333c2c",
  greyishTeal: "#729283",
  purple: "#691db1",
  dustyOrange: "#f1883f",
};
const SIZES = {
  // global sizes
  base: 8,
  font: 14,
  radius: 12,
  padding: 24,

  // font sizes
  largeTitle: 40,
  h1: 30,
  h2: 22,
  h3: 16,
  h4: 14,
  body1: 30,
  body2: 22,
  body3: 16,
  body4: 14,
  body5: 12,

  // app dimensions
  width,
  height,
};

const VICTORY_PIE_COLORS = [
  "#4C6C60",
  "#95B3AA",
  "#7A9188",
  "#758090",
  "#45556B",
  "#B8BCC3",
  "#CB6767",
  "#DAB2B2",
  "#1B1B3A",
  "#FF6347",
];

const appTheme = { COLORS, SIZES, VICTORY_PIE_COLORS };

export default appTheme;
