import axios from "axios";
import { CheckoutSessionExistPayload } from "../constants/payloads";
import { configs } from "../constants";
import { ROUTES } from "../constants/routes";
import { ISyncingLogs } from "../constants/types";

export const checkoutSessionExists = async (
  payload: CheckoutSessionExistPayload
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL +
        ROUTES.Subscription.Base +
        "/checkout-session-exist",
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const unusedSubscription = async () => {
  try {
    const { data } = await axios.get(
      configs.urls.BASE_URL + ROUTES.Subscription.Base + "/unused-subscription",
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const getSyncingInfo = async () => {
  try {
    const { data } = await axios.get(
      configs.urls.BASE_URL + ROUTES.Subscription.Base + "/syncing-info",
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
