import React, { Fragment, useState } from "react";
import _ from "lodash";
import moment from "moment";
import Card from "../../../../common/Card/Card";
import { LoadingPlaceholders } from "../../../../common/LoadingPlaceholders";
import DetailsCardFooter from "../../../../common/PageLayout/components/DetailsCardFooter";
import DetailsCardHeader from "../../../../common/PageLayout/components/DetailsCardHeader";
import LabelValue from "../../../../common/PageLayout/components/LabelValue";
import ManageRunwayCard from "../../../../common/PageLayout/components/ManageRunwayCard";
import { IMAGES, StyledComponents } from "../../../../constants";
import { PaymentStatus } from "../../../../constants/globalConstants";
import { COLORS } from "../../../../constants/theme";
import { formatCurrency } from "../../../../modules";
import {
  decideContactNameLabelByInvoiceType,
  decideRecordNameLabelByInvoiceType,
} from "../../../../modules/decideLabelsByTypes";
import { getInvoiceStatus } from "../../../../modules/getInvoiceStatus";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";
import styled from "styled-components";
import EditModal from "../../../../common/PageLayout/components/EditModal";

interface ShowRecordProps {
  loading: boolean;
  selectedRecord: any;
  setSelectedRecord?: any;
  onClose?: any;
}

const ShowRecord = ({
  loading,
  selectedRecord,
  setSelectedRecord,
  onClose,
}: ShowRecordProps) => {
  const [showMore, setShowMore] = useState(true);
  const [showEditDrawer, setShowEditDrawer] = React.useState(false);

  const handleEditPay = () => {
    setShowEditDrawer(true);
  };

  return (
    <AnimatedView
      className="custom-scrollbar"
      style={{
        height: "calc(100vh - 90px)",
        overflowY: "scroll",
      }}
    >
      <Card
        style={{
          padding: "30px 32px",
          position: "relative",
        }}
        className="m-5 mb-6"
      >
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={handleEditPay}
          hasBadge={false}
          title={`${decideRecordNameLabelByInvoiceType(
            selectedRecord?.type
          )} Details`}
        />
        <EditModal
          showEditPayModal={showEditDrawer}
          setShowEditPayModal={setShowEditDrawer}
          selectedBill={selectedRecord}
          invoiceType={selectedRecord?.type}
          title="Edit Invoice Details"
          setSelectedRecord={setSelectedRecord}
        />
        <div className="flex-direction-row-space-between mt-4">
          <div>
            <LabelValue
              label={`${decideContactNameLabelByInvoiceType(
                selectedRecord?.type
              )} Name`}
              value={selectedRecord?.name}
              loading={loading}
            />
            <LabelValue
              label="Total Amount"
              value={`${selectedRecord?.currency || "AED"} ${formatCurrency(
                parseFloat(selectedRecord?.amountTotal || "0"),
                2
              )}`}
              loading={loading}
            />
            <LabelValue
              label="Due Date"
              value={moment(selectedRecord?.dueDate).format("MMM DD, YYYY")}
              loading={loading}
            />
            <LabelValue
              label="Issue Date"
              value={moment(selectedRecord?.issueDate).format("MMM DD, YYYY")}
              loading={loading}
            />
          </div>
        </div>
        <LabelValue
          label={`${decideRecordNameLabelByInvoiceType(
            selectedRecord?.type
          )} No`}
          value={selectedRecord?.invoiceNumber}
          loading={loading}
        />

        {loading ? (
          <div className="mb-5">
            <LoadingPlaceholders.Text
              style={{ marginBottom: -8, marginLeft: 3, height: 15 }}
              active={loading}
              width={80}
            />
            <LoadingPlaceholders.Text
              style={{ marginBottom: -5, marginLeft: 3 }}
              active={loading}
              width={380}
            />
          </div>
        ) : (
          <div className="mb-5" style={{ width: "350px" }}>
            <div style={{ fontSize: 12, fontWeight: "600" }}>Note to Self</div>
            <div
              style={{
                fontSize: 20,
                fontWeight: "600",
              }}
            >
              {_.isEmpty(selectedRecord?.description) ||
              !selectedRecord?.description.trim() ? (
                <i style={{ fontSize: 12 }}>No note to self.</i>
              ) : selectedRecord?.description.length > 80 ? (
                showMore ? (
                  <span>
                    {selectedRecord?.description.slice(0, 80)}...{" "}
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: COLORS.greenPrimary,
                        fontWeight: "700",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: 14,
                      }}
                    >
                      see more
                    </span>
                  </span>
                ) : (
                  <span>
                    {selectedRecord?.description}
                    <span
                      onClick={() => setShowMore(!showMore)}
                      style={{
                        color: COLORS.greenPrimary,
                        fontWeight: "700",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: 14,
                      }}
                    >
                      {"  "}see less
                    </span>
                  </span>
                )
              ) : (
                selectedRecord?.description
              )}
            </div>
          </div>
        )}

        {selectedRecord?.linkedTransactions?.length &&
        (selectedRecord?.status === PaymentStatus.PartiallyPaid ||
          selectedRecord?.status === PaymentStatus.Paid) ? (
          <Fragment>
            <LabelValue
              label={"Paid at"}
              value={moment(
                getLatestTransaction(selectedRecord?.linkedTransactions)
                  ?.paidOrFailedAt
              ).format("MMM DD, YYYY")}
              loading={loading}
              labelUnderValue={
                selectedRecord?.linkedTransactions.length + " Payment(s)"
              }
              renderHoverableDropdown={() => (
                <div
                  style={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                  }}
                >
                  {selectedRecord?.linkedTransactions.map(
                    (transaction: any, index: number) => (
                      <div
                        key={transaction.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          padding: "5px 0",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <div style={{ fontSize: 10, fontWeight: "600" }}>
                          Ref: {transaction.txnReferenceId}
                        </div>
                        <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                          <div style={{ fontSize: 14, fontWeight: "600" }}>
                            {moment(transaction.paidOrFailedAt).format(
                              "MMM DD, YYYY"
                            )}
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {/* {selectedRecord?.currency}{" "} */}
                            {transaction?.currency}{" "}
                            <span
                              style={{
                                fontWeight: "600",
                                color:
                                  selectedRecord?.type === "Receivable"
                                    ? COLORS.greenPrimary
                                    : COLORS.red,
                              }}
                            >
                              {formatCurrency(
                                parseFloat(transaction.amountPaid || "0"),
                                2
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            />
            {selectedRecord?.authorizedAt && (
              <LabelValue
                label="Authorized at"
                value={moment(
                  getLatestTransaction(selectedRecord?.linkedTransactions)
                    ?.paidOrFailedAt
                ).format("MMM DD, YYYY")}
                loading={loading}
              />
            )}
            {selectedRecord?.authorizedBy && (
              <LabelValue
                label="Authorized by"
                value={selectedRecord?.authorizedBy}
                loading={loading}
              />
            )}
          </Fragment>
        ) : null}

        {selectedRecord?.creditNotes?.length ? (
          <LabelValue
            label={"Credit notes applied"}
            value={moment(selectedRecord?.creditNotes[0]?.date).format(
              "MMM DD, YYYY"
            )}
            loading={loading}
            labelUnderValue={
              selectedRecord?.creditNotes.length + "  Credit Note(s)"
            }
            renderHoverableDropdown={() => (
              <div
                style={{
                  overflowY: "hidden",
                  overflowX: "hidden",
                }}
              >
                {selectedRecord?.creditNotes.map((note: any, index: number) => (
                  <div
                    key={note.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: "5px 0",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontWeight: "600",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Ref: {note.creditNoteNumber}
                    </div>
                    <div className="is-flex is-flex-direction-row is-justify-content-space-between">
                      <div style={{ fontSize: 14, fontWeight: "600" }}>
                        {moment(note.date).format("MMM DD, YYYY")}
                      </div>
                      <div
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {selectedRecord?.currency}{" "}
                        <span
                          style={{
                            fontWeight: "600",
                            color: COLORS.greenPrimary,
                          }}
                        >
                          {formatCurrency(parseFloat(note.amount || "0"), 2)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          />
        ) : null}

        {selectedRecord?.status === PaymentStatus.PendingAuthorization && (
          <Fragment>
            <LabelValue
              label="Queued At"
              value={moment(selectedRecord?.queuedAt).format("MMM DD, YYYY")}
              loading={loading}
            />
            <LabelValue
              label="Queued by"
              value={selectedRecord?.queuedBy}
              loading={loading}
            />
          </Fragment>
        )}

        <DetailsCardFooter
          style={{
            height: "80px",
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 0,
            bottom: -40,
          }}
          left={() =>
            loading ? (
              <LoadingPlaceholders.Text
                style={{ height: 30 }}
                active={loading}
              />
            ) : (
              <div style={{ fontSize: 22, fontWeight: "600" }}>
                Open Balance
              </div>
            )
          }
          right={() =>
            loading ? (
              <LoadingPlaceholders.Text
                style={{ height: 30 }}
                active={loading}
              />
            ) : (
              <div style={{ fontSize: 25, fontWeight: "700" }}>
                {selectedRecord?.currency}{" "}
                {formatCurrency(parseFloat(selectedRecord?.amountDue), 2)}
              </div>
            )
          }
        />
      </Card>

      <div className="m-5" style={{ marginTop: "150px" }}>
        <ManageRunwayCard
          record={selectedRecord}
          loading={loading}
          cardStyles={{ marginBottom: "10px" }}
          onClose={onClose}
        />
      </div>
    </AnimatedView>
  );
};

export default ShowRecord;

const AnimatedView = styled.div`
  /* slide from left on mount starting from parent */
  animation: slide-in-right-view 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-in-right-view {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
`;
