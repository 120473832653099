import React from "react";
import styled from "styled-components";
import { CustomDrawer } from "../../../common/CustomDrawer";
import { Contact } from "../../../constants/globalConstants";
import { useAuth } from "../../../contexts";
import AddBankDetails from "./AddBankDetails";
import Manually from "./Manually";
import ReviewAndConfirm from "./ReviewAndConfirm";
import SelectMethod from "./SelectMethod";
import AddBeneficiaryDetails from "./AddBeneficiaryDetails";
import SyncInDrawer from "../../../common/SyncAccountingSoftware/SyncInDrawer";
import { Step, STEPS } from "../constants";
import { flattenBank, whichTitle } from "../utils";
import AddContactMethod from "./AddContactMethod";
import { useContactsContext } from "../contexts/ContactsContext";
import ListBanks from "./ListBanks";

function AddContact() {
  const { fetchExternalAccounts } = useAuth();
  const {
    showDrawer,
    contact,
    disbursement,
    bank,
    step,
    setStep,
    onClose,
    setBank,
    setContact,
    setDisbursement,
    banks,
    isEditMode,
    setMode,
  } = useContactsContext();

  const steps: { [key: string]: Step } = {
    step0: {
      component: (
        <AddContactMethod handleNext={(step: string) => handleNext(step)} />
      ),
      getNextStep: (data: any) => data,
      prevStep: null,
    },
    step1a: {
      component: (
        <Manually
          contact={contact}
          onSubmit={(values: Contact) => {
            handleNext();
            setContact(values);
            fetchExternalAccounts();
          }}
          onNext={() => {
            handleNext();
          }}
        />
      ),
      getNextStep: (data: any) => STEPS.SELECT_METHOD,
      prevStep: STEPS.ADD_CONTACT,
    },
    step1b: {
      component: (
        <div style={{ alignSelf: "normal" }}>
          <SyncInDrawer />
        </div>
      ),
      getNextStep: (data: any) => null,
      prevStep: STEPS.ADD_CONTACT,
    },
    step2: {
      component: (
        <SelectMethod
          onClose={onClose}
          onSubmit={() => {
            handleNext();
          }}
        />
      ),
      getNextStep: (data: any) => STEPS.ADD_BENEFICIARY_DETAILS,
      prevStep: STEPS.ADD_MANUALLY,
    },
    step3: {
      component: (
        <AddBeneficiaryDetails
          contact={contact}
          beneficiary={disbursement}
          onClose={onClose}
          onSubmit={(beneficiary: any) => {
            setDisbursement(beneficiary);
            fetchExternalAccounts();
            handleNext();
          }}
          onNext={() => {
            onClose();
            // if (banks.length === 0) setMode("add");
          }}
        />
      ),
      getNextStep: (data: any) =>
        isEditMode() && banks.length > 0
          ? STEPS.EDIT_BANKS
          : STEPS.ADD_BANK_DETAILS,
      prevStep: STEPS.ADD_MANUALLY,
    },
    step4: {
      component: (
        <AddBankDetails
          onClose={onClose}
          onSubmit={(bankValues: any) => {
            handleNext();
            setBank(bankValues);
            fetchExternalAccounts();
          }}
          onNext={() => {
            handleNext();
          }}
          bank={bank}
          contactName={contact.name}
          beneficiaryName={disbursement.name}
        />
      ),
      getNextStep: (data: any) => STEPS.REVIEW_AND_CONFIRM,
      prevStep:
        isEditMode() && banks.length > 0
          ? STEPS.EDIT_BANKS
          : STEPS.ADD_BENEFICIARY_DETAILS,
    },
    step5: {
      component: (
        <ReviewAndConfirm
          onClose={onClose}
          bank={bank}
          contact={contact}
          beneficiary={disbursement}
        />
      ),
      getNextStep: (data: any) => null,
      prevStep: STEPS.ADD_BANK_DETAILS,
    },

    // edit steps
    listBanksStep: {
      component: (
        <ListBanks
          handleNext={(bankFromDb: any) => {
            // console.log("bankFromDb", bankFromDb);
            const bank = flattenBank(bankFromDb);
            // console.log("bank", bank);

            setBank({ ...bank });
            handleNext();
          }}
        />
      ),
      getNextStep: (data: any) => STEPS.ADD_BANK_DETAILS,
      prevStep: STEPS.ADD_BENEFICIARY_DETAILS,
    },
  };

  const handleNext = (data?: any) => {
    const nextStep = steps[step].getNextStep(data);
    if (nextStep) {
      setStep(nextStep);
    }
  };

  const onBack = () => {
    const prevStep = steps[step].prevStep;
    if (prevStep) {
      setStep(prevStep);
    } else {
      onClose();
    }
  };
  return (
    <CustomDrawer
      opened={showDrawer}
      onBack={onBack}
      onClose={onClose}
      title={whichTitle(step, contact, isEditMode(), disbursement.name)}
    >
      <Container>{steps[step].component}</Container>
    </CustomDrawer>
  );
}

export default AddContact;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 31px 32px;
  width: 100%;
`;
