import moment from "moment";
import { PaymentStatus } from "../../../../constants/globalConstants";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";

export const filterByDueDate = (array: any[], start: any, end: any) => {
  console.log({ array, start, end });
  return array.filter((record: any) => {
    const date =
      record.status === PaymentStatus.Paid
        ? moment(
            getLatestTransaction(record?.linkedTransactions)?.paidOrFailedAt
          ).toISOString()
        : moment(record.dueDate).toISOString();
    return date >= start && date <= end;
  });
};
