import axios from "axios";
import { configs } from "../constants";

export const conversionRateApi = async (
  amount: number,
  fromCurr: string,
  toCurr: string,
  date: string
) => {
  return await axios.get(configs.urls.BASE_URL + "/convert/converted_amount/", {
    params: {
      amount,
      fromCurr,
      toCurr,
      date,
    },
  });
};

export const verifyTotalAmount = async (
  payments: any,
  totalAmount: any,
  totalCurrency: string
) => {
  return await axios.post(
    configs.urls.BASE_URL + "/convert/verify_total_amount/",
    {
      payments,
      totalAmount,
      totalCurrency,
    }
  );
};
