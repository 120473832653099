import { CashflowConfigs, PeriodFilter } from "./globalConstants";

const NOTIFICATIONS = [
  {
    id: 1,
    title: "New Notification",
    message: "This is a new notification",
    date: "2020-01-01",
    time: "12:00",
    status: "unread",
  },
  {
    id: 2,
    title: "New Notification",
    message: "This is a new notification",
    date: "2020-01-01",
    time: "12:00",
    status: "read",
  },
  {
    id: 3,
    title: "New Notification",
    message: "This is a new notification",
    date: "2020-01-01",
    time: "12:00",
    status: "read",
  },
];

export const INITIAL_CONFIGS: CashflowConfigs = {
  periodFilter: PeriodFilter.Monthly,
  startDate: new Date(),
  // date  6 months from now
  endDate: new Date(new Date().setMonth(new Date().getMonth() + 12)),
  groupByContacts: true,
  filterByExpectedDueDate: true,
  sourceType: "Manual",
};

const RECORDS_TABLE_FILTER = [
  { label: "Invoices", key: "Invoices" },
  // { label: "Line Items", key: "Line Items" },
  // { label: "Credit Notes", key: "Credit Notes" },
  // { label: "Journals", key: "Journals" },
  { label: "Payments", key: "Payments" },
];

const SELECT_FREQUENCY = [
  "One Off",
  "Daily",
  "Weekly",
  "Monthly",
  "Quarterly",
  "Bi-annually",
  "Annually",
];

const EVENT_CARD_DATA = [
  {
    company: "Company 1",
    date: "Aug 1, 2022",
    time: "12:00 PM (GMT+4)",
    reminderBefore: "2 days",
  },
  {
    company: "Company 2",
    date: "Jun 22, 2022",
    time: "10:00 AM (GMT+5)",
    reminderBefore: "1 days",
  },
  {
    company: "Company 2",
    date: "May 19, 2022",
    time: "18:30 PM (GMT+4)",
    reminderBefore: "2 hours",
  },
];

const TABLE_DATA = [
  {
    id: 1,
    title: "VAT Filing",
    dueDate: "Sun, 05 Dec 2022",
    frequency: "Weekly",
  },
  {
    id: 2,
    title: "Audit Filing",
    dueDate: "Sun, 19 Aug 2022",
    frequency: "Weekly",
  },
  {
    id: 3,
    title: "Wednesday Email",
    dueDate: "Sun, 17 May 2022",
    frequency: "Monthly",
  },
  {
    id: 4,
    title: "VAT Filing",
    dueDate: "Sun, 10 Sep 2022",
    frequency: "Weekly",
  },
  {
    id: 5,
    title: "VAT Filing",
    dueDate: "Sun, 27 May 2022",
    frequency: "Monthly",
  },
];

export {
  NOTIFICATIONS,
  RECORDS_TABLE_FILTER,
  EVENT_CARD_DATA,
  TABLE_DATA,
  SELECT_FREQUENCY,
};

export const legendsCashFlow = [
  {
    title: "CURRENT CLOSING BALANCE",
    value: "",
    color: "black",
  },
  {
    title: "TOTAL CASH IN",
    value: "",
    color: "#00d28f",
  },
  {
    title: "TOTAL CASH OUT",
    value: "",
    color: "#c12e5b",
  },
  {
    title: "UNRECONCILED",
    value: "",
    color: "#F6DFD5",
  },
];

export const cashflowDatasets = [
  {
    label: "Balance",
    data: [0],
    borderColor: "#00000",
  },
  {
    label: "Cash In",
    data: [0],
    borderColor: "#00d28f",
  },
  {
    label: "Cash Out",
    data: [0],
    borderColor: "#c12e5b",
  },
  {
    label: "Unreconciled",
    data: [0],
    borderColor: "#F6DFD5",
  },
];

export const incomeExpenseLegends = [
  {
    title: "NET INCOME",
    value: "",
    color: "black",
  },
  {
    title: "INCOME",
    value: "",
    color: "#00d28f",
  },
  {
    title: "EXPENSES",
    value: "",
    color: "#c12e5b",
  },
];

export const incomeExpenseDataSets = [
  {
    label: "Net Income",
    data: [0],
    borderColor: "#00000",
  },
  {
    label: "Income",
    data: [0],
    borderColor: "#00d28f",
  },
  {
    label: "Expenses",
    data: [0],
    borderColor: "#c12e5b",
  },
];

export const AvgCashInData = {
  title: "AVG CASH IN",
  subTitle: "",
  data: [0],
  backgroundColor: "#00d28f",
  borderColor: "#00d28f",
};

export const AvgBurnRateData = {
  title: "AVG BURN RATE",
  subTitle: "",
  data: [0],
  backgroundColor: "#c12e5b",
  borderColor: "#c12e5b",
};
