import "react-phone-input-2/lib/style.css";
import "../SignUp/styles.css";

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";

import {
  getAllOrganizationsOfCurrentUser,
  updateUser,
} from "../../repositories/userRepository";
import { ProgressBar } from "../../common/ProgressBar/ProgressBar";
import BackChevron from "../../common/BackChevron/BackChevron";
import { getUserLS, setUserLS } from "../../modules/localStorage";
import { NEXT_STAGE } from "../../constants/globalConstants";
import { createOrganization } from "../../repositories/organizationRepository";
import SubmitButton from "./components/SubmitButton";

function TellUsAboutYourself() {
  const navigate = useNavigate();
  const [hasShadow, showShadow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      mobileNumber: "",
    },
    onSubmit: async (values) => {
      setIsDisabled(true);
      const user = getUserLS();
      const addMore = user.addMore || false;
      setUserLS({ firstName: values.firstName });
      await updateUser({ ...values, lastStage: "tell-us-about-yourself" });
      if (addMore) {
        setIsDisabled(false);

        navigate(`/${NEXT_STAGE["tell-us-about-yourself"]}`);
      } else {
        let companyName = `${firstName}'s Demo Company`;
        const active = addMore ? false : true;
        const {
          success,
          response: { id, name, createdAt },
        } = await createOrganization({
          companyName,
          isDemo: true,
          currentlyActive: active,
        });
        if (!success) {
          return;
        }
        localStorage.setItem("organizationId", id);
        setUserLS({
          organizationId: id,
          organizationName: name,
          isDemo: true,
          completedTutorial: false,
          createdAt,
        });
        await updateUser({ lastStage: "you-are-all-set-up" });
        setIsDisabled(false);
        navigate("/you-are-all-set-up");
      }
    },
  });
  const { firstName, lastName, mobileNumber } = formik.values;

  const handlePhoneNumberChange = (e: any) => {
    formik.setFieldValue("mobileNumber", e);
  };

  useEffect(() => {
    getUsersInfo();
  }, []);

  const getUsersInfo = async () => {
    const user = await getAllOrganizationsOfCurrentUser();
    if (!user || !user.firstName) {
      return;
    }
    const { firstName, lastName, mobileNumber } = user;
    formik.setFieldValue("firstName", firstName);
    formik.setFieldValue("lastName", lastName);
    formik.setFieldValue("mobileNumber", mobileNumber);
  };

  const isValid = () => {
    if (!firstName || !mobileNumber?.length || !lastName) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <BackChevron />
      <div
        className="card-outer-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <form onSubmit={formik.handleSubmit} className="form-container">
          <ProgressBar />

          <div className="form-button-container">
            <div
              onMouseOver={() => showShadow(true)}
              onMouseOut={() => showShadow(false)}
              className={`card-inner-container ${hasShadow ? "card" : ""}`}
              style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
            >
              <div className="card-content card-padding onboarding">
                <div className="content">
                  <div className="logo-container">
                    <p className="register-form-title">
                      Tell us about yourself
                    </p>
                  </div>
                  <div className="field">
                    <label className="label">First Name</label>
                    <div className="control">
                      <input
                        required
                        className="input"
                        type="text"
                        placeholder="John"
                        id="firstName"
                        name="firstName"
                        onChange={formik.handleChange}
                        value={firstName}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Last Name</label>
                    <div className="control">
                      <input
                        required
                        className="input"
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Doe"
                        onChange={formik.handleChange}
                        value={lastName}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Mobile Number</label>
                    <div className="control">
                      <PhoneInput
                        country={"ae"}
                        value={mobileNumber}
                        onChange={handlePhoneNumberChange}
                        preferredCountries={["ae", "sa", "us", "gb"]}
                      />
                    </div>
                  </div>
                  <div className="mobile-number-desc">
                    Used to verify your account and keep payments secure
                  </div>
                </div>
              </div>
            </div>

            <SubmitButton
              type="submit"
              className="mt-6"
              disabled={!isValid() || isDisabled}
            >
              Continue
            </SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TellUsAboutYourself;
