import React from "react";

function ShowDetailsCard({ renderTopHeader, renderCard, style }: any) {
  return (
    <div
      style={{
        overflowY: "auto",
        width: "100%",
        height: "100%",
        ...style,
      }}
      className="custom-scrollbar"
    >
      <div className="top-header">{renderTopHeader()}</div>
      <div
        style={{
          borderRadius: 15,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
          margin: 30,
          marginBottom: 90,
        }}
      >
        {renderCard && renderCard()}
      </div>
    </div>
  );
}

export default ShowDetailsCard;
