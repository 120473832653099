import { faPowerOff, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import { appTheme, configs } from "../../../constants";

function Logout() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [_, __, removeCookie] = useCookies([
    "user",
    "xeroConnection",
    "xeroOrganization",
  ]);

  return (
    <div>
      <Button
        appearance="subtle"
        style={{
          backgroundColor: appTheme.COLORS.btnDelete,
          color: "white",
          marginTop: 15,
        }}
        loading={loading}
        block
        onClick={() => {
          setLoading(true);
          localStorage.removeItem("id");
          localStorage.removeItem("email");
          axios
            .get(configs.urls.BASE_URL + "/logout", {
              withCredentials: true,
            })
            .then(
              (res) => {
                if (res.data === "success") {
                  removeCookie("user");
                  removeCookie("xeroConnection");
                  removeCookie("xeroOrganization");
                  setTimeout(() => {
                    // navigate("/login");
                    // reload
                    window.location.reload();
                    setLoading(false);
                  }, 1000);
                } else {
                  setLoading(false);
                }
              },
              (err) => {
                showNotification({
                  color: "red",
                  message: `${err}`,
                  icon: <FontAwesomeIcon icon={faTimes} />,
                  autoClose: 2000,
                });
                setLoading(false);
              }
            );
        }}
      >
        <FontAwesomeIcon
          style={{ marginRight: 5, marginTop: 3 }}
          icon={faPowerOff}
        />
        Logout
      </Button>
    </div>
  );
}

export default Logout;
