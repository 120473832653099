import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { configs } from "../../constants";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import Card from "../../common/Card/Card";
import styled from "styled-components";
import "./styles.css";
import { zoho_round_logo } from "../../constants/images";
import { CardTitleBlack } from "../../common/CardTitles";
import { CardSubTitle } from "../../common/CardSubTitle";
import { Loader } from "@mantine/core";
import { COLORS } from "../../constants/theme";

function ZohoOrganizations() {
  const navigate = useNavigate();
  const [zohoOrganizations, setZohoOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    await axios.post(
      configs.urls.BASE_URL + "/zoho/set-organization",
      { organizationId, organizationName },
      { withCredentials: true }
    );
    navigate("/get-started");
    navigate(0);
  };

  useEffect(() => {
    async function getOrganizations() {
      setLoading(true);
      const { data } = await axios.get(
        configs.urls.BASE_URL + "/zoho/all-organizations",
        {
          withCredentials: true,
        }
      );
      if (data.success) {
        setZohoOrganizations(data.organizations);
        setError("");
      } else {
        setError("Please re-connect to Zoho Books");
      }
      setLoading(false);
    }
    getOrganizations();
  }, []);

  return (
    <div className="hero-body-companies companies-container">
      <CardTitle>Sync with your accounting software</CardTitle>
      <Card className="companies-card" isNormal>
        <img className="zoho-logo" src={zoho_round_logo} alt="zoho_logo" />
        <div className="mt-4">
          <CardSubTitle title="Select your Zoho Books organization" />
        </div>
        <form onSubmit={onSubmit}>
          {loading ? (
            <Loader
              variant="oval"
              size="md"
              color={COLORS.greenBlue}
              className="mt-3"
            />
          ) : error !== "" ? (
            <p style={{ color: "red" }}>{error}</p>
          ) : (
            zohoOrganizations.map((org: any) => {
              return (
                <div style={{ margin: "5px" }}>
                  <input
                    type="radio"
                    value={org.name}
                    onChange={(e) => {
                      setOrganizationId(org.organization_id);
                      setOrganizationName(org.name);
                    }}
                  />
                  <label style={{ marginLeft: "10px", fontSize: "16px" }}>
                    {org.name}
                  </label>
                  <br />
                </div>
              );
            })
          )}
          <div style={{ display: "flex" }}></div>
        </form>
      </Card>
      <PrimaryButton
        onClick={onSubmit}
        disabled={loading || organizationName === ""}
        className="mt-6"
        style={{ width: "20%" }}
      >
        Connect
      </PrimaryButton>
      <a className="link-button" onClick={onSubmit} href="/get-started">
        Cancel
      </a>
    </div>
  );
}

export default ZohoOrganizations;

const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  line-height: 120px;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
`;
