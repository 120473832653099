import React from "react";
import { IMAGES } from "../../constants";
import PrimaryButton from "../../common/PrimaryButton/PrimaryButton";
import { Card } from "@mantine/core";
import { notify } from "../../modules/showNotification";
import { upsertBalanceHistoryBank } from "../Cashflow/repository/cashflowConfigs";
import { useBanksContext } from "./context/BanksContext";

interface Props {}

const NoBanksView: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const { fetchLinkedBanks, showUploadModal } = useBanksContext();

  const onUpsertBank = async () => {
    setLoading(true);
    const { success } = await upsertBalanceHistoryBank({
      id: Math.random().toString(36).substr(2, 9),
      name: "Bank 1",
      values: [],
      dateRange: [],
      sourceType: "Manual",
    });

    setTimeout(() => {
      setLoading(false);

      notify({
        success: success && "Bank updated successfully",
        error: !success && "Error while updating bank",
        // onSuccess: () => getCashflowReport(),
      });

      if (success) {
        fetchLinkedBanks();
        showUploadModal(true);
      }
    }, 1000);
  };

  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center mt-6"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <Card style={{ width: 600, marginTop: 80 }}>
        <div className="is-flex is-flex-direction-column is-align-items-center">
          <img
            src={IMAGES.bank_circular_logo}
            alt="connect_bank_account"
            style={{ width: 100, height: 100 }}
          />
          <div className="title has-text-centered is-5 mt-6">
            Generate cashflow from your bank statement automatically
          </div>

          <PrimaryButton
            loading={loading}
            onClick={onUpsertBank}
            className="mt-4"
          >
            Add bank
          </PrimaryButton>
        </div>
      </Card>
    </div>
  );
};

export default NoBanksView;
