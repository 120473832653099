import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { COLORS } from "../../constants/theme";

function Loader() {
  return (
    <div>
      <LoadingOverlay
        loaderProps={{
          size: "xl",
          color: COLORS.greenBlue,
          variant: "oval",
        }}
        overlayOpacity={0.8}
        visible={true}
      />
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "58%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
          fontSize: "1.2rem",
          fontWeight: "bolder",
        }}
      >
        Loading...
      </div>
    </div>
  );
}

export default Loader;
