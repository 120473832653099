import { showNotification, updateNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import { configs } from "../constants";
import { getCashBurnOut, getXeroOpeningBalance } from "../modules";
import { getTheNextMonthByNumber } from "../modules/dashBoardModules";
import { getDateRangeMonths } from "../modules/getDateRangeMonths";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
export const getCashSummary = async () => {
  axios.defaults.withCredentials = true;
  const date = getDateRangeMonths();
  const { data } = await axios.post(
    configs.urls.BASE_URL + "/cashSummaryChart",
    {
      minDate: date.from,
      maxDate: date.to,
    }
  );

  // const { receivableInvoices: cashIn, payableInvoices: cashOut } = data;
  // const dataSet = cashIn.map((cashInv: any, index: number) => {
  //   return cashInv - cashOut[index];
  // });

  return data;
};

export const getBurnRate = async () => {
  const date = moment().format("yyyy-MM-DD");
  let openingBalance = await getXeroOpeningBalance(date, date);
  const prevMonthStart = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("yyyy-MM-DD");
  const prevMonthEnd = moment()
    .subtract(1, "months")
    .endOf("month")
    .format("yyyy-MM-DD");

  const prevMonth = moment().subtract(1, "months").format("MM YYYY");

  let cashBurnOut = await getCashBurnOut(prevMonthStart, prevMonthEnd);

  if (openingBalance && cashBurnOut) {
    const runwayval = Math.trunc(openingBalance / cashBurnOut);
    const lastRunwayMonth = getTheNextMonthByNumber(prevMonth, runwayval);

    return {
      cashBalance: Math.trunc(openingBalance),
      runWay: runwayval,
      months: runwayval,
      lastRunwayMonth: lastRunwayMonth,
      openingBalance: openingBalance,
    };
  }
};

export const payChartRequest = async () => {
  axios.defaults.withCredentials = true;
  const res = await axios.post(configs.urls.BASE_URL + "/recPayChart", {
    minDate: new Date("2022-04-01"),
    maxDate: new Date("2022-09-01"),
  });
  return res.data;
};

export const disconnectFromXero = () => {
  showNotification({
    id: "disconnect",
    loading: true,
    title: "Disconnecting...",
    message: "Please wait while we disconnect you from Xero",
    autoClose: false,
    disallowClose: true,
  });
  axios
    .get(configs.urls.BASE_URL + "/revoke-token", {
      withCredentials: true,
    })
    .then(
      (res) => {
        if (res.data) {
          updateNotification({
            id: "disconnect",
            color: "teal",
            message: "Disconnected Successfully!",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
      (err) => {
        updateNotification({
          id: "disconnect",
          color: "red",
          message: "Cannot Disconnect",
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      }
    );
};

export const connectXero = async () => {
  // e.preventDefault();
  axios.defaults.withCredentials = true;
  axios.post(configs.urls.BASE_URL + "/xero").then((res) => {
    const url = res.data;
    window.open(url, "_self");
  });
};
