import React, { useState } from "react";
import { CustomDrawer } from "../../../../common/CustomDrawer";
import { useAuth } from "../../../../contexts";
import AddRecord from "../../../Payments/components/AddRecord";
import AddRecordAndPayments from "../../../Payments/components/AddRecordAndPayments";
import { InvoiceTypes } from "../../../../constants/globalConstants";

enum AddRecordType {
  ADD_BILL = "ADD_BILL",
  ADD_PAYMENT = "ADD_PAYMENT",
}
function AddRecordDrawer({ drawer, showDrawer, recordType }: any) {
  const [paymentStep, setPaymentStep] = useState<string>(
    AddRecordType.ADD_BILL
  );
  const onClose = () => {
    showDrawer(false);
  };

  const renderContent = () => {
    return (
      <AddRecordAndPayments
        recordType={recordType}
        onSubmit={() => {
          showDrawer(false);
          setPaymentStep(AddRecordType.ADD_BILL);
        }}
        step={paymentStep}
        setStep={setPaymentStep}
      />
    );
  };

  return (
    <CustomDrawer
      opened={drawer}
      onClose={onClose}
      title={"Add a Record"}
      onBack={() => {
        if (paymentStep === AddRecordType.ADD_PAYMENT) {
          setPaymentStep(AddRecordType.ADD_BILL);
        } else {
          showDrawer(false);
        }
      }}
    >
      {renderContent()}
    </CustomDrawer>
  );
}

export default AddRecordDrawer;
