import moment from "moment";
import { Fragment, useRef, useState } from "react";
import styled from "styled-components";
import { LoadingPlaceholders } from "../../../common/LoadingPlaceholders";
import { ReportViewTypes } from "../../../constants/globalConstants";
import { COLORS } from "../../../constants/theme";
import { useResizableColumns } from "../../../hooks/useResizableColumns";
import { formatCurrency } from "../../../modules";
import { getUserLS } from "../../../modules/localStorage";
import { CashflowTitles } from "../constants";
import ShowDetailsDrawer from "./ShowDetails/ShowDetailsDrawer";
import { getPercentageDiff, getValueDiff } from "../../../modules/cashflow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

interface Props {
  id?: string;
  rowHeader: string;
  rowData?: (number | string)[];
  color?: string;
  bgColor?: string;
  paddingLeft?: string;
  headerMinWidth?: string;
  isWeeklyView?: boolean;
  isBold?: boolean;
  loading?: boolean;
  onTitleClick?: () => void;
  valuesType?: string[];
  presentDateIndex?: number;
  currentPeriod?: number;
  row?: any;

  compareTo?: (number | undefined)[];
  compareMode?: boolean;

  isViewOnly?: boolean;
}

function TableRow({
  id,
  rowHeader,
  rowData = [...Array(10).fill(0)],
  color = "black",
  bgColor = "#f5fcfb",
  headerMinWidth = "270px",
  isWeeklyView = false,
  paddingLeft = "5px",
  isBold = false,
  onTitleClick,
  loading,
  currentPeriod = -1,
  valuesType = [],
  presentDateIndex = -1,
  row,
  compareTo = [],
  compareMode = false,
  isViewOnly,
}: Props) {
  const getLoading = () => (
    <LoadingPlaceholders.Text
      active={true}
      inline
      width={80}
      height={25}
      style={{ borderRadius: "10%" }}
    />
  );

  const isOneOfMainRow = () =>
    rowHeader === CashflowTitles.ClosingBalance ||
    rowHeader === CashflowTitles.Unreconciled ||
    rowHeader === CashflowTitles.NetCashflow;

  const whichColor = (index: number, data: number) => {
    switch (valuesType[index]) {
      case "Calculated":
        return "#1D1D1D";
      case "Statement":
        return "orange";
      case "ESTIMATE":
        return "#60d5ce";
      case "Manual":
        if (presentDateIndex === -1) return "#408180";
        else return presentDateIndex < index ? "#691DB1" : "#408180";
      default:
        if (data == 0) return "#969696";

        if (data < 0) {
          if (onTitleClick) return color;
          else return "#C12E5B";
        } else return color;
    }
  };

  const whichColorForValue = (data: number) => {
    if (data == 0) return isOneOfMainRow() ? "#1D1D1D" : "#969696";
    else if (data < 0) {
      return "#C12E5B";
    }
    return color;
  };

  const whichTooltip = (valueType: string) => {
    switch (valueType) {
      case "ESTIMATE":
        return "Value from estimates";
      default:
        return "Value from commitments";
    }
  };

  const isValueBold = (data: number) => {
    if (data != 0) return "700";
    else return isBold ? "700" : "500";
  };

  // const isValueUnderlined = (data: number) => {
  //   if (data != 0) return "underline";
  //   else return undefined;
  // };

  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [widths, handleMouseDown] = useResizableColumns(headerRef, [150]);

  const isHeaderRow = rowHeader === CashflowTitles.CashflowStatement;

  const HeaderRow = ({ index, data }: { index: number; data: any }) => (
    <th
      className="p-4 "
      style={{
        color,
        position: "sticky",
        top: 0,
        textAlign: "end",
        // minWidth: "135px",
        fontWeight: "700",
        width: index === 0 ? "100%" : undefined,
        verticalAlign: "bottom",
        borderTop: `8px solid ${
          moment(data, ReportViewTypes[currentPeriod].format).isValid() &&
          moment(
            moment(data, ReportViewTypes[currentPeriod].format).format(
              "YYYY-MM-DD"
            )
          ).isBefore(new Date())
            ? "#66ceb6"
            : COLORS.veryLightPink
        }`,
        backgroundColor: "white",
      }}
      onMouseDown={(event) => handleMouseDown(event, index)}
    >
      {loading ? (
        getLoading()
      ) : (
        <Fragment>
          {isHeaderRow && data == 0 ? "-" : data}
          <br />
          <span style={{ fontSize: 11 }}>
            {isWeeklyView ? `(Week ${index + 1})` : ""}
          </span>
        </Fragment>
      )}
    </th>
  );

  const NormalRow = ({
    index,
    actualValue,
  }: {
    index: number;
    actualValue: any;
  }) => {
    let _compareTo = compareTo[index];
    let valueDiff = getValueDiff(actualValue, _compareTo);
    let precentDiff = getPercentageDiff(actualValue, _compareTo);
    return (
      <td
        className="p-4 "
        title={valuesType ? whichTooltip(valuesType[index]) : undefined}
        style={{
          color:
            (rowHeader === CashflowTitles.ClosingBalance || onTitleClick) &&
            valuesType
              ? whichColor(index, actualValue)
              : whichColorForValue(actualValue),
          textAlign: "end",
          minWidth: "135px",
          fontWeight: isValueBold(actualValue),
          // textDecoration: isValueUnderlined(data),
        }}
      >
        <ValueBox>
          {compareMode && _compareTo !== undefined ? (
            <Fragment>
              <Actual>
                <div
                  title="Value from saved version"
                  style={{ color: "#707070", fontWeight: "600" }}
                >
                  {formatCurrency(_compareTo || 0, 2)}
                </div>
                <div
                  title="Actual value"
                  style={{ color: "#000", fontWeight: "600" }}
                >
                  {loading ? getLoading() : formatCurrency(actualValue || 0, 2)}
                </div>
              </Actual>
              <Estimate>
                <Fragment>
                  <div
                    style={{
                      fontSize: 9,
                      color: "#707070",
                    }}
                  >
                    {/* {formatCurrency(valueDiff, 2)}{" "} */}
                  </div>
                  <div
                    title="Difference"
                    style={{
                      fontSize: 9,
                      color: "black",
                    }}
                  >
                    {formatCurrency(precentDiff, 2)}%{" "}
                    {/* <ArrowUpIcon fill="red" color="red" /> */}
                    {valueDiff !== 0 ? (
                      <FontAwesomeIcon
                        icon={valueDiff > 0 ? faArrowUp : faArrowDown}
                        color={valueDiff > 0 ? "green" : "red"}
                      />
                    ) : null}
                  </div>
                </Fragment>
              </Estimate>
            </Fragment>
          ) : (
            <div style={{ textAlign: "right" }}>
              {loading ? (
                getLoading()
              ) : (
                <Fragment>
                  <span>{formatCurrency(actualValue || 0, 2)}</span>
                </Fragment>
              )}
            </div>
          )}
        </ValueBox>
        {/*  */}
      </td>
    );
  };

  const ColumnHeader = () => (
    <th
      className="p-4"
      style={{
        minWidth: headerMinWidth,
        fontWeight: "700",
        paddingLeft,
        position: "sticky",
        left: 0,
        top: 0,
        backgroundColor: "white",
        width: `${widths[0]}px`,
        verticalAlign: isHeaderRow ? "bottom" : undefined,
        zIndex: isHeaderRow ? 4 : undefined,
        borderTop: isHeaderRow ? `8px solid #dbdbdb66` : undefined,
      }}
    >
      {onTitleClick ? (
        loading ? (
          getLoading()
        ) : (
          <GhostButton
            disabled={isViewOnly}
            onClick={() => handleOnTitleClick(id)}
          >
            {isHeaderRow ? "" : rowHeader}
          </GhostButton>
        )
      ) : (
        rowHeader
      )}
    </th>
  );

  type WhichSectionClicked = {
    type: string;
    bucket: string;
    name: string;
  };
  const [whichSectionClicked, setWhichSectionClicked] =
    useState<WhichSectionClicked>({} as WhichSectionClicked);

  const [selectedContactId, setSelectedContactId] = useState<any>(undefined);

  const handleOnTitleClick = (_id: any) => {
    if (id == _id) {
      setWhichSectionClicked({
        type: row.type,
        bucket: row.cashflowCategory,
        name: row.name,
      });
      setSelectedContactId(row.referenceId);
    }

    // setSearchParams({
    //   tab: searchParams.get("tab") || "forcast",
    //   selectedContactId: row.referenceId,
    // });
    console.log("row END", row.referenceId);
  };

  return (
    <Fragment>
      <tr
        style={{
          backgroundColor: isHeaderRow ? "white" : bgColor,
          height: isHeaderRow ? "60px" : undefined,
        }}
      >
        <ColumnHeader />

        {rowData?.map((data: number | string, index: number) =>
          isHeaderRow ? (
            <HeaderRow key={index} data={data} index={index} />
          ) : (
            <NormalRow key={index} actualValue={data} index={index} />
          )
        )}
      </tr>
      {onTitleClick ? (
        <ShowDetailsDrawer
          type={whichSectionClicked}
          selectedContactId={selectedContactId}
          setSelectedContactId={setSelectedContactId}
        />
      ) : null}
    </Fragment>
  );
}

export default TableRow;

const GhostButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0;
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: blueviolet;
  margin-left: 35px;

  text-align: left;
`;

const Actual = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* font-size: 11px; */
  gap: 10px;
`;

const Estimate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* font-size: 11px; */
`;
const ValueBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
