import {
  faEllipsisH,
  faPencilAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Dropdown, IconButton, Popover, Whisper } from "rsuite";
import Card from "../../../common/Card/Card";
import { LoadingPlaceholders } from "../../../common/LoadingPlaceholders";
import DetailsCardHeader from "../../../common/PageLayout/components/DetailsCardHeader";
import ShowDetailsCard from "../../../common/PageLayout/components/ShowDetailsCard";
import { IMAGES, StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { getAlpha2CodeForCountry } from "../../../modules/getAlpha2CodeForCountry";
import EditBeneficiary from "../components/EditBeneficiary";
import { useContactsContext } from "../contexts/ContactsContext";
import { Helmet } from "react-helmet";
import { MapCategory } from "../components/MapCategory";
import { If } from "../../../common/Utils/If";

const RightView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { externalAccounts, fetchExternalAccounts } = useAuth();
  const { onAddDisbursement, onEditDisbursement, onAddBankAccount } =
    useContactsContext();

  const selectedContactId = searchParams.get("selectedContactId");
  const [selectedContact, setSelectedContact] = useState<any>();
  const [editable, setEditable] = useState<boolean>(false);
  const [editMapping, setEditMapping] = useState<boolean>(false);

  useEffect(() => {
    const contactIdFromStorage = localStorage.getItem("selectedContactId");
    if (
      (selectedContactId || contactIdFromStorage) &&
      externalAccounts?.length > 0
    ) {
      setLoading(true);

      if (selectedContactId) {
        const contact = externalAccounts?.find(
          (record: any) => record.id == selectedContactId
        );
        if (contact) {
          setSelectedContact(contact);
          setSearchParams({
            tab: "vendor",
            selectedContactId: selectedContactId,
          });
        } else {
          setSelectedContact(externalAccounts[0]);
          setSearchParams({
            tab: "vendor",
            selectedContactId: externalAccounts[0].id,
          });
        }
      } else {
        setSelectedContact(
          externalAccounts?.find(
            (record: any) => record.id == contactIdFromStorage
          )
        );
      }
      setEditable(false);
      setEditMapping(false);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setSelectedContact(undefined);
    }
  }, [selectedContactId, externalAccounts]);

  const [loading, setLoading] = useState<boolean>(false);

  const renderTopHeader = () => (
    <StyledComponents.ListItemCardHeaderContainer>
      {_.isEmpty(selectedContact) || loading ? (
        <span className="mr-3">
          <LoadingPlaceholders.Avatar
            style={{ marginBottom: -5, marginLeft: 3 }}
            active={loading}
          />
        </span>
      ) : (
        <StyledComponents.ImageOnListItemCard
          src={IMAGES.company_logo_placeholder}
        />
      )}

      <StyledComponents.NameOnListItemCard>
        {_.isEmpty(selectedContact) || loading ? (
          <LoadingPlaceholders.Text
            style={{ marginBottom: -5, marginLeft: 3 }}
            active={loading}
          />
        ) : (
          selectedContact?.displayName
        )}
      </StyledComponents.NameOnListItemCard>
    </StyledComponents.ListItemCardHeaderContainer>
  );

  const renderCard = () => (
    <Fragment>
      <Card
        className="details-card-container contacts"
        style={{
          border: `1px solid ${COLORS.veryLightPink}`,
        }}
      >
        <DetailsCardHeader hasButton={false} title="Contact Details" />

        <EditBeneficiary
          selectedContact={selectedContact}
          editable={editable}
          setEditable={setEditable}
          isContactLoading={loading}
          reFetchExternalAccounts={fetchExternalAccounts}
        />
      </Card>

      <Card
        className="details-card-container contacts"
        style={{
          border: `1px solid ${COLORS.veryLightPink}`,
        }}
      >
        <DetailsCardHeader
          hasButton={false}
          title="Map Contact to Cashflow Categories"
        />
        <If
          condition={
            selectedContact?.cashflowCategoryMappings.length > 0 && !loading
          }
        >
          <MapCategory
            mode={editMapping ? "edit" : "view"}
            mappings={selectedContact?.cashflowCategoryMappings}
            contactTypes={selectedContact?.types}
            showTitle={false}
            editableInContactsSection={true}
            toggleView={(value: boolean) => setEditMapping(value)}
            contact={selectedContact}
          />
        </If>
      </Card>
      {/* {selectedContact?.types.length === 1 &&
      selectedContact?.types[0] === "Customer" ? null : (
        <Card
          className="details-card-container"
          style={{
            border: `1px solid ${COLORS.veryLightPink}`,
          }}
        >
          <DetailsCardHeader
            hasButton={true}
            buttonTitle="Add"
            buttonAction={() => {
              onAddDisbursement(selectedContact);
            }}
            title="Disbursement Methods"
          />

          {selectedContact?.disbursements?.map((disbursement: any) => {
            let bankName = disbursement?.banks![0]?.bankName;

            return (
              <Card
                style={{ border: `1px solid ${COLORS.veryLightPink}` }}
                isNormal
                noShadow
                className="mb-5"
              >
                <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center">
                  <div className="is-flex is-flex-direction-row is-align-items-center">
                    <StyledComponents.ImageOnListItemCard
                      src={IMAGES.mini_bank_logo}
                    />
                    <StyledComponents.NameOnListItemCard
                      style={{ fontWeight: "600" }}
                    >
                      {disbursement?.fullName}
                    </StyledComponents.NameOnListItemCard>
                  </div>
                  <div className="ml-3">
                    <Whisper
                      placement="bottomEnd"
                      trigger="click"
                      speaker={({ onClose, left, top, className }, ref) => {
                        const handleSelect = (eventKey: any) => {
                          switch (eventKey) {
                            case 1:
                              onAddBankAccount(selectedContact, disbursement);
                              onClose();
                              break;
                            case 2:
                              onEditDisbursement(
                                selectedContact,
                                disbursement,
                                disbursement.banks
                              );
                              onClose();
                              break;
                            // case 3:
                            // showDetails(disbursement);
                            default:
                              onClose();
                          }
                        };
                        return (
                          <Popover
                            ref={ref}
                            className={className}
                            style={{ left, top }}
                            full
                          >
                            <Dropdown.Menu onSelect={handleSelect}>
                              <Dropdown.Item eventKey={1}>
                                <FontAwesomeIcon icon={faPlus} />
                                <span className="ml-2">Add Bank </span>
                              </Dropdown.Item>
                              <Dropdown.Item eventKey={2}>
                                <FontAwesomeIcon icon={faPencilAlt} />
                                <span className="ml-2">Edit</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Popover>
                        );
                      }}
                    >
                      <IconButton
                        className="button"
                        style={{
                          backgroundColor: "#2C6C76",
                          color: "white",
                          borderRadius: 10,
                        }}
                        icon={<FontAwesomeIcon icon={faEllipsisH} />}
                      ></IconButton>
                    </Whisper>
                  </div>
                </div>
                {disbursement?.banks?.map((bank: any) => {
                  let iban = bank?.accounts![0]?.iban;

                  // format iban with spaces and to upper case

                  // remove space from iban
                  iban = iban?.replace(/\s/g, "");
                  // shrink iban with ...
                  if (iban?.length > 15) {
                    iban = ` <abbr style="text-decoration: none !important;" title=${iban}><span>${iban?.slice(
                      0,
                      4
                    )}
                  ...
                  ${iban?.slice(iban.length - 4)}</span></abbr> `;
                  }
                  let country = bank.address.country;

                  return (
                    <div
                      className="mt-3"
                      style={{ fontSize: "20px", color: COLORS.brownishGrey }}
                    >
                      <span
                        className={`fi fi-${getAlpha2CodeForCountry(
                          country || "United Arab Emirates"
                        )} m-2`}
                      ></span>{" "}
                      | {bank.bankName} | {/* IBAN: AE02…6500
                      <span dangerouslySetInnerHTML={{ __html: iban }}></span>
                    </div>
                  );
                })}
              </Card>
            );
          })}
        </Card>
      )}
    */}
      {/* <Card
        style={{
          border: `1px solid ${COLORS.dustyOrange}`,
          padding: "30px 32px",
          position: "relative",
        }}
      >
        <DetailsCardHeader
          hasButton
          buttonDisabled={_.isEmpty(selectedContact) || loading}
          buttonTitle="Details"
          buttonAction={() => {}}
          title="Payments Overview"
        />

        <LabelValue
          label={"Open Balance"}
          value={"AED 0.00"}
          loading={loading}
          placeholder={_.isEmpty(selectedContact)}
          labelUnderValue="0 Unpaid Bills"
        />
        <LabelValue
          label={"Scheduled"}
          value={"AED 0.00"}
          loading={loading}
          placeholder={_.isEmpty(selectedContact)}
          labelUnderValue="0 Payment(s)"
        />

        <LabelValue
          label={"Paid"}
          value={"AED 0.00"}
          loading={loading}
          placeholder={_.isEmpty(selectedContact)}
          labelUnderValue="0 Payment(s)"
        />
      </Card> */}
    </Fragment>
  );

  return (
    <Fragment>
      <Helmet>
        <title>{selectedContact?.displayName}</title>
      </Helmet>
      <ShowDetailsCard
        renderTopHeader={renderTopHeader}
        renderCard={renderCard}
      />
    </Fragment>
  );
};

export default RightView;
