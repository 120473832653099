import { Drawer } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ListOfRecords from "./ListOfRecords";

function ShowDetailsDrawer({
  type,
  setSelectedContactId,
  selectedContactId,
}: any) {
  // const [searchParams, setSearchParams] = useSearchParams();

  const onClose = () => {
    setSelectedContactId(null);
  };
  // useEffect(() => {
  //   console.log("selectedContactId", selectedContactId);
  //   console.log("type", type);
  // }, [selectedContactId, type]);

  return (
    <Drawer
      opened={selectedContactId}
      onClose={onClose}
      size="40%"
      position="right"
      withCloseButton={false}
      style={{ height: "100vh" }}
      zIndex={999}
    >
      <div
        style={{
          position: "relative",
        }}
        className="custom-scrollbar"
      >
        <ListOfRecords
          onClose={onClose}
          type={type}
          selectedContactId={selectedContactId}
        />
      </div>
    </Drawer>
  );
}

export default ShowDetailsDrawer;
