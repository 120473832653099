import axios from "axios";
import { config } from "dotenv";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { useSearchParams } from "react-router-dom";
import { configs } from "../../../constants";
import { getAllOrganizationsOfCurrentUser } from "../../../repositories/userRepository";

export const myContext = createContext<any>({});

export function useCompaniesContext() {
  return useContext(myContext);
}
const COMPANIES = [
  {
    id: 2,
    name: "Test Company",
    industry: "Software",
    status: "Test account",
  },
  {
    id: 3,
    name: "The Scalable CFO",
    industry: "Finance",
    status: "inactive",
  },
];

export default function CompaniesContext(props: PropsWithChildren<any>) {
  const [companies, setCompanies] = useState<any>(COMPANIES);

  const getCurrentUserOrg = async () => {
    const { organizations } = await getAllOrganizationsOfCurrentUser();
    if (!organizations && !organizations.length) {
      return;
    }
    for (let organization of organizations) {
      const { id: orgId, name: orgName, industry, status } = organization;
      const alreadyExist = companies.find(
        ({ id, name }: any) => id === orgId && name === orgName
      );

      if (alreadyExist) {
        return;
      }
      const newCompany = {
        id: orgId,
        name: orgName,
        industry,
        status,
      };
      setCompanies([...companies, { ...newCompany }]);
    }
  };

  useEffect(() => {
    getCurrentUserOrg();
  }, []);

  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get("tab") || "all";
  let filteredCompanies = companies;

  if (activeTab === "all") {
    filteredCompanies = companies;
  } else {
    filteredCompanies = companies.filter(
      (company: any) => company.status === activeTab
    );
  }

  return (
    <myContext.Provider value={{ filteredCompanies }}>
      {props.children}
    </myContext.Provider>
  );
}
