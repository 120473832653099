import { useEffect, useState } from "react";
import { getAllForecasts } from "../repositories/financialProjection";
import formatEstimates from "../modules/formatEstimates";
import { formatCashflowMappingInInvoices } from "../modules/formatCashflowMapping";
import moment from "moment";

export function useFinancialProjection() {
  const [forecasts, setForecasts] = useState<any[]>([]);

  const fetchForecasts = async (
    filterObject?: any,
    sortOrder?: string,
    sortField?: string
  ) => {
    const { data, success } = await getAllForecasts(
      filterObject,
      sortOrder,
      sortField
    );

    if (success) {
      const formattedRecords = formatCashflowMappingInInvoices(data);
      let records: any[] = formatEstimates(formattedRecords);

      if (sortField === "issueDate" || sortField === "dueDate") {
        setForecasts(
          records.sort((a, b) =>
            sortOrder === "ASC"
              ? (moment(a.dueDate).toDate() as any) -
                (moment(b.dueDate).toDate() as any)
              : (moment(b.dueDate).toDate() as any) -
                (moment(a.dueDate).toDate() as any)
          )
        );
      } else {
        setForecasts(records);
      }
    } else {
      setForecasts([]);
    }
  };

  useEffect(() => {
    fetchForecasts();
  }, []);

  return {
    forecasts,
    setForecasts,
    fetchForecasts,
  };
}
