import "./styles.css";

function SkipButton({ handleOnClick }: any) {
  return (
    <div className="skip-button-container">
      <button onClick={handleOnClick} className="skip-button">
        Skip
      </button>
    </div>
  );
}

export default SkipButton;
