import { Progress, RingProgress, Text } from "@mantine/core";
import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

interface ProgressBarCircularType {
  text: string;
  value: number;
}

function ProgressBarCircular({ text, value }: ProgressBarCircularType) {
  return (
    <Example>
      {/* <CircularProgressbar
        value={Math.abs(value)}
        text={`${text}`}
        styles={buildStyles({
          textSize: "13px",
          textColor: "#000000",
          pathColor: "#00d28f",
          trailColor: "#969696",
        })}
      /> */}

      <RingProgress
        size={window.innerWidth / 9}
        thickness={window.innerWidth / 130}
        label={
          <Text
            align="center"
            size={"md"}
            px="xs"
            sx={{
              pointerEvents: "none",
              fontWeight: "bold",
              fontFamily: "Poppins",
            }}
          >
            {text}
          </Text>
        }
        sections={[
          {
            value: Math.abs(value),
            color: "#00d28f",
          },
          {
            value: 100 - Math.abs(value),
            color: "#969696",
          },
        ]}
      />
    </Example>
  );
}

export const Example = (props: any) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
};

export default ProgressBarCircular;
