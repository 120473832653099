import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import AddBill from "./AddBill";
import ManualBill from "./ManualBill";
import AddBeneficiary from "./AddBeneficiary";
import ConnectBank from "./ConnectBank";
import ReviewAndPay from "./ReviewAndPay";
import { usePaymentsContext } from "../contexts/PaymentsContext";
import { Drawer } from "@mantine/core";
import { COLORS } from "../../../constants/theme";
import UploadBill from "./UploadBill";
import { StyledComponents } from "../../../constants";
import SyncInDrawer from "../../../common/SyncAccountingSoftware/SyncInDrawer";
import AddRecordAndPayments from "./AddRecordAndPayments";

enum AddRecordType {
  ADD_BILL = "ADD_BILL",
  ADD_PAYMENT = "ADD_PAYMENT",
}

function InitiatePayments() {
  const { stepDown, step, showDrawer, drawer, setStep } = usePaymentsContext();
  const [paymentStep, setPaymentStep] = useState<string>(
    AddRecordType.ADD_BILL
  );

  const [flow, setFlow] = React.useState<string>("add-bill");

  const whichStep = () => {
    switch (step) {
      case 0:
        return <SyncInDrawer />;
      case 1:
        return <AddBill setFlow={setFlow} />;
      case 2:
        return flow === "add-bill" ? (
          <AddRecordAndPayments
            recordType={"Payable"}
            onSubmit={() => {
              showDrawer(false);
              setPaymentStep(AddRecordType.ADD_BILL);
            }}
            step={paymentStep}
            setStep={setPaymentStep}
          />
        ) : (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <UploadBill />
          </div>
        );
      case 3:
        return <AddBeneficiary />;
      case 4:
        return <ConnectBank />;
      case 5:
        return <ReviewAndPay />;
      default:
        return <div>Something went wrong</div>;
    }
  };

  const whichTitle = () => {
    switch (step) {
      case 0:
        return "Sync Accounting Software";
      case 1:
        return "Add a Bill to Pay";
      case 2:
        return "Enter Bill Details";
      case 3:
        return "Add vendor as a beneficiary";
      case 4:
        return "Connect your bank account to make a payment";
      case 5:
        return "Review & Confirm";
      default:
        return "Page Title";
    }
  };

  return (
    <Drawer
      zIndex={10}
      className="custom-scrollbar "
      lockScroll
      styles={{
        header: { display: "none" },
        drawer: { overflow: "auto" },
      }}
      position="bottom"
      opened={drawer}
      onClose={() => showDrawer(false)}
      size="full"
    >
      <StyledComponents.DrawerContainer>
        <StyledComponents.DrawerHeader>
          <StyledComponents.DrawerButtonContainer
            variant="default"
            onClick={() => {
              if (paymentStep === AddRecordType.ADD_PAYMENT) {
                setPaymentStep(AddRecordType.ADD_BILL);
              } else {
                if (step === 1) {
                  showDrawer(false);
                } else if (step === 0) {
                  setStep(1);
                } else {
                  stepDown();
                }
              }
            }}
            icon={
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ width: 30, height: 30, color: COLORS.veryLightPink }}
              />
            }
          />

          <StyledComponents.DrawerModalTitle className="mt-5">
            {whichTitle()}
          </StyledComponents.DrawerModalTitle>
          <StyledComponents.DrawerButtonContainer
            variant="default"
            onClick={() => showDrawer(false)}
            icon={
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: 30, height: 30, color: COLORS.veryLightPink }}
              />
            }
          />
        </StyledComponents.DrawerHeader>

        <StyledComponents.DrawerContent>
          {whichStep()}
        </StyledComponents.DrawerContent>
      </StyledComponents.DrawerContainer>
    </Drawer>
  );
}

export default InitiatePayments;
