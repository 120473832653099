import React from "react";
import CardButton from "../../CardButton/CardButton";
import styled from "styled-components";

interface ZeroScreenProps {
  setStep: (step: "ADD_SCENARIO" | "DUPLICATE_SCENARIO") => void;
}

function ZeroScreen(props: ZeroScreenProps) {
  const { setStep } = props;

  return (
    <div
      className="is-flex is-flex-direction-column is-align-items-center"
      style={{
        width: "100%",
        marginTop: "5rem",
      }}
    >
      <CardButton
        cardStyles={{
          padding: 25,
        }}
        title="Create a brand new scenario"
        description={`A cashflow scenario helps you build a cashflow forecast that represents a scenario that may occur in your business like the impact of cash on hiring a new employee, fundraising and much more`}
        onClick={() => {
          setStep("ADD_SCENARIO");
        }}
      />
      <Separator>OR</Separator>
      <CardButton
        cardStyles={{
          padding: 25,
        }}
        title="Duplicate an existing scenario"
        description={`This copies all your contacts, commitments (i.e. bills and invoices), estimates and category mappings from an existing scenario into a brand new scenario that you can modify to create an adjusted scenario like duplicating your main cashflow scenario to a new one and increasing sales to 10%
        `}
        onClick={() => {
          setStep("DUPLICATE_SCENARIO");
        }}
      />
    </div>
  );
}

export default ZeroScreen;

const Separator = styled.div`
  margin: 20px;
  font-size: 20px;
`;
