import axios from "axios";
import { times, constant } from "lodash";
import moment from "moment";
import { map } from "rsuite/esm/utils/ReactChildren";
import { appTheme, configs } from "../constants";
import { PROVIDERS } from "../constants/globalConstants";
import {
  getQBCashSummary,
  getQbProfitAndLossReport,
  getQbProfitAndLossSummaryReport,
  qbPayChartRequest,
} from "../repositories/quickbooksRepository";
import { getBurnRate, getCashSummary } from "../repositories/xeroRepository";
import { formatCurrency } from "./formatCurrency";
import {
  getAllExpensesFromQb,
  getAllRowsFromQb,
  getDateRangeMonths,
  getMonthNameFromNumber,
  getValueRangesFromQb,
  getValuesFromObjectArray,
} from "./getDateRangeMonths";
import { getZohoProfitAndLossReport } from "../repositories/zohoRepository";

export const getReportPNL = async () => {
  let dateRange: any[] = [];
  let pnl_report: any = {};
  // console.log("res.data in useEff Dashb1");
  // const org = await axios.get(configs.urls.BASE_URL + "/organisation", {
  //   withCredentials: true,
  // });

  // const resp = await axios.get(configs.urls.BASE_URL + "/getDateRange", {
  //   withCredentials: true,
  // });

  const date = getDateRangeMonths();
  dateRange = [date.from, date.to];

  // console.log("res.data in useEff Dashb4");
  //Now get report
  if (dateRange[0] && dateRange[1]) {
    const res = await axios.post(
      configs.urls.BASE_URL + "/pnl",
      {
        fromDate: date.from,

        toDate: date.to,
      },
      { withCredentials: true }
    );

    if (res.data !== "error") {
      const pnlReport = res.data![0];
      console.log("The PnL Report: ", pnlReport);

      pnl_report = pnlReport;
    }
  } else {
    console.log("PLEASE PICK DATE RANGE!");
  }

  return {
    dateRange,
    pnlReport: pnl_report,
    months: date.months,
  };
};

export const setPNLReport = async (pnlReport: any) => {
  // ? Set income Periodic Data
  const incomePeriodicDataTemp = [];
  const expensesPeriodicDataTemp = [];
  const profitPeriodicDataTemp = [];

  const headers = pnlReport?.rows?.find(
    (i: any) => i.rowType === "Header"
  )?.cells;

  const monthlyIncome =
    pnlReport?.rows
      ?.find((i: any) => i.title === "Income")
      ?.rows?.find((e: any) => e.rowType === "SummaryRow").cells || [];

  const monthlyOperatingExpense =
    pnlReport?.rows
      ?.find((e: any) => e.title === "Less Operating Expenses")
      ?.rows.find((e: any) => e.rowType === "SummaryRow").cells || [];

  const monthlyCostOfSalesExpense =
    pnlReport?.rows
      ?.find((e: any) => e.title === "Less Cost of Sales")
      ?.rows?.find((e: any) => e.rowType === "SummaryRow").cells || [];

  if (headers) {
    for (let i = 1; i < headers.length; i++) {
      const header =
        headers[i].value.split(" ")[1] + " " + headers[i].value.split(" ")[2];
      incomePeriodicDataTemp.push({
        x: header,
        y:
          monthlyIncome.length !== 0
            ? Math.round(parseFloat(monthlyIncome[i].value))
            : 0,
      });

      // ? Set expense Periodic Data
      const totalExpense =
        (monthlyOperatingExpense.length !== 0
          ? parseFloat(monthlyOperatingExpense[i]?.value)
          : 0) +
        (monthlyCostOfSalesExpense.length !== 0
          ? parseFloat(monthlyCostOfSalesExpense[i]?.value)
          : 0);

      expensesPeriodicDataTemp.push({
        x: header,
        y: Math.round(totalExpense),
      });

      // ? Set profit Periodic Data
      profitPeriodicDataTemp.push({
        x: header,
        y: Math.round(parseFloat(monthlyIncome![i]?.value || 0) - totalExpense),
      });
    }
  }
  // Set income
  console.log(pnlReport.rows);
  const inc =
    pnlReport?.rows
      ?.find((i: any) => i.title === "Income")
      ?.rows?.find((e: any) => e.rowType === "SummaryRow").cells[1].value || 0;
  const _income = Math.round(inc)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Set expense
  const operatingExpenses =
    pnlReport?.rows
      ?.find((e: any) => e.title === "Less Operating Expenses")
      ?.rows.find((e: any) => e.rowType === "SummaryRow").cells[1].value || 0;

  const costOfSalesExpenses =
    pnlReport?.rows
      ?.find((e: any) => e.title === "Less Cost of Sales")
      ?.rows.find((e: any) => e.rowType === "SummaryRow").cells[1].value || 0;

  const _expense = Math.round(
    parseFloat(costOfSalesExpenses) + parseFloat(operatingExpenses)
  )
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Set profit
  const _profit = Math.round(
    parseFloat(inc) -
      (parseFloat(costOfSalesExpenses) + parseFloat(operatingExpenses))
  )
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // get categories
  const categories =
    pnlReport?.rows?.find((e: any) => e.title === "Less Operating Expenses")
      ?.rows || [];

  const categoricalDataTemp = [];

  if (categories.length > 9) {
    let others = 0;
    for (let i = 0; i < categories.length - 1; i++) {
      if (i > 8) {
        others += parseFloat(categories[i].cells[1].value);
      } else {
        categoricalDataTemp.push({
          x: categories[i].cells[0].value,
          y: parseFloat(categories[i].cells[1].value),
          fill: appTheme.VICTORY_PIE_COLORS[i],
        });
      }
    }
    categoricalDataTemp.push({
      x: "Others",
      y: parseFloat(others.toFixed(2)),
      fill: appTheme.VICTORY_PIE_COLORS[9],
    });
  } else {
    for (let i = 0; i < categories.length - 1; i++) {
      categoricalDataTemp.push({
        x: categories[i].cells[0].value,
        y: categories[i].cells[1].value,
        fill: appTheme.VICTORY_PIE_COLORS[i],
      });
    }
  }

  const incomePeriodic = getValuesFromObjectArray(
    [...incomePeriodicDataTemp],
    "y"
  );
  const expensePeriodic = getValuesFromObjectArray(
    [...expensesPeriodicDataTemp],
    "y"
  );
  const profitPeriodic = getValuesFromObjectArray(
    [...profitPeriodicDataTemp],
    "y"
  );

  const categoricData = getValuesFromObjectArray(categoricalDataTemp, "y");
  const categoricDataLabels = categoricalDataTemp.map(
    ({ x, y }) => `${x} $${Math.round(y)}`
  );

  const burnRate = await getBurnRate();
  return {
    reportDate: pnlReport.reportTitles?.[2],
    incomePeriodicData: incomePeriodic.reverse(),
    expensesPeriodicData: expensePeriodic.reverse(),
    profitPeriodicData: profitPeriodic.reverse(),
    income: _income,
    expense: _expense,
    profit: _profit,
    categoricalData: categoricData,
    categoricDataLabels: categoricDataLabels,
    ...burnRate,
  };
};

export const fillArrayWithZeros = (
  array: any[],
  arrayLength: number,
  fill: any
) => {
  let arr = times(arrayLength, constant(fill));
  return arr.map((val, index) => {
    if (array[index]) {
      return array[index];
    }

    return val;
  });
};

export const getTheNextMonthByNumber = (
  currentMonth: any,
  addMonths: number
) => {
  const resultMonth = moment(currentMonth).add(addMonths).format("MM YYYY");

  const [month, year] = resultMonth?.split(" ");
  const monthName = getMonthNameFromNumber(resultMonth);
  return `${monthName} ${year}`;
};

export const getQuickBooksPNLReport = async (startingFrom: string) => {
  let dateRange: any[] = [];
  let pnl_report: any = {};
  let summary_pnl_report: any = {};

  const date = getDateRangeMonths();
  dateRange = [date.from, date.to];
  axios.defaults.withCredentials = true;

  const { success, response } = await getQbProfitAndLossReport(
    date.from,
    date.to
  );
  const { success: summarySuccess, response: summaryRes } =
    await getQbProfitAndLossSummaryReport(date.from, date.to);

  if (success) {
    const pnlReport = response;
    console.log("The PnL Report: ", pnlReport);

    pnl_report = pnlReport;
  }
  if (summarySuccess) {
    summary_pnl_report = summaryRes;
  }
  return {
    dateRange,
    pnlReport: pnl_report,
    months: date.months,
    summaryPnlReport: summary_pnl_report,
  };
};

export const setQBReport = async (qbReport: any, summaryPnlReport: any) => {
  const rows = qbReport?.Rows?.Row;
  const summaryRows = summaryPnlReport?.Rows?.Row;
  let incomeRange = getValueRangesFromQb(rows, "Income");
  let expenseRange = getValueRangesFromQb(rows, "Expenses");

  const expenses = getAllRowsFromQb(summaryRows, "Expenses");
  const allExpenses = getAllExpensesFromQb(expenses);
  const netIncomeRange = incomeRange.map(
    (inc: any, index: number) => inc - expenseRange[index]
  );

  const income = formatCurrency(eval(incomeRange.join("+")), 2);
  const expense = formatCurrency(eval(expenseRange.join("+")), 2);
  const netIcome = formatCurrency(eval(netIncomeRange.join("+")), 2);

  const categoricData = allExpenses.map((expense: any) => {
    return Object.values(expense)?.[0];
  });

  const categoricDataLabels = allExpenses.map((expense: any) => {
    const value = Object.values(expense)?.[0];
    const key = Object.keys(expense)?.[0];
    return `${key} $${formatCurrency(Number(value), 2)}`;
  });

  return {
    reportDate: "reporttt",
    categoricalData: categoricData,
    categoricDataLabels: categoricDataLabels,
    incomePeriodicData: incomeRange,
    expensesPeriodicData: expenseRange,
    profitPeriodicData: netIncomeRange,
    income,
    expense,
    profit: netIcome,
  };
};

export const fetchCashSummary = (provider: string) => {
  // if (provider === PROVIDERS.XERO) {
  return getCashSummary();
  // }
  // const date = getDateRangeMonths();
  // return getQBCashSummary(date.from, date.to);
};

export const fetchBurnRate = async (
  avgBurnRate: number,
  avgCashIn: number,
  openingBalance: number,
  closingBalance: number
) => {
  // if (provider === PROVIDERS.XERO) {
  //   openingBalance = (await getXeroOpeningBalance(date, date)) || 0;
  // } else if (provider === PROVIDERS.QUICKBOOKS) {
  //   openingBalance = (await getQboOpeningBalance(date, date)) || 0;
  // }
  let runWay = Math.abs(Math.trunc(closingBalance / avgBurnRate));
  let netRunWay = Math.abs(
    Math.trunc(closingBalance / (avgBurnRate - avgCashIn))
  );

  if (openingBalance > 0 && avgBurnRate) {
    const runwayval = Math.abs(runWay);
    const netRunway = Math.trunc(closingBalance / (avgBurnRate - avgCashIn));
    const netRunwayVal = Math.abs(netRunway);

    const lastRunwayMonth = moment().add(runwayval, "M").format("MM YYYY");

    const lastNetRunwayMonth = moment()
      .add(netRunwayVal, "M")
      .format("MM YYYY");

    return {
      cashBalance: Math.trunc(openingBalance),
      runWay: runwayval || 0,
      months: runwayval,
      lastRunwayMonth: getMonthNameFromNumber(lastRunwayMonth),
      openingBalance: openingBalance,
      netRunway: netRunwayVal,
      lastNetRunwayMonth: getMonthNameFromNumber(lastNetRunwayMonth),
    };
  }
  const lastRunwayMonth = moment().add(runWay, "M").format("MM YYYY");
  const lastNetRunwayMonth = moment().add(netRunWay, "M").format("MM YYYY");

  return {
    cashBalance: Math.trunc(openingBalance),
    runWay: runWay,
    months: runWay,
    lastRunwayMonth: getMonthNameFromNumber(lastRunwayMonth),
    openingBalance: 0,
    netRunway: netRunWay,
    lastNetRunwayMonth: getMonthNameFromNumber(lastNetRunwayMonth),
  };
};

export const getZohoPNLReport = async (baseCurrency: string) => {
  let dateRange: any[] = [];
  // let pnl_report: any = {};
  // let summary_pnl_report: any = {};

  const date = getDateRangeMonths();
  dateRange = [date.from, date.to];

  const pnlReport = await getZohoProfitAndLossReport(date.from, date.to);

  console.log(pnlReport);
  return {
    dateRange,
    pnlReport,
    months: date.months,
  };
};

const groupBy = (array: any[], property: string | number) =>
  array.reduce(
    (
      grouped: { [x: string]: any },
      element: { [x: string]: string | number }
    ) => ({
      ...grouped,
      [element[property]]: [...(grouped[element[property]] || []), element],
    }),
    {}
  );

export const setZohoPNLReport = async (pnlReport: any) => {
  const { income, expenses } = pnlReport;

  const incomeRange = getValuesFromObjectArray(income, "amount");
  const expenseRange = getValuesFromObjectArray(expenses, "amount");
  const netIncomeRange = incomeRange.map(
    (inc: any, index: number) => inc - expenseRange[index]
  );
  console.log(incomeRange);
  console.log(expenseRange);
  console.log(netIncomeRange);
  const totalIncome = formatCurrency(eval(incomeRange.join("+")), 2);
  const totalExpense = formatCurrency(eval(expenseRange.join("+")), 2);
  const netIcome = formatCurrency(eval(netIncomeRange.join("+")), 2);

  const groupedExpenses = groupBy(expenses, "account_name");
  const categoricalData = [];
  const categoricalDataLabels = [];
  console.log(groupedExpenses);

  const summaryExpenses: { [type: string]: number } = {};
  for (const expenseType in groupedExpenses) {
    if (!summaryExpenses[expenseType]) {
      summaryExpenses[expenseType] = 0;
      categoricalDataLabels.push(expenseType);
      for (const expense of groupedExpenses[expenseType]) {
        summaryExpenses[expenseType] += expense.amount;
      }
      categoricalData.push(summaryExpenses[expenseType]);
    }
  }
  console.log(categoricalDataLabels);
  return {
    incomePeriodicData: incomeRange,
    expensePeriodicData: expenseRange,
    profitPeriodicData: netIncomeRange,
    income: totalIncome,
    expense: totalExpense,
    profit: netIcome,
    categoricalData,
    categoricalDataLabels,
  };
};
