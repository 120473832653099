import "./styles.css";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { tscLogoWithName } from "../../constants/images";
import { changePassword } from "../../repositories/userRepository";

function ChangePasswordForSession() {
  const navigate = useNavigate();
  const { jwt } = useParams();

  const [hasShadow, showShadow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      showNotification({
        color: "red",
        message: "Passwords do not match!",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    } else {
      const updated = await changePassword(jwt, password);
      if (updated) {
        showNotification({
          color: "green",
          message: "Password Updated Successfully! Login using new password",
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
        setPasswordUpdated(true);
      } else {
        showNotification({
          color: "red",
          message: "Password update link expired",
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
        navigate("/login");
      }
    }
  };

  const redirectToSignIn = () => {
    navigate("/login");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        onMouseOver={() => showShadow(true)}
        onMouseOut={() => showShadow(false)}
        className={`card-inner-container ${hasShadow ? "card" : ""}`}
        style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
      >
        <div className="card-content">
          <div className="content">
            <div className="logo-container">
              <img src={tscLogoWithName} className="logo"></img>
            </div>
            {passwordUpdated ? (
              <>
                <p className=" password-updated">
                  Your password has been changed.
                </p>

                <button
                  type="submit"
                  className="button-image"
                  onClick={redirectToSignIn}
                >
                  <span>Continue</span>
                </button>
              </>
            ) : (
              <>
                <p className="already-have-account">Enter new password</p>
                <form onSubmit={onSubmit}>
                  <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                      <input
                        required
                        className="input"
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Confirm Password</label>
                    <div className="control">
                      <input
                        required
                        className="input"
                        type="password"
                        placeholder="Confirm Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <button type="submit" className="button-image">
                    <span>Confirm</span>
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordForSession;
