import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../../common/Card/Card";
import PrimaryButton from "../../../common/PrimaryButton/PrimaryButton";

function InviteTeamMembers() {
  const navigate = useNavigate();

  return (
    <Card
      isNormal
      style={{
        borderRadius: "10px",
        padding: "25px 30px",
      }}
      className="mt-6"
    >
      <div className="is-flex is-flex-direction-row p-2 is-justify-content-space-between">
        <div>
          <div className="title is-6">Invite Team Members</div>
          <div className="subtitle is-6 mt-3">
            Enter a team member's email address and give them a role.
          </div>
        </div>
        {/* <PrimaryButton
          onClick={() => alert("Coming Soon!")}
          style={{ width: "230px" }}
        >
          Coming soon
        </PrimaryButton> */}
        <PrimaryButton
          onClick={() => navigate("/settings?active=collabrator")}
          style={{ width: "230px" }}
        >
          Invite Team Members
        </PrimaryButton>
      </div>
    </Card>
  );
}

export default InviteTeamMembers;
