import "./styles.css";
import React from "react";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <div className="title page-title main-heading">
        Madi Data Privacy Policy
      </div>

      <p>
        The personal data that directly (full name, postal address, email
        address, telephone number, etc.) or indirectly identifies any person
        browsing the Website (as defined below) and/or accessing the services
        offered on the Website and/or Application (hereinafter the Personal
        Data) are considered confidential and must be handled as such.
      </p>

      <p>
        Furthermore, under current legislation regarding the processing and
        protection of Personal Data, specifically the The Abu Dhabi Global
        Market Data Protection Regulations 2021 (hereinafter ADGM or ADGM Data
        Protection Regulations) and the General Data Protection Regulation of 27
        April 2016 (hereinafter the GDPR), The Scalable Technologies Ltd
        (hereinafter The Scalable CFO, TSC, we or us) provided through Madi,
        TSC&rsquo;s flagship software will maintain the confidentiality,
        integrity and security of your Personal Data.
      </p>

      <h3 className="section-title">
        <strong>1. What is the purpose of this Privacy Policy?</strong>
      </h3>

      <p>
        The purpose of this privacy policy (hereinafter the Privacy Policy) is
        to inform you about:
      </p>

      <ul>
        <li>
          The measures taken to protect the confidentiality and security of your
          Personal Data.
        </li>
        <li>Why your Personal Data is being processed.</li>
        <li>The rights you have regarding that processing.</li>
        <li>
          How we collect and process your Personal Data when you (hereinafter
          you, Visitor, Customer or Prospective Customer) enter into a
          relationship with TSC in whatsoever way, including as a:
          <ul>
            <li>
              <strong>Visitor:</strong> when you are browsing our Website&nbsp;
              <a href="https://www.thescalablecfo.com/">
                https://www.thescalablecfo.com/
              </a>
              &nbsp;(hereinafter the Website) or our application{" "}
              <a href="https://app.thescalablecfo.com/">
                https://app.thescalablecfo.com/
              </a>{" "}
              (hereinafter Madi, application, or app) as an internet user,
              Prospective Customer or Customer as defined below.
            </li>
            <li>
              <strong>Prospective Customer:</strong> when you are browsing the
              Website due to an interest in TSC&rsquo;s services.
            </li>
            <li>
              <strong>Customer:</strong> when you are using the Application
              after signing an agreement for TSC&rsquo;s services.
            </li>
          </ul>
        </li>
      </ul>

      <p>
        We would like to draw your attention to the fact that we may modify this
        Privacy Policy at any time in order to ensure transparency about the
        processing of your Personal Data. You will be notified by email of any
        changes. We also encourage you to regularly check the Privacy Policy.
      </p>

      <h3 className="section-title">
        <strong>2. Who is the data controller for your Personal Data ?</strong>
      </h3>

      <p>
        The controller who collects and manages your Personal Data on the
        Website and Application is TSC, a tech startup having its registered
        office at 34 &amp; 35 Floor Al Maqam Tower, ADGM Square, Al Maryah
        Island, Abu Dhabi, United Arab Emirates P.O. box 35665, and registered
        as a Data Controller under trade number #000002578 in ADGM.
      </p>

      <p>
        This means that TSC is your dedicated point of contact for any questions
        about your Personal Data&rsquo;s protection within the Website and
        Application.
      </p>

      <h3 className="section-title">
        <strong>3. When do we collect your Personal Data ?</strong>
      </h3>

      <p>
        You agree that TSC can collect and process your Personal Data in the
        following situations:
      </p>

      <ul>
        <li>
          When you are browsing the Website: We have set up some audience
          measurement tools in order to better understand and interact with
          Users. Those tools may also collect some Personal Data about you.
        </li>
        <li>
          When you contact us for any reason (chat, call, etc.): In this
          situation, some Personal Data may be requested from you so we can
          respond.
        </li>
        <li>
          When you subscribe to or pay for a TSC service whether provided on
          Madi or external to Madi: You will also be asked to provide your
          Personal Data so that service can be properly provided. For instance,
          your Personal Data will be required so that you can log into your Madi
          account or lodge a technical support request.
        </li>
      </ul>

      <h3 className="section-title">
        <strong>
          4. Which Personal Data do we collect and for what purposes ?
        </strong>
      </h3>

      <p>
        While you are browsing the Website and/or App, and using our various
        services, you agree that we can collect your Personal Data under the
        conditions relating to the nature of that use. You agree to provide
        up-to-date and valid identifying Personal Data as part of the
        information required on the Website and/or App, and vouch that you will
        not make any false declarations or provide any incorrect information.
      </p>

      <p>
        For all other Personal Data that we collect by any other means, the form
        on which you enter that Personal Data will explain the applicable rules
        if they differ from the rules presented in this Privacy Policy.
      </p>

      <p>
        Remember that you can configure, modify and/or deactivate your Cookie
        choices at any time by using&nbsp;the Cookie management
        tool&nbsp;available for the browser that you are using. Refer to the
        Cookies section below for more information on how we process
        information.
      </p>

      <h3 className="section-title">
        <strong>5. Who do we share your Personal Data with ?</strong>
      </h3>

      <h3 className="section-sub-title">
        A- <strong>Subcontractors</strong>
      </h3>

      <p>
        As part of operating our business and supplying services, TSC may share
        your Personal Data with third party service providers (hereinafter the
        Subcontractors as defined in Article 4.8 of the GDPR or Data Processors
        as defined in Part IV of ADGM Data Protection Regulation 2021) we may
        engage. In that situation, TSC will ensure that:
      </p>

      <ul>
        <li>
          The Subcontractor is contractually bound to comply with the same
          obligations as ours in terms of Personal Data protection.
        </li>
        <li>
          The Subcontractor has sufficient guarantees regarding the
          implementation of suitable technical and organisation measures to
          ensure that the processing meets the requirements of legislation in
          terms of Personal Data protection, including the GDPR and ADGM.
        </li>
        <li>
          In the event of Personal Data being transferred outside the ADGM, the
          Subcontractor will contractually ensure their security and
          confidentiality.
        </li>
      </ul>

      <p>The Subcontractor categories with which we work include:</p>

      <ul>
        <li>Online payment providers for the Services</li>
        <li>Analysis and performance providers</li>
        <li>Personal Data storage and hosting providers</li>
        <li>Bank account aggregators</li>
        <li>Accounting softwares</li>
        <li>
          Suppliers of communication, CRM, Customer feedback, internal logistics
          and electronic signature tools
        </li>
        <li>
          Suppliers of design, improvement and development tools for the App
        </li>
        <li>Suppliers of Personal Data processing tools</li>
        <li>
          Law firms, consultants, accountancy firms, legal representatives and
          officers of the court or legal system as part of their dispute
          management and debt recovery roles.
        </li>
      </ul>

      <h3 className="section-sub-title">
        B- <strong>Special cases</strong>
      </h3>

      <p>We may also share your Personal Data in the following situations:</p>

      <ul>
        <li>
          When a law, regulation, current regulatory provision or court order
          requires or allows it, or if that disclosure is necessary as a part of
          an investigation or legal proceedings, either in France or abroad.
        </li>
        <li>
          In the event of an audit performed as part of an investment and/or in
          the event of an TSC entity or assets being transferred to any
          potential buyer.
        </li>
        <li>
          When we send non-personal data to third parties, such as aggregated
          statistics generated from Personal Data.
        </li>
      </ul>

      <h3 className="section-title">
        <strong>6. Where do we store your Personal Data ?</strong>
      </h3>

      <p>
        All of the collected Personal Data are hosted in Frankfurt by Digital
        Ocean, an international company with a reputation for security and
        reliability. Digital Ocean holds numerous security certifications and
        that list can be publicly accessed on their website.
      </p>

      <h3 className="section-title">
        <strong>7. Do we transfer your Personal Data outside of ADGM ?</strong>
      </h3>

      <p>
        If we do need to transfer your Personal Data outside the ADGM, for
        example if one of our Subcontractors is not located in ADGM, TSC will
        ensure the security and confidentiality of that Personal Data is
        maintained. This may involve the signature, on a case by case basis, of:
      </p>

      <ul>
        <li>
          Data protection agreements set up between ADGM and the destination
          country.
        </li>
        <li>
          Standard contractual clauses in accordance with Part V of the ADGM
          Data Protection Regulation.
        </li>
        <li>
          Personal Data subcontracting contracts in accordance with ADGM Data
          Protection Regulation.
        </li>
      </ul>

      <p>
        If a data transfer is being considered to a country not recognized by
        ADGM as offering an adequate level of protection, TSC will notify you of
        this.
      </p>

      <h3 className="section-title">
        <strong>
          8. What security measures do we have to protect your Personal Data ?
        </strong>
      </h3>

      <p>
        In order to ensure the security of the Personal Data you transmit to us,
        we have implemented appropriate technical and organizational measures
        to:
      </p>

      <ul>
        <li>
          Prevent unauthorized access, use, modification, destruction, loss,
          damage or disclosure;
        </li>
        <li>
          To ensure the security and confidentiality of the Personal Data
          collected by preventing them from being distorted, damaged or
          communicated to unauthorized third parties;
        </li>
      </ul>

      <p>In particular, the security of your Personal Data is ensured by:</p>

      <ul>
        <li>A strict access control policy;</li>
        <li>
          The use of the TLS protocol to ensure the security of Personal Data in
          transit;
        </li>
        <li>
          The encryption of the Personal Data present via proven algorithms;
        </li>
        <li>
          An annual security audit of our services by an independent company.
        </li>
      </ul>

      <p>
        To reinforce this approach of protection of your Personal Data, it is
        strongly recommended that you use a password that is long enough and
        includes several characters, which you are invited to change regularly
        and keep confidential.
      </p>

      <h3 className="section-title">
        <strong>
          9. What rights do you have regarding the processing of your Personal
          Data ?
        </strong>
      </h3>

      <p>
        In accordance with Part III of ADGM&rsquo;s Data Protection Regulation,
        you have the following rights:
      </p>

      <ul>
        <li>
          <strong>Right of access:</strong>&nbsp;In accordance with Article 13
          of Part III of the ADGM Data Protection Regulation, you have the right
          to ask TSC for: (i) your Personal Data in an accessible format; (ii)
          confirmation that your Personal Data are or are not being processed;
          (iii) information about the processing purpose, categories of Personal
          Data processed and who your Personal Data is communicated to; and (iv)
          the storage period of your Personal Data or the criteria used to
          determine that period.
        </li>
        <li>
          <strong>Right to data portability:</strong>&nbsp;In accordance with
          Article 18 of Part III of the ADGM Data Protection Regulation, you
          have the right to request a copy of your Personal Data from us in a
          format that is structured, commonly used and machine readable so you
          can provide them to another data controller.
        </li>
        <li>
          <strong>Right to rectification:</strong>&nbsp;In accordance with
          Article 14 of Part III of the ADGM Data Protection Regulation, you
          have the right to ask us to modify, add to and update your Personal
          Data if they are found to be incorrect, incomplete, ambiguous or out
          of date.
        </li>
        <li>
          <strong>Right to erasure (right to be forgotten):</strong>&nbsp;In
          accordance with Article 15 of Part III of the ADGM Data Protection
          Regulation, you have the right to ask us to permanently erase your
          Personal Data as soon as practicable, including when you consider that
          they are no longer necessary in terms of the purpose for which they
          were collected or that we are no longer justified in processing them.
        </li>
        <li>
          <strong>Right to restrict processing:</strong>&nbsp;You have the right
          to ask us to restrict the processing of all or part of your Personal
          Data only in the situations described in Article 16 of Part III of the
          ADGM Data Protection Regulation, being:
        </li>
        <li>Checking the accuracy of the Personal Data that you dispute.</li>
        <li>
          Helping you confirm, exercise or defend your legal rights, even when
          TSC no longer needs your Personal Data.
        </li>
        <li>
          Checking if TSC&rsquo;s legitimate interests prevail over yours, in
          the event that you object to your Personal Data being processed on the
          basis of TSC&rsquo;s legitimate interest.
        </li>
        <li>
          Complying with your request to restrict the use of your Personal Data
          rather than erasing them, when the processing of that data has proven
          to be unlawful.
        </li>
        <li>
          <strong>Right to object:</strong>&nbsp;In accordance with Article 19
          of Part III of the ADGM Data Protection Regulation, you have the right
          to object at any time, for reasons relating to your situation, to the
          processing of your Personal Data for canvassing purposes or a purpose
          based on legitimate interests. If we cannot demonstrate a legitimate,
          pressing interest for that processing, we will only continue to
          process the Personal Data not covered by your request.
        </li>
      </ul>

      <h3 className="section-title">
        <strong>10. What can you do if your Personal Data is breached ?</strong>
      </h3>

      <p>
        If there is a breach of your Personal Data likely to result in a risk to
        your rights and freedoms, TSC and/or our Subcontractors will notify ADGM
        as soon as possible and preferably within seventy-two (72) hours of
        becoming aware of the breach. You will also be informed as soon as
        possible of any Personal Data breach likely to result in greater risk to
        your rights and freedoms, so you can take all necessary measures, in
        accordance with the provisions of Article 32 of the ADGM Data Protection
        Regulation.
      </p>

      <p>
        Without prejudice to any other administrative or legal recourse, if you
        believe that the processing of your Personal Data constitutes a breach
        of current legislation, you can lodge a complaint with the relevant
        supervisory body such as ADGM via its website. You can exercise this
        right at any time and at no cost to you, except for any costs arising
        from postage or your choice to engage a third party to assist and
        represent you in the process.
      </p>

      <h3 className="section-title">
        11.{" "}
        <strong>
          Do you have questions about your Personal Data processing or want to
          exercise your rights ?
        </strong>
      </h3>

      <p>
        Please contact TSC by emailing our Data Protection Officer at&nbsp;
        <a href="mailto:dpo@agicap.com">dpo@thescalablecfo.com</a> or by
        completing the contact form on our Website or Application.
      </p>

      <p>In your email please state:</p>

      <ul>
        <li>Your full name and email address</li>
        <li>
          The subject of your request, and if it involves exercising a right:
        </li>
        <li>
          The type of right you want to exercise and the reasons justifying it,
          and
        </li>
        <li>Where appropriate, your request to exercise that right</li>
        <li>TSC may check your identity before actioning your request.</li>
      </ul>

      <p>
        We will provide you with information about the actions taken as soon as
        possible, and in any case within one (1) month of receiving your
        request. This timeframe may be extended to two (2) months for more
        complex requests. If we cannot action your request, we will tell you why
        and inform you of your ability to lodge a complaint with a supervisory
        body or pursue legal recourse.
      </p>

      <p>
        There is no cost to exercise these rights. However, in the event of
        blatantly unfounded or excessive requests, TSC reserves the right to (i)
        require payment of expenses taking into account administrative costs,
        and (ii) refuse to process those requests.
      </p>

      <h3 className="section-title">
        <strong>12. COOKIES POLICY</strong>
      </h3>

      <p>What are Cookies ?</p>

      <p>
        When viewing the Website, technical data may be recorded. This includes:
      </p>

      <ul>
        <li>
          Accessing and using the Website (your Internet Protocol address)
        </li>
        <li>Configuration (terminal and browser type, etc.)</li>
        <li>
          Your browsing (date and time, pages viewed, errors encountered, etc.)
        </li>
      </ul>

      <p>
        This information may be stored through your browser software in short
        text files called &lsquo;cookies (hereinafter Cookies), depending on
        your settings, on disk space reserved for TSC and/or our partners
        processing those technical data in a completely anonymous manner, as
        they are not attached to any information enabling your identification
        and are not provided to third parties.
      </p>

      <p>
        <strong>These Cookies may be Personal Data.</strong>
      </p>

      <p className="privacy-cookie">
        <strong>1. Which Cookies do we collect and why?</strong>
      </p>

      <p>
        Some Cookies are stored for essentially technical reasons, however
        others can be used for audience measurement purposes for the various
        Website pages and content, and to help us better understand your
        preferences and better meet your needs and expectations.
      </p>

      <p>
        To help you better understand the types of Cookies and their purposes
        and storage periods, we have created the table below.
      </p>

      <table className="privacy-policy-table">
        <thead>
          <tr className="privacy-table-row">
            <th className="privacy-table-column">Type of cookie</th>
            <th className="privacy-table-column">Purpose</th>
            <th className="privacy-table-column">Storage period</th>
          </tr>
        </thead>
        <tbody>
          <tr className="privacy-table-row">
            <td className="privacy-table-column">Strictly necessary</td>
            <td className="privacy-table-column">
              These Cookies are essential for you to&nbsp;navigate our Website
              and use its functionalities..The information collected by these
              Cookies is tied to our Website&rsquo;s operation, such as its
              script language and the security tokens used to secure the
              different parts of our Website.
            </td>
            <td className="privacy-table-column">8h</td>
          </tr>
          <tr className="privacy-table-row">
            <td className="privacy-table-column">Performance and analytics</td>
            <td className="privacy-table-column">
              These cookies collect&nbsp;anonymous information about how you use
              our Website,such as the pages you visit most often, if you receive
              any error message and how you arrived on our Website. The
              information collected by these Cookies is used solely for the
              purpose of improving your use of our Website, and never to
              identify you. Sometimes, these Cookies may be installed by third
              party web traffic analytics providers like Google Analytics.
            </td>
            <td className="privacy-table-column">13 month</td>
          </tr>
          <tr className="privacy-table-row">
            <td className="privacy-table-column">Functionality</td>
            <td className="privacy-table-column">
              These cookies&nbsp;remember the choices you make&nbsp;like the
              country from which you are visiting our Website, your language and
              any changes you make to text size or other parts of the web pages
              that you can personalize, in order to&nbsp;improve your experience
              on our Website&nbsp;and make your visits&nbsp;more personal and
              pleasant. The information collected by these Cookies can be
              anonymized and cannot be used to track your activities on other
              websites.
            </td>
            <td className="privacy-table-column">13 month</td>
          </tr>
          <tr className="privacy-table-row">
            <td className="privacy-table-column">Personalization</td>
            <td className="privacy-table-column">
              These Cookies allow you to&nbsp;access the Website using general
              settings, either predefined on your device or specifically defined
              by you.&nbsp;For example, this could include your language, the
              browser you use to visit the Website, your choice of currency and
              your preferred content display mode.
            </td>
            <td className="privacy-table-column">13 month</td>
          </tr>
        </tbody>
      </table>

      <p className="privacy-cookie">
        <strong>2. How can you choose your Cookies settings ?</strong>
      </p>

      <p>
        In accordance with the specifically applicable regulations, Cookies that
        are not solely for technical purposes will never be saved without your
        consent. Your consent is requested via a banner that appears during your
        first visit to the Website and informs you of those cookies and their
        purpose.
      </p>

      <p>
        You can configure, modify and/or deactivate your Cookie choices at any
        time by deleting Cookies using your browser&rsquo;s settings.
      </p>

      <p>
        If you agree to your browser software storing Cookies on your terminal,
        the Cookies for the pages and content you have viewed may be temporarily
        stored in a dedicated space on your terminal. They can only be read by
        their issuer.
      </p>

      <p>
        Your consent to Cookies remains valid for six (6) months. After that, we
        need to ask for your consent again.
      </p>

      <p>
        However, please be aware that configuring your settings and blocking or
        deleting Cookies may affect some functionalities that are required for
        browsing certain parts of the Website and will therefore affect how you
        access our services. This may occur if TSC or our service providers
        cannot, in terms of technical compatibility, recognise the type of
        browser your terminal is using, its language or display settings, or the
        country in which the terminal in question is connecting to the internet.
      </p>

      <p>
        TSC accepts no liability if the systematic deactivation of Cookies by
        your browser prevents you from using certain services and
        functionalities supplied by TSC, and this issue will in no way
        constitute any damage giving rise to any right to compensation.
      </p>

      <p>
        <strong>
          3. How can you configure your choices on the browser you are using ?
        </strong>
      </p>

      <p>
        Each browser has a different method for configuring your Cookies
        settings. You can block Cookies by configuring your browser as described
        below:
      </p>

      <ul>
        <li>
          <strong>Internet Explorer:</strong>&nbsp;o Go to Tools &gt; Internet
          Options. o Click on the privacy tab and, under Settings, select
          Advanced. o Select &lsquo;authorise&rsquo;, &lsquo;block&rsquo; or
          &lsquo;prompt&rsquo; to configure how internal and third party Cookies
          are handled. - Firefox: o At the top of the Firefox window, click on
          the shield on the left of the address bar. o Configure the protection
          setting. o Select the tracking protection you want.
        </li>
        <li>
          <strong>For Chrome:</strong>&nbsp;o Click on the three dots to the
          right of the address bar. o Select &quot;Settings&quot;. o Click on
          &quot;Privacy and Security&quot;. o Click on &quot;Cookies and other
          site data&quot;. o In the &ldquo;General Settings&rdquo; section, you
          can block third-party cookies and data.
        </li>
        <li>
          <strong>Safari:</strong>&nbsp;o Go to Settings, then Preferences. o
          Click on the Privacy tab. o In the &lsquo;Block cookies&rsquo;
          section, tick the &lsquo;Always&rsquo; box.
        </li>
        <li>
          <strong>Opera:</strong>&nbsp;o Go to Settings, then Preferences. o
          Click on the advanced tab and select &lsquo;Privacy &amp;
          Security&rsquo;. o In the cookies section, tick &lsquo;Never accept
          cookies&rsquo;.
        </li>
      </ul>
      <br />
      <br />
    </div>
  );
}

export default PrivacyPolicy;
