import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { IMAGES } from "../../../constants";
import { formatCurrency } from "../../../modules";
import { cutLengthyString } from "../../../modules/cutLengthyString";
import { getHighlightedText } from "../../../modules/getHighlightedText";

function EstimateListCard({
  record,
  searchText = "",
}: {
  record: any;
  searchText?: string;
}) {
  return (
    <div style={{ position: "relative" }}>
      {/* Header */}
      <div className="is-flex is-flex-direction-row is-justify-content-space-between">
        <div className="flex-direction-row">
          <figure className="image" style={{ width: 40, height: 40 }}>
            <img
              className="is-rounded"
              src={IMAGES.company_logo_placeholder}
              alt=""
            />
          </figure>
          <div className="subtitle ml-2 mt-1" style={{ fontWeight: "700" }}>
            {getHighlightedText(cutLengthyString(record?.name, 25), searchText)}
          </div>
        </div>
      </div>

      {/* Details */}
      <div className="flex-direction-row-space-between  date-amount-container">
        <div>
          <div className="card-date">
            {getHighlightedText(
              "Due " +
                moment(record.dueDate).format("MMM, DD, YYYY").toString() || "",
              searchText
            )}
          </div>
          <div className="card-code">
            {_.isEmpty(record.repeats) || !record.repeats.trim() ? (
              <i>No record number.</i>
            ) : (
              getHighlightedText(`${record.repeats}`, searchText)
            )}
          </div>
        </div>
        <div className="subtitle ml-4" style={{ fontWeight: "700" }}>
          {record.currency || "AED"}{" "}
          {/* {getHighlightedText(
            formatCurrency(parseFloat(record?.amount || "0"), 2) || "",
            searchText
          )} */}
          {getHighlightedText(record?.amount, searchText)}
        </div>
      </div>
      {record.image && (
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <FontAwesomeIcon icon={faPaperclip} />
        </div>
      )}
    </div>
  );
}

export default EstimateListCard;
