import React from "react";

interface Beneficiary {
  // displayName: string;
  // name: string;
  bankIdentifier: string;
  // address: string;
  // city: string;
  // country: string;
  accountNumber: string;
  swiftCode: string;
  iban: string;
}
const initialValues: Beneficiary = {
  //   bankIdentifier: "ENBD_UAE",
  //   accountNumber: "1008223921001",
  //   swiftCode: "EBILAEAD",
  //   iban: "AB12 1234 1234 1234 1234",
  bankIdentifier: "",
  accountNumber: "",
  swiftCode: "",
  iban: "",
};

export const useBeneficiary = () => {
  const [beneficiary, setBeneficiary] =
    React.useState<Beneficiary>(initialValues);

  const resetBeneficiary = () => {
    setBeneficiary(initialValues);
  };

  return { beneficiary, setBeneficiary, resetBeneficiary };
};
