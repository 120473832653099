import "../../pages/index.css";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import {
  approval_icon,
  bank_icon,
  cashflow_icon,
  connect_with_CFO,
  digitize_bill,
  sync_accounting,
} from "../../constants/images";
import Card from "./components/Card";
import { updateUser } from "../../repositories/userRepository";
import { Helmet } from "react-helmet";
import SubmitButton from "./components/SubmitButton";

function WhatWeDo() {
  const navigate = useNavigate();
  const cards = [
    {
      image: "/assets/images/accounting-integration.png",
      title: "Sync Accounting",
      content:
        "Transform your accounting data to a cashflow forecast instantly",
    },
    {
      image: "/assets/images/tag-cashflow.svg",
      title: "Tag Cash Flow",
      content: "Customize your cash inflows and outflows with smart tagging",
    },
    {
      image: "/assets/images/compare-scenarios.svg",
      title: "Compare Scenarios",
      content:
        "Compare how different scenarios can impact your future cash balance",
    },
    {
      image: "/assets/images/manage-runway.svg",
      title: "Manage Runway",
      content: "Simplify forecasting with recurring estimates",
    },
    {
      image: "/assets/images/hire-an-employee.svg",
      title: "Hire an Employee",
      content: "Understand how business decisions can impact your cash balance",
    },

    {
      image: "/assets/images/fundraise.png",
      title: "Fundraise",
      content: "Set your cashflow up for a successful fundraise",
    },
  ];

  const handleClick = async () => {
    await updateUser({
      lastStage: "what-we-do",
    });
    navigate("/tell-us-who-you-are");
  };
  return (
    <div
      className="card-outer-container"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <Helmet>
        <script>
          {`gtag('event', 'conversion', {'send_to': 'AW-sdad/-dsafdsa'});`}
        </script>
      </Helmet> */}
      <p className="what-we-do-title">
        We help businesses make better financial decisions
      </p>
      <div className="card-container">
        {cards.map(({ image, title, content }, index) => (
          <>
            <Card image={image} title={title} content={content} />
            {index == 2 && <div style={{ flexBasis: "100%", height: 0 }}></div>}
          </>
        ))}
      </div>

      <SubmitButton
        style={{
          width: "300px",
        }}
        onClick={handleClick}
      >
        Let's get started
      </SubmitButton>
    </div>
  );
}

export default WhatWeDo;
