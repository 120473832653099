import React, { useState } from "react";
import { COLORS } from "../../../constants/theme";
import { xero_round_logo } from "../../../constants/images";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  children: string;
}

const SignUpWithXeroButton: React.FC<Props> = (props) => {
  const { loading, children, style, className, ...rest } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      style={{
        backgroundColor: isHovered ? "#f2f2f2" : "white",
        border: isHovered ? "1px solid #c4c4c4" : "1px solid #d9d9d9",
        color: "#4a4a4a",
        padding: "10px 20px",
        borderRadius: "10px",
        width: "100%",
        cursor: "pointer",
        transition: "background-color 0.2s ease, border-color 0.2s ease",
        ...style,
      }}
      className={`button ${loading ? "is-loading" : ""} ` + className}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <img src={xero_round_logo} alt="Xero Logo" className="xero-logo" />

      {children}
    </button>
  );
};

export default SignUpWithXeroButton;
