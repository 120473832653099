import React, { Fragment, useEffect, useMemo, useState } from "react";
import Card from "../../Card/Card";
import { useAuth } from "../../../contexts";
import { Checkbox, Table } from "@mantine/core";
import styled from "styled-components";
import moment from "moment";
import { If } from "../../Utils/If";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { deleteScenario } from "../../../repositories/cashflowScenario";
import { showNotification } from "@mantine/notifications";

interface DeleteScenariosProps {
  close: () => void;
}
const DeleteScenarios: React.FC<DeleteScenariosProps> = (props) => {
  const { close } = props;
  const {
    allScenarios,
    user,
    fetchScenarios,
    fetchCashflowReport,
    fetchForecasts,
    fetchExternalAccounts,
    getRecords,
    getReportLayout,
  } = useAuth();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedScenarios, setSelectedScenarios] = useState<string[]>([]);

  useEffect(() => {
    // Update the selectAll state when the selectedScenarios change
    setSelectAll(selectedScenarios.length === allScenarios.length - 1);
  }, [selectedScenarios, allScenarios]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    scenarioId: string
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedScenarios([...selectedScenarios, scenarioId]);
    } else {
      setSelectedScenarios(selectedScenarios.filter((id) => id !== scenarioId));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      let filteredScenarios = allScenarios.filter(
        (scenario) =>
          scenario.name !== "Main Cashflow Scenario" && !scenario.isDefault
      );

      setSelectedScenarios(filteredScenarios.map((scenario) => scenario.id));
    } else {
      setSelectedScenarios([]);
    }
  };

  const handleDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete the selected scenario(s)?"
      )
    ) {
      const { success } = await deleteScenario({ ids: selectedScenarios });

      if (success) {
        showNotification({
          title: "Success",
          message: "Scenario(s) deleted successfully",
          color: "green",
        });

        fetchScenarios();
        fetchCashflowReport();
        fetchForecasts();
        fetchExternalAccounts();
        getRecords();
        getReportLayout();
        close();
      } else {
        showNotification({
          title: "Error",
          message: "Could not delete scenario(s)",
          color: "red",
        });
      }
    }
  };

  return (
    <Fragment>
      <Card style={{ width: "50%" }}>
        <Table className="mt-4" withBorder>
          <thead>
            <tr>
              <th>
                <Checkbox
                  indeterminate={
                    selectedScenarios.length > 0 &&
                    selectedScenarios.length < allScenarios.length - 1
                  }
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </th>
              <th>Scenario Name</th>
              <th>Created By</th>
              <th>Created</th>
              {/* <th>Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {allScenarios.map((scenario) => {
              const isChecked = selectedScenarios.includes(scenario.id);
              const isDisabled =
                scenario.name === "Main Cashflow Scenario" ||
                scenario.isDefault;

              return (
                <tr key={scenario.id}>
                  <td>
                    <Checkbox
                      disabled={isDisabled}
                      checked={isChecked}
                      onChange={(event) =>
                        handleCheckboxChange(event, scenario.id)
                      }
                    />
                  </td>
                  <td>{scenario.name}</td>
                  <td>{user?.firstName}</td>
                  <td>{moment(scenario.createdAt).fromNow()}</td>
                  {/* <td>
                  <button disabled={isDisabled}>Delete</button>
                </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      <PrimaryButton
        onClick={handleDelete}
        disabled={selectedScenarios.length === 0}
        style={{ width: "20%", marginTop: 15 }}
      >
        Delete
      </PrimaryButton>
    </Fragment>
  );
};

export default DeleteScenarios;

const Delete = styled.button`
  background-color: red;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #ff0000;
  }

  &:disabled {
    background-color: #ff000055;
    cursor: not-allowed;
  }
`;
