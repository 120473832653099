import React from "react";

interface MapProps<T> {
  array: T[];
  render: (item: T, index: number) => React.ReactNode;
}

export function Map<T>(props: MapProps<T>) {
  const { array, render } = props;

  return <>{array.map(render)}</>;
}
