import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import { appTheme, configs } from "../../constants";

function ImportUsers() {
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const getUsers = async () => {
    setLoading(true);
    const res = await axios.get(configs.urls.BASE_URL + "/importUsers", {
      withCredentials: true,
    });
    if (res.data !== "error") {
      setResponse(res.data);
    } else {
      setResponse(undefined);
      showNotification({
        message: "Cannot import users!",
        color: "red",
        icon: <FontAwesomeIcon icon={faTimes} />,
      });
    }
    setLoading(false);
  };

  return (
    <section
      style={{
        display: "flex",
        alignItems: "center",
        flex: 1,
      }}
      className="hero has-text-centered"
    >
      <div
        style={{
          border: "1px solid black",
          borderRadius: 15,
          marginTop: "10%",
        }}
        className="hero-body"
      >
        <p className="title">Import Users</p>
        {response ? (
          <p className="subtitle">
            {response?.imported} users imported, {response?.updated} updated &{" "}
            {response?.totalRecords} total.
          </p>
        ) : (
          <p className="subtitle">
            Click the button below to import users{" "}
            <p>from the CSV file into the database.</p>
          </p>
        )}
        <div style={{ marginBottom: 15 }}>
          <Button
            disabled={true}
            loading={loading}
            onClick={getUsers}
            appearance="primary"
            size="lg"
            style={{ backgroundColor: appTheme.COLORS.primary }}
            block
          >
            IMPORT
          </Button>
        </div>
        <Button
          block
          onClick={() => navigate("/login")}
          style={{
            backgroundColor: appTheme.COLORS.primaryLight,
            color: "black",
          }}
          size="md"
          appearance="subtle"
        >
          <FontAwesomeIcon style={{ marginRight: 5 }} icon={faArrowLeft} /> Back
        </Button>
      </div>
    </section>
  );
}

export default ImportUsers;
