import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { COLORS } from "../../../constants/theme";
import { upsertScenario } from "../../../repositories/cashflowScenario";
import { showNotification } from "@mantine/notifications";
import { If } from "../../../common/Utils/If";
import { useBanksContext } from "../context/BanksContext";
import { upsertBalanceHistoryBank } from "../../Cashflow/repository/cashflowConfigs";

interface CreateBankProps {
  onSubmit: (id: string) => void;
  mode: "add" | "edit";
  selectedBankToEdit?: any;
}

interface CreateBankFormValues {
  name: string;
}

function CreateBank(props: CreateBankProps) {
  const { onSubmit, mode, selectedBankToEdit } = props;
  const { banks, selectedBank } = useBanksContext();

  const formik = useFormik<CreateBankFormValues>({
    initialValues: {
      name: "",
      // spread selected scenario values if mode is edit
      ...(mode === "edit" && {
        name: selectedBankToEdit?.bankName,
      }),
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      // let switchToNewScenario = window.confirm(
      //   "Do you want to switch to the new scenario?"
      // );
      let randId = Math.random().toString(36).substr(2, 9);
      const { success } = await upsertBalanceHistoryBank({
        id: mode === "edit" ? selectedBankToEdit?.bankId : randId,
        name: values.name,
        values: [],
        dateRange: [],
        sourceType: "Manual",
      });

      setSubmitting(false);

      if (success) {
        showNotification({
          title: "Success",
          message: "Bank created successfully",
          color: "green",
        });
        onSubmit(randId);
      } else {
        showNotification({
          title: "Error",
          message: "Could not create bank",
          color: "red",
        });
      }
    },

    validate: (values) => {
      const errors: CreateBankFormValues = {} as CreateBankFormValues;

      if (!values.name) {
        errors.name = "Required";
      } else if (
        banks.some(
          (bank) => bank.bankName.toLowerCase() === values.name.toLowerCase()
        )
      ) {
        errors.name = "Bank already exists";
      }

      return errors;
    },
  });

  const isError = (field: keyof CreateBankFormValues) => {
    return formik.errors[field];
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Field>
        <Label
          style={{
            color: isError("name") ? "red" : "#707070",
          }}
        >
          Name your Bank *
        </Label>
        <Input
          value={formik.values.name}
          onChange={formik.handleChange}
          name="name"
          placeholder="i.e. ENBD Bank"
          style={{
            border: isError("name") ? "1px solid red" : "1px solid #0005",
          }}
        />
        <If condition={isError("name")}>
          <div style={{ color: "red" }}>{formik.errors.name}</div>
        </If>
      </Field>

      <If condition={mode === "add"}>
        <Button
          type="submit"
          className={`is-align-self-center ${
            formik.isSubmitting ? "button is-loading" : ""
          }`}
        >
          Save
        </Button>
      </If>

      <If condition={mode === "edit"}>
        <Button
          type="submit"
          className={`is-align-self-center ${
            formik.isSubmitting ? "button is-loading" : ""
          }`}
        >
          Update
        </Button>
      </If>
    </Form>
  );
}

export default CreateBank;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 40%;
`;
const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;
const Input = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0005;
  outline: none;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  ::placeholder {
    color: #0005;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #0005;
  outline: none;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  /* multiline placeholder */
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: break-spaces;
  text-align: left;

  ::placeholder {
    color: #0005;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  background-color: ${COLORS.greenBlue};
  color: white;
`;
