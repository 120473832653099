import React, { Fragment } from "react";
import CardButton from "../../../common/CardButton/CardButton";
import { IMAGES } from "../../../constants";

const cardStyles: React.CSSProperties = {
  boxShadow: "none",
};

const imageStyles: React.CSSProperties = {
  height: "70px",
  width: "70px",
};

function SelectMethod({ onSubmit }: any) {
  return (
    <Fragment>
      <CardButton
        cardStyles={cardStyles}
        imageStyles={imageStyles}
        icon={IMAGES.mini_bank_logo}
        title="Bank Transfer"
        onClick={onSubmit}
      />
      <CardButton
        cardStyles={cardStyles}
        imageStyles={imageStyles}
        icon={IMAGES.card_payment}
        title="Card Payments"
        onClick={() => alert("Coming Soon!")}
        disabled
      />
      <CardButton
        cardStyles={cardStyles}
        imageStyles={imageStyles}
        icon={IMAGES.cheque}
        title="Cheque"
        onClick={() => alert("Coming Soon!")}
        disabled
      />
      <CardButton
        cardStyles={cardStyles}
        imageStyles={imageStyles}
        icon={IMAGES.petty_cash}
        title="Cash"
        onClick={() => alert("Coming Soon!")}
        disabled
      />
      <CardButton
        cardStyles={cardStyles}
        imageStyles={imageStyles}
        icon={IMAGES.digital_wallet}
        title="Digital Wallet"
        onClick={() => alert("Coming Soon!")}
        disabled
      />
    </Fragment>
  );
}

export default SelectMethod;
