import {
  Accordion,
  Button,
  Group,
  Loader,
  LoadingOverlay,
  Menu,
  Radio,
  Select,
  Tooltip,
} from "@mantine/core";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { StyledComponents } from "../../constants";
import {
  IconArrowsSort,
  IconDeviceFloppy,
  IconPencil,
  IconPlus,
  IconTrash,
} from "@tabler/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import AddEditCashflowScenario from "./AddEditCashflowScenario";
import { useAuth } from "../../contexts";
import { switchScenario } from "../../repositories/cashflowScenario";
import { showNotification } from "@mantine/notifications";
import DeleteScenarios from "./ModalFrames/DeleteScenarios";
import { CustomDrawer } from "../CustomDrawer";
import { PLANS, Tier } from "../../constants/globalConstants";
import { doPlanAllowMoreScenarios } from "../../modules/doPlanAllow";
import ViewSnapshots from "./ModalFrames/ViewSnapshots";
import { SelectPicker } from "rsuite";
import { isUserViewOnly } from "../../modules/localStorage";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";

type ToggleCashflowScenarioProps = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

const ToggleCashflowScenario: React.FC<ToggleCashflowScenarioProps> = (
  props
) => {
  const { loading, setLoading } = props;
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState<"add" | "edit">("add");
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openViewSnapshots, setOpenViewSnapshots] = React.useState(false);
  const {
    allScenarios,
    selectedScenario,
    fetchCashflowReport,
    fetchForecasts,
    fetchExternalAccounts,
    getRecords,
    getReportLayout,
    fetchCashflowConfigs,
    organization,
    currentPlan,
    fetchScenarios,
    compareTo,
    setCompareTo,
  } = useAuth();
  const [selectedRadio, setSelectedRadio] = useState(selectedScenario?.name);
  const [step, setStep] = React.useState<
    "ZERO_SCREEN" | "ADD_SCENARIO" | "DUPLICATE_SCENARIO"
  >("ZERO_SCREEN");

  const isViewOnly = isUserViewOnly();

  const navigate = useNavigate();

  const onSwitchScenario = async (scenarioId: string, name: string) => {
    setCompareTo(undefined);
    setLoading(true);
    const { success } = await switchScenario(scenarioId);
    if (success) {
      showNotification({
        title: "Success",
        message: "Successfully switched scenario to " + name,
        color: "green",
      });
      fetchCashflowReport();
      fetchForecasts();
      fetchExternalAccounts();
      getRecords();
      getReportLayout();
      fetchCashflowConfigs();
      fetchScenarios();
    } else {
      showNotification({
        title: "Error",
        message: "Could not switch scenario",
        color: "red",
      });
    }
    setLoading(false);
  };

  let compareToOptions = allScenarios.flatMap((scenario) =>
    scenario.snapshots.map((snap) => ({
      label: `${scenario.name} - v${snap.version}`,
      value: `${scenario.name} - v${snap.version}`,
      id: snap.id,
    }))
  );

  let moreScenariosAllowed = doPlanAllowMoreScenarios(
    organization?.subscription,
    allScenarios.length
  );

  const clippedName = (name: string) => {
    if (name) {
      if (name.length > 30) {
        return name.slice(0, 30) + "...";
      } else {
        return name;
      }
    } else return "";
  };

  return (
    <Container>
      <Menu shadow="md" position="bottom">
        <Menu.Target>
          <Button
            className={`chevron-button pl-4 pb-2 `}
            sx={{
              display: "flex",
              gap: 20,
              alignItems: "flex-start",
              // text over flow clip
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingRight: 0,
            }}
            title={selectedScenario?.name || "No scenario selected"}
          >
            <StyledComponents.ListItemCardHeaderContainer style={{ gap: 20 }}>
              <StyledComponents.NameOnListItemCard
                style={{ flex: 1, fontSize: 17 }}
              >
                {clippedName(selectedScenario?.name || "No scenario selected")}
              </StyledComponents.NameOnListItemCard>
            </StyledComponents.ListItemCardHeaderContainer>
            {/* <FontAwesomeIcon className="ml-4" icon={faChevronDown} /> */}
          </Button>
        </Menu.Target>

        <Menu.Dropdown className="company-dropdown-menu ml-6 p-2">
          {!isViewOnly && (
            <Tooltip
              transition="scale-x"
              // label="You can't create more scenarios, please upgrade your plan."
              // label={`I know you want to create more scenarios, but I can't help you. My hands are tied. You'll need to UPGRADE your PLAN if you want more.`}
              label={`You can only create ${currentPlan?.capabilities?.noOfScenarios} scenarios in this plan. You'll need to Upgrade your Plan if you want more.`}
              position="right"
              offset={15}
              width={300}
              multiline
              withArrow
              hidden={moreScenariosAllowed}
            >
              <div>
                <Menu.Item
                  disabled={!moreScenariosAllowed}
                  icon={<IconPlus size={9} />}
                  onClick={() => {
                    setMode("add");
                    setOpen(true);
                  }}
                >
                  Create new scenario
                </Menu.Item>
              </div>
            </Tooltip>
          )}

          <Accordion>
            <Accordion.Item value="1">
              <Accordion.Control icon={<IconArrowsSort size={9} />}>
                Switch to another scenario
              </Accordion.Control>
              <Accordion.Panel>
                <LoadingOverlay visible={loading} />
                <Radio.Group
                  defaultValue={selectedScenario?.name}
                  value={selectedRadio}
                  onChange={async (value) => {
                    if (value !== selectedRadio) {
                      setSelectedRadio(value);

                      let scenario = allScenarios.find(
                        (scenario) => scenario.name === value
                      );
                      let scenarioId = scenario?.id;

                      if (scenarioId) {
                        await onSwitchScenario(scenarioId, value);
                      }
                    }
                  }}
                >
                  <Group
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginLeft: 20,
                    }}
                  >
                    {allScenarios.map((scenario, index) => {
                      return (
                        <Fragment>
                          {allScenarios.length >
                            currentPlan?.capabilities?.noOfScenarios &&
                          index >= 1 ? (
                            <Fragment>
                              <div className="blurred-data">
                                <Radio
                                  key={scenario.id}
                                  value={scenario.name}
                                  label={scenario.name}
                                />
                              </div>
                            </Fragment>
                          ) : (
                            <Radio
                              key={scenario.id}
                              value={scenario.name}
                              label={scenario.name}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                    {allScenarios.length >
                      currentPlan?.capabilities?.noOfScenarios && (
                      <Tooltip
                        label={`Upgrade your plan to view scenarios`}
                        transition="scale-y"
                        transitionDuration={300}
                        position="top"
                        offset={15}
                        multiline
                        withArrow
                        // hidden={isSyncingAllowed}
                        style={{ textAlign: "center" }}
                      >
                        <PrimaryButton
                          className={"mb-3 py-4 is-small mt-2 "}
                          onClick={() => {
                            navigate("/pricing");
                          }}
                          style={{
                            borderRadius: 5,
                          }}
                        >
                          Upgrade
                        </PrimaryButton>
                      </Tooltip>
                    )}
                  </Group>
                </Radio.Group>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
          {!isViewOnly && (
            <Fragment>
              <Menu.Item
                icon={<IconPencil size={9} />}
                onClick={() => {
                  if (
                    selectedScenario?.name === "Main Cashflow Scenario" ||
                    selectedScenario?.isDefault
                  ) {
                    alert("You cannot edit the default cashflow scenario!");
                  } else {
                    setMode("edit");
                    setStep("ADD_SCENARIO");
                    setOpen(true);
                  }
                }}
              >
                Edit existing scenario
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                icon={<IconDeviceFloppy size={9} />}
                onClick={() => {
                  setOpenViewSnapshots(true);
                }}
              >
                View snapshots
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                color="red"
                icon={<IconTrash size={9} />}
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
              >
                Delete existing scenario
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item>
                You have created{" "}
                <b>
                  {allScenarios.length} /{" "}
                  {currentPlan?.capabilities?.noOfScenarios} scenarios
                  <br />
                </b>
                {allScenarios.length >
                  currentPlan?.capabilities?.noOfScenarios &&
                  "Upgrade your plan to view all scenarios."}
              </Menu.Item>
            </Fragment>
          )}
        </Menu.Dropdown>
      </Menu>

      <CompareTo>Compare to</CompareTo>
      <div>
        <SelectPicker
          value={compareTo?.value}
          renderValue={(value) => {
            return <div style={{ color: "black" }}>{value}</div>;
          }}
          placeholder="None"
          data={compareToOptions}
          style={{ padding: 0 }}
          onSelect={(_, item) => {
            let snaps = allScenarios.flatMap((scenario) => scenario.snapshots);
            let version = snaps.find((s) => s.id == item.id);
            setCompareTo(version);
          }}
          onClean={() => setCompareTo(undefined)}
          appearance="subtle"
        />
      </div>

      {/* Modals */}
      <AddEditCashflowScenario
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        mode={mode}
        step={step}
      />

      <CustomDrawer
        opened={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        title={"Delete Cashflow Scenarios"}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <DeleteScenarios close={() => setOpenDeleteModal(false)} />
        </Container>
      </CustomDrawer>

      <CustomDrawer
        opened={openViewSnapshots}
        onClose={() => {
          setOpenViewSnapshots(false);
        }}
        title={"Saved Versions"}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <ViewSnapshots close={() => setOpenViewSnapshots(false)} />
        </Container>
      </CustomDrawer>
    </Container>
  );
};

export default ToggleCashflowScenario;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
const CompareTo = styled.div`
  font-size: 12px;
`;
