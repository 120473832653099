import axios from "axios";
import { configs } from "../constants";
import { NotificationSettingPayload } from "../constants/payloads";

export const getNotificationSettingsApi = async () => {
  try {
    const { data } = await axios.get(
      configs.urls.BASE_URL + "/notification-settings",
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const setNotificationSettingsApi = async (
  payload: NotificationSettingPayload
) => {
  try {
    const { data } = await axios.post(
      configs.urls.BASE_URL + "/set-notifications",
      {
        ...payload,
      },
      {
        withCredentials: true,
      }
    );

    if (data.success) {
      return { success: true, data: data.response };
    } else {
      return { success: false, data: data.errors };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
