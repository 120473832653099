import "./styles.css";

import { useState } from "react";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { tscLogoWithName } from "../../constants/images";
import { forgetPassword } from "../../repositories/userRepository";

function ResetPassword() {
  const [hasShadow, showShadow] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const {
        data: { success, response, errors },
      } = await forgetPassword(email, 'change-password-session');

      if (!success) {
        showNotification({
          color: "red",
          message: errors,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      } else {
        showNotification({
          color: "green",
          message: "We have sent you an email to change your password",
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
      }
    } catch (e: any) {
      showNotification({
        color: "red",
        message: "Something went wrong",
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        onMouseOver={() => showShadow(true)}
        onMouseOut={() => showShadow(false)}
        className={`card-inner-container ${hasShadow ? "card" : ""}`}
        style={{ border: "1px solid #e6e6e6", borderRadius: "10px" }}
      >
        <div className="card-content">
          <div className="content">
            <div className="logo-container">
              <img src={tscLogoWithName} className="logo"></img>
            </div>

            <p className="already-have-account">Reset your password?</p>

            <form onSubmit={onSubmit}>
              <div className="field">
                <label className="label">Your Email</label>
                <div className="control">
                  <input
                    required
                    className="input"
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <button type="submit" className="button-image">
                <span>Change Password</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
