import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Card from "../../../common/Card/Card";
import { LoadingPlaceholders } from "../../../common/LoadingPlaceholders";
import DetailsCardHeader from "../../../common/PageLayout/components/DetailsCardHeader";
import ShowDetailsCard from "../../../common/PageLayout/components/ShowDetailsCard";
import SyncAccountingSoftware from "../../../common/SyncAccountingSoftware/SyncAccountingSoftware";
import { configs, StyledComponents } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { getAvatarPlaceholder } from "../../../modules/getAvatarPlaceholder";
import { getAllOrganizationsOfCurrentUser } from "../../../repositories/userRepository";
import Company from "../components/Company";
import Currencies from "../components/Currencies";
import Integrations from "../components/Integrations";
import LeanPayment from "../components/LeanPayment";
import Logout from "../components/Logout";
import Profile from "../components/Profile";
import Notification from "../components/Notification";
import { SETTINGS } from "./LeftView";
import PricingPlanSettings from "../components/PricingPlan";
import TeamSettings from "../components/TeamSettings/TeamSettings";

const RightView = ({ isViewOnly }: any) => {
  const {
    user,
    connectedToXero,
    connectedToQuickBooks,
    setGlobalLoading,
    organizationName,
  } = useAuth();

  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("active");
  const [active, setActive] = useState<string>(activeTab || "profile");

  useEffect(() => {
    if (activeTab) {
      // let activeTabSaved = localStorage.getItem("activeSetting");
      // if (activeTabSaved !== activeTab) {
      //   localStorage.setItem("activeTab", activeTab);
      // }
      setActive(activeTab);
    }
  }, [activeTab]);

  const renderCard = () => {
    switch (active) {
      case "profile":
        return <Profile />;
      case "company":
        return <Company />;
      case "sync":
        return (
          <Card
            style={{
              padding: "30px 32px",
              border: `1px solid ${
                connectedToXero
                  ? COLORS.xero
                  : connectedToQuickBooks
                  ? COLORS.quickbooks
                  : "#e6e6e6"
              }`,
            }}
          >
            {/* <Integrations /> */}
            <SyncAccountingSoftware isSettingsScreen />
          </Card>
        );
      case "currencies":
        return (
          <Card
            style={{
              padding: "30px 32px",
            }}
          >
            <Currencies />
          </Card>
        );
      case "connect":
        return (
          <Card
            style={{
              padding: "30px 32px",
            }}
          >
            <LeanPayment />
          </Card>
        );
      case "email":
        return <Notification />;
      case "pricing_plan":
        return <PricingPlanSettings />;
      case "collabrator":
        return (
          <Card
            style={{
              padding: "30px 32px",
            }}
          >
            <TeamSettings />
          </Card>
        );
      default:
        return (
          <div
            style={{
              height: "64vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="subtitle is-4">
              {SETTINGS.find((setting: any) => setting.key === active)?.title ||
                ""}
            </div>
            <img
              src={require("../../../assets/images/coming-soon-page.png")}
              alt=""
            />
          </div>
        );
    }
  };

  const renderTopHeader = () => (
    <div className="flex-direction-row-space-between">
      {/* <button
        onClick={() => {
          axios.get(configs.urls.BASE_URL + "/createCustomer", {
            withCredentials: true,
          });
        }}
      >
        asdas
      </button> */}
      <StyledComponents.ListItemCardHeaderContainer>
        <StyledComponents.ImageOnListItemCard
          src={getAvatarPlaceholder(user?.email)}
        />
        <StyledComponents.NameOnListItemCard>
          {user?.email}
          <CompanyText>{organizationName}</CompanyText>
        </StyledComponents.NameOnListItemCard>
      </StyledComponents.ListItemCardHeaderContainer>
      <Logout />
    </div>
  );

  return (
    <ShowDetailsCard
      renderTopHeader={renderTopHeader}
      renderCard={renderCard}
    />
  );
};

export default RightView;

const CompanyText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  opacity: 0.5;
`;
