import React from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  children: string;
  isSignIn?: boolean;
}

const SubmitButton: React.FC<Props> = (props) => {
  const { loading, children, style, className, isSignIn, ...rest } = props;
  return (
    <button
      style={{
        backgroundColor: "#00d28f",
        color: "white",
        padding: "10px 20px",
        borderRadius: "10px",
        width: "100%",
        // minWidth: "300px",
        ...style,
      }}
      className={
        `button my-${isSignIn ? "0" : "2"} ${loading ? "is-loading" : ""} ` +
        className
      }
      {...rest}
    >
      {children}
    </button>
  );
};

export default SubmitButton;
