import "./styles.css";
import React from "react";

function PageLayout({ left, right, leftStyles, rightStyles }: any) {
  return (
    <div className="columns" style={{ margin: 0 }}>
      <div
        className=""
        style={{
          maxWidth: "50%",
          minWidth: "50%",
          overflowY: "hidden",
          ...leftStyles,
        }}
      >
        {left}
      </div>
      <div
        className="page-layout-right pt-4 pb-6"
        style={{
          backgroundColor: "white",
          position: "fixed",
          right: 0,
          maxWidth: "50%",
          minWidth: "50%",
          height: "100vh",
          overflowY: "hidden",
          overflowX: "hidden",
          ...rightStyles,
        }}
      >
        {right}
      </div>
    </div>
  );
}

export default PageLayout;
