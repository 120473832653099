import {
  faCheck,
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import _ from "lodash";
import React, { Fragment, useMemo } from "react";
import ConnectBankAccount from "../../../common/ConnectBankAccount/ConnectBankAccount";
import { appTheme, configs } from "../../../constants";
import { IS_LEAN_SANDBOX, LEAN_APP_TOKEN } from "../../../constants/configs";
import { useAuth } from "../../../contexts";
import CreateDestination from "./CreateDestination";
import EnterAmountModal from "./EnterAmountModal";

// const LEAN_APP_TOKEN = "3ab64f2c-2310-4083-91f1-69189020fa77";
// const LEAN_APP_TOKEN = process.env.LEAN_APP_TOKEN || "";

function LeanPayment() {
  const { fetchConnections: reFetchConnections } = useAuth();
  const { setGlobalLoading } = useAuth();

  const [customerId, setCustomerId] = React.useState<string>("");
  const [entityId, setEntityId] = React.useState<string>(
    "34851ff9-99c6-3e01-b82a-12fe1eefd288"
  );
  const [loading, setloading] = React.useState<boolean>(false);
  const [detailLoading, setDetailLoading] = React.useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = React.useState<any[]>([]);
  const [externalAccounts, setExternalAccounts] = React.useState<any[]>([]);
  const [paymentSource, setPaymentSource] = React.useState<any>();

  const fetchLinkedBanks = async () => {
    axios
      .get(configs.urls.BASE_URL + "/getLinkedLeanAccounts", {
        withCredentials: true,
      })
      .then((res) => {
        console.log("getLinkedLeanAccounts", res.data);
        if (res.data !== "error") {
          setBankAccounts(res.data);
        }
      });
  };
  const fetchExternalAccounts = async () => {
    axios
      .get(configs.urls.BASE_URL + "/contact/getAll", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          setExternalAccounts(res.data.response);
        }
      });
  };

  let filterExternalAccounts = useMemo(() => {
    return externalAccounts.filter((item) => item.destinationId !== "");
  }, [externalAccounts]);

  const fetchPaymentSource = async () => {
    // getPaymentSource
    axios
      .get(configs.urls.BASE_URL + "/getPaymentSource", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data !== "error") {
          setPaymentSource(res.data);
        }
      });
  };

  React.useEffect(() => {
    // get customer Id
    axios
      .get(configs.urls.BASE_URL + "/getLeanCustomerId", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data !== "error") {
          setCustomerId(res.data);
          console.log("gotLeanCustomerId", res.data);
        }
      });

    fetchLinkedBanks();
    // fetchExternalAccounts();
    // fetchPaymentSource();
  }, []);

  const leanCallback = (payload: any) => {
    console.log("Callback triggered", payload);
    if (payload.status === "ERROR" || payload.status === "CANCELLED") {
      showNotification({
        color: "orange",
        title: payload.status,
        message: `${payload.message ? payload.message : ""}`,
        icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
        autoClose: 2000,
      });
    } else {
      showNotification({
        color: "teal",
        title: payload.status,
        message: payload.message,
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 2000,
      });
    }
  };

  // const linkBankAccount = () => {
  //   // check if customer exists

  //   if (customerId === "") {
  //     return showNotification({
  //       color: "red",
  //       message: `Error: Your organization is not registered with Lean, try contacting your developer!.`,
  //       icon: <FontAwesomeIcon icon={faTimes} />,
  //       autoClose: 2000,
  //     });
  //   }
  //   // @ts-ignore
  //   Lean.link({
  //     app_token: LEAN_APP_TOKEN,
  //     customer_id: customerId,
  //     permissions: ["identity", "accounts", "balance"],
  //     sandbox: "true",
  //     callback: () => fetchLinkedBanks(),
  //   });
  // };

  const getbankDetails = () => {
    if (entityId === "") {
      return showNotification({
        color: "red",
        message: `Please link a bank account with Lean first.`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
    setDetailLoading(true);

    fetch(configs.urls.LEAN_URL + "/v1/accounts", {
      method: "POST",
      headers: {
        "lean-app-token": LEAN_APP_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        entity_id: entityId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("Response from accounts", res);
        const accountsResponse = res?.payload?.accounts;
        if (accountsResponse?.length > 0) {
          let tempAccounts: any[] = [];
          accountsResponse.forEach((account: any) => {
            fetch(configs.urls.LEAN_URL + "/v1/balance", {
              method: "POST",
              headers: {
                "lean-app-token": LEAN_APP_TOKEN,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                entity_id: entityId,
                account_id: account.account_id,
              }),
            })
              .then((res) => res.json())
              .then((res) => {
                console.log("Response from Lean account balance", res.payload);
                tempAccounts.push({
                  ...account,
                  balance: res?.payload?.balance,
                });
              });
          });
          console.log("tempAccounts", tempAccounts);

          setTimeout(() => {
            setDetailLoading(false);
            setBankAccounts(tempAccounts);
          }, 2000);
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `Cannot connect to Lean, ${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      });
  };

  const unlinkBank = (customerId: string, entityId: string) => {
    fetch(
      `${configs.urls.LEAN_URL}/customers/v1/${customerId}/entities/${entityId}`,
      {
        method: "DELETE",
        headers: {
          "lean-app-token": LEAN_APP_TOKEN,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reason: "USER_REQUESTED",
        }),
      }
    ).then((res) => {
      console.log("Response from Lean", res);
      if (res.status === 200) {
        // setCustomerId("");
        // setEntityId("");
        // setBankAccounts([]);
        axios
          .post(
            configs.urls.BASE_URL + "/deleteLeanEntity",
            { entityId },
            {
              withCredentials: true,
            }
          )
          .then(() => {
            fetchLinkedBanks();
            reFetchConnections();
          });
        showNotification({
          color: "green",
          message: `Bank has been unlinked successfully.`,
          icon: <FontAwesomeIcon icon={faCheck} />,
          autoClose: 2000,
        });
      }
    });
  };

  const unlinkAllBanks = () => {
    setGlobalLoading(true);
    // setBankAccounts([]);
    axios
      .delete(configs.urls.BASE_URL + "/unlinkAllBankConnections", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.success) {
          setTimeout(async () => {
            await fetchPaymentSource();
            showNotification({
              color: "teal",
              message: res.data.response,
              icon: <FontAwesomeIcon icon={faCheck} />,
              autoClose: 2000,
            });
            await fetchLinkedBanks();
            setGlobalLoading(false);
          }, 2000);
        } else {
          showNotification({
            color: "red",
            message: `Error: ${res.data.response}`,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
          setGlobalLoading(false);
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: err,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
        setGlobalLoading(false);
      });
  };

  const deleteExternalAccount = (iban: string) => {
    // fetch(`https://sandbox.leantech.me/customers/v1/${customerId}/entities/`, {
    //   method: "DELETE",
    //   headers: {
    //     "lean-app-token": LEAN_APP_TOKEN,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     reason: "USER_REQUESTED",
    //   }),
    // }).then((res) => {
    //   console.log("response after deleting entities:", res);
    // });
    axios
      .delete(configs.urls.BASE_URL + "/deleteExternalAccount", {
        withCredentials: true,
        data: { iban },
      })
      .then((res) => {
        console.log("deleteExternalAccount", res.data);
        if (res.data === "success") {
          showNotification({
            color: "teal",
            message: "Account has been deleted successfully.",
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
          fetchExternalAccounts();
        } else {
          showNotification({
            color: "red",
            message: "Error: Account could not be deleted.",
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      });
  };
  //
  const [selectedDestinationId, setSelectedDestinationId] = React.useState<
    string | null
  >(null);
  const [createDestModal, showCreateDestModal] = React.useState<boolean>(false);
  const [transferAmountModal, showTransferAmountModal] =
    React.useState<boolean>(false);

  const findPaymentSource = async (destinationId: string) => {
    console.log("customerId in paymentSources", customerId);
    const paymentSourcesResponse = await axios.get(
      `https://sandbox.leantech.me/customers/v1/${customerId}/payment-sources/`,
      {
        headers: {
          "lean-app-token": LEAN_APP_TOKEN,
          "Content-Type": "application/json",
        },
      }
    );

    console.log(
      "paymentSource object for destID: ",
      destinationId,
      " is ",
      paymentSourcesResponse.data
    );
    const paymentSources = paymentSourcesResponse.data;

    return paymentSources;
  };

  const initiatePaymentProcess = (
    beneficiaryStatus: any,
    accountId: any,
    amountToPay: any,
    destinationId: any
  ) => {
    console.log("beneficiaryStatus", beneficiaryStatus);
    console.log("accountId", accountId);
    if (beneficiaryStatus === "ACTIVE") {
      // create payment intent
      fetch(`${configs.urls.LEAN_URL}/payments/v1/intents`, {
        method: "POST",
        headers: {
          "lean-app-token": LEAN_APP_TOKEN,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amountToPay,
          currency: localStorage.getItem("baseCurrency") || "AED",
          payment_destination_id: destinationId,
          customer_id: customerId,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log("response after PAYING:", res);
          const paymentIntentId = res.payment_intent_id;
          // @ts-ignore
          Lean.pay({
            app_token: LEAN_APP_TOKEN,
            payment_intent_id: paymentIntentId,
            sandbox: IS_LEAN_SANDBOX,
            account_id: accountId,
            callback: (payload: any) => {
              fetchPaymentSource();
              leanCallback(payload);
            },
          });
        })
        .catch((err) => {
          console.log("Error while creating payment intent", err);
        });
    } else {
      // show notification that benf is in COOL_OFF period
      showNotification({
        color: "red",
        title: "Transaction Failed!",
        message: `This beneficiary is in ${beneficiaryStatus} status`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  // const payWithLean = async (destinationId: any, amountToPay: number) => {
  //   console.log("destinationId", destinationId);
  //   // const amountToPay = 1000;

  //   const paymentSources = await findPaymentSource(destinationId);

  //   console.log("paymentSources", paymentSources);
  //   const currentPaymentSource = paymentSources.find(
  //     (source: any) =>
  //       source.beneficiaries[0].payment_destination_id === destinationId
  //   );
  //   console.log("currentPaymentSource", currentPaymentSource);

  //   if (currentPaymentSource) {
  //     initiatePaymentProcess(currentPaymentSource, amountToPay, destinationId);
  //   } else {
  //     // create payment source
  //     if (paymentSources.length === 0) {
  //       //@ts-ignore
  //       Lean.createPaymentSource({
  //         app_token: LEAN_APP_TOKEN,
  //         permissions: ["payments"],
  //         customer_id: customerId,
  //         payment_destination_id: destinationId,
  //         sandbox: "true",
  //         callback: async (payload: any) => {
  //           leanCallback(payload);

  //           const paymentSourcesNew = await findPaymentSource(destinationId);
  //           const currentPaymentSourceNew = paymentSourcesNew.find(
  //             (source: any) =>
  //               source.beneficiaries[0].payment_destination_id === destinationId
  //           );
  //           console.log(
  //             "currentPaymentSourceNew(created)",
  //             currentPaymentSourceNew
  //           );
  //           initiatePaymentProcess(
  //             currentPaymentSourceNew,
  //             amountToPay,
  //             destinationId
  //           );
  //         },
  //       });
  //     } else {
  //       // that means Payment Source for other user exists
  //       //@ts-ignore
  //       Lean.updatePaymentSource({
  //         app_token: LEAN_APP_TOKEN,
  //         permissions: ["payments"],
  //         customer_id: customerId,
  //         payment_source_id: paymentSources[0].id,
  //         payment_destination_id: destinationId,
  //         sandbox: "true",
  //         callback: async (payload: any) => {
  //           leanCallback(payload);

  //           const paymentSourcesNew = await findPaymentSource(destinationId);
  //           const currentPaymentSourceNew = paymentSourcesNew[0];
  //           console.log(
  //             "currentPaymentSourceNew(updated)",
  //             currentPaymentSourceNew
  //           );
  //           initiatePaymentProcess(
  //             currentPaymentSourceNew,
  //             amountToPay,
  //             destinationId
  //           );
  //         },
  //       });
  //     }

  //     // new get created payment source to initiate payment
  //   }
  // };

  /////////////////////////////////
  // ufff! new flow?! ; chalo let's start
  const payP2P = (destinationId: any) => {
    console.log("paymentSource", paymentSource);
    if (paymentSource) {
      if (
        paymentSource.beneficiaries.find(
          (b: any) => b.destinationId === destinationId
        )
      ) {
        // alert("already exists!");
        showTransferAmountModal(true);
      } else {
        // update
        //@ts-ignore
        Lean.createBeneficiary({
          app_token: LEAN_APP_TOKEN,
          permissions: ["payments"],
          customer_id: customerId,
          payment_source_id: paymentSource?.paymentSourceId,
          payment_destination_id: destinationId,
          sandbox: IS_LEAN_SANDBOX,
          callback: async (payload: any) => {
            leanCallback(payload);
            if (payload.status !== "ERROR" && payload.status !== "CANCELLED") {
              fetchPaymentSource();
              showTransferAmountModal(true);
            }

            // const paymentSourcesNew = await findPaymentSource(destinationId);
            // const currentPaymentSourceNew = paymentSourcesNew[0];
            // console.log(
            //   "currentPaymentSourceNew(updated)",
            //   currentPaymentSourceNew
            // );
            // initiatePaymentProcess(
            //   currentPaymentSourceNew,
            //   amountToPay,
            //   destinationId
            // );
          },
        });
      }
    } else {
      // create
      //@ts-ignore
      Lean.createPaymentSource({
        app_token: LEAN_APP_TOKEN,
        permissions: ["payments"],
        customer_id: customerId,
        payment_destination_id: destinationId,
        sandbox: IS_LEAN_SANDBOX,
        callback: async (payload: any) => {
          leanCallback(payload);
          if (payload.status !== "ERROR" && payload.status !== "CANCELLED") {
            setTimeout(() => {
              fetchPaymentSource();
              showTransferAmountModal(true);
            }, 2000);
          }
          // initiatePaymentProcess(paymentSource, amountToPay, destinationId);
        },
      });
    }
  };

  return (
    <div>
      {/* <div
        className={`button is-subtle m-3`}
        onClick={() => showCreateDestModal(true)}
      >
        Create Beneficiary
      </div> */}

      {bankAccounts.length > 0 ? (
        process.env.REACT_APP_NODE_ENV === "production" ? (
          <ConnectBankAccount
            fetchLinkedBanks={fetchLinkedBanks}
            bankAccounts={bankAccounts}
            fetchPaymentSource={fetchPaymentSource}
          />
        ) : (
          <Fragment>
            <div className="title mt-5">Linked Banks</div>
            {bankAccounts.map((bankAccount, index) => (
              <div
                key={bankAccount.account_id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 20,

                  paddingTop: 5,
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img
                    style={{
                      backgroundColor: bankAccount.mainColor,
                      height: 150,
                      width: 150,
                      borderRadius: 10,
                    }}
                    src={bankAccount.logo}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    flex: 6,
                    borderLeft: "5px solid #ccc",
                    paddingLeft: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontWeight: "700", fontSize: 16 }}>
                      {index + 1}. {bankAccount.bankName}
                    </span>
                    <button
                      className="button is-danger is-small"
                      onClick={() =>
                        unlinkBank(customerId, bankAccount.entityId)
                      }
                    >
                      Unlink
                    </button>
                  </div>
                  <em>Accounts:</em>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {bankAccount.leanAccounts.map(
                      (account: any, index: number) => (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #ccc",
                            margin: 3,
                            padding: 3,
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "700",
                              borderBottom: "1px solid #ccc",
                            }}
                          >
                            {index + 1}
                          </div>
                          <div>
                            <strong>Name: </strong>
                            {account.name}
                          </div>
                          <div>
                            <strong>Type: </strong> {account.type}
                          </div>
                          <div>
                            <strong>Currency: </strong>
                            {account.currencyCode}
                          </div>
                          <div>
                            <strong>Balance: </strong>{" "}
                            {account.balance
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Fragment>
        )
      ) : (
        <ConnectBankAccount
          bankAccounts={bankAccounts}
          fetchLinkedBanks={fetchLinkedBanks}
          fetchPaymentSource={fetchPaymentSource}
        />
      )}

      {process.env.REACT_APP_NODE_ENV === "production" ? (
        <div
          className="is-flex is-flex-direction-column is-align-items-center mt-6 pt-6"
          // style={{ borderTop: "2px solid #ccc" }}
        >
          {/* <button
            disabled={_.isEmpty(paymentSource)}
            className={`button m-3  `}
            style={{
              backgroundColor: appTheme.COLORS.btnDelete,
              color: appTheme.COLORS.white,
            }}
            onClick={unlinkAllBanks}
          >
            {paymentSource ? "Unlink Bank Connections" : "No bank connected"}
          </button> */}
        </div>
      ) : (
        <div
          className="is-flex is-flex-direction-column is-align-items-center mt-6 pt-6"
          // style={{ borderTop: "2px solid #ccc" }}
        >
          {/* <button
            disabled={_.isEmpty(paymentSource)}
            className={`button m-3  `}
            style={{
              backgroundColor: appTheme.COLORS.btnDelete,
              color: appTheme.COLORS.white,
            }}
            onClick={() => {
              // unlinkBankConnections
              axios
                .delete(
                  configs.urls.BASE_URL + "/contact/unlinkBankConnections",
                  {
                    withCredentials: true,
                  }
                )
                .then((res) => {
                  if (res.data === "success") {
                    showNotification({
                      color: "teal",
                      message: "Bank has been unlinked successfully.",
                      icon: <FontAwesomeIcon icon={faCheck} />,
                      autoClose: 2000,
                    });
                    fetchPaymentSource();
                  } else {
                    showNotification({
                      color: "red",
                      message: "Error: Cannot unlink the bank.",
                      icon: <FontAwesomeIcon icon={faTimes} />,
                      autoClose: 2000,
                    });
                  }
                })
                .catch((err) => {
                  showNotification({
                    color: "red",
                    message: `${err}`,
                    icon: <FontAwesomeIcon icon={faTimes} />,
                    autoClose: 2000,
                  });
                });
            }}
          >
            {paymentSource ? "Unlink Payments Connection" : "No bank connected"}
          </button> */}
        </div>
      )}
      {/* <div className="title mt-6">Beneficiaries</div> */}
      {/* <div style={{}}>
        {filterExternalAccounts.length === 0 ? (
          <div>No contact seems to be added as a beneficiary.</div>
        ) : (
          filterExternalAccounts.map(
            (account: any, index: number) =>
              account.destinationId !== "" && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    key={index}
                    style={{
                      border: "1px solid #ccc",
                      margin: 3,
                      padding: 3,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                        borderBottom: "1px solid #ccc",
                      }}
                    >
                      {index + 1}
                    </div>
                    <div>
                      <strong>Display Name: </strong>
                      {account.displayName}
                    </div>
                    <div>
                      <strong>City: </strong> {account.city}
                    </div>
                    <div>
                      <strong>IBAN: </strong>
                      {account.iban}
                    </div>
                  </div>

                  <div>
                    <button
                      className="button is-success is-small m-2"
                      onClick={() => {
                        setSelectedDestinationId(account.destinationId);
                        // showTransferAmountModal(true);
                        payP2P(account.destinationId);
                      }}
                    >
                      Pay this benificiary
                    </button>
                    <button
                      className="button is-danger is-small m-2"
                      onClick={() => deleteExternalAccount(account.iban)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )
          )
        )}
      </div> */}
      {/* Modal */}
      <CreateDestination
        opened={createDestModal}
        onClose={() => {
          showCreateDestModal(false);
          fetchExternalAccounts();
        }}
      />
      {paymentSource && (
        <EnterAmountModal
          paymentSource={paymentSource}
          opened={transferAmountModal}
          onClose={() => {
            showTransferAmountModal(false);
          }}
          onSubmit={(values: any, selectedAccount: any) => {
            showTransferAmountModal(false);
            const beneficiaryStatus = paymentSource?.beneficiaries?.find(
              (b: any) => b.destinationId === selectedDestinationId
            )?.status;
            const accountId = selectedAccount.accountId;
            selectedDestinationId &&
              initiatePaymentProcess(
                beneficiaryStatus,
                accountId,
                parseFloat(values.amount),
                selectedDestinationId
              );
          }}
          onBankChange={() => {
            showTransferAmountModal(false);
            //@ts-ignore
            Lean.connect({
              app_token: LEAN_APP_TOKEN,
              permissions: ["identity", "accounts", "transactions", "balance"],
              customer_id: customerId,
              payment_destination_id: selectedDestinationId,
              sandbox: IS_LEAN_SANDBOX,
              callback: async (payload: any) => {
                leanCallback(payload);
                if (
                  payload.status !== "ERROR" &&
                  payload.status !== "CANCELLED"
                ) {
                  fetchPaymentSource();
                }
                showTransferAmountModal(true);
              },
            });
          }}
        />
      )}
    </div>
  );
}

export default LeanPayment;

/**
 * curl -X POST 'https://sandbox.leantech.me/v1/accounts' \
    --header 'Content-Type: application/json' \
    --header 'lean-app-token: 3ab64f2c-2310-4083-91f1-69189020fa77' \
    --data-raw '{ "entity_id": "b7638eb6-8019-3ff6-a87c-3439b7a44b32" }'

    curl -X POST 'https://sandbox.leantech.me/customers/v1' \
--header 'lean-app-token: 3ab64f2c-2310-4083-91f1-69189020fa77' \
--header 'Content-Type: application/json' \
--data-raw '{
  "app_user_id": "prod_user_001"
}'

curl -X GET 'https://sandbox.leantech.me/customers/v1/7b980cc6-69fb-4b4d-972a-deabb8532aed/entities' \
    --header 'lean-app-token: 3ab64f2c-2310-4083-91f1-69189020fa77' \
        --header 'Content-Type: application/json'


        curl -X DELETE 'https://sandbox.leantech.me/customers/v1/7b980cc6-69fb-4b4d-972a-deabb8532aed//entities/b7638eb6-8019-3ff6-a87c-3439b7a44b32' \
  --header 'Content-Type: application/json' \
    --header 'lean-app-token: 3ab64f2c-2310-4083-91f1-69189020fa77' \
  --data-raw '{
    "reason": "USER_REQUESTED"
  }'

  curl -X GET 'https://sandbox.leantech.me/customers/v1/app-user-id/applivity' \
--header 'lean-app-token: 3ab64f2c-2310-4083-91f1-69189020fa77', \
--header 'Content-Type: application/json' 


curl -X POST 'https://sandbox.leantech.me/data/v1/accounts' \
    --header 'lean-app-token: 3ab64f2c-2310-4083-91f1-69189020fa77' \
        --header 'Content-Type: application/json'\
        --data-raw '{ "entity_id": "b7638eb6-8019-3ff6-a87c-3439b7a44b32" }'

    curl -X POST 'https://sandbox.leantech.me/payments/v1/destinations' \
  --header 'Content-Type: application/json' \
  --header 'lean-app-token: YOUR_APP_TOKEN' \
  --data-raw '{
    "display_name": "Acme USD Account",
    "name": "Acme Inc.",
    "bank_identifier": "ENBD_UAE",
    "address": "123 Fake St.",
    "city": "Dubai",
    "country": "ARE",
    "account_number": "1008223921001",
    "swift_code": "EBILAEAD"
    "iban": "AE260260001008223921001",
  }'


curl -X DELETE 'https://api.leantech.me/customers/v1/918f4b68-6308-4d1e-a1a4-d0daaa930c4d/payment-sources/' \
    --header 'lean-app-token: 3ab64f2c-2310-4083-91f1-69189020fa77' \
    --header 'Content-Type: application/json' \
    --data-raw '{
        "reason": "USER_REQUESTED"
    }'




 */
