import "./styles.css";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";

import { PREV_STAGE } from "../../constants/globalConstants";

function BackChevron() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    const currentRoute = location.pathname;
    navigate(PREV_STAGE[currentRoute]);
  };

  return (
    <FontAwesomeIcon
      className="left-chevron"
      icon={faChevronLeft}
      onClick={handleBackClick}
    />
  );
}

export default BackChevron;
