import axios from "axios";

import { configs } from "../constants";
import { getUserLS } from "../modules/localStorage";

export const updateOrganization = async (updateUser: Record<string, any>) => {
  const organizationId = localStorage.getItem("organizationId");
  const { organizationId: userOrgId } = getUserLS();
  const { data: { success } = {} } = await axios.patch(
    configs.urls.BASE_URL +
      `/organization/update/${organizationId || userOrgId}`,
    {
      ...updateUser,
    }
  );
  return success;
};

export const createOrganization = async (
  createOrganization: Record<string, any>
) => {
  try {
    const userId = localStorage.getItem("userId");
    const { data } = await axios.post(
      configs.urls.BASE_URL + `/organization/create/`,
      {
        userId,
        status: "active",
        ...createOrganization,
      }
    );
    return data;
  } catch (err) {
    return { success: false, data: {} };
  }
};

export const getAllOrganizations = async () => {
  const {
    data: { response },
  } = await axios.get(configs.urls.BASE_URL + `/organization/`);
  return response;
};

export const getOrganizationById = async (id: string) => {
  const {
    data: { response },
  } = await axios.get(configs.urls.BASE_URL + `/organization/${id}`);
  return response;
};
export const setActiveOrganization = async (id: string) => {
  axios.defaults.withCredentials = true;
  const {
    data: { response },
  } = await axios.patch(
    configs.urls.BASE_URL + `/organization/setActive/${id}`
  );
  return response;
};
