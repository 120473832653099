import { Form, Formik } from "formik";
import React, { Fragment, useState } from "react";
import { CardForm } from "../../../common/CardForm";
import { CardTitle } from "../../../common/CardForm/CardForm";
import { CustomInput } from "../../../common/CustomInput";
import { configs, StyledComponents } from "../../../constants";
import { COUNTRIES_LIST } from "../../../constants/globalConstants";
import { COLORS } from "../../../constants/theme";
import Select from "react-select";
import HelpFooter from "./HelpFooter";
import styled from "styled-components";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { INITIAL_BENEFICIARY } from "../constants";
import { useContactsContext } from "../contexts/ContactsContext";

function AddBeneficiaryDetails({
  onClose,
  onSubmit,
  contact,
  beneficiary,
  onNext,
}: any) {
  const [continueLater, setContinueLater] = useState(false);
  const { isEditMode } = useContactsContext();

  const saveContact = (values: any, setSubmitting: Function) => {
    axios
      .post(
        configs.urls.BASE_URL + "/contact/saveBeneficiary",
        {
          ...values,
          contactName: contact.name,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          showNotification({
            color: "teal",
            message: res.data.response,
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      })
      .finally(() => {
        setSubmitting(false);
        if (continueLater) onClose();
        else onSubmit(values);
      });
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          ...beneficiary,
        }}
        validate={(values: any) => {
          const errors: any = {};
          // //   displayName: string;
          // if (!values.displayName) {
          //   errors.displayName = "Display Name Required";
          // }
          // //   name: string;
          if (!values.name) {
            errors.name = "Name required.";
          } else if (!/^[a-zA-Z0-9\s_]*$/g.test(values.name)) {
            errors.name = "Name should be only alphanumeric.";
          }

          if (!values.email) {
            errors.email = "Email required.";
          } else if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.country) {
            errors.country = "Country required.";
          }

          if (!values.state) {
            errors.state = "State required.";
            errors.country = `&nbsp;`;
          }

          if (!values.street) {
            errors.street = "Street required.";
          }

          if (!values.poBox) {
            errors.poBox = "P.O. Box required.";
          } else if (!/^[0-9]*$/g.test(values.poBox)) {
            errors.poBox = "P.O. Box should be only numeric.";
          } else if (values.poBox.length > 5 || values.poBox.length < 2) {
            errors.poBox = "P.O. Box should be 2-5 digits.";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }: any) => {
          setSubmitting(true);
          setTimeout(() => {
            saveContact(values, setSubmitting);
          }, 400);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          handleChange,
          values,
          setValues,
        }: any) => (
          <Form>
            <CardForm
              title={"Beneficiary details"}
              cardStyles={{
                position: "relative",
                width: "90%",
                zIndex: 5,
              }}
            >
              <CustomInput label={"Beneficiary type"} />
              <div className="flex-direction-row ml-2 is-justify-content-flex-start">
                <StyledComponents.CustomCheckbox
                  checked={values.type === "person"}
                  onChange={() => {
                    setValues({
                      ...values,
                      type: "person",
                    });
                  }}
                  label="Person"
                  size="sm"
                  labelPosition="right"
                  color={COLORS.greenBlue}
                  className="mr-6"
                />

                <StyledComponents.CustomCheckbox
                  checked={values.type === "organization"}
                  onChange={() => {
                    setValues({
                      ...values,
                      type: "organization",
                    });
                  }}
                  label="Organization"
                  size="sm"
                  labelPosition="right"
                  color={COLORS.greenBlue}
                />
              </div>

              <CustomInput name="types" formikErrors={{ errors, touched }} />

              <CustomInput
                label={`Full name of ${values.type}`}
                onChange={handleChange}
                name="name"
                placeholder={
                  values.type === "person" ? "e.g. John Doe" : "e.g. Amazon"
                }
                formikErrors={{ errors, touched }}
                value={values.name}
              />
              <CustomInput
                label="Email address"
                onChange={handleChange}
                name="email"
                placeholder="e.g. john@gmail.com"
                formikErrors={{ errors, touched }}
                value={values.email}
              />

              <CardTitle className="mt-5">Billing address</CardTitle>

              <div className="flex-direction-row mt-3 is-justify-content-space-between">
                <div
                  className="is-flex is-flex-direction-column  is-justify-content-flex-end"
                  style={{ width: "50%" }}
                >
                  <CustomInput
                    label="Country"
                    formikErrors={{ errors, touched }}
                    labelStyles={{
                      position: "absolute",
                      top: 0,
                    }}
                    value={values.country}
                  />
                  <Select
                    className="select-field mr-2 ml-2 mb-2"
                    value={{
                      value: values.country,
                      label: values.country,
                    }}
                    // isClearable={true}
                    onChange={(newValue: any) => {
                      setValues({
                        ...values,
                        country: newValue.value,
                      });
                    }}
                    options={COUNTRIES_LIST}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        fontSize: "17px",
                      }),

                      // bold input text
                      input: (provided) => ({
                        ...provided,
                        fontWeight: "bold",
                      }),
                    }}
                    name="country"
                  />
                  {errors.country && touched.country && (
                    <FieldError
                      dangerouslySetInnerHTML={{
                        __html: errors.country,
                      }}
                    />
                  )}
                </div>
                <CustomInput
                  label="State"
                  onChange={handleChange}
                  name="state"
                  placeholder="Dubai"
                  formikErrors={{ errors, touched }}
                  value={values.state}
                />
              </div>
              <div className="flex-direction-row ">
                <CustomInput
                  label="Street"
                  onChange={handleChange}
                  name="street"
                  placeholder="45 Landing Street"
                  formikErrors={{ errors, touched }}
                  value={values.street}
                />
                <CustomInput
                  label="P.O. Box"
                  onChange={handleChange}
                  name="poBox"
                  placeholder="43243"
                  formikErrors={{ errors, touched }}
                  value={values.poBox}
                />
              </div>
              <HelpFooter />
            </CardForm>

            <div
              className="flex-direction-column is-align-items-center"
              style={{
                color: "white",
              }}
            >
              {_.isEqual(values, beneficiary) &&
              !_.isEqual(values, INITIAL_BENEFICIARY) ? (
                <button
                  type="button"
                  onClick={onNext}
                  style={{
                    backgroundColor: COLORS.greenPrimary,
                    color: "white",
                    width: "70%",
                  }}
                  className={`button mt-4 is-bold mr-2 `}
                >
                  Next
                </button>
              ) : (
                <Fragment>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    style={{
                      backgroundColor: COLORS.greenPrimary,
                      color: "white",
                      width: "70%",
                    }}
                    className={`button mt-4 is-bold mr-2 ${
                      isSubmitting && "is-loading"
                    }`}
                  >
                    {isEditMode() ? "Update" : "Save"} & Continue
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      if (_.isEmpty(errors)) {
                        setContinueLater(true);
                      } else {
                        setContinueLater(false);
                      }
                    }}
                    style={{
                      width: "60%",
                      fontSize: 12,
                    }}
                    className="button mt-4 is-bold mr-2 is-text"
                  >
                    {isEditMode() ? "Update" : "Save"} & continue later
                  </button>
                </Fragment>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}

export default AddBeneficiaryDetails;

const FieldError = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
  margin-left: 8px;
`;
