import React, { useEffect } from "react";
import Card from "../../common/Card/Card";
import ConnectBankAccount from "../../common/ConnectBankAccount/ConnectBankAccount";
import ListContainer from "../../common/PageLayout/components/ListContainer";
import ListHeader from "../../common/PageLayout/components/ListHeader";
import SyncAccountingSoftware from "../../common/SyncAccountingSoftware/SyncAccountingSoftware";
import {
  getAllOrganizationsOfCurrentUser,
  isEmailVerified,
} from "../../repositories/userRepository";
import InviteTeamMembers from "./components/InviteTeamMembers";

function GetStarted() {
  // useEffect(() => {
  //   getRoute();
  // }, []);

  // const getRoute = async () => {
  //   const verified = await isEmailVerified();
  //   const user = await getAllOrganizationsOfCurrentUser();

  //   if (!user.lastStage && !verified) {
  //     window.location.href = "/verify-email";
  //     return;
  //   }
  //   if (user.lastStage && user.lastStage !== "you-are-all-set-up") {
  //     window.location.href = user.lastStage;
  //   }
  // };

  return (
    <div
      style={{ height: "90vh", overflowY: "scroll" }}
      className="custom-scrollbar"
    >
      <ListContainer className="list-container-get-paid">
        <ListHeader
          hasButton={false}
          buttonIsOutlined
          buttonTitle="View Demo Account"
          title="Let’s get connected"
          style={{ marginBottom: 0 }}
          buttonAction={() => alert("Coming Soon!")}
          // buttonAction={() => setShowAddModal(true)}
        />
        <div className="get-started-container">
          {/* <AlertBox /> */}
          <div className="is-flex is-flex-direction-row is-justify-content-space-between is-flex-wrap-wrap get-started-cards-container">
            <Card isNormal className="get-started-card">
              <SyncAccountingSoftware isGettingStartedScreen />
            </Card>
            <Card isNormal style={{ width: "48%" }}>
              <ConnectBankAccount />
            </Card>
          </div>
          <InviteTeamMembers />
        </div>
      </ListContainer>
    </div>
  );
}

export default GetStarted;
