import "../styles.css";
import { Select, TextInput, Tooltip } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { Fragment, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  CardSubtilteSmall,
  CardTitleBlack,
} from "../../../../common/CardTitles";
import { contacts } from "../../../../constants/images";
import PrimaryButton from "../../../../common/PrimaryButton/PrimaryButton";
import { COLORS } from "../../../../constants/theme";
import { Button } from "rsuite";
import { CustomDrawer } from "../../../../common/CustomDrawer";
import { configs } from "../../../../constants";
import { useResizableColumns } from "../../../../hooks/useResizableColumns";
import { capitalizeFirstLetter } from "../../../../modules/capitalizeString";
import { useAuth } from "../../../../contexts";
import { hasPlanUsersLimitExceeded } from "../../../../modules/doPlanAllow";
import { PLANS, Tier } from "../../../../constants/globalConstants";
import { useNavigate } from "react-router-dom";
import { Button as MantineButton } from "@mantine/core";
import ConfirmationModal from "../../../../common/ConfirmationModal/ConfirmationModal";

const COLUMNS = [
  { label: "Email" },
  { label: "Role" },
  { label: "Status" },
  { label: "Action" },
];

function TeamSettings() {
  const { user, organization, currentPlan } = useAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMemberShowing, setIsMemberShowing] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [roleType, setRoleType] = useState("");
  const [showList, setShowList] = useState<any[]>([]);
  const [allowedMembers, setAllowedMembers] = useState(0);
  const [shouldNotAllowInvite, setShouldNotAllowInvite] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [confirmDeleteParams, setConfirmDeleteParams] = useState<any[]>([]);

  const headerRef = useRef<HTMLTableSectionElement>(null);
  const [widths, handleMouseDown] = useResizableColumns(
    headerRef,
    [100, 100, 100, 100]
  );

  const inviteStatus = (status: string) => {
    if (status === "active") {
      return "Pending";
    } else if (status === "used") {
      return "Accepted";
    } else {
      return "Rejected";
    }
  };

  const plan =
    PLANS.find((plan) => plan.tier === organization?.subscription?.tier) ||
    ({} as any);

  const fetchInvitationsInfo = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        configs.urls.BASE_URL +
          `/invitation/get_all_invitations_info_for_sender`,
        {
          withCredentials: true,
        }
      );

      if (response.data.success === true) {
        const invitationsInfo = response.data.response.invitationsInfo;

        if (plan.tier === Tier.Demo) {
          setAllowedMembers(1);
          setShouldNotAllowInvite(true);
        } else {
          if (invitationsInfo.length) {
            setAllowedMembers(invitationsInfo?.length + 1);
            let usersLimitCrossed = hasPlanUsersLimitExceeded(
              organization?.subscription,
              invitationsInfo?.length
            );
            setShouldNotAllowInvite(usersLimitCrossed);
          } else {
            setAllowedMembers(1);
          }
        }

        const temp = [];

        // temp[0] = {
        //   email: user.email,
        //   role: "Admin",
        //   status: "Not Applicable",
        // };

        for await (let invitation of invitationsInfo) {
          let userInfo = {};

          userInfo = {
            email: invitation.receiverEmail,
            role: capitalizeFirstLetter(invitation.role),
            status: inviteStatus(invitation.status),
            invitationId: invitation.id,
            isCancelled: invitation.isCancelled,
          };

          temp.push(userInfo);
        }

        setShowList(temp);
      }
    } catch (error) {
      console.log("Error while fetching invitations info: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmail("");
    setEmailError("");
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailError("");
  };

  const handleSendEmail = async () => {
    try {
      setLoading(true);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern

      if (email.trim() === "") {
        setEmailError("Valid email is required");
        return;
      }

      if (!emailRegex.test(email)) {
        setEmailError("Invalid email format");
        return;
      }

      const organizationId = "test123";

      const response = await axios.post(
        configs.urls.BASE_URL + "/invitation/send_invitation",
        {
          role: roleType,
          email,
          organizationId,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setEmailError("");
        showNotification({
          title: "Email sent successfully",
          message: "Invitation sent to " + email,
          color: "green",
        });
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log("Failed to send email: ", error);
      showNotification({
        title: "Error",
        message: "Failed to send invitation email",
        color: "red",
      });
    } finally {
      setLoading(false);
      fetchInvitationsInfo();
    }
  };

  const cancelOrRemoveInvitationOrUser = async (
    invitationId: number,
    cancel: boolean,
    email: string
  ) => {
    try {
      setLoading(true);

      const response = await axios.post(
        configs.urls.BASE_URL +
          "/invitation/cancel_or_remove_invitation_or_user",
        {
          invitationId,
          cancel,
          email,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setEmailError("");
        showNotification({
          title: "Invitation cancelled",
          message: "Invitation has been canceled successfully",
          color: "green",
        });
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log("Failed to send email: ", error);
      showNotification({
        title: "Error",
        message: "Failed to send invitation email",
        color: "red",
      });
    } finally {
      setLoading(false);
      fetchInvitationsInfo();
      setConfirmDeleteParams([]);
      setConfirmDeleteModal(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchInvitationsInfo();
  }, []);

  const data = [
    {
      label: "Manager",
      value: "manager",
    },
    {
      label: "Viewer",
      value: "viewer",
    },
  ];

  return (
    <Fragment>
      <CustomDrawer
        opened={isModalOpen}
        onBack={handleCloseModal}
        onClose={handleCloseModal}
        title="Invite Team Members"
      >
        <Container>
          <InputContainer>
            <Label>Email</Label>
            <Fragment>
              <TextInput
                placeholder="Enter Email"
                style={{
                  width: "340px",
                }}
                className="form-edit-field"
                value={email}
                disabled={loading}
                name={"Email"}
                error={emailError}
                onChange={(event) => handleEmailChange(event.target.value)}
              />
            </Fragment>
          </InputContainer>

          <InputContainer>
            <Label>Role</Label>
            <Fragment>
              <Select
                value={roleType}
                variant="filled"
                styles={{
                  input: {
                    border: "1px solid #ddd!important",
                    fontSize: "14px!important",
                    background: "#fff!important",
                    borderBottom: "none!important",
                  },
                }}
                style={{
                  width: "340px",
                }}
                placeholder="Select user role"
                data={data}
                onChange={(value: any) => {
                  setRoleType(value);
                }}
              />
            </Fragment>
          </InputContainer>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <PrimaryButton
              className={`${loading && "is-loading"}`}
              onClick={handleSendEmail}
              style={{ width: "340px" }}
            >
              Invite Team
            </PrimaryButton>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              disabled={loading}
              onClick={handleCloseModal}
              style={{
                backgroundColor: "#fff",
                border: "1px solid #ced4da",
                color: "#000",
                width: "340px",
              }}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </CustomDrawer>
      <CustomDrawer
        opened={isMemberShowing}
        onBack={() => {
          setIsMemberShowing(false);
        }}
        onClose={() => {
          setIsMemberShowing(false);
        }}
        title="Team Members"
      >
        <Container>
          <ConfirmationModal
            loading={loading}
            modalOpened={confirmDeleteModal}
            setModalOpened={setConfirmDeleteModal}
            confirmDeleteFunction={cancelOrRemoveInvitationOrUser}
            title="Confirm Delete"
            message="Are you sure you want to cancel or remove this invitation?"
            confirmDeleteParams={confirmDeleteParams}
          />
          <Fragment>
            {showList.length === 0 ? (
              <Fragment>
                <div
                  style={{
                    marginTop: "10px",
                  }}
                  className="flex-center card-title-black"
                >
                  No invites found
                </div>
              </Fragment>
            ) : (
              <TableContainer className="custom-scrollbar">
                <Table>
                  <thead>
                    <tr>
                      {COLUMNS.map((column, index) => (
                        <th
                          data-resizable
                          key={column.label}
                          style={{ width: `${widths[index]}px` }}
                          onMouseDown={(event) => handleMouseDown(event, index)}
                        >
                          {column.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <Body>
                    {showList.map((row, i) => (
                      <Fragment>
                        <tr key={i}>
                          {Object.keys(row).map((cell, j) => (
                            <td key={j}>
                              {cell === "invitationId" &&
                                row[cell] !== false && (
                                  <Fragment>
                                    <div
                                      style={{
                                        padding: "0.2em",
                                      }}
                                    >
                                      <MantineButton
                                        className={`${loading && "is-loading"}`}
                                        variant="filled"
                                        color="pink"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                          setConfirmDeleteModal(true);
                                          let cancel = false;
                                          if (row?.status === "Pending") {
                                            cancel = true;
                                          } else {
                                            cancel = false;
                                          }
                                          setConfirmDeleteParams([
                                            row[cell],
                                            cancel,
                                            row?.email,
                                          ]);
                                          // cancelOrRemoveInvitationOrUser(
                                          //   row[cell],
                                          //   cancel,
                                          //   row?.email
                                          // );
                                        }}
                                      >
                                        {row?.status === "Pending"
                                          ? "Cancel Invite"
                                          : "Remove User"}
                                      </MantineButton>
                                    </div>
                                  </Fragment>
                                )}
                              {cell !== "invitationId" && (
                                <div
                                  style={{
                                    padding: "0.9em 0.9em 0.9em 0",
                                  }}
                                >
                                  <p>{row[cell]}</p>
                                </div>
                              )}
                            </td>
                          ))}
                        </tr>
                      </Fragment>
                    ))}
                  </Body>
                </Table>
                {allowedMembers > plan.capabilities.noOfUsers && (
                  <Tooltip
                    label={`Upgrade your plan to view users`}
                    transition="scale-y"
                    transitionDuration={300}
                    position="bottom"
                    offset={15}
                    multiline
                    withArrow
                    // hidden={isSyncingAllowed}
                    style={{ textAlign: "center" }}
                  >
                    <div className="flex-center">
                      <PrimaryButton
                        className={`${loading && "is-loading"}`}
                        style={{ width: "70%" }}
                        onClick={() => {
                          navigate("/pricing");
                        }}
                      >
                        Upgrade
                      </PrimaryButton>
                    </div>
                  </Tooltip>
                )}
              </TableContainer>
            )}
          </Fragment>
        </Container>
      </CustomDrawer>
      <div
        className="currency-card"
        style={{ marginLeft: "5rem", marginRight: "5rem" }}
      >
        <div
          style={{
            padding: "20px",
            borderRadius: "50%",
            border: "1px solid #fafafa",
            background: "#fafafa",
            boxShadow: "4px 10px 5px #eee",
            marginBottom: "5px",
          }}
        >
          <img
            style={{
              width: "45px",
              height: "45px",
            }}
            alt="currency_logo"
            src={contacts}
          />
        </div>

        <CardTitleBlack title={"Team Settings"} />
        <CardSubtilteSmall title="Share invite link with your team to join your organization." />
        {/* <CardSubtilteSmall
          title={`You have invited ${allowedMembers}/${plan.capabilities.noOfUsers} Users`}
        /> */}
        {!shouldNotAllowInvite ? (
          <Tooltip
            label={`${
              currentPlan?.capabilities?.noOfUsers - allowedMembers
            } more invitation(s) available.`}
            transition="scale-y"
            transitionDuration={300}
            position="left"
            offset={15}
            multiline
            withArrow
            // hidden={isSyncingAllowed}
            style={{ textAlign: "center" }}
          >
            <PrimaryButton
              disabled={loading || shouldNotAllowInvite}
              className={`${loading && "is-loading"}`}
              style={{ width: "100%" }}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Invite Team
            </PrimaryButton>
          </Tooltip>
        ) : (
          <Tooltip
            transition="scale-x"
            label={"Please upgrade your plan to invite more members."}
            position="left"
            offset={15}
            multiline
            withArrow
            style={{ textAlign: "center" }}
          >
            <PrimaryButton
              className={`${loading && "is-loading"}`}
              style={{ width: "100%" }}
              onClick={() => {
                navigate("/pricing");
              }}
            >
              Upgrade
            </PrimaryButton>
          </Tooltip>
        )}

        <Button
          className={`${loading && "is-loading"}`}
          style={{
            width: "100%",
            backgroundColor: "#fff",
            border: "1px solid #ced4da",
            color: "#000",
            marginTop: "10px",
          }}
          onClick={() => {
            setIsMemberShowing(true);
          }}
        >
          View Members
        </Button>

        <div className="card-note-black ">
          <p>
            {/* {!(currentPlan.tier === Tier.Demo) && (
              <> */}
            Your organization currently has{" "}
            <b>
              {allowedMembers}/{plan.capabilities.noOfUsers} Users.{" "}
            </b>
            {/* </>
            )} */}
            {allowedMembers >= plan.capabilities.noOfUsers &&
              "Upgrade your plan to invite more users."}
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default TeamSettings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 5px;
`;

const InputContainer = styled.div`
  position: relative;
  margin: 8px;
  margin-left: 0px;
  margin-top: 18px;
  font-weight: 700;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;

const TableContainer = styled.div`
  margin-top: 25px;
  height: 60vh;
  overflow-y: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
  }

  tbody tr:hover {
    background-color: #f5f5f5;
  }
`;

const Body = styled.tbody`
  td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
