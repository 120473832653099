import React from "react";
import { Button } from "rsuite";
import styled from "styled-components";
import { If } from "../Utils/If";

function CardButton({
  icon,
  title,
  description,
  onClick,
  imageStyles,
  cardStyles,
  disabled = false,
  children,
}: any) {
  return (
    <ButtonContainer onClick={onClick} style={cardStyles} disabled={disabled}>
      <If condition={icon !== undefined}>
        <Image
          src={icon}
          style={{
            ...imageStyles,
            // disabled style
            opacity: disabled ? 0.3 : 1,
          }}
        />
      </If>

      <Details>
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
      </Details>
      {children}
    </ButtonContainer>
  );
}

export default CardButton;

export const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 37%;
  box-shadow: 0px 1px 5px #9e9e9e;
  background-color: white;
  border: 1px solid #0005;
  @media screen and (max-width: 1600px) {
    width: 47%;
  }
`;
const Image = styled.img`
  margin: 10px;
  margin-right: 20px;
  width: 50px;
  height: 50px;
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Title = styled.h4`
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;
const Subtitle = styled.p`
  color: gray;
  /* take lengthy strings to next line*/
  word-wrap: break-word;
  white-space: break-spaces;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 15px;
  }
`;
