import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import Card from "../../../common/Card/Card";
import styled from "styled-components";
import { COLORS } from "../../../constants/theme";
import { Table } from "../../../common/Table/Table";
import { getUserCurrency } from "../../../modules/getUserCurrency";
import moment from "moment";
import axios from "axios";
import { saveInvoice } from "../../../repositories/invoiceRepository";
import { InvoiceStatus } from "../../../constants/types";
import { useAuth } from "../../../contexts";
import { getUserLS } from "../../../modules/localStorage";
import { Select, Text } from "@mantine/core";
import AddMorePayments from "../../../common/AddMorePayments/AddMorePayments";
import { verifyTotalAmount } from "../../../repositories/currencyRepository";
import AlertModal from "../../../common/AlertModal/AlertModal";

const COLUMNS: Record<string, any> = [
  {
    Header: "Payment Date",
    accessor: "paidOrFailedAt",
    isEditable: true,
    type: "date",
  },
  { Header: "Payment Amount", accessor: "amountPaid" },
  {
    Header: "Currency",
    id: "currency",
    isEditable: true,
    type: "currency",
    accessor: (str: any) => "currency",
    Cell: (tableProps: any) => (
      <span className="no-edit-currency" onClick={() => {}}>
        {getUserCurrency()}
      </span>
    ),
  },
];

interface IAddPaymentType {
  record: Record<string, any>;
  recordType: string;
  onSubmit: Function;
  title: string;
}
function AddPayment({ record, recordType, onSubmit, title }: IAddPaymentType) {
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState(COLUMNS);

  const { getRecords, externalAccounts: _externalAccounts } = useAuth();

  const [convertedTotal, setConvertedTotal] = useState<any>(0);

  const [amountIsConverted, setAmountIsConverted] = useState<boolean>(false);

  const [modalState, setModalState] = useState({
    open: false,
    title: "",
    message: "",
  });

  const modalReset = () => {
    setModalState({
      open: false,
      title: "",
      message: "",
    });
  };

  useEffect(() => {
    const _columns = cloneDeep(columns);

    // setData([
    //   {
    //     paidOrFailedAt: new Date(),
    //     amountPaid: "",
    //     currency: getUserCurrency(),
    //   },
    // ]);
    _columns[0].maxDate = new Date();
    setColumns(_columns);
  }, []);

  useEffect(() => {
    console.log("Dataaaa >>>> ", data);
  }, [data]);

  const addMorePayments = () => {
    setData([
      ...data,
      {
        paidOrFailedAt: new Date(),
        amountPaid: "",
        currency: record?.currency,
      },
    ]);
  };

  const updateMyData = async (rowIndex: any, columnId: any, value: any) => {
    setData((prevState: any) =>
      prevState.map((row: any, index: any) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const updateFieldInData = (index: any, field: any, value: any) => {
    setData((prevData) =>
      prevData.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };

  const handleDeletePayment = (index: any) => {
    setData((prevData) => {
      return prevData.filter((_, i) => i !== index);
    });
  };

  const validateCurrencyInData = (data: any[]) => {
    return data.every(
      (item) => typeof item.currency === "string" && item.currency.trim() !== ""
    );
  };

  const handleConvertData = async () => {
    let invoice: Record<string, any> = {
      ...record,
      name: record.name,
      amountTotal: record.amount,
      amountPaid: "0",
      amountDue: record.amount.toString(),
      dueDate: moment(record.dueDate).format("YYYY-MM-DD"),
      issueDate: moment(new Date()).format("YYYY-MM-DD"),
      type: recordType,
      tags: "",
      // currency: getUserCurrency(),
      currency: record.currency,
      expectedDueDate: moment(record.dueDate).format("YYYY-MM-DD"),
      invoiceNumber: record.referenceNumber,
      cashflowCategory: record.selectedCashflowBucket,
      linkedTransactions: [],
      status: "",
    };

    let totalAmount = 0;
    const selectedBillTotal = Number(invoice.amountTotal);

    if (!validateCurrencyInData(data)) {
      setModalState({
        open: true,
        title: "Invalid Currency!",
        message: "Currency values should not be empty.",
      });
      return;
    }

    for (let obj of data as any) {
      const amountPaid = Number(obj.amountPaid);
      if (!amountPaid || amountPaid === 0) {
        setModalState({
          open: true,
          title: "Invalid Values!",
          message: "Payment amount should not be empty or zero.",
        });
        return; // This will exit the handleSavePayment function entirely
      }

      // totalAmount += amountPaid;
    }

    const filteredData = data.filter(
      (item) => item.amountPaid !== "" && item.paidOrFailedAt !== ""
    );

    try {
      const response = await verifyTotalAmount(
        filteredData,
        selectedBillTotal,
        record.currency
      );

      if (response.data.success) {
        if (
          Number(response.data.response.convertedTotal?.toFixed(2)) >
          selectedBillTotal
        ) {
          setModalState({
            open: true,
            title: "Payment Amount Is Invalid!",
            message:
              "Total payment amount after conversion should not be greater than bill amount.",
          });
          setConvertedTotal(Number(response.data.response.convertedTotal));
          return;
        }
        setConvertedTotal(Number(response.data.response.convertedTotal));
      }
    } catch (error) {
      console.error("Error verifying total amount: ", error);
      setModalState({
        open: true,
        title: "Payment Submission Failed!",
        message: "Missing payment details.",
      });

      return;
    }
  };

  const handleSavePayment = async () => {
    let invoice: Record<string, any> = {
      ...record,
      name: record.name,
      amountTotal: record.amount,
      amountPaid: "0",
      amountDue: record.amount.toString(),
      dueDate: moment(record.dueDate).format("YYYY-MM-DD"),
      issueDate: moment(new Date()).format("YYYY-MM-DD"),
      type: recordType,
      tags: "",
      // currency: getUserCurrency(),
      currency: record.currency,
      expectedDueDate: moment(record.dueDate).format("YYYY-MM-DD"),
      invoiceNumber: record.referenceNumber,
      cashflowCategory: record.selectedCashflowBucket,
      linkedTransactions: [],
      status: "",
    };
    let totalAmount = 0;
    let selectedBillTotal = Number(invoice.amountTotal);

    console.log("RECORD DATAAA >>>>> ", invoice);

    if (!validateCurrencyInData(data)) {
      setModalState({
        open: true,
        title: "Invalid Currency!",
        message: "Currency values should not be empty.",
      });
      return;
    }

    // remove payments with empty values
    for (const item of data) {
      if (item.amountPaid === "" || item.paidOrFailedAt === "") {
        setModalState({
          open: true,
          title: "Invalid Values!",
          message: "Payment amount should not be empty or zero.",
        });
        return; // This will exit the handleSavePayment function entirely
      }
    }

    const filteredData = data.filter(
      (item) => item.amountPaid !== "" && item.paidOrFailedAt !== ""
    );

    try {
      const response = await verifyTotalAmount(
        filteredData,
        selectedBillTotal,
        invoice.currency
      );
      if (response.data.success) {
        console.log(
          "CONVERTED TOTAL >>>>> ",
          response.data.response.convertedTotal
        );
        totalAmount = Number(response.data.response.convertedTotal?.toFixed(2));
        if (
          Number(response.data.response.convertedTotal?.toFixed(2)) >
          selectedBillTotal
        ) {
          setModalState({
            open: true,
            title: "Payment Amount Is Invalid!",
            message:
              "Total payment amount after conversion should not be greater than bill amount.",
          });

          return;
        }
      }
    } catch (error) {
      console.error("Error verifying total amount: ", error);
      setModalState({
        open: true,
        title: "Payment Submission Failed!",
        message: "Missing payment details.",
      });

      return;
    }

    // for (let obj of data as any) {
    //   totalAmount = totalAmount + Number(obj.amountPaid);
    // }

    console.log("selectedBillTotal >>>>> ", selectedBillTotal);
    console.log("totalAmount >>>>> ", totalAmount);

    let amountDue = selectedBillTotal - totalAmount;

    console.log("AMOUNT DUE >>>>> ", amountDue);

    if (totalAmount === 0) {
      invoice = {
        ...invoice,
        status: InvoiceStatus.Unpaid,
        amountDue: String(amountDue),
      };
    }

    if (totalAmount < selectedBillTotal && totalAmount !== 0) {
      invoice = {
        ...invoice,
        status: InvoiceStatus.PartiallyPaid,
        amountDue: String(amountDue),
      };
    }

    if (totalAmount === selectedBillTotal) {
      invoice = {
        ...invoice,
        status: InvoiceStatus.Paid,
      };
    }

    let transactionsList = [];

    for (let transaction of data) {
      transactionsList.push({
        id: transaction.id,
        currency: transaction.currency,
        amountPaid: transaction.amountPaid,
        paidOrFailedAt: transaction.paidOrFailedAt,
      });
    }
    invoice.linkedTransactions = transactionsList;

    axios.defaults.withCredentials = true;
    await saveInvoice({ inv: JSON.stringify(invoice) });
    getRecords(undefined, 0, recordType, "dueDate", "DESC", {});
    setData([]);
    onSubmit();
  };

  return (
    <Container>
      <Card className="add-record-card-container" isNormal noShadow>
        <CardTitle>{title}</CardTitle>
        <div className="flex-direction-column payment-contact-container ">
          <div className="payment-contact">{record.name}</div>
          <br />
          <div className="flex-direction-row-space-between">
            <div className="flex-direction-column">
              <div className="payment-contact-key">Due Date</div>
              <div className="payment-contact-value">{record.dueDate}</div>
            </div>
            <div className="flex-direction-column">
              <div className="payment-contact-key">Total Amount</div>
              <div className="payment-contact-value">
                {record.currency} {record.amount}
              </div>
            </div>
          </div>
        </div>
        {/* <Table
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          selectedCurrency={record.currency}
        /> */}

        <AddMorePayments
          billCurrency={record?.currency}
          convertedTotal={convertedTotal}
          setAmountIsConverted={setAmountIsConverted}
          data={data}
          handleDeletePayment={handleDeletePayment}
          updateFieldInData={updateFieldInData}
        />

        {modalState && (
          <AlertModal
            modalOpened={modalState.open}
            resetModal={modalReset}
            title={modalState.title}
            message={modalState.message}
          />
        )}

        <div className="is-flex">
          <button
            onClick={handleSavePayment}
            style={{
              backgroundColor: COLORS.greenPrimary,
              color: "white",
              width: "100%",
            }}
            className="button mt-4 is-bold "
          >
            Save
          </button>

          <button
            disabled={!data.length}
            onClick={handleConvertData}
            style={{
              backgroundColor: COLORS.greenPrimary,
              color: "white",
              width: "100%",
            }}
            className="button mt-4 is-bold "
          >
            Calculate Total
          </button>

          <button
            onClick={addMorePayments}
            style={{
              backgroundColor: COLORS.greenPrimary,
              color: "white",
              width: "100%",
            }}
            className="button mt-4 is-bold "
          >
            Add Payment
          </button>
        </div>
      </Card>
    </Container>
  );
}

export default AddPayment;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  min-width: 50%;
`;

const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
    margin-left: 6px;
  }
`;
