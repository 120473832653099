import "./styles.css";
import { IconButton } from "rsuite";
import {
  filterBlackIcon,
  filterIcon,
  filterSelectedButton,
} from "../../constants/images";

export const renderFilterIconButton = (
  handleFilterClick?: any,
  selectedFilter?: boolean,
  menuSelected?: string,
  isViewOnly?: boolean
) => {
  return (
    <IconButton
      disabled={isViewOnly}
      className="sort-icon-button filter-icon-button"
      title="Filter"
      onClick={() => {
        handleFilterClick();
      }}
      icon={
        <img
          height="1.5rem"
          src={
            menuSelected
              ? filterSelectedButton
              : selectedFilter
              ? filterBlackIcon
              : filterIcon
          }
        />
      }
    />
  );
};
