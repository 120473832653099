import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Card from "../../../common/Card/Card";
import { IMAGES } from "../../../constants";
import {
  configs,
  IS_LEAN_SANDBOX,
  LEAN_APP_TOKEN,
} from "../../../constants/configs";
import { PaymentStatus } from "../../../constants/globalConstants";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { formatCurrency } from "../../../modules";
import { usePaymentsContext } from "../contexts/PaymentsContext";

function ReviewAndPay() {
  const {
    bill,
    showDrawer,
    leanCallback,
    customerId,
    paymentSource,
    endUserId,
    selectedRecord,
    searchParams,
    setSearchParams,
    setBill,
  } = usePaymentsContext();

  const { getRecords } = useAuth();

  const [loading, setLoading] = useState(false);

  // paymentSource?.accounts![2].accountNumber

  const initiatePaymentProcess = (
    beneficiaryStatus: any,
    accountId: any,
    amountToPay: any,
    destinationId: any
  ) => {
    if (beneficiaryStatus === "ACTIVE") {
      // create payment intent
      //428337d8-28b0-4ad0-9227-4cfcea6f1df6
      setLoading(true);
      axios
        .post(
          `${configs.urls.BASE_URL}/getPaymentIntent`,
          {
            amount: amountToPay,
            currency: localStorage.getItem("baseCurrency") || "AED",
            destinationId: destinationId,
            customerId: customerId,
          },
          { withCredentials: true }
        )

        .then((response: any) => {
          console.log("getPaymentIntent", response);
          setLoading(false);

          if (response.data.success) {
            const res = response.data.response;

            if (res.data && res.data.status === "INVALID_PARAMETERS") {
              showNotification({
                title: "Error",
                message: res.message,
                color: "red",
                icon: <FontAwesomeIcon icon={faTimes} />,
              });
            } else {
              const paymentIntentId = res.payment_intent_id;
              // @ts-ignore
              Lean.pay({
                app_token: LEAN_APP_TOKEN,
                payment_intent_id: paymentIntentId,
                sandbox: IS_LEAN_SANDBOX,
                end_user_id: endUserId,
                // account_id: accountId,
                // description: selectedRecord.invoiceId,
                callback: (payload: any) => {
                  // fetchPaymentSource();
                  // leanCallback(payload);
                  if (
                    payload.status !== "ERROR" &&
                    payload.status !== "CANCELLED"
                  ) {
                    axios
                      .post(
                        configs.urls.BASE_URL + "/invoice/updatePaymentStatus",
                        {
                          id: selectedRecord.id,
                          status:
                            // amountToPay < 500 || amountToPay > 1000
                            // ? PaymentStatus.Paid
                            PaymentStatus.PendingAuthorization,
                          paymentIntentId: paymentIntentId,
                        },
                        {
                          withCredentials: true,
                        }
                      )
                      .then((res) => {
                        if (res.data.success) {
                          getRecords();
                          showNotification({
                            title: "Success",
                            message: "Payment Queued successfully!",
                            color: "green",
                            icon: <FontAwesomeIcon icon={faCheck} />,
                          });
                          setSearchParams({
                            tab: "queued",
                          });
                        }
                      });
                  } else {
                    leanCallback(payload);
                  }

                  showDrawer(false);
                },
              });
            }
          } else {
            showNotification({
              title: "Error",
              message: response.data.errors,
              color: "red",
              icon: <FontAwesomeIcon icon={faTimes} />,
            });
          }
        })
        .catch((err) => {
          console.log("Error while creating payment intent", err);
          setLoading(false);
        });
    } else {
      // show notification that benf is in COOL_OFF period
      showNotification({
        color: "red",
        title: "Transaction Failed!",
        message: `This beneficiary is in ${beneficiaryStatus} status`,
        icon: <FontAwesomeIcon icon={faTimes} />,
        autoClose: 2000,
      });
    }
  };

  const pay = async () => {
    // const beneficiaryStatus = paymentSource?.beneficiaries?.find(
    //   (b: any) => b.destinationId === destinationId
    // )?.status;
    // const accountId = paymentSource?.accounts![1].accountId;
    const accountId = paymentSource?.accounts.find(
      (a: any) => a.name === "Call Account"
    )?.accountId;

    console.log("accountId", accountId);

    initiatePaymentProcess(
      // paymentSource?.status,
      "ACTIVE",
      accountId,
      parseFloat(selectedRecord?.amountDue || bill?.amountDue),
      selectedRecord.contact.destinationId
    );
  };
  return (
    <Container>
      <Card className="review-pay-card" isNormal noShadow>
        <CardTitle>Bill Details</CardTitle>

        <InputContainer>
          <Label>
            Total amount
            {/* <EditButton>Edit</EditButton> */}
          </Label>
          <Value>
            {localStorage.getItem("baseCurrency") || "AED"}{" "}
            {formatCurrency(Number(selectedRecord?.amountDue), 2)}
          </Value>
        </InputContainer>

        <Subtitle>Pay to</Subtitle>
        <InputContainer>
          <Label>
            Business Name
            {/* <EditButton>Edit</EditButton> */}
          </Label>
          <Value>{selectedRecord?.name}</Value>
        </InputContainer>

        <InputContainer>
          <Label>
            Invoice No.
            {/* <EditButton>Edit</EditButton> */}
          </Label>
          <Value>#{selectedRecord?.invoiceNumber}</Value>
        </InputContainer>

        <Subtitle>Pay from my</Subtitle>
        <ContainerWithImage>
          <Image src={IMAGES.bank_cashflow} />
          <InputContainer>
            <Label>
              Bank Account
              {/* <EditButton>Edit</EditButton> */}
            </Label>
            {/* <Value>Call Account</Value> */}
          </InputContainer>
        </ContainerWithImage>

        <ContainerWithImage>
          <Image src={IMAGES.payment_schedule} />
          <InputContainer>
            <Label>Payment will be deducted on</Label>
            <Value>
              {moment(selectedRecord?.dueDate).format("MMM DD, YYYY")}
            </Value>
          </InputContainer>
        </ContainerWithImage>

        <InputContainer>
          <Label>Transaction Fee</Label>
          <Value style={{ height: 30 }}>
            {localStorage.getItem("baseCurrency") || "AED"} 0.00
          </Value>
          <Label style={{ fontWeight: "500" }}>No additional charges</Label>
        </InputContainer>
      </Card>
      <button
        onClick={() => {
          // showDrawer(false);
          pay();
        }}
        style={{
          backgroundColor: COLORS.greenPrimary,
          color: "white",
          width: "20%",
        }}
        className={`button mt-4 is-bold ${loading && "is-loading"}`}
      >
        {/* {parseFloat(selectedRecord?.amountDue || bill?.amountDue) < 500 || */}
        {/* parseFloat(selectedRecord?.amountDue || bill?.amountDue) > 1000 */}
        Queue Payment
      </button>
    </Container>
  );
}

export default ReviewAndPay;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
  background-color: #fafafa;
`;
const CardTitle = styled.h4`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
    margin-left: 6px;
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin: 8px;
  margin-top: 18px;
  flex: 1;
`;
const Label = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #707070;
`;

const Value = styled.div`
  width: 100%;
  height: 40px;
  font-weight: normal;
  font-size: 24px;
  margin-top: 5px;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #aaa;
  text-transform: uppercase;
  margin: 15px 8px 2px 8px;
`;

const Image = styled.img`
  margin: 10px;
  width: 50px;
  height: 50px;

  @media screen and (max-width: 1600px) {
    width: 43px;
    height: 43px;
  }
`;

const ContainerWithImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
