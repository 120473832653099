import moment from "moment";
import { PaymentStatus } from "../../../../constants/globalConstants";
import { getLatestTransaction } from "../../../../modules/getLatestTransaction";

export const ascendingSortByDueDate = (array: any, field: string) => {
  const getRecTime = (record: any) => {
    return record.status === PaymentStatus.Paid
      ? getLatestTransaction(record?.linkedTransactions)?.paidOrFailedAt
      : record.dueDate;
  };
  return array.sort(
    (a: any, b: any) =>
      new Date(getRecTime(a)).getTime() - new Date(getRecTime(b)).getTime()
  );
};

export const descendingSortByDueDate = (array: any, field: string) => {
  const getRecTime = (record: any) =>
    record.status === PaymentStatus.Paid
      ? moment(
          getLatestTransaction(record?.linkedTransactions)?.paidOrFailedAt
        ).toISOString()
      : moment(record.dueDate).toISOString();
  return array.sort(
    (a: any, b: any) =>
      new Date(getRecTime(b)).getTime() - new Date(getRecTime(a)).getTime()
  );
};
