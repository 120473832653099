import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Map } from "../../../common/Utils/Map";
import { CardTitle } from "../../../common/CardForm/CardForm";
import { CustomSelect } from "../../../common/CustomSelect";
import { COLORS } from "../../../constants/theme";
import { IconButton } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { If } from "../../../common/Utils/If";
import { getAllScenarios } from "../../../repositories/cashflowScenario";
import { useAuth } from "../../../contexts";
import { IContactCategoryMapping } from "../../../constants/types";
import axios from "axios";
import { configs } from "../../../constants";
import { showNotification } from "@mantine/notifications";

interface MapCategoryProps {
  // edit or show only
  mode: "edit" | "view";
  contactTypes?: string[];
  mappings?: IContactCategoryMapping[];
  setMappings?: (mappings: IContactCategoryMapping[]) => void;
  onSave?: (mappings: IContactCategoryMapping[]) => void;
  showTitle?: boolean;
  hasSaveButton?: boolean;
  editableInContactsSection?: boolean;
  toggleView?: (value: boolean) => void;
  contact?: any;
  title?: string;
}

// const mockData: IContactCategoryMapping[] = [
//   {
//     id: "1",
//     contactType: "Vendor",
//     scenario: "Main Scenario",
//     category: "Payable",
//     type: "Payable",
//   },
// ];

const SCENARIOS = ["Main Scenario", "Scenario 1", "Scenario 2"];
const CATEGORIES = [
  { name: "Payable", type: "Payable" },
  { name: "Debt", type: "Payable" },
  { name: "Receivable", type: "Receivable" },
  { name: "Sales", type: "Receivable" },
];
const CONTACT_TYPES = ["Vendor", "Customer", "Employee"];

export const MapCategory: React.FC<MapCategoryProps> = (props) => {
  const {
    mode,
    contactTypes: _contactTypes = [],
    onSave = () => {},
    mappings: _mappings = {} as IContactCategoryMapping[],
    showTitle = true,
    hasSaveButton = false,
    editableInContactsSection = false,
    toggleView = () => {},
    contact,
    title,
    // setMappings = () => {},
  } = props || {};
  const { cashflowBuckets, fetchExternalAccounts, allScenarios } = useAuth();
  const [mappings, setMappings] = React.useState<IContactCategoryMapping[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [categories, setCategories] = React.useState<Record<string, string>[]>(
    []
  );
  const [scenarios, setScenarios] = React.useState<Record<string, any>[]>([]);
  const [contactTypes, setContactTypes] =
    React.useState<string[]>(_contactTypes);
  const [error, setError] = React.useState<Record<number, string> | undefined>(
    undefined
  );

  const onRemoveMapping = () => {
    setMappings(mappings.slice(0, mappings.length - 1));
    setError(undefined);
  };

  const onAddMapping = () => {
    setMappings([
      ...mappings,
      {
        id: Math.random().toString(36).substring(7),
        category: { name: "" },
        scenario: { name: "" },
        type: "",
      } as any,
    ]);
  };

  const onChangeMapping = (
    index: number,
    key: keyof IContactCategoryMapping,
    value: string,
    id?: any
  ) => {
    const newMappings = [...mappings];
    if (key === "scenario" || key === "category") {
      newMappings[index][key]["name"] = value;
      newMappings[index][key]["id"] = id;
      if (key === "category") {
        newMappings[index]["category"]["type"] =
          categories.find((cat) => cat.id === id)?.type || "";
      }
    } else {
      newMappings[index][key] = value;
      if (key === "contactType") {
        newMappings[index]["type"] =
          value === "Vendor" || value === "Employee" ? "Payable" : "Receivable";
      }
    }
    setMappings(newMappings);
  };

  const validateCombination = (
    selectedScenario: string,
    selectedCategory: string,
    selectedContactType: string,
    currentMappingIndex: number
  ): boolean => {
    // Check if the combination exists in the existing mappings within scenario

    const combinationExists = mappings
      .filter((_, index) => index !== currentMappingIndex)
      .some(
        (mapping) =>
          mapping.scenario.name === selectedScenario &&
          mapping.contactType === selectedContactType
      );

    // check if the combination exists

    // Check if the combination is allowed
    const isCombinationAllowed = true;

    // Set the error message if the combination is not allowed or already exists
    if (combinationExists) {
      setError({ [currentMappingIndex]: "Combination already exists" });
      return false;
    } else {
      setError(undefined);
      return true;
    }
  };

  const filterCategoriesByType = useCallback(
    (type: string, scenarioId: string) => {
      if (!type || type === "") return [];

      if (!scenarioId) return [];

      let filteredByScenario =
        scenarios.find((scn) => scn.id == scenarioId)?.categoriesTemplates![0]
          ?.categories || [];

      return filteredByScenario.filter((i: any) =>
        type === "Vendor" || type === "Employee"
          ? i.type === "Payable"
          : i.type === "Receivable"
      );
    },
    [scenarios]
  );

  useEffect(() => {
    if (mode === "view") return;

    setScenarios(allScenarios);
  }, [mode]);

  useEffect(() => {
    if (mode === "view") return;

    if (cashflowBuckets && cashflowBuckets.length) {
      const newCategories = cashflowBuckets.map((item: any) => {
        return { name: item.value, type: item.name, id: item.id };
      });
      setCategories(newCategories);
    }
  }, [cashflowBuckets, mode]);

  useEffect(() => {
    if (_contactTypes && _contactTypes.length) {
      setContactTypes(_contactTypes);
    }
  }, [_contactTypes]);

  useEffect(() => {
    if (_mappings && _mappings.length) {
      setMappings(_mappings);
    }
  }, [_mappings]);

  useEffect(() => {
    if (mode === "view" || _mappings.length) return;

    let mapps = contactTypes.flatMap((item) => {
      // some random id
      let id = Math.random().toString(36).substring(7);
      return scenarios.map((scn) => {
        let filteredCategories = filterCategoriesByType(item, scn?.id);
        let defaultCategory = filteredCategories.find((i: any) => i.isDefault);
        let category = defaultCategory || filteredCategories[0];

        return {
          id,
          contactType: item,
          scenario: {
            id: scn?.id || "",
            name: scn?.name || "",
            categories: scn?.categoriesTemplates![0].categories || [],
          },
          category: {
            name: category?.name || "",
            type: category?.type || "",
            id: category?.id || "",
          },
          type: category?.type || "",
        };
      });
    });

    setMappings(mapps);
  }, [contactTypes, categories, scenarios, mode]);

  const saveContact = () => {
    if (!contact) return alert("No contact found");
    setIsSubmitting(true);
    axios
      .post(
        configs.urls.BASE_URL + "/contact/update/" + contact.id,
        {
          ...contact,
          mappings,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          showNotification({
            color: "teal",
            message: res.data.response,
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
          fetchExternalAccounts();
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        toggleView(false);
      });
  };

  return (
    <Container>
      <If condition={showTitle}>
        <CardTitle className="is-align-self-flex-start">
          {title ? title : "Contact Mapping"}
        </CardTitle>
      </If>

      <Map
        array={mappings}
        render={(item, index) => (
          <div>
            <List key={item.id}>
              <If condition={mode === "edit"}>
                <CustomSelect
                  data={scenarios.map((item) => ({
                    label: item.name,
                    value: item.name,
                    id: item.id,
                  }))}
                  placeholder="Scenario"
                  readOnly={mode === "view"}
                  onSelect={(value) => {
                    validateCombination(
                      value as string,
                      item.category.name,
                      item.contactType,
                      index
                    );
                    onChangeMapping(
                      index,
                      "scenario",
                      value,
                      scenarios.find((scn) => scn.name === value)?.id
                    );
                  }}
                  value={item.scenario.name}
                  label={!index ? "Cashflow Scenario" : undefined}
                />
                <CustomSelect
                  data={contactTypes.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  placeholder="Contact Type"
                  readOnly={mode === "view"}
                  value={item.contactType}
                  onSelect={(value) => {
                    validateCombination(
                      item.scenario.name,
                      item.category.name,
                      value as string,
                      index
                    );
                    onChangeMapping(index, "contactType", value);
                  }}
                  label={!index ? "Contact Type" : undefined}
                />
                <CustomSelect
                  data={filterCategoriesByType(
                    item.contactType,
                    item.scenario.id
                  ).map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    type: item.type,
                    id: item.id,
                  }))}
                  placeholder="Category"
                  readOnly={mode === "view"}
                  onSelect={(value) => {
                    validateCombination(
                      item.scenario.name,
                      value as string,
                      item.contactType,
                      index
                    );
                    onChangeMapping(
                      index,
                      "category",
                      value,
                      categories.find((cat) => cat.name === value)?.id
                    );
                  }}
                  value={item.category.name}
                  label={!index ? "Cashflow Category" : undefined}
                />
              </If>

              <If condition={mode === "view"}>
                <List>
                  <Action>
                    <If condition={index === 0}>
                      <label>Cashflow Scenario</label>
                    </If>
                    <section>{item.scenario.name}</section>
                  </Action>
                  <Action>
                    <If condition={index === 0}>
                      <label>Contact Type</label>
                    </If>
                    <section>{item.contactType}</section>
                  </Action>
                  <Action>
                    <If condition={index === 0}>
                      <label>Cashflow Category</label>
                    </If>
                    <section>{item.category.name}</section>
                  </Action>
                </List>
              </If>

              <If condition={mode === "edit"}>
                <Action>
                  <If condition={index === 0}>
                    <label>Delete</label>
                  </If>
                  <IconButton
                    type="button"
                    disabled={mappings.length <= contactTypes.length}
                    onClick={onRemoveMapping}
                    className="ml-2"
                    icon={<FontAwesomeIcon icon={faTimes} />}
                  />
                </Action>
              </If>
            </List>
            <If condition={error ? error[index] !== undefined : false}>
              <Error>{error && error[index]}</Error>
            </If>
          </div>
        )}
      />

      <If condition={mode === "edit"}>
        <AddButton
          type={"button"}
          disabled={mappings.length >= contactTypes.length * scenarios.length}
          onClick={onAddMapping}
        >
          Map to another scenario
        </AddButton>
      </If>

      <If condition={hasSaveButton}>
        <button
          type="submit"
          disabled={error !== undefined}
          style={{
            backgroundColor: COLORS.greenPrimary,
            color: "white",
            width: "90%",
            alignSelf: "center",
          }}
          onClick={() => onSave(mappings)}
          className={`button mt-4 is-bold mr-2 `}
        >
          {/* {isEditMode() ? "Update" : "Save"} & Continue */}
          Save
        </button>
      </If>

      <If condition={editableInContactsSection}>
        <div style={{ position: "absolute", right: 20, top: 20 }}>
          <If condition={mode === "edit"}>
            <IconButton
              appearance="default"
              onClick={() => toggleView(false)}
              icon={<FontAwesomeIcon icon={faTimes} />}
              style={{ marginRight: 5 }}
              disabled={isSubmitting}
            />
          </If>

          <button
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              if (mode === "view") {
                toggleView(true);
              } else {
                saveContact();
              }
            }}
            className="button is-small"
            style={{
              backgroundColor: "#2C6C76",
              color: COLORS.white,
              borderRadius: 8,
              width: 132,
              height: 36,
            }}
          >
            {mode === "view" ? "Edit" : isSubmitting ? "Saving..." : "Save"}
          </button>
        </div>
      </If>
    </Container>
  );
};

const Container = styled.div`
  margin: 25px 0;
  display: flex;
  flex-direction: column;
`;
const List = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  > div {
    flex: 1;
  }

  /* 4th child flex 0.3 */
  > div:nth-child(4) {
    flex: 0.3;
  }
`;
const AddButton = styled.button`
  background-color: ${COLORS.pale};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 15px;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;

  align-self: center;

  user-select: none;

  :disabled {
    background-color: ${COLORS.veryLightPink};
  }
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;

  > label {
    font-size: 15px;
    margin: 5px;
    margin-bottom: 15px;
    color: #212529;
    /* dont break word */
    white-space: nowrap;
    text-overflow: ellipsis;

    @media screen and (max-width: 1350px) {
      font-size: 14px;
    }
  }

  > section {
    font-size: 16px;
    color: black;
    /* border-bottom: 1px solid black; */
    padding: 5px 5px;
    margin: 5px;
    /* dont break word */
    white-space: nowrap;
    text-overflow: ellipsis;

    /* overflow: hidden; */
    @media screen and (max-width: 1350px) {
      font-size: 14px;
    }
  }
`;

/* 
  const disabledOptions = (
    key: keyof IContactCategoryMapping,
    currentId: string | number
  ): string[] => {
    let mappingsTemp = mappings.filter((i) => i.id != currentId);
    switch (key) {
      case "contactType":
        return mappingsTemp.map((item) => item.contactType);
      case "scenario":
        // disabled those scenarios that are already selected with all of contact types
        return (
          mappingsTemp
            // .filter(
            //   (item) => item.contactType === mappings[currentId].contactType
            // )
            .map((item) => item.scenario)
        );

      case "category":
        return mappingsTemp.map((item) => item.category);
      default:
        return [];
    }
  };
  // disabledOptions={disabledOptions("contactType", item.id)}

*/
