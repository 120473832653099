import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import ManualBill from "./ManualBill";
import { configs, IMAGES } from "../../../constants";
import { COLORS } from "../../../constants/theme";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import AddRecord from "./AddRecord";
import { usePaymentsContext } from "../contexts/PaymentsContext";

function UploadBill() {
  const [files, setFiles] = React.useState<File[]>([]);
  const [showDropzone, setShowDropzone] = React.useState<boolean>(true);
  const { showDrawer } = usePaymentsContext();

  useEffect(() => {
    if (files.length > 0) {
      setShowDropzone(false);
    } else {
      setShowDropzone(true);
    }
  }, [files]);

  const renderNoFileSelectedZone = () => {
    return (
      <NoFileSelectedZone>
        <img src={IMAGES.upload_bill} alt="Upload" style={{ opacity: 0.4 }} />
        <ClickToUpload>Click to upload files</ClickToUpload>
      </NoFileSelectedZone>
    );
  };

  const renderFileSelectedZone = () => {
    return (
      <FileSelectedZone
        style={{ alignItems: files.length === 1 ? "center" : undefined }}
      >
        <ImagesContainer>
          {files.map((file) => (
            <div
              style={{
                margin: "5px",
                position: "relative",
                alignSelf: "center",
                border: "1px solid #ccc",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <img
                src={URL.createObjectURL(file)}
                // width={files.length > 1 ? "100px" : undefined}
                alt="Upload"
              />
              <UnselectImage
                onClick={() =>
                  setFiles((prevFiles: any) =>
                    prevFiles.filter(
                      (prevFile: any) => prevFile.name !== file.name
                    )
                  )
                }
                icon={faTimesCircle}
              />
            </div>
          ))}
        </ImagesContainer>
        <ContainerFooter>
          <FilesUploaded>
            {files.length} {files.length > 1 ? "files" : "file"} uploaded
          </FilesUploaded>
          <FileInput
            onChange={(event: any) => {
              setFiles([...files, ...event.currentTarget.files]);
            }}
            accept={"image/*"}
            className="button link is-text is-small"
            type="file"
            style={{ color: COLORS.greenBlue }}
            name="Click to upload another"
          />
        </ContainerFooter>
      </FileSelectedZone>
    );
  };

  return (
    <Container>
      {showDropzone ? (
        <CustomDropzone
          // disabled={files.length > 0}
          onDrop={(files: any) => {
            console.log("accepted files", files);
            setFiles(files);
          }}
          maxSize={3 * 1024 ** 2}
          accept={IMAGE_MIME_TYPE}
          multiple
        >
          {files.length > 0
            ? renderFileSelectedZone()
            : renderNoFileSelectedZone()}
        </CustomDropzone>
      ) : (
        <DropzoneContainer
          style={{
            justifyContent: files.length === 1 ? "center" : undefined,
          }}
        >
          {files.length > 0
            ? renderFileSelectedZone()
            : renderNoFileSelectedZone()}
        </DropzoneContainer>
      )}

      {/* <ManualBill
        files={files}
        containerStyles={{ margin: 0, width: "48%" }}
        cardStyles={{ width: "auto" }}
      /> */}
      <AddRecord
        recordType={"Payable"}
        containerStyles={{ margin: 0 }}
        cardStyles={{ width: "auto" }}
        onSubmit={() => {
          showDrawer(false);
        }}
      />
    </Container>
  );
}

export default UploadBill;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-top: 30px;

  @media screen and (max-width: 1600px) {
    width: 90%;
  }
`;

const CustomDropzone = styled(Dropzone)`
  margin: 0px;
  padding-bottom: 0px;
  width: 48%;
  height: 100%;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const DropzoneContainer = styled.div`
  margin: 0px;
  padding-left: 10px;
  width: 48%;
  min-height: 100%;
  border-radius: 1%;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px dashed #e0e0e0;
  background-color: white;
`;

const NoFileSelectedZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ClickToUpload = styled.div`
  font-size: 20px;
  color: ${COLORS.greenBlue};
  text-decoration: underline;
  margin-top: 10px;
`;
//////////////////////////////////

const FileSelectedZone = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 97%;
  background-color: white;
`;
const FilesUploaded = styled.div`
  color: ${COLORS.brownishGrey};
  align-self: center;
`;

const UnselectImage = styled(FontAwesomeIcon)`
  color: red;
  font-size: 20px;
  margin-right: 10px;
  position: absolute;
  top: 5px;
  right: -5px;
  z-index: 5;
  cursor: pointer;
  :hover {
    color: #ff000077;
  }
  :active {
    color: #ff000033;
  }
`;

const FileInput = styled.input`
  ::-webkit-file-upload-button {
    visibility: hidden;
  }
  ::before {
    content: "Click to upload another";
    align-self: flex-end;
    font-weight: 700;
    -webkit-user-select: none;
    font-size: 10pt;
    display: flex;
    align-items: flex-end;
  }
  width: 190px;
`;
