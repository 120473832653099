import React, { useEffect } from "react";
import styled from "styled-components";
import { CustomDrawer } from "../CustomDrawer";
import ZeroScreen from "./ModalFrames/ZeroScreen";
import CreateScenario from "./ModalFrames/CreateScenario";

interface AddEditCashflowScenarioProps {
  open: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  step: "ZERO_SCREEN" | "ADD_SCENARIO" | "DUPLICATE_SCENARIO";
}

export default function AddEditCashflowScenario(
  props: AddEditCashflowScenarioProps
) {
  const { open, onClose: _onClose, mode, step: _step } = props;

  const STEPS = {
    ZERO_SCREEN: {
      title: "Cashflow Scenarios",
      component: (
        <ZeroScreen
          setStep={(step: "ADD_SCENARIO" | "DUPLICATE_SCENARIO") => {
            setStep(step);
          }}
        />
      ),
      prevStep: undefined,
    },
    ADD_SCENARIO: {
      title: "Add Cashflow Scenario",
      component: <CreateScenario mode={mode} onSubmit={() => onClose()} />,
      prevStep: "ZERO_SCREEN",
    },
    DUPLICATE_SCENARIO: {
      title: "Duplicate Cashflow Scenario",
      component: <div></div>,
      prevStep: "ZERO_SCREEN",
    },
  };

  const [step, setStep] = React.useState<keyof typeof STEPS>(_step as any);

  const onClose = () => {
    _onClose();

    setTimeout(() => {
      setStep("ZERO_SCREEN");
    }, 1000);
  };

  const onBack = () => {
    if (step === "ZERO_SCREEN") {
      _onClose();
    } else {
      setStep(STEPS[step].prevStep as keyof typeof STEPS);
    }
  };

  return (
    <CustomDrawer
      opened={open}
      onBack={onBack}
      onClose={onClose}
      title={STEPS[step].title}
    >
      <Container>{STEPS[mode === "edit" ? _step : step].component}</Container>
    </CustomDrawer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px 31px 32px;
  width: 100%;
`;
