import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useBanks } from "../hooks";
import { URLSearchParamsInit } from "react-router-dom";

interface Data {
  banks: any[];
  transactions: any[];
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
  selectedBank: any;
  loading: boolean;
  fetchLinkedBanks: () => void;
  uploadModal: boolean;
  showUploadModal: (value: boolean) => void;
  setSelectedBank: (value: any) => void;
  setTransaction: (value: any) => void;
}

export const BanksContext = createContext<Data>({} as Data);

export function useBanksContext() {
  return useContext(BanksContext);
}

export default function BanksProvider(props: PropsWithChildren<any>) {
  const data = useBanks();

  return (
    <BanksContext.Provider value={{ ...data }}>
      {props.children}
    </BanksContext.Provider>
  );
}
