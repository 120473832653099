import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { Fragment } from "react";
import { CardForm } from "../../../common/CardForm";
import { CustomInput } from "../../../common/CustomInput";
import { configs, StyledComponents } from "../../../constants";
import { Contact } from "../../../constants/globalConstants";
import { COLORS } from "../../../constants/theme";
import { useAuth } from "../../../contexts";
import { INITIAL_CONTACT } from "../constants";
import { useContactsContext } from "../contexts/ContactsContext";
import { MapCategory } from "./MapCategory";
import { IContactCategoryMapping } from "../../../constants/types";

function Manually({
  onSubmit,
  contact,
  onNext,
}: {
  onSubmit: any;
  contact: Contact;
  onNext: any;
}) {
  const [continueLater, setContinueLater] = React.useState(false);
  const { isEditMode, onClose } = useContactsContext();
  const { fetchExternalAccounts, getAllContactRecords } = useAuth();
  const [mappings, setMappings] = React.useState<IContactCategoryMapping[]>([]);

  const saveContact = (
    contact: any,
    setSubmitting: Function = () => {},
    mappings: any
  ) => {
    axios
      .post(
        configs.urls.BASE_URL + "/contact/save",
        {
          ...contact,
          mappings,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          showNotification({
            color: "teal",
            message: res.data.response,
            icon: <FontAwesomeIcon icon={faCheck} />,
            autoClose: 2000,
          });
        } else {
          showNotification({
            color: "red",
            title: "Error!",
            message: res.data.errors,
            icon: <FontAwesomeIcon icon={faTimes} />,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        showNotification({
          color: "red",
          message: `${err}`,
          icon: <FontAwesomeIcon icon={faTimes} />,
          autoClose: 2000,
        });
      })
      .finally(() => {
        setSubmitting(false);
        // if (continueLater) {
        fetchExternalAccounts();
        onClose();
        getAllContactRecords();
        // } else onSubmit(contact);
      });
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={contact}
        validate={(values: any) => {
          const errors: any = {};

          if (!values.name) {
            errors.name = "Name Required";
          } else if (!/^[a-zA-Z0-9\s_]*$/g.test(values.name)) {
            errors.name = "Name should be only alphanumeric.";
          }

          if (
            values.email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (
            !values.types.vendor &&
            !values.types.customer &&
            !values.types.employee
          ) {
            errors.types = "At least one type should be selected";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }: any) => {
          setSubmitting(true);

          console.log("mappings", mappings);

          setTimeout(() => {
            // saveContact(values, setSubmitting);
            // alert(JSON.stringify(values, null, 2));
            // saveContact(values, setSubmitting);
          }, 400);
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          handleChange,
          values,
          setValues,
          onSubmit,
          setSubmitting,
        }: any) => (
          <Form>
            <CardForm
              title={"Add new contact"}
              cardStyles={{
                // height: "70vh",
                position: "relative",
                width: "100%",
                // overflowY: "hidden",
              }}
            >
              <CustomInput label={"Contact type"} />
              <div className="flex-direction-row ml-2 is-justify-content-space-between">
                <StyledComponents.CustomCheckbox
                  checked={values.types.vendor}
                  onChange={(event) => {
                    setValues({
                      ...values,
                      types: {
                        ...values.types,
                        vendor: event.currentTarget.checked,
                      },
                    });
                  }}
                  label="Vendor"
                  size="sm"
                  labelPosition="right"
                  color={COLORS.greenBlue}
                />

                <StyledComponents.CustomCheckbox
                  checked={values.types.customer}
                  onChange={(event) => {
                    setValues({
                      ...values,
                      types: {
                        ...values.types,
                        customer: event.currentTarget.checked,
                      },
                    });
                  }}
                  label="Customer"
                  size="sm"
                  labelPosition="right"
                  color={COLORS.greenBlue}
                />

                <StyledComponents.CustomCheckbox
                  checked={values.types.employee}
                  onChange={(event) => {
                    setValues({
                      ...values,
                      types: {
                        ...values.types,
                        employee: event.currentTarget.checked,
                      },
                    });
                  }}
                  label="Employee"
                  size="sm"
                  labelPosition="right"
                  color={COLORS.greenBlue}
                />
              </div>

              <CustomInput name="types" formikErrors={{ errors, touched }} />

              <CustomInput
                label="Full name"
                onChange={handleChange}
                name="name"
                placeholder="e.g. John Doe"
                formikErrors={{ errors, touched }}
                value={values.name}
              />
              <CustomInput
                label="Email address (optional)"
                onChange={handleChange}
                name="email"
                placeholder="e.g. john@gmail.com"
                formikErrors={{ errors, touched }}
                value={values.email}
              />

              <MapCategory
                mode="edit"
                contactTypes={[
                  ...Object.keys(values.types)
                    .filter((key) => values.types[key])
                    .map((key) => key.charAt(0).toUpperCase() + key.slice(1)),
                ]}
                // mappings={mappings}
                // setMappings={setMappings}
                onSave={(mappings: IContactCategoryMapping[]) => {
                  if (_.isEmpty(errors)) {
                    saveContact(values, setSubmitting, mappings);
                  } else {
                  }
                }}
                hasSaveButton={true}
                title="Map Contact to Cashflow Categories"
              />

              <div
                className="flex-direction-column  is-align-items-center"
                style={{
                  color: "white",
                  position: "absolute",
                  bottom: -105,
                  width: "93%",
                }}
              >
                {_.isEqual(values, contact) &&
                !_.isEqual(values, INITIAL_CONTACT) ? (
                  <button
                    type="button"
                    onClick={onNext}
                    style={{
                      backgroundColor: COLORS.greenPrimary,
                      color: "white",
                      width: "70%",
                    }}
                    className={`button mt-4 is-bold mr-2 `}
                  >
                    Close
                  </button>
                ) : (
                  <Fragment>
                    {/* <button
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        backgroundColor: COLORS.greenPrimary,
                        color: "white",
                        width: "90%",
                      }}
                      className={`button mt-4 is-bold mr-2 ${
                        isSubmitting && "is-loading"
                      }`}
                    >
                      Save
                    </button> */}

                    {/* <button
                      type="submit"
                      onClick={() => {
                        if (_.isEmpty(errors)) {
                          setContinueLater(true);
                        } else {
                          setContinueLater(false);
                        }
                      }}
                      style={{
                        width: "60%",
                        fontSize: 12,
                      }}
                      className="button mt-4 is-bold mr-2 is-text"
                    >
                      {isEditMode() ? "Update" : "Save"} & continue later
                    </button> */}
                  </Fragment>
                )}
              </div>

              {/* <div className="flex-direction-row">
        <CustomInput
          label="City"
          onChange={handleChange}
          name="city"
          placeholder="Dubai"
          formikErrors={{ errors, touched }}
        />
        <CustomInput
          label="Country"
          onChange={handleChange}
          name="country"
          placeholder="UAE"
          formikErrors={{ errors, touched }}
        />
      </div>

      <CustomInput
        label="Address"
        onChange={handleChange}
        name="address"
        placeholder="Street, building, floor"
        formikErrors={{ errors, touched }}
      /> */}
            </CardForm>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}

export default Manually;
